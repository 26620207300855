import { useContext, useEffect, useMemo, useState } from 'react';
import PageHeader from 'components/PageHeader';
import Table from 'components/Tables/Table';
import UsersContent from 'components/ModalsContent/UsersContent';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import Loader from 'components/Loader';
import { accessByRole } from 'helpers/roleHelpers';
import { headers } from 'helpers/tableHandler';
import { editSort } from 'helpers/editFormsValues';
import { useDispatch, useSelector } from 'react-redux';
import FiltersModal from 'components/Filters/FiltersModal';
import { filtersAreaHandler } from 'helpers/Filters/filtersAreaHandler';
import { getAllFilteredUsers, getAllUsers } from 'redux/thunks/usersThunks';
import { getAllPositions } from 'redux/thunks/positionsThunks';
import styles from './styles.module.scss';

const AdminPanel = () => {
    const { openModal, closeModal } = useContext(ModalContext);
    const [filters, setFilters] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const [fullName, setFullName] = useState([]);
    const [username, setUsername] = useState([]);
    const [role, setRole] = useState([]);
    const [position, setPosition] = useState([]);

    const dispatch = useDispatch();
    const users = useSelector((state) => state.users);
    const positions = useSelector(
        (state) => state.positions.DropdownPositionsListValues
    );
    const [sort, setSort] = useState({ property: 'fullName', order: 'asc' });

    const currentRole = useSelector((state) => state.auth.userData.userRole);

    useEffect(() => {
        if (accessByRole('AdminPanel', 'getAllUsers', currentRole)) {
            dispatch(getAllUsers(setIsLoading));
            dispatch(getAllPositions());
        }
    }, [dispatch]);

    useEffect(() => {
        if (accessByRole('AdminPanel', 'getAllFilteredUsers', currentRole)) {
            if (!isFirstLoad) {
                dispatch(getAllFilteredUsers(filters, sort, setIsLoading));
            } else {
                setIsFirstLoad(false);
            }
        }
    }, [dispatch, filters, sort]);

    const handleClickFilters = () => {
        setIsOpenFilters(true);
    };

    const dataForFilters = useMemo(
        () => ({
            fullName,
            changeFullName: setFullName,
            fullNameList: users.dropDownValuesFullName,
            username,
            changeUsername: setUsername,
            usernameList: users.dropDownValuesUserName,
            role,
            changeRole: setRole,
            roleList: users.dropDownValuesRole,
            position,
            changePosition: setPosition,
            positionList: positions,
        }),
        [
            fullName,
            users.dropDownValuesFullName,
            users.dropDownValuesUserName,
            users.dropDownValuesRole,
            positions,
            username,
            role,
            position,
        ]
    );

    const filtersForModal = useMemo(
        () => filtersAreaHandler('ADMIN_PANEL', dataForFilters),
        [dataForFilters]
    );

    const handleClickSaveFilters = () => {
        setFilters((prev) => ({
            ...prev,
            fullNames: fullName.map((elem) => elem?.value),
            positions: position.map((elem) => elem?.value),
            usernames: username.map((elem) => elem?.value),
            role: role.map((elem) => elem?.value),
        }));
    };

    const handleClick = (title, children) => {
        openModal({ title, children });
    };

    const onSortHandler = (header) => {
        editSort(header, sort, setSort);
    };

    return (
        <>
            <PageHeader
                title="Панель администратора"
                addButton={accessByRole('AdminPanel', 'addButton', currentRole)}
                addButtonText="Добавить пользователя"
                filtersButton={accessByRole(
                    'AdminPanel',
                    'filtersButton',
                    currentRole
                )}
                handlerClickFiltersButton={handleClickFilters}
                buttonIconName="plus"
                openAddModal={() => {
                    handleClick(
                        'Добавление пользователя',
                        <UsersContent closeModal={closeModal} />
                    );
                }}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <FiltersModal
                        isActive={isOpenFilters}
                        setIsActive={setIsOpenFilters}
                        filters={filtersForModal}
                        onSave={handleClickSaveFilters}
                    />
                    {!users.data?.length ? (
                        <div className={styles.empty_block}>
                            Список пользователей пуст
                        </div>
                    ) : (
                        <Table
                            header={headers.users}
                            items={users.data}
                            caseName="USERS"
                            deleteIcon={accessByRole(
                                'AdminPanel',
                                'deleteIcon',
                                currentRole
                            )}
                            editIcon={accessByRole(
                                'AdminPanel',
                                'editIcon',
                                currentRole
                            )}
                            openEditModal={(initial) => {
                                handleClick(
                                    'Редактирование пользователя',
                                    <UsersContent
                                        closeModal={closeModal}
                                        initialValues={initial}
                                    />
                                );
                            }}
                            openDeleteModal={(initial) => {
                                handleClick(
                                    `Вы действительно хотите удалить пользователя ${initial.items.name}?`,
                                    <DeleteModal
                                        closeModal={closeModal}
                                        data={users.data}
                                        id={initial.id}
                                        tableType="users"
                                    />
                                );
                            }}
                            onSortHandler={onSortHandler}
                            sort={sort}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default AdminPanel;
