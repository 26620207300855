import { func, string } from 'prop-types';
import styles from './styles.module.scss';

const LoadFile = ({
    idFile = '',
    text,
    onLoadFile = () => {},
    formDataKey,
}) => {
    const onChange = (e) => {
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append(formDataKey, e.target.files[0]);
            onLoadFile(formData);
        }
    };
    return (
        <label htmlFor={idFile} className={styles.table__addFileButton}>
            <input
                type="file"
                id={idFile}
                className={styles.table__uploadFIle}
                onChange={onChange}
            />
            <div className={styles.table__addFileButton_text}>{text}</div>
        </label>
    );
};

export default LoadFile;

LoadFile.propTypes = {
    idFile: string,
    text: string,
    onLoadFile: func,
    formDataKey: string,
};
