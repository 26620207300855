import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';

const TableHeader = () => {
    const monthsArr = useSelector((state) => state.reports.monthsArr);

    return (
        <thead className={styles.table__head}>
            <tr>
                <td rowSpan="2">Сотрудник</td>
                <td colSpan="2">Всего</td>
                {monthsArr.map((item) => (
                    <td key={item.value} colSpan="2">
                        {item.value}
                    </td>
                ))}
            </tr>
            <tr>
                <td>План</td>
                <td>Факт</td>
                {monthsArr.map((item) => (
                    <Fragment key={item.value}>
                        <td>План</td>
                        <td>Факт</td>
                    </Fragment>
                ))}
            </tr>
        </thead>
    );
};

export default TableHeader;
