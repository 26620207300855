import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useValidator from 'hooks/useValidator';
import { bool, func, shape, string } from 'prop-types';
import DropdownList from 'components/DropdownList';
import Input from 'components/Input';
import Button from 'components/Button';
import { getFilteredPersonsForDropDown } from 'redux/thunks/personThunks';
import { addBaseTariff, editBaseTariff } from 'redux/thunks/baseTariffsThunks';
import styles from './styles.module.scss';

const BaseTariffRateContent = ({
    initialValues = {},
    isLoading = false,
    closeModal = () => {},
    setIsLoading = () => {},
}) => {
    const dispatch = useDispatch();
    const { id, personId, items = [] } = initialValues;
    const { personFullName, tariffRateHour } = items;

    const [selectedPerson, setSelectedPerson] = useState({
        id: personId,
        value: personFullName,
    });
    const [ratePerHourValue, setRatePerHourValue] = useState(tariffRateHour);
    const [submitTouched, setSubmitTouched] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const personsList = useSelector(
        (state) => state.persons.DropdownListValuesFiltered
    );

    useEffect(() => {
        dispatch(getFilteredPersonsForDropDown({ isActive: true }));
    }, []);

    const onSave = () => {
        if (isValid) {
            if (id) {
                dispatch(
                    editBaseTariff(
                        id,
                        selectedPerson,
                        ratePerHourValue,
                        closeModal,
                        setIsLoading
                    )
                );
            } else {
                dispatch(
                    addBaseTariff(
                        selectedPerson,
                        ratePerHourValue,
                        closeModal,
                        setIsLoading
                    )
                );
            }
        }
        setSubmitTouched(true);
    };

    const { isValid, validator } = useValidator({
        selectedPerson: selectedPerson?.value || selectedPerson,
        ratePerHourValue,
    });

    return (
        <div className={styles.speciality_content}>
            {!id && (
                <DropdownList
                    selected={selectedPerson}
                    items={personsList}
                    setSelected={setSelectedPerson}
                    handelOnOpenChange={setIsOpen}
                    placeholderInput="Сотрудник"
                    error={!validator.selectedPerson && submitTouched}
                />
            )}
            {id && (
                <Input
                    disabled
                    placeholder="Сотрудник"
                    value={selectedPerson.value}
                />
            )}
            <Input
                onChange={setRatePerHourValue}
                placeholder=" Тарифная ставка в час, руб."
                symbolLimit={4}
                type="number"
                value={ratePerHourValue}
                error={!validator.ratePerHourValue && submitTouched}
            />
            <Input
                disabled
                placeholder="Тарифная ставка в день, руб."
                symbolLimit={5}
                type="number"
                value={ratePerHourValue && ratePerHourValue * 8}
            />
            <div className={styles.buttons_container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    disabled={isOpen}
                    onClick={closeModal}
                />
                <Button
                    text="Сохранить"
                    disabled={isOpen || isLoading}
                    onClick={onSave}
                />
            </div>
        </div>
    );
};

BaseTariffRateContent.propTypes = {
    initialValues: shape({ string }),
    isLoading: bool,
    closeModal: func,
    setIsLoading: func,
};
export default BaseTariffRateContent;
