import {
    GET_PROJECT_TEAMS_DATA,
    DELETE_PROJECT_TEAMS_DATA,
    ADD_PROJECT_TEAM_DATA,
    EDIT_PROJECT_TEAM_DATA,
} from 'redux/types';

const defaultState = {
    data: [],
};

export const projectTeams = (state = defaultState, action) => {
    switch (action.type) {
        case GET_PROJECT_TEAMS_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case DELETE_PROJECT_TEAMS_DATA:
            return {
                ...state,
                data: state.data.filter(
                    (item) => item.teamId !== action.payload
                ),
            };
        case ADD_PROJECT_TEAM_DATA:
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case EDIT_PROJECT_TEAM_DATA:
            return {
                ...state,
                data: [
                    ...state.data.map((item) => {
                        if (item.teamId === action.payload.teamId) {
                            return {
                                ...item,
                                persons: [
                                    ...item.persons.map((person) => {
                                        if (
                                            person.personId ===
                                            action.payload
                                                .teamPeriodCompactResponseDto
                                                .personId
                                        ) {
                                            return {
                                                ...person,
                                                periods: [
                                                    ...person.periods.map(
                                                        (period) => {
                                                            if (
                                                                period.teamPeriodId ===
                                                                action.payload
                                                                    .id
                                                            ) {
                                                                return {
                                                                    ...period,
                                                                    yearPeriod:
                                                                        action
                                                                            .payload
                                                                            .teamPeriodCompactResponseDto
                                                                            .year,
                                                                    monthPeriod:
                                                                        action
                                                                            .payload
                                                                            .teamPeriodCompactResponseDto
                                                                            .month,
                                                                    personPlannedHours:
                                                                        action
                                                                            .payload
                                                                            .teamPeriodCompactResponseDto
                                                                            .personPlannedHours,
                                                                    tariffRateHour:
                                                                        action
                                                                            .payload
                                                                            .teamPeriodCompactResponseDto
                                                                            .tariffRateHour,
                                                                    tariffRateDay:
                                                                        action
                                                                            .payload
                                                                            .teamPeriodCompactResponseDto
                                                                            .tariffRateDay,
                                                                    competenceLevel:
                                                                        {
                                                                            ...action
                                                                                .payload
                                                                                .teamPeriodCompactResponseDto
                                                                                .personCompetenceLevel,
                                                                        },
                                                                };
                                                            }
                                                            return period;
                                                        }
                                                    ),
                                                ],
                                            };
                                        }
                                        return person;
                                    }),
                                ],
                            };
                        }
                        return item;
                    }),
                ],
            };
        default:
            return state;
    }
};
