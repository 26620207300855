import { bool, func, string } from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Reports,
    Groups,
    Projects,
    Teams,
    Plans,
    Admin,
    Calendar,
    ModulesIcon,
    Workers,
    Specialties,
    CVIcon,
    Positions,
    BaseTariffRatesIcon,
    Organizations,
    ProfileIcon,
} from 'components/svg/SidebarIcon/sidebarIcons';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const Icon = ({ path, setActive, active, text, isOpen }) => {
    const cx = classNames.bind(styles);

    const getFillSvg = (p) => `${active}` === p;

    const components = {
        '/directories/workers': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/directories/workers'),
                })}
            >
                <Workers />
            </div>
        ),
        '/directories/persons-cv': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/directories/persons-cv'),
                })}
            >
                <CVIcon />
            </div>
        ),
        '/directories/specialties': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/directories/specialties'),
                })}
            >
                <Specialties />
            </div>
        ),
        '/directories/positions': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/directories/positions'),
                })}
            >
                <Positions />
            </div>
        ),
        '/directories/base-tariff-rates': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/directories/base-tariff-rates'),
                })}
            >
                <BaseTariffRatesIcon />
            </div>
        ),
        '/directories/organizations': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/directories/organizations'),
                })}
            >
                <Organizations />
            </div>
        ),
        '/teams': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/teams'),
                })}
            >
                <Teams />
            </div>
        ),
        '/projects': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/projects'),
                })}
            >
                <Projects />
            </div>
        ),

        '/reports': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/reports'),
                })}
            >
                <Reports />
            </div>
        ),
        '/production-plans': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/production-plans'),
                })}
            >
                <Plans />
            </div>
        ),
        '/directories': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/directories'),
                })}
            >
                <Groups />
            </div>
        ),

        '/admin-panel': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/admin-panel'),
                })}
            >
                <Admin />
            </div>
        ),
        '/user': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/profile'),
                })}
            >
                <ProfileIcon />
            </div>
        ),
        '/calendar': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/calendar'),
                })}
            >
                <Calendar />
            </div>
        ),
        '/modules': (
            <div
                className={cx('svg_fill', {
                    active_fill: getFillSvg('/modules'),
                })}
            >
                <ModulesIcon />
            </div>
        ),
    };

    return (
        <div
            className={styles.sidebar_icon}
            data-tooltip-content={text}
            data-tooltip-id="directories"
            data-tooltip-place="right"
            data-tooltip-hidden={isOpen}
            data-tooltip-delay-show={500}
            onClick={() => setActive(path)}
        >
            <Link to={path}>
                {components[path]}
                {isOpen ? (
                    <span
                        className={`${styles.sidebar__link_text} ${
                            active && active.includes(path)
                                ? styles.sidebar__link_text_active
                                : ''
                        }`}
                    >
                        {text}
                    </span>
                ) : null}
            </Link>
        </div>
    );
};
export default Icon;

Icon.propTypes = {
    path: string,
    setActive: func,
    active: string,
    text: string,
    isOpen: bool,
};
