import { useMemo, useState } from 'react';
import { bool, func, number, oneOfType, string } from 'prop-types';
import ComponentTitle from 'components/ComponentTitle';
import classNames from 'classnames/bind';
import { getFio } from 'helpers/editFormsValues';
import SearchIcon from 'components/svg/ButtonIcon/search';
import errorIcon from 'icons/error.svg';
import passwordVisibility from 'icons/passwordVisibility.svg';
import passwordVisibilityOff from 'icons/passwordVisibilityOff.svg';
import styles from './styles.module.scss';

const onChangeWithType = {
    number: (onChange, value) => {
        const numberValue = Number(value) >= 0 && Number(value);
        if (numberValue || numberValue === 0) onChange(numberValue);
        if (value === '') onChange('');
    },
    float: (onChange, value, symbolLimit) => {
        const regExpFloat = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;
        if (value.match(regExpFloat)) {
            onChange(value);
        }
    },
    budget: (onChange, value) => {
        const numberValue =
            Number(value) > 0
                ? Number(value.replace(/\ /g, ''))
                : Number(Math.abs(value.replace(/\ /g, '')));
        if (numberValue || numberValue === 0)
            onChange(
                numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            );
        if (value === '') onChange('');
    },
    date: (onChange, value) => {
        const numberValue = value.replace(/[^.\d]/g, '');
        if (numberValue) onChange(numberValue);
        if (value === '') onChange('');
    },
    deviation: (onChange, value) => {
        const numberValue = value === '-' ? '-' : Math.trunc(value);
        if (numberValue || numberValue === 0) onChange(numberValue);
        if (value === '') onChange('');
    },
    workload: (onChange, value) => {
        const reg = /[^0-9]/gi;
        let numberValue = value.replace(reg, '');

        if (numberValue.length > 1) {
            numberValue = `${numberValue[0]}.${numberValue.slice(1, 3)}`;
        }

        if (numberValue[3] === '0') {
            numberValue = numberValue.slice(0, 3);
        }

        if (numberValue >= 1) numberValue = 1;

        if (numberValue || numberValue === 0) onChange(numberValue);
        if (value === '') onChange('');
    },
    text: (onChange, value) => {
        onChange(value);
    },
    nameOrSurname: (onChange, value) => {
        onChange(getFio(value, true));
    },
    nameSurname: (onChange, value) => {
        onChange(getFio(value, false));
    },
    email: (
        onChange,
        value,
        setInputErrorMessage,
        setInputError,
        changeEmailInputError
    ) => {
        let emailUsername;
        let emailDomainname;
        let errorMessage = '';
        setInputError(false);
        changeEmailInputError(false);

        if (value.includes('@')) {
            emailUsername = value.split('@').shift();
            emailDomainname = value.split('@').pop();
        } else {
            setInputErrorMessage((errorMessage += 'Отсутствует символ @. \n'));
            setInputError(true);
            changeEmailInputError(true);
        }

        if (!emailUsername) {
            setInputErrorMessage(
                (errorMessage += 'Отсутствует имя пользователя. \n')
            );
            setInputError(true);
            changeEmailInputError(true);
        }

        if (emailUsername && emailUsername.includes(' ')) {
            setInputErrorMessage(
                (errorMessage +=
                    'Присутствуют пробелы в имени пользователя. \n')
            );
            setInputError(true);
            changeEmailInputError(true);
        }

        if (!emailDomainname) {
            setInputErrorMessage(
                (errorMessage += 'Отсутствует доменная часть. \n')
            );
            setInputError(true);
            changeEmailInputError(true);
        }

        if (emailDomainname && !emailDomainname.includes('.')) {
            setInputErrorMessage(
                (errorMessage += 'Отсутствуют точки в доменной части. \n')
            );
            setInputError(true);
            changeEmailInputError(true);
        }

        if (emailDomainname && emailDomainname.includes(' ')) {
            setInputErrorMessage(
                (errorMessage += 'Присутствуют пробелы в доменной части. \n')
            );
            setInputError(true);
            changeEmailInputError(true);
        }

        if (emailDomainname && emailDomainname.length === 1) {
            setInputErrorMessage(
                (errorMessage += 'Доменная часть слишком короткая. \n')
            );
            setInputError(true);
            changeEmailInputError(true);
        }

        if (value.length > 100) {
            setInputErrorMessage(
                (errorMessage += 'Превышение длины email (>100 символов). \n')
            );
            setInputError(true);
            changeEmailInputError(true);
        }

        setInputErrorMessage(errorMessage);
        onChange(value);
    },
};

const Input = ({
    disabled = false,
    onChange = () => {},
    title = '',
    symbolLimit = 99,
    type = 'text',
    value = '',
    error = false,
    success = false,
    isTableCell = false,
    isSelectSearchField = false,
    isSearch = false,
    noMargin = false,
    placeholder = '',
    password = false,
    bigInput = false,
    onBlur = () => {},
    changeEmailInputError = () => {},
}) => {
    const cx = classNames.bind(styles);
    const [isShowPass, setIsShowPass] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [inputErrorMessage, setInputErrorMessage] = useState(' ');

    const handleChange = (e) => {
        const input = e.target.value;
        const newValue =
            symbolLimit >= input.toString().length
                ? input
                : input.slice(0, symbolLimit);
        onChangeWithType[type](
            onChange,
            newValue,
            setInputErrorMessage,
            setInputError,
            changeEmailInputError,
            symbolLimit
        );
    };

    const typeInput = useMemo(() => {
        if (password) {
            if (!isShowPass) {
                return 'password';
            }
            return 'text';
        }
        return 'text';
    }, [isShowPass, password]);

    return (
        <div
            className={cx('input_container', {
                input_container_cell: isTableCell,
                input_container_select: isSelectSearchField,
                input_container_table_search: isSearch,
            })}
        >
            {title && (
                <ComponentTitle title={title} error={error} success={success} />
            )}
            <div className={cx('input_block')}>
                <input
                    className={cx('input', {
                        error: error || inputError,
                        success,
                        isTableCell,
                        isSelectSearchField,
                        isSearch,
                        noMargin,
                        disabled,
                        bigInput,
                    })}
                    onBlur={onBlur}
                    tabIndex={0}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={value ?? ''}
                    type={typeInput}
                />
                {error || inputError ? (
                    <div className={cx('error_icon')}>
                        <img src={errorIcon} alt="error" />
                    </div>
                ) : null}
                <div className={cx('error_message')}>{inputErrorMessage}</div>
                {password && isShowPass === false && (
                    <div className={cx('password_visibility_icon')}>
                        <img
                            src={passwordVisibility}
                            alt="error"
                            onClick={() => {
                                setIsShowPass(true);
                            }}
                        />
                    </div>
                )}
                {password && isShowPass === true && (
                    <div className={cx('password_visibility_icon')}>
                        <img
                            src={passwordVisibilityOff}
                            alt="error"
                            onClick={() => {
                                setIsShowPass(false);
                            }}
                        />
                    </div>
                )}
                {isSearch && !value ? (
                    <div className={cx('search')}>
                        <SearchIcon />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

Input.propTypes = {
    disabled: bool,
    onChange: func,
    title: string,
    placeholder: string,
    symbolLimit: number,
    type: string,
    value: oneOfType([number, string]),
    error: bool,
    success: bool,
    isTableCell: bool,
    isSelectSearchField: bool,
    isSearch: bool,
    noMargin: bool,
    password: bool,
    bigInput: bool,
    onBlur: func,
    changeEmailInputError: func,
};
export default Input;
