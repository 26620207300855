import { arrayOf, func, shape } from 'prop-types';
import { months, differenceInDate, shortDate } from 'helpers/tableHandler';
import DeleteIcon from 'components/svg/deleteIcon';
import styles from './styles.module.scss';

const ModalTable = ({ personsList = [], deleteItemFromTable = () => {} }) => (
    <table className={styles.table}>
        <thead>
            <tr className={styles.table__header_row}>
                <th className={styles.table__header_cell}>Сотрудник</th>
                <th className={styles.table__header_cell}>
                    Уровень компетенции
                </th>
                <th className={styles.table__header_cell}>Организация</th>
                <th className={styles.table__header_cell}>Период</th>
                <th className={styles.table__header_cell}>
                    План. часы, месяц/период
                </th>
                <th className={styles.table__header_cell}>
                    Тариф. ставка, час/день
                </th>
                <th className={styles.table__header_cell}> </th>
            </tr>
        </thead>
        <tbody>
            {personsList.map((item, index) => (
                <tr key={index} className={styles.table__body_row}>
                    <td className={styles.table__body_cell}>
                        {item.person.value}
                    </td>
                    <td className={styles.table__body_cell}>
                        {item.selectedCompetence.value}
                    </td>
                    <td className={styles.table__body_cell}>
                        {item.organization.value}
                    </td>
                    <td className={styles.table__body_cell}>
                        {months[item.selectedStartPeriod.getMonth()]}{' '}
                        {item.selectedStartPeriod.getFullYear()} -{' '}
                        {months[item.selectedEndPeriod.getMonth()]}{' '}
                        {item.selectedEndPeriod.getFullYear()}
                    </td>
                    <td className={styles.table__body_cell}>
                        {item.personPlannedHours
                            ? `${item.personPlannedHours} / ${
                                  item.personPlannedHours *
                                  (differenceInDate(
                                      item.selectedStartPeriod,
                                      item.selectedEndPeriod
                                  ) +
                                      1)
                              }`
                            : 'По произв. календарю'}
                    </td>
                    <td className={styles.table__body_cell}>
                        {item.tariffRateHour} / {item.tariffRateHour * 8}
                    </td>
                    <td className={styles.table__body_cell}>
                        <button
                            type="button"
                            onClick={deleteItemFromTable.bind(null, index)}
                        >
                            <DeleteIcon />
                        </button>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default ModalTable;

ModalTable.propTypes = {
    personsList: arrayOf(shape({})),
    deleteItemFromTable: func,
};
