export const arrowsIcons = [
    'M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z',
    'M12 8L6 14L7.41 15.41L12 10.83L16.59 15.41L18 14L12 8Z',
];
export const ArrowUp = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_3457_18986)">
            <path
                d="M12 8L6 14L7.41 15.41L12 10.83L16.59 15.41L18 14L12 8Z"
                fill="#696C80"
            />
        </g>
        <defs>
            <clipPath id="clip0_3457_18986">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export const ArrowDown = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_3457_18985)">
            <path
                d="M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z"
                fill="#696C80"
            />
        </g>
        <defs>
            <clipPath id="clip0_3457_18985">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
