import { useState } from 'react';
import { func, number, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import useValidator from 'hooks/useValidator';
import Input from 'components/Input';
import Button from 'components/Button';
import {
    editOrganization,
    addOrganization,
} from 'redux/thunks/organizationsThunks';
import styles from 'components/ModalsContent/OrganisationContent/styles.module.scss';

const OrganisationContent = ({ closeModal = () => {}, initialValues = {} }) => {
    const { id, name = '' } = initialValues;
    const [organisationValue, setOrganisationValue] = useState(name);
    const [submitTouched, setSubmitTouched] = useState(false);
    const dispatch = useDispatch();

    const onSave = () => {
        if (isValid) {
            if (id) {
                dispatch(editOrganization(id, organisationValue, closeModal));
            } else {
                dispatch(addOrganization(organisationValue, closeModal));
            }
        }
        setSubmitTouched(true);
    };

    const { isValid, validator } = useValidator({
        organisationValue,
    });

    return (
        <div className={styles.organisation_content}>
            <Input
                value={organisationValue}
                onChange={setOrganisationValue}
                placeholder="Наименование организации"
                error={!validator.organisationValue && submitTouched}
            />
            <div className={styles.buttons_container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                />
                <Button text="Сохранить" onClick={onSave} />
            </div>
        </div>
    );
};

OrganisationContent.propTypes = {
    closeModal: func,
    initialValues: shape({
        id: number,
        name: string,
    }),
};
export default OrganisationContent;
