import {
    GET_REPORTS_PROJECTS_DATA,
    GET_REPORTS_PERSONS_DATA,
    GET_REPORTS_PERSONS_WORKLOAD_DATA,
    GET_REPORTS_PROJECT_WORKLOAD_DATA,
    GET_REPORTS_PROJECT_TYPE_DATA,
    GET_REPORTS_COMPETENCE_LEVEL_DATA,
    GET_REPORTS_MANAGER_KPI_DATA,
    GET_PROJECTS_QUARTERS_DATA,
    GET_UNUSED_PERSONS_REPORT_DATA,
    GET_PERSONS_EXPERIENCE_REPORT_DATA,
    GET_BALANCE_BUDGETS_REPORT_DATA,
    SET_MONTH_FROM,
    SET_MONTH_TO,
    GET_TEAM_PERIOD_PERSONS_DATA,
} from 'redux/types';
import { monthsValues } from 'helpers/tableHandler';

const defaultState = {
    projects: {},
    persons: {},
    dropDownPersonValues: [],
    personsWorkload: null,
    projectWorkload: null,
    managerKpi: null,
    projectsQuarter: null,
    projectTypes: null,
    competenceLevel: null,
    projectTypesOfQuarter: null,
    unusedPersons: null,
    personsExperience: [],
    balanceBudgets: [],
    monthFrom: 0,
    monthTo: 11,
    monthsArr: monthsValues,
};

export const reports = (state = defaultState, action) => {
    switch (action.type) {
        case GET_REPORTS_PROJECTS_DATA:
            return {
                ...state,
                projects: action.payload,
            };
        case GET_TEAM_PERIOD_PERSONS_DATA:
            return {
                ...state,
                persons: action.payload,
                dropDownPersonValues: action.payload.map((elem) => ({
                    id: elem.personId,
                    value: elem.personFullName,
                })),
            };
        case GET_REPORTS_PERSONS_DATA:
            return {
                ...state,
                persons: action.payload,
            };
        case GET_REPORTS_PERSONS_WORKLOAD_DATA:
            return {
                ...state,
                personsWorkload: action.payload,
            };
        case GET_REPORTS_PROJECT_WORKLOAD_DATA:
            return {
                ...state,
                projectWorkload: action.payload,
            };
        case GET_REPORTS_PROJECT_TYPE_DATA:
            return {
                ...state,
                projectTypes: action.payload,
            };
        case GET_REPORTS_MANAGER_KPI_DATA:
            return {
                ...state,
                managerKpi: action.payload,
            };
        case GET_REPORTS_COMPETENCE_LEVEL_DATA:
            return {
                ...state,
                competenceLevel: action.payload,
            };
        case GET_PROJECTS_QUARTERS_DATA:
            return {
                ...state,
                projectsQuarter: action.payload,
            };
        case GET_UNUSED_PERSONS_REPORT_DATA:
            return {
                ...state,
                unusedPersons: action.payload,
            };
        case GET_PERSONS_EXPERIENCE_REPORT_DATA:
            return {
                ...state,
                personsExperience: action.payload,
            };
        case GET_BALANCE_BUDGETS_REPORT_DATA:
            return {
                ...state,
                balanceBudgets: action.payload.map((item) => ({
                    balanceDeviation: item.balanceDeviation,
                    balanceDeviationToMonth: item.balanceDeviationToMonth,
                    budgetDeviationFact: item.budgetDeviationFact,
                    budgetDeviationPlan: item.budgetDeviationPlan,
                    initialBudget: item.initialBudget,
                    month: item.month,
                    sumFact: item.sumFact,
                    sumFactMonth: item.sumFactMonth,
                    sumFactMonthFromMonth: item.sumFactMonthFromMonth,
                    sumFactMonthToMonth: item.sumFactMonthToMonth,
                    sumPlan: item.sumPlan,
                    sumPlanMonth: item.sumPlanMonth,
                    sumPlanMonthFromMonth: item.sumPlanMonthFromMonth,
                    sumPlanMonthToMonth: item.sumPlanMonthToMonth,
                    year: item.year,
                    projectName: item.project.name,
                    id: item.project.id,
                })),
            };
        case SET_MONTH_FROM:
            return {
                ...state,
                monthFrom: action.payload,
                monthsArr: monthsValues.filter(
                    (el) => el.id >= action.payload && el.id <= state.monthTo
                ),
            };
        case SET_MONTH_TO:
            return {
                ...state,
                monthTo: action.payload,
                monthsArr: monthsValues.filter(
                    (el) => el.id >= state.monthFrom && el.id <= action.payload
                ),
            };
        default:
            return state;
    }
};
