import { useGoogleLogout } from 'react-google-login';
import { logout } from 'redux/actions/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetDataPersonCv } from 'redux/actions/personsCv';

const useLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        dispatch(resetDataPersonCv());
        const userTheme = localStorage.getItem('app-theme');
        localStorage.clear();
        localStorage.setItem('app-theme', userTheme);
        navigate('/');
    };

    const signOut = () => {
        dispatch(logout());
        dispatch(resetDataPersonCv());
        const userTheme = localStorage.getItem('app-theme');
        localStorage.clear();
        localStorage.setItem('app-theme', userTheme);
        navigate('/');
    };

    return { signOut, handleLogout };
};

export default useLogout;
