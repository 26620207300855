import {
    GET_ALL_UNUSED_PERSONS,
    DELETE_UNUSED_PERSON,
    EDIT_UNUSED_PERSON,
    CLEAR_UNUSED_PERSONS,
    SET_SIZE_UNUSED_PERSONS_TABLES,
} from 'redux/types';

const defaultState = {
    data: [],
    toggleSize: { id: 1, value: 'open' },
};

export const unusedPersons = (state = defaultState, action) => {
    switch (action.type) {
        case GET_ALL_UNUSED_PERSONS:
            return {
                ...state,
                data: action.payload,
                toggleSize: action.payload.length
                    ? { id: 1, value: 'open' }
                    : { id: 0, value: 'close' },
            };
        case DELETE_UNUSED_PERSON:
            return {
                ...state,
                data: state.data.filter((item) => item.id !== action.payload),
            };
        case EDIT_UNUSED_PERSON:
            return {
                ...state,
                data: [
                    ...state.data.filter(
                        (item) => item.id !== action.payload.id
                    ),
                    action.payload,
                ],
            };
        case SET_SIZE_UNUSED_PERSONS_TABLES:
            return {
                ...state,
                toggleSize: action.payload,
            };
        case CLEAR_UNUSED_PERSONS:
            return defaultState;
        default:
            return state;
    }
};
