import {
    GET_CALENDAR,
    SET_CURRENT_DATE_CALENDAR,
    CLEAR_CALENDAR,
} from 'redux/types';
import { getCalendarContent } from 'helpers/editFormsValues';

const defaultState = {
    data: {
        data: null,
        currentDate: new Date(),
    },
};

export const calendar = (state = defaultState, action) => {
    switch (action.type) {
        case GET_CALENDAR:
            return {
                ...state,
                data: getCalendarContent(action.payload),
            };
        case SET_CURRENT_DATE_CALENDAR:
            return {
                ...state,
                currentDate: action.payload,
            };
        case CLEAR_CALENDAR:
            return {
                ...state,
                data: null,
            };
        default:
            return state;
    }
};
