import { bool, func, string } from 'prop-types';
import SortButton from 'components/SortButton';
import Input from 'components/Input';
import styles from './styles.module.scss';

const ShortTableHeader = ({
    header = '',
    tableIcons = false,
    onSortHandler = () => {},
    sortShort = '',
    searchValue = '',
    setSearchValue = () => {},
    withSearch = false,
}) => (
    <thead className={styles.table__header}>
        <tr>
            <td>
                <div className={styles.table__header_cell_text}>
                    <span>{header}</span>
                    <SortButton
                        onSortHandler={onSortHandler}
                        sortShort={sortShort}
                    />
                    {withSearch ? (
                        <div className={styles.table__header_cell_input}>
                            <Input
                                value={searchValue}
                                onChange={setSearchValue}
                                type="text"
                                isTableSearch
                            />
                        </div>
                    ) : null}
                </div>
            </td>
            {tableIcons && <td />}
        </tr>
    </thead>
);

export default ShortTableHeader;

ShortTableHeader.propTypes = {
    header: string,
    tableIcons: bool,
    onSortHandler: func,
    sortShort: string,
    searchValue: string,
    setSearchValue: func,
    withSearch: bool,
};
