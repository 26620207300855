import API from 'api/api';
import { logout } from 'redux/actions/auth';
import { getCompetencesData } from 'redux/actions/specialties';
import { useToastify } from 'helpers/toastify/useToastify';

export const getAllCompetences = () => (dispatch) => {
    API.competence
        .getAllCompetences()
        .then((res) => dispatch(getCompetencesData(res.data.content)))
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify('Ошибка при загрузки компетенций', 'error');
        });
};
