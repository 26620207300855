import { useContext, useEffect, useState } from 'react';
import PageHeader from 'components/PageHeader';
import OrganisationContent from 'components/ModalsContent/OrganisationContent';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import ShortTable from 'components/Tables/ShortTable';
import Loader from 'components/Loader';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrganizations } from 'redux/thunks/organizationsThunks';
import Input from 'components/Input';
import { accessByRole } from 'helpers/roleHelpers';
import styles from './styles.module.scss';

const Organizations = () => {
    const { openModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [sort, setSort] = useState('asc');
    const [organizations, setOrganizations] = useState([]);

    const currentRole = useSelector((state) => state.auth.userData.userRole);

    const dispatch = useDispatch();
    const data = useSelector((state) => state.organizations.data);

    useEffect(() => {
        if (accessByRole('Organizations', 'getAllOrganizations', currentRole)) {
            dispatch(
                getAllOrganizations(
                    {},
                    {
                        property: 'name',
                        order: `${sort}`,
                    },
                    setIsLoading
                )
            );
        }
    }, [dispatch, sort]);

    useEffect(() => {
        setOrganizations(
            data.filter((elem) => elem.name.includes(searchValue))
        );
    }, [data, searchValue]);

    const handleClick = (title, children) => {
        openModal({ title, children });
    };

    const onSortHandler = () => {
        if (sort === 'asc') setSort('desc');
        else setSort('asc');
    };

    return (
        <>
            <PageHeader
                title="Справочник организаций"
                addButton={accessByRole(
                    'Organizations',
                    'addButton',
                    currentRole
                )}
                addButtonText="Добавить организацию"
                openAddModal={() => {
                    handleClick(
                        'Добавление организации',
                        <OrganisationContent closeModal={closeModal} />
                    );
                }}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.search_container}>
                        <Input
                            placeholder="Название организации"
                            isSearch
                            value={searchValue}
                            onChange={setSearchValue}
                            type="text"
                        />
                    </div>
                    <ShortTable
                        items={organizations}
                        header="Организации"
                        deleteIcon={accessByRole(
                            'Organizations',
                            'deleteIcon',
                            currentRole
                        )}
                        editIcon={accessByRole(
                            'Organizations',
                            'editIcon',
                            currentRole
                        )}
                        openEditModal={(initial) => {
                            handleClick(
                                'Редактирование организации',
                                <OrganisationContent
                                    closeModal={closeModal}
                                    initialValues={initial}
                                />
                            );
                        }}
                        openDeleteModal={(initial) => {
                            handleClick(
                                `Вы действительно хотите удалить организацию ${initial.name}?`,
                                <DeleteModal
                                    closeModal={closeModal}
                                    data={data}
                                    id={initial.id}
                                    tableType="organizations"
                                />
                            );
                        }}
                        onSortHandler={onSortHandler}
                        sortShort={sort}
                    />
                </>
            )}
        </>
    );
};

export default Organizations;
