import Button from 'components/Button';
import useLogout from 'hooks/useLogout';
import { func } from 'prop-types';
import styles from './styles.module.scss';

const ExitModal = ({ closeModal = () => {} }) => {
    const { signOut } = useLogout();

    return (
        <div className={styles.modal__row}>
            <Button onClick={signOut} text="Выйти" />
            <Button onClick={closeModal} text="Отмена" />
        </div>
    );
};

ExitModal.propTypes = {
    closeModal: func,
};

export default ExitModal;
