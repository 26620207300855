import { arrayOf, node, oneOfType } from 'prop-types';
import KebabIcon from 'components/svg/kebabIcon';
import { useEffect, useRef, useState } from 'react';
import styles from 'components/Tables/Table/TableBody/KebabMenu/styles.module.scss';

const KebabMenu = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    const popup = useRef(null);
    const button = Array.isArray(children)
        ? children.filter((child) => !!child)
        : [children];

    useEffect(() => {
        const onClick = (e) => {
            if (isOpen)
                if (buttonRef.current.contains(e.target)) {
                    if (isOpen) {
                        setIsOpen(false);
                    } else {
                        setIsOpen(true);
                    }
                } else {
                    setIsOpen(false);
                }
        };
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, [isOpen]);

    const getParentTabel = (HTMLelement) => {
        if (HTMLelement.parentElement.tagName === 'TABLE')
            return HTMLelement.parentElement;
        return getParentTabel(HTMLelement.parentElement);
    };

    const culcPosition = (targetElement) => {
        const table = getParentTabel(targetElement);
        const bottomCoordTable = table.getBoundingClientRect().bottom;
        const bottomCoordButton = targetElement.getBoundingClientRect().bottom;
        if (bottomCoordButton + popup.current.clientHeight > bottomCoordTable) {
            popup.current.style.top = `${
                17 +
                bottomCoordTable -
                bottomCoordButton -
                popup.current.clientHeight
            }px`;
            popup.current.style.right = '20px';
        }
        setIsOpen(!isOpen);
    };

    return (
        <div
            ref={buttonRef}
            className={`${styles.main_icon} ${
                isOpen ? styles.main_icon_active : ''
            }`}
            onClick={(event) => {
                culcPosition(event.currentTarget);
            }}
        >
            <KebabIcon />
            <ul
                ref={popup}
                className={`${styles.dropDown} ${isOpen ? styles.active : ''}`}
            >
                {button.map((child, index) => (
                    <li key={index}>{child}</li>
                ))}
            </ul>
        </div>
    );
};
KebabMenu.propTypes = {
    children: oneOfType([arrayOf(node), node]),
};

export default KebabMenu;
