import { useSelector } from 'react-redux';
import styles from './styles.module.scss';

const TableHeader = () => {
    const monthsArr = useSelector((state) => state.reports.monthsArr);
    return (
        <thead className={styles.table__head}>
            <tr>
                <td>Проект</td>
                <td>Специализация</td>
                <td>Сотрудник</td>
                <td>Итого</td>
                {monthsArr.map((item) => (
                    <td key={item.value}>{item.value}</td>
                ))}
            </tr>
        </thead>
    );
};

export default TableHeader;
