import React, { useCallback, useMemo, useState } from 'react';
import { useTransition, animated } from 'react-spring';
import { element } from 'prop-types';
import Popup from 'components/Popup/Popup';
import ModalContext from './ModalContext';

const ModalProvider = ({ children }) => {
    const [modalOpened, setModalOpened] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const transitions = useTransition(modalOpened, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 150 },
    });

    const openModal = useCallback((modalConfig) => {
        setModalContent(modalConfig);
        setModalOpened(true);
    }, []);

    const closeModal = useCallback(() => {
        setModalOpened(false);
    }, []);

    const valueModelProvider = useMemo(
        () => ({
            openModal,
            closeModal,
        }),
        [openModal, closeModal]
    );

    return (
        <ModalContext.Provider value={valueModelProvider}>
            {transitions(
                (props, item) =>
                    item && (
                        <animated.div
                            style={{
                                ...props,
                                position: 'absolute',
                                zIndex: 1000,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Popup content={modalContent} />
                        </animated.div>
                    )
            )}
            {children}
        </ModalContext.Provider>
    );
};

ModalProvider.propTypes = {
    children: element,
};

export default ModalProvider;
