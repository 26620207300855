import { func, string, bool } from 'prop-types';
import classNames from 'classnames/bind';
import SyncIcon from 'components/svg/ButtonIcon/syncIcon';
import PlusIcon from 'components/svg/ButtonIcon/plus';
import DownloadIcon from 'components/svg/ButtonIcon/download';
import SearchIcon from 'components/svg/ButtonIcon/search';
import styles from 'components/Button/styles.module.scss';
import FilterIcon from 'components/svg/FilterIcon/filterIcon';
import KebabIcon from 'components/svg/kebabIcon';
import EditIcon from 'components/svg/editIcon';
import { CVIcon } from 'components/svg/SidebarIcon/sidebarIcons';
import LogoutIcon from 'components/svg/logoutIcon';

const Button = ({
    buttonIconName = '',
    text = '',
    buttonStyle = 'filled',
    className = '',
    disabled = false,
    wide = false,
    rotatedIcon = false,
    onSubmit = false,
    isProjectsTeams = false,
    onClick = () => {},
}) => {
    const cx = classNames.bind(styles);

    const onEnterPressed = (e) => {
        if (e.key === 'enter') {
            onClick();
        }
    };

    const buttonIcon = (iconName) => {
        switch (iconName) {
            case 'sync': {
                return <SyncIcon />;
            }
            case 'plus': {
                return <PlusIcon />;
            }
            case 'download': {
                return <DownloadIcon />;
            }
            case 'search': {
                return <SearchIcon />;
            }
            case 'filters': {
                return <FilterIcon />;
            }
            case 'kebab': {
                return <KebabIcon />;
            }
            case 'edit': {
                return <EditIcon />;
            }
            case 'cv': {
                return <CVIcon />;
            }
            case 'logout': {
                return <LogoutIcon />;
            }
            default: {
                return '';
            }
        }
    };

    return (
        <button
            type={onSubmit ? 'submit' : 'button'}
            tabIndex={0}
            disabled={disabled}
            onClick={onClick}
            onKeyPress={onEnterPressed}
            className={`${styles.button} ${styles[buttonStyle]} ${
                disabled ? styles.disabled : ''
            } ${text === '' && styles.only_icon}
            ${wide ? styles.wide : ''}
             ${className ?? undefined} 
             ${isProjectsTeams ? styles.projects_temas_button : ''}`}
        >
            {buttonIconName && (
                <div
                    className={cx('icon', `icon_${buttonStyle}`, {
                        rotatedIcon,
                        noMargin: text === '',
                    })}
                >
                    {buttonIcon(buttonIconName)}
                </div>
            )}
            {text}
        </button>
    );
};

Button.propTypes = {
    buttonIconName: string,
    onClick: func,
    buttonStyle: string,
    text: string,
    className: string,
    disabled: bool,
    wide: bool,
    rotatedIcon: bool,
    onSubmit: bool,
    isProjectsTeams: bool,
};
export default Button;
