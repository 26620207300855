import { shape } from 'prop-types';
import TableHeader from 'components/Tables/ReportTables/ManagersTable/TableHeader';
import TableBody from 'components/Tables/ReportTables/ManagersTable/TableBody';
import NoDataInfo from 'components/NoDataInfo';
import styles from './styles.module.scss';

const ManagersTable = ({ items = {} }) => (
    <div className={styles.table__wrapper}>
        {items?.infoByPersons?.length ? (
            <table className={styles.table_main}>
                <TableHeader />
                <TableBody items={items} />
            </table>
        ) : (
            <NoDataInfo />
        )}
    </div>
);

export default ManagersTable;

ManagersTable.propTypes = {
    items: shape({}),
};
