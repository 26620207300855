import {
    GET_REPORTS_PROJECTS_DATA,
    GET_REPORTS_PERSONS_DATA,
    GET_REPORTS_PERSONS_WORKLOAD_DATA,
    GET_REPORTS_PROJECT_WORKLOAD_DATA,
    GET_REPORTS_PROJECT_TYPE_DATA,
    GET_REPORTS_MANAGER_KPI_DATA,
    GET_PROJECTS_QUARTERS_DATA,
    GET_REPORTS_COMPETENCE_LEVEL_DATA,
    GET_UNUSED_PERSONS_REPORT_DATA,
    GET_PERSONS_EXPERIENCE_REPORT_DATA,
    GET_BALANCE_BUDGETS_REPORT_DATA,
    SET_MONTH_FROM,
    SET_MONTH_TO,
    GET_TEAM_PERIOD_PERSONS_DATA,
} from 'redux/types';

export const getReportsProjectsData = (data) => ({
    type: GET_REPORTS_PROJECTS_DATA,
    payload: data,
});

export const getTeamPeriodPersons = (data) => ({
    type: GET_TEAM_PERIOD_PERSONS_DATA,
    payload: data,
});

export const getReportsPersonsData = (data) => ({
    type: GET_REPORTS_PERSONS_DATA,
    payload: data,
});

export const getReportsPersonsWorkloadData = (data) => ({
    type: GET_REPORTS_PERSONS_WORKLOAD_DATA,
    payload: data,
});

export const getReportsProjectWorkloadData = (data) => ({
    type: GET_REPORTS_PROJECT_WORKLOAD_DATA,
    payload: data,
});
export const getReportsProjectTypeData = (data) => ({
    type: GET_REPORTS_PROJECT_TYPE_DATA,
    payload: data,
});
export const getReportsCompetenceLevelData = (data) => ({
    type: GET_REPORTS_COMPETENCE_LEVEL_DATA,
    payload: data,
});
export const getReportsManagerKpiData = (data) => ({
    type: GET_REPORTS_MANAGER_KPI_DATA,
    payload: data,
});
export const getProjectsQuartersData = (data) => ({
    type: GET_PROJECTS_QUARTERS_DATA,
    payload: data,
});

export const getUnusedPersonsReportData = (data) => ({
    type: GET_UNUSED_PERSONS_REPORT_DATA,
    payload: data,
});

export const getPersonsExperienceReportData = (data) => ({
    type: GET_PERSONS_EXPERIENCE_REPORT_DATA,
    payload: data,
});

export const getBalanceBudgetsReportData = (data) => ({
    type: GET_BALANCE_BUDGETS_REPORT_DATA,
    payload: data,
});

export const setMonthFrom = (data) => ({
    type: SET_MONTH_FROM,
    payload: data,
});

export const setMonthTo = (data) => ({
    type: SET_MONTH_TO,
    payload: data,
});
