import { USER_DATA, LOGOUT, SET_IS_LOADING_AUTH } from 'redux/types';

export const userData = (data) => ({
    type: USER_DATA,
    payload: data,
});

export const logout = () => ({
    type: LOGOUT,
});

export const setIsLoadingAuth = (data) => ({
    type: SET_IS_LOADING_AUTH,
    payload: data,
});
