import dayjs from 'dayjs';
import { agetostr } from 'helpers/dateHelper';

export const headers = {
    teams: [
        {
            name: 'Проект',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Месяц',
            filterable: false,
            sortable: true,
        },
        {
            name: 'Год',
            filterable: false,
            sortable: true,
        },
        {
            name: 'Организация',
            filterable: false,
            sortable: true,
        },
        {
            name: 'Сотрудник',
            filterable: true,
            sortable: true,
        },
    ],
    personImport: [
        {
            name: '',
            dtoKey: 'synchronize',
        },
        {
            name: 'Имя сотрудника в модуле',
            dtoKey: 'displayName',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Имя сотрудника в системе',
            dtoKey: 'russianName',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Корпоративная почта',
            dtoKey: 'emailAddress',
            filterable: false,
            sortable: false,
        },
    ],
    persons: [
        {
            name: 'Сотрудник',
            dtoKey: 'fullName',
            fieldType: 'text',
            sortable: true,
        },
        {
            name: 'Должность',
            dtoKey: 'position',
            fieldType: 'text',
            sortable: true,
        },
        {
            name: 'Специализация',
            dtoKey: 'specialityName',
            fieldType: 'text',
            sortable: true,
        },
        {
            name: 'Уровень компетенций',
            dtoKey: 'competenceLevel',
            fieldType: 'text',
            sortable: true,
        },
        {
            name: 'Табельный номер',
            dtoKey: 'personNumber',
            fieldType: 'number',
            sortable: true,
        },
        {
            name: 'Статус',
            sortable: false,
        },
    ],
    personsInfo: [
        {
            name: 'Сотрудник',
            dtoKey: 'fullName',
            fieldType: 'text',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Корпоративная почта',
            dtoKey: 'corpEmail',
            fieldType: 'text',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Cпециализация',
            dtoKey: 'specialityName',
            fieldType: 'text',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Уровень. комп',
            dtoKey: 'competenceLevel',
            fieldType: 'text',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Общий стаж',
            dtoKey: 'experience',
            fieldType: 'text',
            filterable: false,
            sortable: true,
        },
        {
            name: 'Стаж в компании',
            dtoKey: 'corpExperience',
            fieldType: 'text',
            filterable: false,
            sortable: true,
        },
        {
            name: 'Дата создания',
            dtoKey: 'atCreated',
            fieldType: 'text',
            filterable: false,
            sortable: true,
        },
        {
            name: 'Дата изменения',
            dtoKey: 'atUpdated',
            fieldType: 'text',
            filterable: false,
            sortable: true,
        },
        {
            name: 'Статус',
            filterable: true,
            sortable: false,
        },
        {
            name: 'Комментарии',
            dtoKey: 'comment',
            fieldType: 'text',
            filterable: false,
            sortable: false,
        },
    ],
    projects: [
        {
            name: 'Проект',
            dtoKey: 'name',
            fieldType: 'text',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Тип проекта',
            dtoKey: 'type',
            fieldType: 'text',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Дата начала',
            dtoKey: 'dateStart',
            fieldType: 'date',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Дата окончания',
            dtoKey: 'dateEnd',
            fieldType: 'date',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Бюджет проекта',
            dtoKey: 'initialBudget',
            fieldType: 'budget',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Менеджер',
            dtoKey: 'managerName',
            fieldType: 'text',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Статус',
            filterable: true,
            sortable: false,
        },
    ],
    baseTariffs: [
        {
            name: 'Сотрудник',
            dtoKey: 'personFullName',
            fieldType: 'text',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Корпоративная почта',
            dtoKey: 'personCorpEmail',
            fieldType: 'text',
            sortable: true,
        },
        {
            name: 'Тарифная ставка в час',
            dtoKey: 'tariffRateHour',
            fieldType: 'number',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Тарифная ставка день',
            dtoKey: 'tariffRateDay',
            fieldType: 'number',
            filterable: true,
            sortable: true,
        },
    ],
    productionPlans: [
        [
            {
                id: 1,
                name: 'Сотрудник',
                dtoKey: 'personFullName',
                fieldType: 'text',
                sortable: true,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 2,
                name: 'Специализация',
                dtoKey: 'specialityName',
                fieldType: 'text',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 3,
                name: 'Организация',
                dtoKey: 'organizationName',
                fieldType: 'text',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 4,
                name: 'Менеджер',
                dtoKey: 'managerFullName',
                fieldType: 'text',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 5,
                name: 'Проект',
                dtoKey: 'projectName',
                filterable: true,
                sortable: true,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 6,
                name: 'Тип проекта',
                dtoKey: 'projectType',
                fieldType: 'text',
                sortable: true,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 7,
                name: 'Часы',
                dtoKey: 'planHours',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '2',
            },
            {
                id: 8,
                name: 'Месяц',
                dtoKey: 'planMonth',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '2',
            },
            {
                id: 9,
                name: 'Ставка,\n час/день',
                dtoKey: 'tariffRateHour',
                fieldType: 'budget',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 10,
                name: 'Отклонение',
                dtoKey: 'deviationHours',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '2',
            },
            {
                id: 11,
                name: 'Сумма в рамках\n договора',
                dtoKey: 'initialBudget',
                fieldType: 'budget',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 12,
                name: 'Примечание',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
        ],
        [
            {
                id: 13,
                name: 'План.',
                dtoKey: 'planHours',
                fieldType: 'number',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 14,
                name: 'Факт.',
                dtoKey: 'factHours',
                fieldType: 'number',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 15,
                name: 'План.',
                dtoKey: 'planMonth',
                fieldType: 'number',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 16,
                name: 'Факт.',
                dtoKey: 'factMonth',
                fieldType: 'number',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 17,
                name: 'Часов',
                dtoKey: 'deviationHours',
                fieldType: 'deviation',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 18,
                name: 'Рублей',
                dtoKey: 'deviationMonth',
                fieldType: 'deviation',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
        ],
    ],
    unusedPersons: [
        [
            {
                id: 1,
                name: 'Сотрудник',
                dtoKey: 'personFullName',
                fieldType: 'text',
                sortable: true,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 2,
                name: 'Неотработанные часы',
                dtoKey: 'planHours',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '2',
            },
            {
                id: 3,
                name: 'Неотработанная сумма',
                dtoKey: 'planMonth',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '2',
            },
            {
                id: 4,
                name: `Ставка, час/день`,
                dtoKey: 'baseTariffRateHour',
                fieldType: 'number',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 5,
                name: 'Отработано',
                dtoKey: 'deviationHours',
                fieldType: 'deviation',
                sortable: false,
                rowSpan: '1',
                columnSpan: '2',
            },
            {
                id: 6,
                name: 'Примечание',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
        ],
        [
            {
                id: 7,
                name: 'План. часы',
                dtoKey: 'planHours',
                fieldType: 'number',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 8,
                name: 'Факт. часы',
                dtoKey: 'factHours',
                fieldType: 'number',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 9,
                name: 'План. месяц',
                dtoKey: 'planMonth',
                fieldType: 'number',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 10,
                name: 'Факт. месяц',
                dtoKey: 'factMonth',
                fieldType: 'number',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 11,
                name: 'Часы',
                dtoKey: 'deviationHours',
                fieldType: 'deviation',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 12,
                name: 'Сумма, руб.',
                dtoKey: 'deviationMonth',
                fieldType: 'deviation',
                sortable: true,
                rowSpan: '1',
                columnSpan: '1',
            },
        ],
    ],
    reportCardError: [
        {
            name: 'Имя сотрудника из файла',
            dtoKey: 'timesheetFullName',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Табельный номер',
            dtoKey: 'timesheetPersonNumber',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Часов из файла',
            dtoKey: 'timesheetPlanHours',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Период',
            dtoKey: 'period',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Имя сотрудника в системе',
            dtoKey: 'accountingPersonFullName',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Проекты по плану производству',
            dtoKey: 'availableProjectIds',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Факт. часы',
            dtoKey: 'timesheetFactHours',
            filterable: false,
            sortable: false,
        },
        {
            name: '',
            dtoKey: 'synchronize',
        },
    ],
    budgets: [
        {
            name: 'Баланс',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Потрачено',
            filterable: true,
            sortable: true,
        },
        {
            name: 'Дата изменения баланса',
            filterable: true,
            sortable: true,
        },
    ],
    productionPlansErrorModal: [
        {
            name: 'Табельный номер',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Количество часов',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Период',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Описание ошибки',
            filterable: false,
            sortable: false,
        },
    ],
    users: [
        {
            name: 'Пользователь',
            dtoKey: 'fullName',
            filterable: false,
            sortable: true,
        },
        {
            name: 'Корпоративная почта',
            dtoKey: 'username',
            filterable: false,
            sortable: true,
        },
        {
            name: 'Роль',
            dtoKey: 'role',
            filterable: false,
            sortable: true,
        },
        {
            name: 'Должность',
            dtoKey: 'position',
            filterable: false,
            sortable: true,
        },
    ],
    personsExperience: [
        // отчет по стажу, формируется по аналогии с основными таблицами, так как необходимы фильтры
        {
            name: 'Сотрудник',
            dtoKey: 'fullName',
            fieldType: 'text',
            filterable: false,
            sortable: false,
        },
        {
            name: `Специализация`,
            dtoKey: 'specialityName',
            fieldType: 'text',
            filterable: true,
            sortable: false,
        },
        {
            name: 'Компетенция',
            dtoKey: 'competenceLevel',
            fieldType: 'text',
            filterable: true,
            sortable: false,
        },
        {
            name: 'Стаж работы в компании',
            dtoKey: 'corpExperience',
            fieldType: 'number',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Общий стаж работы',
            dtoKey: 'experience',
            fieldType: 'number',
            filterable: false,
            sortable: false,
        },
    ],
    balanceBudgets: [
        [
            {
                id: 1,
                name: 'Проект',
                dtoKey: 'projectName',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 2,
                name: 'Год',
                dtoKey: 'year',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 3,
                name: 'Месяц',
                dtoKey: 'month',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 4,
                name: 'Бюджет месяца',
                dtoKey: 'sumPlanMonth',
                sortable: false,
                rowSpan: '1',
                columnSpan: '2',
            },
            {
                id: 5,
                name: 'Итоговый бюджет месяца',
                dtoKey: 'sumPlanMonthToMonth',
                sortable: false,
                rowSpan: '1',
                columnSpan: '2',
            },
            {
                id: 6,
                name: 'Бюджетный остаток от общего плана',
                dtoKey: 'sumPlanMonthFromMonth',
                sortable: false,
                rowSpan: '1',
                columnSpan: '2',
            },
            {
                id: 7,
                name: 'Итоговый бюджет',
                dtoKey: 'sumPlan',
                sortable: false,
                rowSpan: '1',
                columnSpan: '2',
            },
            {
                id: 8,
                name: 'Перерасход к этому месяцу',
                dtoKey: 'balanceDeviationToMonth',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 9,
                name: 'Перерасход',
                dtoKey: 'balanceDeviation',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
            {
                id: 10,
                name: 'Перерасход бюджета',
                dtoKey: 'budgetDeviationPlan',
                sortable: false,
                rowSpan: '1',
                columnSpan: '2',
            },
            {
                id: 11,
                name: 'Бюджет',
                dtoKey: 'initialBudget',
                sortable: false,
                rowSpan: '2',
                columnSpan: '1',
            },
        ],
        [
            {
                id: 12,
                name: 'План.',
                dtoKey: 'sumPlanMonth',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 13,
                name: 'Факт.',
                dtoKey: 'sumFactMonth',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 14,
                name: 'План.',
                dtoKey: 'sumPlanMonthToMonth',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 15,
                name: 'Факт.',
                dtoKey: 'sumFactMonthToMonth',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 16,
                name: 'План.',
                dtoKey: 'sumPlanMonthFromMonth',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 17,
                name: 'Факт.',
                dtoKey: 'sumFactMonthFromMonth',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 18,
                name: 'План.',
                dtoKey: 'sumPlan',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 19,
                name: 'Факт.',
                dtoKey: 'sumFact',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 20,
                name: 'План.',
                dtoKey: 'budgetDeviationPlan',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '1',
            },
            {
                id: 21,
                name: 'Факт.',
                dtoKey: 'budgetDeviationFact',
                fieldType: 'number',
                sortable: false,
                rowSpan: '1',
                columnSpan: '1',
            },
        ],
    ],
    projectImport: [
        {
            name: '',
            dtoKey: 'synchronize',
        },
        {
            name: 'Название проекта в модуле',
            dtoKey: 'name',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Название проекта в системе',
            dtoKey: 'projectId',
            filterable: false,
            sortable: false,
        },
    ],
    cvTableForProfile: [
        {
            name: '№ CV',
            dtoKey: 'cvId',
            fieldType: 'number',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Сотрудник',
            dtoKey: 'fullName',
            fieldType: 'text',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Cпециализация',
            dtoKey: 'specialityName',
            fieldType: 'text',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Уровень. комп',
            dtoKey: 'competenceLevel',
            fieldType: 'text',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Общий стаж',
            dtoKey: 'experience',
            fieldType: 'text',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Стаж в компании',
            dtoKey: 'corpExperience',
            fieldType: 'text',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Дата создания',
            dtoKey: 'atCreated',
            fieldType: 'text',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Дата изменения',
            dtoKey: 'atUpdated',
            fieldType: 'text',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Статус',
            filterable: false,
            sortable: false,
        },
        {
            name: 'Комментарии',
            dtoKey: 'comment',
            fieldType: 'text',
            filterable: false,
            sortable: false,
        },
    ],
};

export const getYear = (data) => new Date(data).getFullYear();
export const getMonth = (data) => new Date(data).getMonth();

export const getMonthYear = (data) => dayjs(data).format('MM.YYYY');
export const shortDate = (date) => dayjs(date).format('DD.MM.YYYY');
export const fullDate = (date) =>
    `${date.split('.').reverse().join('-')}T00:00:00Z`;
export const differenceInDate = (startPeriod, endPeriod) =>
    dayjs(endPeriod).diff(dayjs(startPeriod), 'month');

export const numbersDepth = (numbers) =>
    numbers || numbers === 0
        ? numbers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        : null;
export const floatDepth = (numbers) => {
    return numbers || numbers === 0
        ? numbers.toString().replace(/[0-9]+\.[0-9]+/g, ' ')
        : null;
};

export const removeNumbersSpacing = (numbers) =>
    typeof numbers === 'string' ? Number(numbers.replace(/\s/g, '')) : numbers;

export const projectType = {
    FIXED_PRICE: 'Фикспрайс',
    SUPPORT: 'Сопровождение',
    INVESTMENTS: 'Инвестиции',
    TIME_AND_MATERIAL: 'Тайм_материал',
    BENCH: 'Бенч',
};

export const projectTypeRev = {
    Фикспрайс: 'FIXED_PRICE',
    Сопровождение: 'SUPPORT',
    Инвестиции: 'INVESTMENTS',
    Тайм_материал: 'TIME_AND_MATERIAL',
    Бенч: 'BENCH',
};

export const months = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
};

export const monthsArr = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const monthsValues = [
    { id: 0, value: 'Январь' },
    { id: 1, value: 'Февраль' },
    { id: 2, value: 'Март' },
    { id: 3, value: 'Апрель' },
    { id: 4, value: 'Май' },
    { id: 5, value: 'Июнь' },
    { id: 6, value: 'Июль' },
    { id: 7, value: 'Август' },
    { id: 8, value: 'Сентябрь' },
    { id: 9, value: 'Октябрь' },
    { id: 10, value: 'Ноябрь' },
    { id: 11, value: 'Декабрь' },
];

export const weekDays = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

export const quarters = ['1 квартал', '2 квартал', '3 квартал', '4 квартал'];

const personsBody = (body) =>
    body.map((item) => ({
        id: item.id,
        additionalData: {
            name: item.name,
            middleName: item.middleName,
            surname: item.surname,
        },
        items: {
            fullName: item.fullName,
            position: item.position,
            speciality: item.specialityName,
            competenceLevel: item.competenceLevel,
            personNumber: item.personNumber,
            status: item.isActive,
        },
    }));

const personsInfo = (body) => {
    const isEmptyDate = (date) => (date ? shortDate(date) : '');
    return body?.map((item) => ({
        id: item.id,
        items: {
            fullName: item.person.fullName,
            corpEmail: item.person.corpEmail,
            specialityName: item.person.specialityName,
            competenceLevel: item.person.competenceLevel,
            experience: agetostr(item.experience),
            corpExperience: agetostr(item.corpExperience),
            atCreated: isEmptyDate(item.atCreated),
            atUpdated: isEmptyDate(item.atUpdated),
            status: item.person.isActive,
            comment: item.comment,
        },
    }));
};

export const projectsBody = (body) =>
    body.map((item) => ({
        id: item.id,
        tools: item.technologies,
        items: {
            name: item.name,
            type: projectType[item.type],
            dateStart: shortDate(item.dateStart),
            dateEnd: shortDate(item.dateEnd),
            budget: numbersDepth(item.initialBudget),
            manager: item.manager.fullName,
            status: item.isActive,
        },
    }));

const projectsImportBody = (body) =>
    body.map((item) => ({
        id: item.id,
        items: {
            synchronize: item.synchronize,
            name: item.name,
            projectId: item.projectId,
        },
    }));
const personImportBody = (body) =>
    body.map((item) => ({
        id: item.id,
        items: {
            synchronize: item.synchronize,
            displayName: item.displayName,
            russianName: item.russianName,
            emailAddress: item.emailAddress,
        },
    }));

const baseTariffsBody = (body) =>
    body.map((item) => ({
        id: item.id,
        personId: item.personId,
        items: {
            personFullName: item.personFullName,
            corpEmail: item.personCorpEmail,
            tariffRateHour: item.tariffRateHour,
            tariffRateDay: item.tariffRateDay,
        },
    }));

const teamsBody = (body) =>
    body.map((item) => ({
        id: item.id,
        items: {
            name: item.projectName,
            month: months[item.persons.map((person) => person.month)],
            year: getYear(item.persons.map((person) => person.year)),
            organization: item.persons.map((person) => person.organizationName),
            person: item.persons.map(
                (person) => `${person.personName} ${person.personSurname}`
            ),
        },
    }));

const teamPeriodsBody = (body) =>
    body.map((item) => ({
        id: item.id,
        teamId: item.teamId,
        items: {
            name: item.projectName,
            month: months[item.teamPeriodCompactRsDto.month],
            year: item.teamPeriodCompactRsDto.year,
            organization: item.teamPeriodCompactRsDto.organizationName,
            person: item.teamPeriodCompactRsDto.personName,
        },
    }));

const productionPlansBody = (body) =>
    body.map((item) => ({
        id: item.id,
        additionalData: {
            month: item.month,
            year: item.year,
            personId: item.person.id,
            projectId: item.id,
            validity: item.validity,
            RateHour: item?.tariffRateHours,
            RateDay: item?.tariffRateDay,
        },
        items: {
            personName: item.person.fullName,
            personSpeciality: item.person.specialityName,
            organization: item.person.organizationName,
            manager: item.project.managerFullName,
            projectName: item.project.name,
            projectType: projectType[item.project.type],
            plannedHours: numbersDepth(item.planHours),
            factHours: numbersDepth(item.factHours),
            plannedMonth: numbersDepth(item.planMonth),
            factMonth: numbersDepth(item.factMonth),
            ratePerHour: `${numbersDepth(
                item?.tariffRateHour
            )} / ${numbersDepth(item?.tariffRateDay)}`,
            deviationHours: numbersDepth(item.deviationHours),
            deviationMonth: numbersDepth(item.deviationMonth),
            sumByContract: numbersDepth(item.amountUnderTheContract),
            note: item.note,
        },
    }));

const budgetsBody = (body) =>
    body.map((item) => ({
        id: item.id,
        items: {
            balance: item.balance,
            spent: item.spent,
            timestamp: `${months[getMonth(item.timestamp)]} ${getYear(
                item.timestamp
            )}`,
        },
    }));

const prodPlansErrorModal = (body) =>
    body.map((item) => ({
        items: {
            id: item.identifier,
            hours: item.hours,
            period: item.period,
            description: item.description,
        },
    }));

const unusedPersonsBody = (body) =>
    body.map((item) => ({
        id: item.id,
        additionalData: {
            month: item.month,
            year: item.year,
            personId: item.personId,
        },
        items: {
            personName: item.personFullName,

            plannedHours: item.planHours,
            factHours: item.factHours,
            plannedMonth: numbersDepth(item.planMonth),
            factMonth: numbersDepth(item.factMonth),
            baseTariffRateHour: `${numbersDepth(
                item.baseTariffRateHour
            )} / ${numbersDepth(item.baseTariffRateDay)}`,
            deviationHours: item.deviationHours,
            deviationMonth: numbersDepth(item.deviationMonth),
            note: item.note,
        },
    }));

export const usersBody = (body) =>
    body.map((item) => ({
        id: item.id,
        additionalData: {
            firstName: item.firstName,
            middleName: item.middleName,
            lastName: item.lastName,
        },
        items: {
            name: `${item.lastName} ${item.firstName || ''} ${
                item.middleName || ''
            } `,
            username: item.username,
            role: item.role,
            position: item.position,
        },
    }));
const personsExperience = (body) =>
    body.map((item) => ({
        items: {
            fullName: item.personName,
            specialityName: item.specialityName,
            competenceLevel: item.competenceName,
            corpExperience: item.companyExperience,
            experience: item.experience,
        },
    }));

const balanceBudgets = (body) =>
    body.map((item) => ({
        id: item.id,
        items: {
            projectName: item.projectName,
            year: item.year,
            month: months[item.month - 1],
            sumPlanMonth: numbersDepth(item.sumPlanMonth),
            sumFactMonth: numbersDepth(item.sumFactMonth),
            sumPlanMonthToMonth: numbersDepth(item.sumPlanMonthToMonth),
            sumFactMonthToMonth: numbersDepth(item.sumFactMonthToMonth),
            sumPlanMonthFromMonth: numbersDepth(item.sumPlanMonthFromMonth),
            sumFactMonthFromMonth: numbersDepth(item.sumFactMonthFromMonth),
            sumPlan: numbersDepth(item.sumPlan),
            sumFact: numbersDepth(item.sumFact),
            balanceDeviationToMonth: numbersDepth(item.balanceDeviationToMonth),
            balanceDeviation: numbersDepth(item.balanceDeviation),
            budgetDeviationPlan: numbersDepth(item.budgetDeviationPlan),
            budgetDeviationFact: numbersDepth(item.budgetDeviationFact),
            initialBudget: numbersDepth(item.initialBudget),
        },
    }));

const reportCardError = (body) => [
    ...body.map((item) => ({
        id: item.id,
        items: [
            {
                timesheetFullName: {
                    value: item.timesheetFullName,
                    rowSpan:
                        item.availableProjectIds.length > 0
                            ? item.availableProjectIds.length
                            : 1,
                },
                timesheetPersonNumber: {
                    value: item.timesheetPersonNumber,
                    rowSpan:
                        item.availableProjectIds.length > 0
                            ? item.availableProjectIds.length
                            : 1,
                },
                timesheetFactHours: {
                    value: item.timesheetFactHours
                        ? item.timesheetFactHours
                        : 0,
                    rowSpan:
                        item.availableProjectIds.length > 0
                            ? item.availableProjectIds.length
                            : 1,
                },
                period: {
                    value: `${months[item.timesheetMonth - 1]} ${
                        item.timesheetYear
                    }`,
                    rowSpan:
                        item.availableProjectIds.length > 0
                            ? item.availableProjectIds.length
                            : 1,
                },
                accountingPersonId: {
                    value: item.accountingPersonId,
                    rowSpan:
                        item.availableProjectIds.length > 0
                            ? item.availableProjectIds.length
                            : 1,
                },
                availableProjectName: {
                    value: item.availableProjectIds[0]?.name,
                    rowSpan: 1,
                },
                availableProjectFactHours: {
                    additionalData: {
                        projectId: item.availableProjectIds[0]?.projectId,
                        personId: item.availableProjectIds[0]?.personId,
                        isError: item.availableProjectIds[0]?.isErrorFactHours,
                    },
                    value: item.availableProjectIds[0]?.factHours,
                    rowSpan: 1,
                },
                synchronize: {
                    value: item.availableInAccounting,
                    rowSpan:
                        item.availableProjectIds.length > 0
                            ? item.availableProjectIds.length
                            : 1,
                },
            },
            item.availableProjectIds.slice(1).map((el) => ({
                availableProjectName: {
                    value: el?.name,
                    rowSpan: 1,
                },
                availableProjectFactHours: {
                    additionalData: {
                        projectId: el?.projectId,
                        personId: el?.personId,
                        isError: el?.isErrorFactHours,
                    },
                    value: el?.factHours,
                    rowSpan: 1,
                },
            })),
        ],
    })),
];

export const bodyHandler = (type, body) => {
    switch (type) {
        case 'PROJECTS_IMPORT':
            return projectsImportBody(body);
        case 'PERSON_IMPORT':
            return personImportBody(body);
        case 'PERSONS':
            return personsBody(body);
        case 'PROJECTS':
            return projectsBody(body);
        case 'BASE_TARIFFS':
            return baseTariffsBody(body);
        case 'TEAMS':
            return teamsBody(body);
        case 'TEAM_PERIODS':
            return teamPeriodsBody(body);
        case 'PRODUCTION_PLANS':
            return productionPlansBody(body);
        case 'UNUSED_PERSONS':
            return unusedPersonsBody(body);
        case 'BUDGETS':
            return budgetsBody(body);
        case 'USERS':
            return usersBody(body);
        case 'ERROR_MODAL':
            return prodPlansErrorModal(body);
        case 'PERSONS_EXPERIENCE':
            return personsExperience(body);
        case 'PERSONS_CV':
            return personsInfo(body);
        case 'BALANCE_BUDGETS':
            return balanceBudgets(body);
        case 'REPORT_CARD_ERROR':
            return reportCardError(body);
        default:
            return [];
    }
};
