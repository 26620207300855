import Button from 'components/Button';
import DropdownList from 'components/DropdownList';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { addModule } from 'redux/thunks/modulesThunks';
import isEmpty from 'helpers/isEmpty';
import Input from 'components/Input';
import { statusList } from 'helpers/Filters/filtersAreaHandler';
import styles from './styles.module.scss';

const AddModuleModal = () => {
    const systems = useSelector((state) => state.systems.DropdownList);
    const modules = useSelector((state) => state.modules.data);

    const { closeModal } = useContext(ModalContext);
    const dispatch = useDispatch();

    const [typeModule, setTypeModule] = useState({});
    const [status, setStatus] = useState({});
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [username, setUsername] = useState('');
    const [pass, setPass] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [secretApiKey, setSecretApiKey] = useState('');
    const [time, setTime] = useState(null);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        resetSettingsField();
        if (typeModule.id) {
            setTime(
                modules.find((elem) => elem.systemId === typeModule.id)?.timeout
            );
            setApiKey(
                modules.find((elem) => elem.systemId === typeModule.id)?.apiKey
            );
            setSecretApiKey(
                modules.find((elem) => elem.systemId === typeModule.id)
                    ?.secretApiKey
            );
            setStatus(statusList.find((elem) => elem.boolValue === true));
            setIsValid(true);
        }
    }, [typeModule, modules]);

    const resetSettingsField = () => {
        setDescription('');
        setUrl('');
        setUsername('');
        setPass('');
        setApiKey('');
        setSecretApiKey('');
        setTime('');
    };

    const dataForSaved = () => {
        const dateNow = new Date();

        const forSave = {
            module: {
                apiKey,
                password: pass,
                secretApiKey,
                url,
                username,
                isActive: status.boolValue,
                timeout: time,
                systemId: typeModule.id,
                isValid,
                description,
            },
            frequency: {
                dateStart: dateNow,
                frequency: 100000,
                type: systems.find((elem) => elem.id === typeModule.id).value,
                isActive: true,
            },
        };
        return forSave;
    };

    const handlerClickAdd = () => {
        dispatch(addModule(dataForSaved().module, dataForSaved().frequency));
        closeModal();
    };

    const isEmptyField =
        isEmpty(typeModule) &&
        !!description &&
        !!url &&
        !!username &&
        !!pass &&
        !!secretApiKey;

    const header = (
        <div className={styles.header}>
            <div className={styles.title}>Выберите модуль: </div>
        </div>
    );
    const settingsField = (
        <div className={styles.settings_container}>
            <div className={styles.dropDown}>
                <DropdownList
                    placeholderInput="Модуль"
                    items={systems}
                    selected={typeModule}
                    setSelected={setTypeModule}
                />
            </div>
            <div className={styles.settings}>
                {typeModule.id && (
                    <div className={styles.settings__inputs}>
                        <div className={styles.row}>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Описание"
                                    value={description}
                                    onChange={setDescription}
                                />
                            </div>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Адрес"
                                    value={url}
                                    onChange={setUrl}
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Имя пользователя"
                                    value={username}
                                    onChange={setUsername}
                                />
                            </div>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Пароль"
                                    value={pass}
                                    onChange={setPass}
                                    password
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Публичный ключ"
                                    value={apiKey}
                                    onChange={setApiKey}
                                    password
                                />
                            </div>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Токен"
                                    value={secretApiKey}
                                    onChange={setSecretApiKey}
                                    password
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Время ожидания сервера, мс"
                                    value={time}
                                    onChange={setTime}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className={styles.buttons_container}>
                    <Button
                        buttonStyle="outlined"
                        text="Отмена"
                        onClick={closeModal}
                    />
                    {typeModule.id && (
                        <Button
                            text="Подключить"
                            disabled={!isEmptyField}
                            onClick={handlerClickAdd}
                        />
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            {header}
            {settingsField}
        </div>
    );
};

export default AddModuleModal;
