import API from 'api/api';
import {
    editUnusedPersonData,
    getUnusedPersonsData,
} from 'redux/actions/unusedPersons';
import { useToastify } from 'helpers/toastify/useToastify';
import { logout } from 'redux/actions/auth';

export const getAllUnusedPersons =
    (
        filtersPersons = {},
        sortPersons = {},
        setIsLoadingUnusedPersons = () => {},
        closeModal = () => {}
    ) =>
    (dispatch) => {
        setIsLoadingUnusedPersons(true);
        const queryPersons = { ...filtersPersons };

        if (sortPersons.property)
            queryPersons.sort = `${sortPersons.property},${sortPersons.order}`;

        API.unusedPersons
            .getAllUnusedPersons({ ...queryPersons })
            .then((res) => {
                dispatch(getUnusedPersonsData(res.data.content));
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => {
                setIsLoadingUnusedPersons(false);
                closeModal();
            });
    };
export const editUnusedPerson =
    (id, data = {}, closeModal = () => {}) =>
    (dispatch) => {
        const query = { ...data };
        API.unusedPersons
            .editUnusedPerson(id, { ...query })
            .then((res) => {
                dispatch(editUnusedPersonData(res.data));
                useToastify(
                    'План незадействованных сотрудников изменён',
                    'success'
                );
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => closeModal());
    };
