import { useDispatch } from 'react-redux';
import { setIsLoadingAuth, userData } from 'redux/actions/auth';
import { useToastify } from 'helpers/toastify/useToastify';
import Login, { getCurrentUrl } from 'helpers/Login';
import axios from 'axios';
import API from 'api/api';
import gitlab from 'icons/gitlab.svg';
import gitlabColor from 'icons/gitlabColor.svg';
import styles from './styles.module.scss';

const GitLabButton = () => {
    const dispatch = useDispatch();
    const theme = localStorage.getItem('app-theme');

    const handleLoginGitlab = async (res) => {
        const code = Object.values(res)[0];
        if (code) {
            const gitLabResponse = await axios
                .post('https://gitlab.codeinside.ru/oauth/token', {
                    client_id: process.env.REACT_APP_GITLAB_CLIENT_ID,
                    redirect_uri: getCurrentUrl(),
                    code,
                    scope: 'api',
                    grant_type: 'authorization_code',
                })
                .catch(() =>
                    useToastify('Ошибка при получении токена Gitlab', 'error')
                );
            if (gitLabResponse) {
                dispatch(setIsLoadingAuth(true));
                API.auth
                    .login(gitLabResponse.data.access_token, 'gitlab')
                    .then((r) => {
                        const authTokenEncoded = window.btoa(
                            r?.data?.entity?.token
                        );
                        localStorage.setItem('authToken', authTokenEncoded);
                        localStorage.setItem('authType', 'gitlab');
                        const refreshTokenEncoded = window.btoa(
                            r?.data?.entity?.refreshToken
                        );
                        localStorage.setItem(
                            'refreshToken',
                            refreshTokenEncoded
                        );
                        localStorage.setItem('userLoggedIn', true);
                        const userDataInfo = {
                            userShortName: r.data.entity.userShortName,
                            userFullName: r.data.entity.userFullName,
                            userName: r.data.entity.userName,
                            userMiddleName: r.data.entity.userMiddleName,
                            userSurname: r.data.entity.userSurname,
                            personId: r.data.entity.personId,
                            userLogin: r.data.entity.userLogin,
                            userRole: r.data.entity.userRole,
                            userPosition: r.data.entity.userPosition,
                        };
                        dispatch(userData(userDataInfo));
                    })
                    .catch((r) => useToastify(r.message, 'error'))
                    .finally(() => dispatch(setIsLoadingAuth(false)));
            }
        }
    };

    return (
        <Login
            clientID={process.env.REACT_APP_GITLAB_CLIENT_ID}
            onSuccess={handleLoginGitlab}
            type="gitlab"
        >
            <button className={styles.loginpage__modal_button} type="button">
                <img
                    className={styles.loginpage__modal_icon}
                    src={theme === 'blue' ? gitlabColor : gitlab}
                    alt="gitlab_icon"
                />
                <span>Войти с помощью Gitlab</span>
            </button>
        </Login>
    );
};

export default GitLabButton;
