import { useContext } from 'react';
import { useSelector } from 'react-redux';
import Input from 'components/Input';
import PageHeader from 'components/PageHeader';
import useLogout from 'hooks/useLogout';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import ExitModal from 'components/ModalsContent/ExitModal';
import styles from './styles.module.scss';

const User = () => {
    const userData = useSelector((state) => state.auth.userData);
    const currentRole = userData.userRole;
    const { openModal, closeModal } = useContext(ModalContext);

    const { signOut } = useLogout();

    const handleClickLogout = (title, children) => {
        openModal({ title, children });
    };

    return (
        <div>
            <PageHeader
                title="Профиль сотрудника"
                logoutButton
                handleClickLogout={() => {
                    handleClickLogout(
                        'Выйти из системы?',
                        <ExitModal closeModal={closeModal} />
                    );
                }}
            />

            <div className={styles.userContainer}>
                <div className={styles.userContainer_info}>
                    <div className={styles.userContainer__title}>ФИО</div>
                    <Input disabled placeholder={userData?.userName} bigInput />
                    <Input
                        disabled
                        placeholder={userData?.userSurname}
                        bigInput
                    />
                    <Input
                        disabled
                        placeholder={userData?.userMiddleName}
                        bigInput
                    />
                </div>

                <div className={styles.userContainer_info}>
                    <div className={styles.userContainer__title}>
                        Служебная информация
                    </div>
                    <Input
                        disabled
                        placeholder={userData?.userLogin}
                        bigInput
                    />
                    <Input disabled placeholder={currentRole} bigInput />
                    <Input
                        disabled
                        placeholder={userData?.userPosition}
                        bigInput
                    />
                </div>
            </div>
        </div>
    );
};

export default User;
