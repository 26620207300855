import API from 'api/api';
import { useToastify } from 'helpers/toastify/useToastify';
import { logout } from 'redux/actions/auth';
import {
    editOrganizationsData,
    getOrganizationsData,
} from 'redux/actions/organizations';

export const getOrganization =
    (id, setIsLoading = () => {}) =>
    (dispatch) => {
        API.organizations
            .getOrganization(id)
            .then((res) => dispatch(getOrganizationsData(res.data.content)))
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => setIsLoading(false));
    };
export const editOrganization =
    (id, name = '', closeModal = () => {}) =>
    (dispatch) => {
        API.organizations
            .editOrganization(id, name)
            .then((res) => {
                dispatch(editOrganizationsData(res.data));
                useToastify('Название организации изменено', 'success');
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => closeModal());
    };
export const getAllOrganizations =
    (name = {}, sort = {}, setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        const query = { ...name };
        if (sort.property) query.sort = `${sort.property},${sort.order}`;
        API.organizations
            .getAllOrganizations({ ...query })
            .then((res) => dispatch(getOrganizationsData(res.data.content)))
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => setIsLoading(false));
    };
export const addOrganization =
    (name = '', closeModal = () => {}) =>
    (dispatch) => {
        API.organizations
            .addOrganization({
                name: name.trim(),
            })
            .then(() => {
                dispatch(
                    getAllOrganizations(
                        { name: '' },
                        {
                            property: 'name',
                            order: `asc`,
                        }
                    )
                );
                useToastify('Организация добавлена', 'success');
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => closeModal());
    };
