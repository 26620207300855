import { useContext, useEffect, useState } from 'react';
import PageHeader from 'components/PageHeader';
import Table from 'components/Tables/Table';
import BaseTariffRateContent from 'components/ModalsContent/BaseTariffRateContent';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import Loader from 'components/Loader';
import { headers } from 'helpers/tableHandler';
import { editSort } from 'helpers/editFormsValues';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBaseTariffs } from 'redux/thunks/baseTariffsThunks';
import FiltersModal from 'components/Filters/FiltersModal';
import { filtersAreaHandler } from 'helpers/Filters/filtersAreaHandler';
import { initPlaceholder } from 'helpers/Filters/FiltersModalHelpers';
import { accessByRole } from 'helpers/roleHelpers';
import { getAllPersonsForDropDown } from 'redux/thunks/personThunks';
import styles from 'components/Pages/Directories/styles.module.scss';

const BaseTariffRates = () => {
    const dispatch = useDispatch();
    const { openModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const data = useSelector((state) => state.baseTariffRates.data);
    const currentRole = useSelector((state) => state.auth.userData.userRole);

    const personsList = useSelector(
        (state) => state.persons.DropdownListValues
    );

    const [persons, setPersons] = useState([]);
    const [tariffRateHour, setTariffRateHour] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });
    const [tariffRateDay, setTariffRateDay] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });

    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState({
        property: 'personFullName',
        order: 'asc',
    });

    useEffect(() => {
        dispatch(
            getAllPersonsForDropDown({
                sort: { property: 'fullName', order: 'asc' },
            })
        );
    }, []);

    useEffect(() => {
        if (data.length) {
            setValue();
        }
    }, [data]);

    useEffect(() => {
        if (accessByRole('BaseTariffRates', 'getAllBaseTariffs', currentRole)) {
            dispatch(
                getAllBaseTariffs(sort, filters, setIsLoading, closeModal)
            );
        }
    }, [filters, sort]);

    const setValue = () => {
        const tariffRateHourNumbers = data
            .filter((elem) => elem.tariffRateHour)
            .map((elem) => elem.tariffRateHour);

        initPlaceholder(
            tariffRateHourNumbers,
            {
                min: tariffRateHour.placeholderMin,
                max: tariffRateHour.placeholderMax,
            },
            setTariffRateHour
        );
        const tariffRateDayNumbers = data
            .filter((elem) => elem.tariffRateDay)
            .map((elem) => elem.tariffRateDay);

        initPlaceholder(
            tariffRateDayNumbers,
            {
                min: tariffRateDay.placeholderMin,
                max: tariffRateDay.placeholderMax,
            },
            setTariffRateDay
        );
    };

    const dataForFilters = () => ({
        personsList,
        persons,
        changePersons: setPersons,
        tariffRateDay,
        changeTariffRateDay: setTariffRateDay,
        tariffRateHour,
        changeTariffRateHour: setTariffRateHour,
    });

    const handleClickSaveFilters = () => {
        setFilters((prev) => ({
            ...prev,
            personIds: persons.map((elem) => elem?.id),
            tariffRateHourFrom: tariffRateHour.from || '',
            tariffRateHourTo: tariffRateHour.to || '',
            tariffRateDayFrom: tariffRateDay.from || '',
            tariffRateDayTo: tariffRateDay.to || '',
        }));
    };

    const handleClick = (title, children) => {
        openModal({ title, children });
    };

    const onSortHandler = (header) => {
        editSort(header, sort, setSort);
    };

    const handleClickFilters = () => {
        setIsOpenFilters(true);
    };

    return (
        <>
            <PageHeader
                title="Справочник базовых тарифных ставок"
                addButton={accessByRole(
                    'BaseTariffRates',
                    'addButton',
                    currentRole
                )}
                addButtonText="Добавить базовую тарифную ставку"
                filtersButton={accessByRole(
                    'BaseTariffRates',
                    'filtersButton',
                    currentRole
                )}
                handlerClickFiltersButton={handleClickFilters}
                openAddModal={() => {
                    handleClick(
                        'Добавить базовую тарифную ставку',
                        <BaseTariffRateContent
                            closeModal={closeModal}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    );
                }}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <FiltersModal
                        isActive={isOpenFilters}
                        setIsActive={setIsOpenFilters}
                        filters={filtersAreaHandler(
                            'BASE_TARIFFS',
                            dataForFilters()
                        )}
                        onSave={handleClickSaveFilters}
                    />
                    {!data?.length ? (
                        <div className={styles.empty_block}>
                            Список тарифных ставок пуст
                        </div>
                    ) : (
                        <Table
                            header={headers.baseTariffs}
                            items={data}
                            caseName="BASE_TARIFFS"
                            editIcon={accessByRole(
                                'BaseTariffRates',
                                'editIcon',
                                currentRole
                            )}
                            openEditModal={(initial) => {
                                handleClick(
                                    'Редактирование базовой тарифной ставки',
                                    <BaseTariffRateContent
                                        closeModal={closeModal}
                                        initialValues={initial}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                    />
                                );
                            }}
                            filters={filters}
                            setFilters={setFilters}
                            onSortHandler={onSortHandler}
                            sort={sort}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default BaseTariffRates;
