import { Fragment } from 'react';
import { shape } from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const TableBody = ({ items = {} }) => {
    const cx = classNames.bind(styles);

    return (
        <tbody className={styles.table__body}>
            {items.infoByProjects.map(
                (infoByProjectsItem, infoByProjectsIndex) => (
                    <Fragment key={infoByProjectsIndex}>
                        <tr>
                            <td
                                className={cx('left_border')}
                                rowSpan={
                                    infoByProjectsItem.infoByRoles.length * 2 +
                                    infoByProjectsItem.infoByRoles.reduce(
                                        (prev, el) => {
                                            return (
                                                prev + el.infoByPeople.length
                                            );
                                        },
                                        0
                                    ) +
                                    1
                                }
                            >
                                <div>{infoByProjectsItem.projectName}</div>
                            </td>
                        </tr>
                        {infoByProjectsItem.infoByRoles.map(
                            (infoByRolesItem, infoByRolesIndex) => (
                                <Fragment key={infoByRolesIndex}>
                                    <tr>
                                        <td
                                            rowSpan={
                                                infoByRolesItem.infoByPeople
                                                    .length + 1
                                            }
                                        >
                                            <div>
                                                {infoByRolesItem.roleName}
                                            </div>
                                        </td>
                                    </tr>
                                    {infoByRolesItem.infoByPeople.map(
                                        (person, i) => (
                                            <tr key={i}>
                                                <td>{person.personName}</td>
                                                <td>
                                                    <span>
                                                        {person.totalFactHours}
                                                    </span>
                                                </td>
                                                {person.personInfoByPeriods.map(
                                                    (personFactHour, inde) => (
                                                        <td key={inde}>
                                                            <span>
                                                                {
                                                                    personFactHour.factHours
                                                                }
                                                            </span>
                                                        </td>
                                                    )
                                                )}
                                            </tr>
                                        )
                                    )}
                                    <tr className={cx('highlighted_cell')}>
                                        <td colSpan={2}>
                                            Итог по специализации{' '}
                                            {infoByRolesItem.roleName}
                                        </td>
                                        <td>
                                            <span>
                                                {infoByRolesItem.totalFactHours}
                                            </span>
                                        </td>
                                        {infoByRolesItem.roleInfoByPeriods.map(
                                            (totalFactHourByRole, i) => (
                                                <td key={i}>
                                                    <span>
                                                        {
                                                            totalFactHourByRole.factHours
                                                        }
                                                    </span>
                                                </td>
                                            )
                                        )}
                                    </tr>
                                </Fragment>
                            )
                        )}
                        <tr className={cx('highlighted_cell')}>
                            <td className={cx('left_border')} colSpan={3}>
                                Итог по проекту {infoByProjectsItem.projectName}
                            </td>
                            <td>
                                <span>{infoByProjectsItem.totalFactHours}</span>
                            </td>
                            {infoByProjectsItem.projectInfoByPeriods.map(
                                (
                                    projectInfoByPeriodsItem,
                                    projectInfoByPeriodsIndex
                                ) => (
                                    <td key={projectInfoByPeriodsIndex}>
                                        <span>
                                            {projectInfoByPeriodsItem.factHours}
                                        </span>
                                    </td>
                                )
                            )}
                        </tr>
                    </Fragment>
                )
            )}
            <tr className={cx('highlighted_cell', 'active_text')}>
                <td className={cx('left_border')}>
                    Проектов: {items?.projectCount}
                </td>
                <td>Специализаций: {items?.specialityCount}</td>
                <td>Сотрудников: {items?.personCount}</td>
                <td>
                    <span>{items?.totalFactHours}</span>
                </td>
                {items?.infoByPeriods.map(
                    (infoByPeriodsItem, infoByPeriodsIndex) => (
                        <td key={infoByPeriodsIndex}>
                            <span>{infoByPeriodsItem.factHours}</span>
                        </td>
                    )
                )}
            </tr>
        </tbody>
    );
};

export default TableBody;

TableBody.propTypes = {
    items: shape({}),
};
