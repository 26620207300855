import { bool, func, shape, string, arrayOf, instanceOf } from 'prop-types';
import DropdownList from 'components/DropdownList';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import Legend from 'components/Pages/UsersCalendar/Legend';
import KebabButton from 'components/KebabButton';
import CreatePlanKebabIcon from 'components/svg/KebabIcon/createPlanIcon';
import AddKebabIcon from 'components/svg/KebabIcon/addIcon';
import DownloadKebabIcon from 'components/svg/KebabIcon/downloadIcon';
import UploadKebabIcon from 'components/svg/KebabIcon/uploadIcon';
import BackIcon from 'components/svg/backIcon';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';

const PageHeader = ({
    title,
    addButton = false,
    editButton = false,
    button = false,
    importButton = false,
    filtersButton = false,
    logoutButton = false,
    uploadButton = false,
    calendarFilter = false,
    syncButton = false,
    isProductionPlan = false,
    legend = false,
    backButton = false,
    addButtonText = '',
    editButtonText = '',
    buttonText = '',
    buttonIconName = '',
    importButtonText = '',
    reports = [],
    productionPlansItems = [],
    selectedReport = null,
    selectedTable = null,
    selectedPeriod,
    createPlan = false,
    addPlan = false,
    downloadPlan = false,
    uploadPlan = false,
    openAddModal = () => {},
    handlerClickEdit = () => {},
    openDownloadButtonsModal = () => {},
    handlerClick = () => {},
    importButtonHandler = () => {},
    syncJira = () => {},
    changeSelectedPeriod = () => {},
    openCreatePlanModal = () => {},
    setSelectedReport = () => {},
    setSelectedTable = () => {},
    handlerUploadBtn = () => {},
    handlerClickFiltersButton = () => {},
    handleClickLogout = () => {},
}) => {
    const navigate = useNavigate();
    return (
        <div className={styles.header__main}>
            <div className={styles.header__main_group}>
                <div className={styles.header__main_title}>
                    {backButton && (
                        <div
                            className={styles.backIcon}
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <BackIcon />
                        </div>
                    )}
                    {title}
                </div>
                {productionPlansItems.length > 0 && (
                    <div>
                        <DropdownList
                            isReport
                            items={productionPlansItems}
                            selected={selectedTable}
                            setSelected={setSelectedTable}
                            selectedOnly
                            isInHeader
                        />
                    </div>
                )}
                {reports.length > 0 && (
                    <DropdownList
                        isReport
                        items={reports}
                        selected={selectedReport}
                        setSelected={setSelectedReport}
                        selectedOnly
                        isInHeader
                    />
                )}
                {calendarFilter && (
                    <div className={styles.btn_container}>
                        <DatePicker
                            noMargin
                            showMonthOnly
                            isHeader
                            dateDrop={selectedPeriod}
                            setDateDrop={changeSelectedPeriod}
                            isRemoveButton={false}
                            isRemoveButtonModal={false}
                        />
                    </div>
                )}
            </div>
            <div className={styles.header__main_group_button}>
                {addButton && (
                    <div
                        className={styles.btn_container}
                        data-tooltip-content={addButtonText}
                        data-tooltip-id="directories"
                        data-tooltip-place="bottom"
                        data-tooltip-delay-show={500}
                    >
                        <Button
                            text="Добавить"
                            onClick={openAddModal}
                            buttonIconName="plus"
                        />
                    </div>
                )}
                {editButton && (
                    <div
                        className={styles.btn_container}
                        data-tooltip-content={editButtonText}
                        data-tooltip-id="directories"
                        data-tooltip-place="bottom"
                        data-tooltip-delay-show={500}
                    >
                        <Button
                            buttonStyle="outlined"
                            text="Редактировать"
                            onClick={handlerClickEdit}
                            buttonIconName="edit"
                        />
                    </div>
                )}
                {importButton && (
                    <div
                        className={styles.btn_container}
                        data-tooltip-content={importButtonText}
                        data-tooltip-id="directories"
                        data-tooltip-place="bottom"
                        data-tooltip-delay-show={500}
                    >
                        <Button
                            buttonStyle="outlined"
                            text="Импорт"
                            onClick={importButtonHandler}
                            buttonIconName="download"
                        />
                    </div>
                )}

                {button && (
                    <div
                        className={styles.btn_container}
                        data-tooltip-content={buttonText}
                        data-tooltip-id="directories"
                        data-tooltip-place="bottom"
                        data-tooltip-delay-show={500}
                    >
                        <Button
                            text={buttonText}
                            onClick={handlerClick}
                            buttonIconName={buttonIconName}
                        />
                    </div>
                )}
                {syncButton && (
                    <div
                        className={styles.btn_container}
                        data-tooltip-content="Синхронизировать"
                        data-tooltip-id="directories"
                        data-tooltip-place="bottom"
                        data-tooltip-delay-show={500}
                    >
                        <Button
                            onClick={syncJira}
                            buttonIconName="sync"
                            buttonStyle="outlined"
                        />
                    </div>
                )}
                {uploadButton && (
                    <div
                        className={styles.btn_container}
                        data-tooltip-content="Выгрузить"
                        data-tooltip-id="directories"
                        data-tooltip-place="bottom"
                        data-tooltip-delay-show={500}
                    >
                        <Button
                            onClick={handlerUploadBtn}
                            buttonIconName="download"
                            text="Выгрузить"
                            buttonStyle="outlined"
                        />
                    </div>
                )}
                {isProductionPlan &&
                    (createPlan || addPlan || downloadPlan || uploadPlan) && (
                        <KebabButton>
                            {createPlan && (
                                <div
                                    className={styles.kebab_container}
                                    onClick={openCreatePlanModal}
                                >
                                    <CreatePlanKebabIcon />
                                    Создать план
                                </div>
                            )}
                            {addPlan && (
                                <div
                                    className={styles.kebab_container}
                                    onClick={openAddModal}
                                >
                                    <AddKebabIcon />
                                    Добавить план
                                </div>
                            )}
                            {downloadPlan && (
                                <div
                                    className={styles.kebab_container}
                                    onClick={openDownloadButtonsModal}
                                >
                                    <DownloadKebabIcon />
                                    Загрузить
                                </div>
                            )}
                            {uploadPlan && (
                                <div
                                    className={styles.kebab_container}
                                    onClick={handlerUploadBtn}
                                >
                                    <UploadKebabIcon />
                                    Выгрузить
                                </div>
                            )}
                        </KebabButton>
                    )}
                {filtersButton && selectedReport?.id !== 7 && (
                    <div className={styles.btn_container}>
                        <Button
                            text="Фильтры"
                            onClick={handlerClickFiltersButton}
                            buttonIconName="filters"
                            buttonStyle="outlined"
                        />
                    </div>
                )}
                {logoutButton && (
                    <div
                        data-tooltip-content="Выйти из системы"
                        data-tooltip-id="directories"
                        data-tooltip-place="bottom"
                        data-tooltip-delay-show={500}
                    >
                        <Button
                            text="Выйти"
                            onClick={handleClickLogout}
                            buttonIconName="logout"
                        />
                    </div>
                )}
                {legend ? (
                    <div className={styles.legend_container}>
                        <Legend />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export default PageHeader;

PageHeader.propTypes = {
    addButton: bool,
    addButtonText: string,
    buttonIconName: string,
    button: bool,
    buttonText: string,
    editButton: bool,
    editButtonText: string,
    backButton: bool,
    handlerClickEdit: func,
    reports: arrayOf(shape({})),
    productionPlansItems: arrayOf(shape({})),
    importButton: bool,
    importButtonText: string,
    importButtonHandler: func,
    openDownloadButtonsModal: func,
    calendarFilter: bool,
    selectedPeriod: instanceOf(Date),
    changeSelectedPeriod: func,
    addPlan: bool,
    openAddModal: func,
    createPlan: bool,
    openCreatePlanModal: func,
    downloadPlan: bool,
    uploadPlan: bool,
    selectedReport: shape({}),
    setSelectedReport: func,
    selectedTable: shape({}),
    setSelectedTable: func,
    title: string.isRequired,
    handlerClick: func,
    syncButton: bool,
    syncJira: func,
    handlerUploadBtn: func,
    filtersButton: bool,
    handlerClickFiltersButton: func,
    isProductionPlan: bool,
    legend: bool,
    logoutButton: bool,
    handleClickLogout: func,
    uploadButton: bool,
};
