import { arrayOf, bool, func, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import SortButton from 'components/SortButton';
import CheckBox from 'components/CheckBox';
import styles from './styles.module.scss';

const TableHeader = ({
    caseName = '',
    header = [],
    data = [],
    tableIcons = false,
    sort = {},
    onSortHandler = () => {},
    changeData = () => {},
    setCheckedHandlerAll = () => {},
}) => {
    const cx = classNames.bind(styles);
    const isPlan =
        caseName === 'PRODUCTION_PLANS' || caseName === 'UNUSED_PERSONS';
    const isPersons = caseName === 'PERSONS_CV' || caseName === 'PERSONS';

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        if (caseName === 'PERSON_IMPORT' || caseName === 'PROJECTS_IMPORT') {
            setIsChecked(data.every((item) => item.items.synchronize === true));
        }
        if (caseName === 'REPORT_CARD_ERROR') {
            setIsChecked(
                data.every((item) => item.availableInAccounting === true)
            );
        }
    }, [caseName, data]);

    const handleChecked = (value) => {
        if (caseName === 'REPORT_CARD_ERROR') {
            setCheckedHandlerAll(value);
        } else {
            changeData((prev) =>
                prev.map((elem) => ({ ...elem, synchronize: value }))
            );
        }
    };

    return (
        <thead className={cx('table__header')}>
            <tr>
                {header.map((item, index) => (
                    <td
                        className={cx(
                            'table__header_cell',
                            {
                                'table__header_cell-plan': isPlan,
                            },
                            {
                                'table__header_cell-persons': isPersons,
                            }
                        )}
                        key={index}
                    >
                        <div
                            className={cx(
                                'table__header_cell_content',
                                {
                                    'table__header_cell_content-plan': isPlan,
                                },
                                {
                                    'table__header_cell_content-persons':
                                        isPersons,
                                },
                                {
                                    table__header_cell_checkbox:
                                        item.dtoKey === 'synchronize',
                                }
                            )}
                        >
                            <div
                                className={cx(
                                    'table__header_cell_text',
                                    {
                                        'table__header_cell_text-plan': isPlan,
                                    },
                                    {
                                        'table__header_cell_text-persons':
                                            isPersons,
                                    }
                                )}
                            >
                                {item.sortable ? (
                                    <button
                                        type="button"
                                        onClick={() =>
                                            onSortHandler(item?.dtoKey)
                                        }
                                    >
                                        {item.fullName ?? item.name}
                                    </button>
                                ) : (
                                    <span>{item.fullName ?? item.name}</span>
                                )}
                                {item.dtoKey === 'synchronize' ? (
                                    <div
                                        className={
                                            styles.table__body_cell_checkbox
                                        }
                                        data-tooltip-content="Выбрать/Снять всё"
                                        data-tooltip-id="directories"
                                        data-tooltip-place="right"
                                        data-tooltip-delay-show={500}
                                    >
                                        <CheckBox
                                            isCell
                                            checked={isChecked}
                                            setChecked={(value) => {
                                                handleChecked(value);
                                            }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            {item.sortable ? (
                                <span className={cx('table__header_cell_sort')}>
                                    <SortButton
                                        onSortHandler={onSortHandler}
                                        sort={sort}
                                        header={item.dtoKey}
                                    />
                                </span>
                            ) : null}
                        </div>
                    </td>
                ))}
                {tableIcons && (
                    <td className={cx(`table__header_cell-button`)} />
                )}
            </tr>
        </thead>
    );
};

export default TableHeader;

TableHeader.propTypes = {
    caseName: string,
    header: arrayOf(shape({})),
    tableIcons: bool,
    data: arrayOf(shape({})),
    sort: shape({}),
    onSortHandler: func,
    changeData: func,
    setCheckedHandlerAll: func,
};
