import { arrayOf, bool, func, shape, string } from 'prop-types';
import classNames from 'classnames/bind';
import DropdownList from 'components/DropdownList';
import ActiveStatus from 'components/ActiveStatus';
import BudgetLeftoversContent from 'components/ModalsContent/BudgetLeftoversContent';
import { removeNumbersSpacing } from 'helpers/tableHandler';
import { ButtonsForTable } from 'components/Tables/Table/TableBody/helpers';

import CheckBox from 'components/CheckBox';
import Input from 'components/Input';
import styles from 'components/Tables/Table/TableBody/styles.module.scss';
import KebabMenu from './KebabMenu';

const TableBody = ({
    items = [],
    dropDownItems = [],
    loockIcon = false,
    deleteIcon = false,
    editIcon = false,
    addPersonIcon = false,
    lockPersonIcon = false,
    loockProdPlanIcon = false,
    infoIcon = false,
    changeComment = false,
    openEditModal = () => {},
    caseName = '',
    openProdPlanModal = () => {},
    openDeleteModal = () => {},
    changeCvCommentModel = () => {},
    openCvPerson = () => {},
    openJournalModal = () => {},
    handleSelect = () => {},
    onChangeInput = () => {},
    setChecked = () => {},
}) => {
    const cx = classNames.bind(styles);
    const isPlan =
        caseName === 'PRODUCTION_PLANS' || caseName === 'UNUSED_PERSONS';
    const isBalanceBudgets = caseName === 'BALANCE_BUDGETS';

    const setCheckedHandler = (item) => {
        if (caseName === 'PERSON_IMPORT') {
            setChecked(item.items.emailAddress);
        }
        if (caseName === 'PROJECTS_IMPORT') {
            setChecked(item.id);
        }
        return null;
    };

    return (
        <tbody>
            {items.map((item, index) => (
                <tr key={index}>
                    {Object.values(item.items)?.map((cell, i) => (
                        <td
                            key={i}
                            className={cx('table__body_cell', {
                                first_column:
                                    caseName === 'PRODUCTION_PLANS' ||
                                    caseName === 'BALANCE_BUDGETS' ||
                                    caseName === 'UNUSED_PERSONS',

                                validity_yellow:
                                    (caseName === 'PRODUCTION_PLANS' &&
                                        item?.additionalData?.validity === 1) ||
                                    (caseName === 'BASE_TARIFFS' &&
                                        (!item?.items?.tariffRateHour ||
                                            !item?.items?.tariffRateDay)),
                                validity_orange:
                                    caseName === 'PRODUCTION_PLANS' &&
                                    item?.additionalData?.validity === 2,

                                table__body_cell__deviation:
                                    (caseName === 'PRODUCTION_PLANS' &&
                                        removeNumbersSpacing(cell) < 0 &&
                                        (i === 11 || i === 12)) ||
                                    (caseName === 'UNUSED_PERSONS' &&
                                        removeNumbersSpacing(cell) < 0 &&
                                        (i === 6 || i === 7)),

                                table__body_cell__deviation_plus:
                                    (caseName === 'PRODUCTION_PLANS' &&
                                        removeNumbersSpacing(cell) > 0 &&
                                        (i === 11 || i === 12)) ||
                                    (caseName === 'UNUSED_PERSONS' &&
                                        removeNumbersSpacing(cell) > 0 &&
                                        (i === 6 || i === 7)),
                            })}
                        >
                            <div
                                className={cx(
                                    {
                                        table__body_cell_text:
                                            Object.keys(item.items)[i] !==
                                            'status',
                                    },
                                    {
                                        'table__body_cell_text-status':
                                            Object.keys(item.items)[i] ===
                                            'status',
                                    },
                                    {
                                        'table__body_cell_text-checkbox':
                                            Object.keys(item.items)[i] ===
                                            'synchronize',
                                    },
                                    {
                                        'table__body_cell_text-plan': isPlan,
                                    },
                                    {
                                        'table__body_cell_text-center':
                                            (isPlan && i > 5) ||
                                            (caseName === 'UNUSED_PERSONS' &&
                                                i > 0) ||
                                            (isBalanceBudgets && i > 0),
                                    },
                                    {
                                        'table__body_cell_text-budget':
                                            Object.keys(item.items)[i] ===
                                            'budget',
                                    },
                                    {
                                        'table__body_cell_text-comment':
                                            Object.keys(item.items)[i] ===
                                            'comment',
                                    },
                                    {
                                        'table__body_cell_text-description':
                                            caseName === 'ERROR_MODAL',
                                    }
                                )}
                            >
                                {Object.keys(item.items)[i] === 'status' && (
                                    <ActiveStatus isActive={cell} />
                                )}
                                {caseName === 'PROJECTS_IMPORT' &&
                                Object.keys(item.items)[i] === 'projectId' ? (
                                    <DropdownList
                                        isCell
                                        items={dropDownItems.filter(
                                            (itemProject) =>
                                                itemProject.isSelected === false
                                        )}
                                        selected={dropDownItems.find(
                                            (elem) =>
                                                elem.id === item.items.projectId
                                        )}
                                        setSelected={(event) => {
                                            handleSelect(item, event);
                                        }}
                                    />
                                ) : caseName === 'PERSON_IMPORT' &&
                                  Object.keys(item.items)[i] ===
                                      'russianName' ? (
                                    <Input
                                        isTableCell
                                        value={item.items.russianName}
                                        onChange={(event) => {
                                            onChangeInput(
                                                event,
                                                item.items.emailAddress
                                            );
                                        }}
                                    />
                                ) : (caseName === 'PERSON_IMPORT' ||
                                      caseName === 'PROJECTS_IMPORT') &&
                                  Object.keys(item.items)[i] ===
                                      'synchronize' ? (
                                    <div
                                        className={
                                            styles.table__body_cell_checkbox
                                        }
                                    >
                                        <CheckBox
                                            isCell
                                            checked={item.items.synchronize}
                                            setChecked={() => {
                                                setCheckedHandler(item);
                                            }}
                                        />
                                    </div>
                                ) : caseName === 'ERROR_MODAL' &&
                                  Object.keys(item.items)[i] ===
                                      'description' ? (
                                    cell.split(', ').map((str, ind) => (
                                        <>
                                            <div key={ind}>{str}</div>
                                            <br />
                                        </>
                                    ))
                                ) : cell &&
                                  typeof cell === 'object' &&
                                  !cell.length ? (
                                    cell.value
                                ) : (
                                    cell
                                )}
                            </div>
                        </td>
                    ))}
                    {(deleteIcon ||
                        editIcon ||
                        loockIcon ||
                        addPersonIcon ||
                        lockPersonIcon ||
                        loockProdPlanIcon ||
                        infoIcon) && (
                        <td className={styles.table__body_cell}>
                            <div className={styles.table__body_cellicons}>
                                <KebabMenu>
                                    {editIcon && (
                                        <ButtonsForTable
                                            text="Редактировать"
                                            iconName="edit"
                                            className="table__body_icon"
                                            cklick={() => openEditModal(item)}
                                        />
                                    )}
                                    {changeComment && (
                                        <ButtonsForTable
                                            text="Комментарий"
                                            iconName="edit"
                                            className="table__body_icon"
                                            cklick={() =>
                                                changeCvCommentModel(item)
                                            }
                                        />
                                    )}
                                    {loockIcon && (
                                        <ButtonsForTable
                                            text="Посмотреть"
                                            iconName="loock"
                                            className="table__body_icon"
                                            cklick={() => openCvPerson(item)}
                                        />
                                    )}
                                    {loockProdPlanIcon && (
                                        <ButtonsForTable
                                            text="План произв"
                                            iconName="loockProdPlan"
                                            className="table__body_icon"
                                            cklick={() =>
                                                openProdPlanModal(item)
                                            }
                                        />
                                    )}
                                    {deleteIcon && (
                                        <ButtonsForTable
                                            text="Удалить"
                                            iconName="delete"
                                            className="table__body_icon"
                                            cklick={() => openDeleteModal(item)}
                                        />
                                    )}
                                    {/* {Object.keys(item.items)[i] ===
                                        'budget' && (
                                        <BudgetLeftoversContent
                                            itemId={item.id}
                                        />
                                    )} */}
                                    {addPersonIcon &&
                                        lockPersonIcon &&
                                        (item.items.status ? (
                                            <ButtonsForTable
                                                text="Заблокировать"
                                                iconName="lockPerson"
                                                className="table__body_icon"
                                                cklick={() =>
                                                    openDeleteModal(item)
                                                }
                                            />
                                        ) : (
                                            <ButtonsForTable
                                                text="Разблокировать"
                                                iconName="addPerson"
                                                className="table__body_icon"
                                                cklick={() =>
                                                    openDeleteModal(item)
                                                }
                                            />
                                        ))}
                                    {caseName === 'PRODUCTION_PLANS' &&
                                        infoIcon && (
                                            <ButtonsForTable
                                                text="Инфо"
                                                iconName="info"
                                                className="table__body_icon"
                                                cklick={() => {
                                                    openJournalModal(item.id);
                                                }}
                                            />
                                        )}
                                </KebabMenu>
                            </div>
                        </td>
                    )}
                </tr>
            ))}
        </tbody>
    );
};

export default TableBody;

TableBody.propTypes = {
    items: arrayOf(shape({})),
    dropDownItems: arrayOf(shape({})),
    caseName: string,
    loockIcon: bool,
    deleteIcon: bool,
    editIcon: bool,
    addPersonIcon: bool,
    lockPersonIcon: bool,
    loockProdPlanIcon: bool,
    infoIcon: bool,
    changeComment: bool,
    openEditModal: func,
    openDeleteModal: func,
    openCvPerson: func,
    changeCvCommentModel: func,
    openJournalModal: func,
    handleSelect: func,
    onChangeInput: func,
    setChecked: func,
    openProdPlanModal: func,
};
