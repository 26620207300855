import { GET_SYSTEMS_DATA } from 'redux/types';

const defaultState = {
    data: [],
    DropdownList: [],
};

export const systems = (state = defaultState, action) => {
    switch (action.type) {
        case GET_SYSTEMS_DATA:
            return {
                ...state,
                data: action.payload,
                DropdownList: action.payload.map((el) => ({
                    id: el.id,
                    value: el.name,
                })),
            };
        default:
            return state;
    }
};
