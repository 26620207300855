import {
    ADD_BASE_TARIFF_RATE_DATA,
    EDIT_BASE_TARIFF_RATE_DATA,
    GET_BASE_TARIFF_RATES_DATA,
    GET_BASE_TARIFF_RATES_FOR_SELECTED_PERSON,
} from 'redux/types';

export const addBaseTariffRateData = (data) => ({
    type: ADD_BASE_TARIFF_RATE_DATA,
    payload: data,
});

export const editBaseTariffRateData = (data, id) => ({
    type: EDIT_BASE_TARIFF_RATE_DATA,
    payload: { data, id },
});

export const getBaseTariffRatesData = (data) => ({
    type: GET_BASE_TARIFF_RATES_DATA,
    payload: data,
});

export const getBaseTariffRatesForSelectedPerson = (data) => ({
    type: GET_BASE_TARIFF_RATES_FOR_SELECTED_PERSON,
    payload: data,
});
