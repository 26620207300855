import {
    arrayOf,
    bool,
    func,
    oneOfType,
    shape,
    string,
    number,
} from 'prop-types';
import { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import TableHeader from 'components/Tables/Table/TableHeader';
import TableBody from 'components/Tables/Table/TableBody';
import { bodyHandler } from 'helpers/tableHandler';
import TabelHeaderForProdPlan from './TabelHeaderForProdPlan';
import styles from './styles.module.scss';

const Table = ({
    caseName,
    items = [],
    dropDownItems = [],
    header,
    loockIcon = false,
    deleteIcon = false,
    editIcon = false,
    addPersonIcon = false,
    lockPersonIcon = false,
    loockProdPlanIcon = false,
    infoIcon = false,
    changeComment = false,
    openCvPerson = () => {},
    openEditModal = () => {},
    openDeleteModal = () => {},
    changeCvCommentModel = () => {},
    openJournalModal = () => {},
    handleSelect = () => {},
    onChangeInput = () => {},
    setChecked = () => {},
    sort = {},
    onSortHandler = () => {},
    openProdPlanModal = () => {},
    changeData = () => {},
    tableScroll = {},
    changeTableScroll = () => {},
}) => {
    const cx = classNames.bind(styles);

    const tableWrapperRef = useRef();

    const handleTableScroll = (event) => {
        changeTableScroll(event.target.scrollLeft, event.target.scrollTop);
    };

    useEffect(() => {
        tableWrapperRef.current.scrollTo(tableScroll.left, tableScroll.top);
    });

    return (
        <div
            ref={tableWrapperRef}
            onScroll={handleTableScroll}
            className={cx('table__wrapper', {
                table__wrapper_persons: caseName === 'PERSONS',
                table__wrapper_plan: caseName === 'PRODUCTION_PLANS',
                table__wrapper_unused_person: caseName === 'UNUSED_PERSONS',
                table__wrapper_persons_cv: caseName === 'PERSONS_CV',
                table__wrapper_balance_budgets: caseName === 'BALANCE_BUDGETS',
                table__wrapper_project_import: caseName === 'PROJECTS_IMPORT',
                table__wrapper_persons_import: caseName === 'PERSON_IMPORT',
            })}
        >
            <table className={styles.table_main}>
                {caseName === 'PRODUCTION_PLANS' ||
                caseName === 'UNUSED_PERSONS' ||
                caseName === 'BALANCE_BUDGETS' ? (
                    <TabelHeaderForProdPlan
                        caseName={caseName}
                        header={header}
                        tableIcons={
                            items.length > 0 &&
                            (deleteIcon ||
                                editIcon ||
                                loockIcon ||
                                addPersonIcon ||
                                lockPersonIcon ||
                                loockProdPlanIcon ||
                                infoIcon)
                        }
                        sort={sort}
                        onSortHandler={onSortHandler}
                    />
                ) : (
                    <TableHeader
                        caseName={caseName}
                        header={header}
                        data={bodyHandler(caseName, items)}
                        tableIcons={
                            items.length > 0 &&
                            (deleteIcon || editIcon || loockIcon)
                        }
                        sort={sort}
                        onSortHandler={onSortHandler}
                        changeData={changeData}
                    />
                )}

                <TableBody
                    items={bodyHandler(caseName, items)}
                    dropDownItems={dropDownItems}
                    caseName={caseName}
                    loockIcon={loockIcon}
                    deleteIcon={deleteIcon}
                    editIcon={editIcon}
                    loockProdPlanIcon={loockProdPlanIcon}
                    infoIcon={infoIcon}
                    addPersonIcon={addPersonIcon}
                    lockPersonIcon={lockPersonIcon}
                    changeComment={changeComment}
                    openCvPerson={openCvPerson}
                    openEditModal={openEditModal}
                    openDeleteModal={openDeleteModal}
                    changeCvCommentModel={changeCvCommentModel}
                    openProdPlanModal={openProdPlanModal}
                    openJournalModal={openJournalModal}
                    handleSelect={handleSelect}
                    onChangeInput={onChangeInput}
                    setChecked={setChecked}
                />
            </table>
        </div>
    );
};

export default Table;

Table.propTypes = {
    header: oneOfType([arrayOf(shape({})), arrayOf(arrayOf(shape({})))]),
    items: arrayOf(shape({})),
    dropDownItems: arrayOf(shape({})),
    caseName: string,
    openCvPerson: func,
    openEditModal: func,
    openDeleteModal: func,
    changeCvCommentModel: func,
    openJournalModal: func,
    handleSelect: func,
    onChangeInput: func,
    setChecked: func,
    loockProdPlanIcon: bool,
    loockIcon: bool,
    deleteIcon: bool,
    editIcon: bool,
    addPersonIcon: bool,
    lockPersonIcon: bool,
    infoIcon: bool,
    changeComment: bool,
    sort: shape({}),
    onSortHandler: func,
    openProdPlanModal: func,
    changeData: func,
    tableScroll: shape({}),
    changeTableScroll: func,
};
