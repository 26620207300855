import { useRef, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import classNames from 'classnames/bind';
import PlusIcon from 'components/svg/ButtonIcon/plus';
import useOnClickOutside from 'hooks/useOnClickOutside';
import styles from './styles.module.scss';

const CVInput = ({
    hidePlus = false,
    foundedTags = [],
    addNewTag = () => {},
    inputValue = '',
    placeholder = '',
    setInputValue = () => {},
}) => {
    const cx = classNames.bind(styles);
    const [listsDropIsOpen, setListsDropIsOpen] = useState(false);
    const finalSetValue = (e) => setInputValue(e.target.value);

    const toggleDrop = (e) => {
        if (e.target.value === undefined) {
            setListsDropIsOpen(!listsDropIsOpen);
        } else setListsDropIsOpen(true);
    };
    const closeDrop = () => {
        setListsDropIsOpen(false);
    };
    const ref = useRef();
    useOnClickOutside(ref, closeDrop);

    const onEnterPressed = (e) => {
        if (e.key === 'Enter' && !hidePlus) {
            addNewTag(inputValue);
        }
    };

    return (
        <div className={cx('addNewTool_dropdownTool')} ref={ref}>
            <div className={cx('addNewTool')} onClick={(e) => toggleDrop(e)}>
                <input
                    type="text"
                    value={inputValue}
                    onChange={finalSetValue}
                    placeholder={placeholder}
                    onKeyPress={onEnterPressed}
                />
                {!hidePlus && (
                    <button type="button" onClick={() => addNewTag(inputValue)}>
                        <PlusIcon />
                    </button>
                )}
            </div>
            {listsDropIsOpen && foundedTags?.length > 0 && (
                <div className={cx('dropdownTool')}>
                    {foundedTags.map((toolTag) => (
                        <span
                            key={toolTag.id}
                            onClick={() => addNewTag(toolTag.title)}
                        >
                            {toolTag.title}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CVInput;

CVInput.propTypes = {
    foundedTags: arrayOf(shape({})),
    addNewTag: func,
    inputValue: string,
    setInputValue: func,
    placeholder: string,
    hidePlus: bool,
};
