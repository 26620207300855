import { Fragment } from 'react';
import { shape } from 'prop-types';
import { numbersDepth } from 'helpers/tableHandler';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const TableBody = ({ items = {} }) => {
    const cx = classNames.bind(styles);

    return (
        <tbody className={styles.table__body}>
            {items.infoByPersons.map((item, index) => (
                <Fragment key={index}>
                    <tr>
                        <td
                            className={cx('left_border')}
                            rowSpan={item.infoByProjects.length + 1}
                        >
                            <div>{item.personName}</div>
                        </td>
                    </tr>
                    {item?.infoByProjects.map((project, ind) => (
                        <Fragment key={ind}>
                            <tr>
                                <td>
                                    <div>{project.projectName}</div>
                                </td>
                                <td>
                                    <span>
                                        {numbersDepth(
                                            project.totalPlanExpenses
                                        )}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {numbersDepth(
                                            project.totalFactExpenses
                                        )}
                                    </span>
                                </td>
                                {project.projectInfoByPeriods.map(
                                    (projectValue, i) => (
                                        <Fragment key={i}>
                                            <td>
                                                <span>
                                                    {numbersDepth(
                                                        projectValue.projectPlanExpenses
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {numbersDepth(
                                                        projectValue.projectFactExpenses
                                                    )}
                                                </span>
                                            </td>
                                        </Fragment>
                                    )
                                )}
                            </tr>
                        </Fragment>
                    ))}
                </Fragment>
            ))}
        </tbody>
    );
};
export default TableBody;

TableBody.propTypes = {
    items: shape({}),
};
