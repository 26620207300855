import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageHeader from 'components/PageHeader';
import {
    PersonDirectorisIcon,
    OrganizationsDirectoriesIcon,
    SpecialtiesDirectoriesIcon,
    BaseTariffDirectoriesIcon,
    PersonsCVDirectoriesIcon,
} from 'components/svg/DirectoriesIcon/directoriesIcon';
import styles from './styles.module.scss';

const directoriesContent = [
    {
        id: 1,
        title: `Справочник организаций`,
        path: '/directories/organizations',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'ACCOUNTANT'],
    },
    {
        id: 2,
        title: 'Справочник специализаций',
        path: '/directories/specialties',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
    },
    {
        id: 3,
        title: 'Справочник сотрудников',
        path: '/directories/workers',
        accessToPages: [
            'ADMIN',
            'SUPERVISOR',
            'MANAGER',
            'ACCOUNTANT',
            'HR',
            'PS',
        ],
    },
    {
        id: 4,
        title: 'Справочник базовых тарифных ставок',
        path: '/directories/base-tariff-rates',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'ACCOUNTANT'],
    },
    {
        id: 5,
        title: 'Справочник CV',
        path: '/directories/persons-cv',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
    },
];

const Directories = () => {
    const navigate = useNavigate();
    const currentRole = useSelector((state) => state.auth.userData.userRole);

    const filteredDirectories = directoriesContent.filter(
        (item) => item.accessToPages.indexOf(currentRole) !== -1
    );
    const directoriesIcon = (id) => {
        switch (id) {
            case 1: {
                return <OrganizationsDirectoriesIcon />;
            }
            case 2: {
                return <SpecialtiesDirectoriesIcon />;
            }
            case 3: {
                return <PersonDirectorisIcon />;
            }
            case 4: {
                return <BaseTariffDirectoriesIcon />;
            }
            case 5: {
                return <PersonsCVDirectoriesIcon />;
            }
            default: {
                return '';
            }
        }
    };
    useEffect(
        () =>
            filteredDirectories.length === 1 &&
            navigate(filteredDirectories[0].path),
        []
    );

    return (
        <div>
            <PageHeader title="Справочники" />
            <div>
                <div className={styles.directories__main_grid}>
                    {filteredDirectories?.map((item) => (
                        <Link
                            key={item.id}
                            className={styles.directories__main_grid_item}
                            to={`${item.path}`}
                        >
                            <div
                                className={
                                    styles.directories__main_grid_item_icon
                                }
                            >
                                {directoriesIcon(item.id)}
                            </div>
                            <div
                                className={
                                    styles.directories__main_grid_item_text
                                }
                            >
                                {item.title}
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Directories;
