import axios from 'axios';

class UnauthorizedError extends Error {
    constructor(message) {
        super(message);
        this.name = 'UNAUTHORIZED';
    }
}

const getQueryParams = (queryParams) => {
    const query = [];
    const queryKeys = Object.keys(queryParams);
    for (let i = 0; i < queryKeys.length; i += 1) {
        if (Array.isArray(queryParams[queryKeys[i]])) {
            query.push(
                ...queryParams[queryKeys[i]].map(
                    (soloObjectParam) =>
                        `${queryKeys[i]}=${String(soloObjectParam).replaceAll(
                            '+',
                            '%2b'
                        )}`
                )
            );
        } else {
            query.push(
                `${queryKeys[i]}=${String(queryParams[queryKeys[i]]).replaceAll(
                    '+',
                    '%2b'
                )}`
            );
        }
    }
    return query.join('&');
};

const options = ({
    method,
    url,
    body,
    queryParams,
    headers,
    responseType,
    type,
}) => {
    const tokenType = 'Bearer';
    const accessToken = localStorage.getItem('authToken')
        ? window.atob(localStorage.getItem('authToken'))
        : null;
    const urlPath = `/${url ?? ''}${
        queryParams && Object.keys(queryParams).length !== 0
            ? `?${getQueryParams(queryParams)}`
            : ''
    }`;
    return {
        method,
        type,
        url: urlPath,
        ...(responseType && { responseType }),
        headers: {
            ...headers,
            ...(accessToken && {
                Authorization: `${tokenType} ${accessToken}`,
            }),
            'Access-Control-Allow-Origin': '*',
        },
        data: body ?? null,
    };
};

axios.interceptors.response.use(
    (res) => res,
    async (error) => {
        const triggerRequest = error.config;
        if (error.response.data.error === 'UNAUTHORIZED') {
            if (triggerRequest?.type !== 'refresh') {
                localStorage.setItem('isRefresh', true);
                const refreshToken = localStorage.getItem('refreshToken')
                    ? window.atob(localStorage.getItem('refreshToken'))
                    : null;
                try {
                    const res = await requestList().auth.refresh(refreshToken);
                    const authTokenEncoded = window.btoa(
                        res?.data?.entity?.token
                    );
                    localStorage.setItem('authToken', authTokenEncoded);
                    const refreshTokenEncoded = window.btoa(
                        res?.data?.entity?.refreshToken
                    );
                    localStorage.setItem('refreshToken', refreshTokenEncoded);
                    localStorage.setItem('isRefresh', false);
                    triggerRequest.headers.Authorization = `Bearer ${res?.data?.entity?.token}`;
                    const response = await axios(triggerRequest);
                    return response;
                } catch (err) {
                    if (err.response) {
                        throw new Error(err.response.data.error_description);
                    }
                }
            }
            throw new UnauthorizedError(error.response.data.error_description);
        }
        throw new Error(
            error.response.data?.error_description ||
                error.response.data?.message
        );
    }
);

const request = async ({ ...props }) => {
    try {
        const res = await axios(options(props));
        return res;
    } catch (err) {
        if (err.name === 'UNAUTHORIZED') {
            throw new UnauthorizedError(err.message);
        }
        throw new Error(err.message);
    }
};

const requestList = () => {
    const requestMethod =
        ({ method, ...opts }) =>
        (args) =>
            request({
                method,
                ...args,
                ...opts,
            });

    const refresh = requestMethod({ method: 'POST', type: 'refresh' });
    const get = requestMethod({ method: 'GET' });
    const post = requestMethod({ method: 'POST' });
    const put = requestMethod({ method: 'PUT' });
    const remove = requestMethod({ method: 'DELETE' });
    const patch = requestMethod({ method: 'PATCH' });

    const auth = (url) => ({
        login: (token, type) =>
            post({
                url: `${url}?id_token=${token}&type=${type}`,
            }),
        refresh: (refreshToken) =>
            refresh({
                url: `${url}?refresh_token=${refreshToken}`,
            }),
    });

    const users = (url) => ({
        getUser: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        editUser: (id, data) =>
            put({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        deleteUser: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
        getAllUsers: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addUser: (data) =>
            post({
                url,
                body: { ...data },
            }),
    });

    const budget = (url) => ({
        getBudget: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        editBudget: (id) =>
            put({
                url: `${url}/${id}`,
            }),
        deleteBudget: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
        getAllBudgets: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addBudget: (data) =>
            post({
                url,
                body: { ...data },
            }),
        calcBudget: (id) =>
            post({
                url: `${url}/${id}`,
            }),
    });

    const errorJour = (url) => ({
        getErrorJour: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
    });

    const organizations = (url) => ({
        getOrganization: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        editOrganization: (id, name) =>
            put({
                url: `${url}/${id}`,
                body: { name },
            }),
        deleteOrganization: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
        getAllOrganizations: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addOrganization: (data) =>
            post({
                url,
                body: { ...data },
            }),
    });

    const persons = (url) => ({
        getPerson: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        getUserProfile: (params) =>
            get({
                url: `${url}/userProfile`,
                queryParams: { ...params },
            }),
        editPerson: (id, data) =>
            put({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        editStatusPerson: (id, data) =>
            patch({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        deletePerson: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
        getAllPersons: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addPerson: (data) =>
            post({
                url,
                body: { ...data },
            }),
    });

    const plans = (url) => ({
        getPlan: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        editPlan: (id, data) =>
            put({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        deletePlan: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
        getAllPlans: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addPlan: (data) =>
            post({
                url,
                body: { ...data },
            }),
        deletePlanByDate: (year, month) =>
            remove({
                url: `${url}/${year}/${month}`,
            }),
        createPlan: (params) =>
            post({
                url: `${url}/all`,
                queryParams: { ...params },
            }),
        // Через этот еndpoint осуществлялась выгрузка production-plan

        // unload: (params) =>
        //     get({
        //         url: `${url}/upload-production-plan`,
        //         queryParams: { ...params },
        //     }),
        resolvePlan: (id) =>
            put({
                url: `${url}/${id}/validated`,
            }),
    });

    const specialties = (url) => ({
        getSpeciality: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        editSpeciality: (id, name) =>
            put({
                url: `${url}/${id}`,
                body: { name },
            }),
        deleteSpeciality: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
        getAllSpecialties: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addSpeciality: (data) =>
            post({
                url,
                body: { ...data },
            }),
    });

    const positions = (url) => ({
        getPosition: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        editPosition: (id, name) =>
            put({
                url: `${url}/${id}`,
                body: { name },
            }),
        deletePosition: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
        getAllPositions: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addPosition: (data) =>
            post({
                url,
                body: { ...data },
            }),
    });

    const competence = (url) => ({
        getAllCompetences: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
    });

    const projects = (url) => ({
        getProject: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        editProject: (id, data) =>
            put({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        deleteProject: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
        getAllProjects: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addProject: (data) =>
            post({
                url,
                body: { ...data },
            }),
    });

    const baseTariffs = (url) => ({
        getBaseTariff: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        editBaseTariff: (id, data) =>
            put({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        getAllBaseTariffs: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addBaseTariff: (data) =>
            post({
                url,
                body: { ...data },
            }),
        getBaseTariffByPerson: (personId) =>
            get({
                url: `${url}/byPerson/${personId}`,
            }),
    });

    const periods = (url) => ({
        getTeamPeriod: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        editTeamPeriod: (id, data) =>
            patch({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        deleteTeamPeriod: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
        getAllTeamsPeriods: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addTeamPeriod: (data) =>
            post({
                url,
                body: { ...data },
            }),
    });

    const teams = (url) => ({
        getTeam: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        editTeam: (id, data) =>
            put({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        deleteTeam: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
        getAllTeams: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addTeam: (data) =>
            post({
                url,
                body: { ...data },
            }),
    });
    const upload = (url) => ({
        fact: (file) =>
            post({
                url: `${url}/upload/fact`,
                body: file,
            }),
        plan: (file) =>
            post({
                url: `${url}/upload/timesheet-data`,
                body: file,
            }),
        distributionFactHours: (data) =>
            post({
                url: `${url}/import/timesheet-data`,
                body: data,
            }),
        ProductionPlan: (file) =>
            post({
                url: `${url}/upload/import-production-plan`,
                body: file,
            }),
    });
    const unload = (url) => ({
        productionPlan: (params) =>
            get({
                url: `${url}/production-plans/unload`,
                queryParams: { ...params },
            }),
        unusedPersons: (params) =>
            get({
                url: `${url}/unused-persons/unload`,
                queryParams: { ...params },
            }),
        balanceBudgets: (params) =>
            get({
                url: `${url}/view-balance-budgets/unload`,
                queryParams: { ...params },
            }),
        cvPerson: (cvId) =>
            get({
                url: `${url}/persons-info/${cvId}/unload`,
                responseType: 'arraybuffer',
            }),
    });
    const reports = (url) => ({
        getProjectReport: (params) =>
            get({
                url: `${url}/by-project`,
                queryParams: { ...params },
            }),
        getPersonsReport: (params) =>
            get({
                url: `${url}/by-person`,
                queryParams: { ...params },
            }),
        getProjectTypeReport: (params) =>
            get({
                url: `${url}/by-project-type`,
                queryParams: { ...params },
            }),
        getPersonsWorkloadReport: (params) =>
            get({
                url: `${url}/by-person-workload`,
                queryParams: { ...params },
            }),
        getProjectWorkloadReport: (params) =>
            get({
                url: `${url}/by-project-workload`,
                queryParams: { ...params },
            }),
        getManagerKpiReport: (params) =>
            get({
                url: `${url}/by-manager`,
                queryParams: { ...params },
            }),
        getProjectsQuartersReport: (params) =>
            get({
                url: `${url}/by-project-quarter/`,
                queryParams: { ...params },
            }),
        getUnusedPersonsReport: (params) =>
            get({
                url: `${url}/by-person-unused`,
                queryParams: { ...params },
            }),
        getPersonsExperienceReport: (params) =>
            get({
                url: `${url}/by-person-experience`,
                queryParams: { ...params },
            }),
        getBalanceBudgetsReport: (params) =>
            get({
                url: `api/view-balance-budgets`,
                queryParams: { ...params },
            }),
        getTeamPeriods: (params) =>
            get({
                url: `${url}/team-periods`,
                queryParams: { ...params },
            }),
        getTeamPeriodPersons: (params) =>
            get({
                url: `${url}/team-period-persons`,
                queryParams: { ...params },
            }),
        getCompetenceLevel: (params) =>
            get({
                url: `${url}/by-person-competence`,
                queryParams: { ...params },
            }),
    });
    const unusedPersons = (url) => ({
        getAllUnusedPersons: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        editUnusedPerson: (id, data) =>
            put({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        deleteUnusedPerson: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
    });
    const UsersCalendar = (url) => ({
        getCalendar: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        addCalendar: (data) =>
            post({
                url,
                body: { ...data },
            }),
        editCalendar: (id, data) =>
            put({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        gerCalendarAggregate: (params) =>
            get({
                url: `${url}/aggregate`,
                queryParams: { ...params },
            }),
        deleteUserCalendar: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
    });
    const personsInfo = (url) => ({
        getAllPersonInfo: (params) =>
            get({
                url: `${url}`,
                queryParams: { ...params },
            }),
        getPersonCV: (id) =>
            get({
                url: `${url}/${id}`,
            }),
        postPersonCv: (data) =>
            post({
                url: `${url}`,
                body: { ...data },
            }),
        editPersonCV: (id, data) =>
            put({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        editCommentCV: (id, comment) =>
            put({
                url: `${url}/${id}/comment/${comment}`,
            }),
        deleteCvPerson: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
    });
    const projectInfo = (url) => ({
        getProjectCV: (id, filters) =>
            get({
                url: `${url}/${id}`,
                body: { ...filters },
            }),
        postProjectsCV: (data) =>
            post({
                url: `${url}/all`,
                body: [...data],
            }),
        editProjectCV: (id, data) =>
            put({
                url: `${url}/${id}`,
                body: { ...data },
            }),
        editProjectsCV: (data) =>
            put({
                url: `${url}/all`,
                body: [...data],
            }),
        removeProjectCV: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
    });
    const cvToolTags = (url) => ({
        getCvToolTags: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
    });
    const cvProjectsParticipants = (url) => ({
        getProjectsParticipants: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
        postProjectsParticipants: (data) =>
            post({
                url,
                body: { ...data },
            }),
        putProjectsParticipants: (id, data) =>
            put({
                url: `${url}/${id}`,
                body: { ...data },
            }),
    });
    const projectsTimeCrawler = (url) => ({
        getProjectsTimeCrawler: (params) =>
            get({
                url,
                queryParams: { ...params },
            }),
    });
    const synchronize = (url) => ({
        jiraSynchronize: (data) =>
            post({
                url,
                queryParams: { ...data },
            }),
    });
    const modules = (url) => ({
        getAllModules: () =>
            get({
                url,
            }),
        addModule: (data) =>
            post({
                url,
                body: { ...data },
            }),
        editModule: (id, data) => {
            return patch({
                url: `${url}/${id}`,
                body: { ...data },
            });
        },

        deleteModule: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
    });
    const systems = (url) => ({
        getSystems: () =>
            get({
                url,
            }),
    });
    const frequency = (url) => ({
        getFrequency: () =>
            get({
                url,
            }),
        addFrequency: (data) =>
            post({
                url,
                body: data,
            }),
        editFrequency: (id, data) =>
            patch({
                url: `${url}/${id}`,
                body: data,
            }),
    });
    const jiraProjects = (url) => ({
        getProjects: (id) =>
            get({
                url: `${url}/projects`,
                queryParams: { moduleId: id },
            }),
        postProjects: (data) =>
            post({
                url: `${url}/import-jira-projects`,
                body: data,
            }),
    });
    const jiraPersons = (url) => ({
        getPersons: (id) =>
            get({
                url: `${url}/persons`,
                queryParams: { moduleId: id },
            }),
        postPerson: (data) =>
            post({
                url: `${url}/import-jira-persons`,
                body: data,
            }),
    });
    const images = (url) => ({
        getPersonImg: (personId) =>
            get({
                url: `${url}/byPersonId/${personId}`,
            }),
        postPersonImg: (id, file) =>
            post({
                url,
                queryParams: { personId: id },
                body: file,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
        deletePersonImg: (id) =>
            remove({
                url: `${url}/${id}`,
            }),
    });
    const asyncTasks = (url) => ({
        getAsyncTasks: () => get({ url }),
    });
    const userInfo = (url) => ({
        getUserInfo: () => get({ url }),
    });
    return {
        auth: auth('api/auth/token'),
        budget: budget('api/balance-budgets'),
        errorJour: errorJour('api/error-jour'),
        organizations: organizations('api/organizations'),
        periods: periods('api/team-periods'),
        persons: persons('api/persons'),
        plans: plans('api/production-plans'),
        projects: projects('api/projects'),
        specialties: specialties('api/specialties'),
        positions: positions('api/positions'),
        competence: competence('api/competence-levels'),
        baseTariffs: baseTariffs('api/base-tariff-rates'),
        teams: teams('api/teams'),
        users: users('api/users'),
        upload: upload('api/statements'),
        unload: unload('api'),
        reports: reports('api/reports'),
        unusedPersons: unusedPersons('api/unused-persons'),
        UsersCalendar: UsersCalendar('api/calendar'),
        cvPersons: personsInfo('api/persons-info'),
        cvProjects: projectInfo('api/projects-info'),
        cvToolTags: cvToolTags('api/tags'),
        cvProjectsParticipants: cvProjectsParticipants(
            'api/project-participants'
        ),
        projectsTimeCrawler: projectsTimeCrawler('api/timecrawler-projects'),
        synchronize: synchronize('api/jira/synchronize'),
        modules: modules('api/modules'),
        systems: systems('api/systems'),
        frequency: frequency('api/synch-schedules'),
        jiraProjects: jiraProjects('api/jira'),
        jiraPersons: jiraPersons('api/jira'),
        asyncTasks: asyncTasks('api/async-task-statuses/status'),
        images: images('api/images'),
        userInfo: userInfo('api/auth/userInfo'),
    };
};

export default requestList();
