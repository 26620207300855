import { func, shape } from 'prop-types';
import Button from 'components/Button';
import DropdownList from 'components/DropdownList';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { getSystems } from 'redux/thunks/systemsThunks';
import { getFrequency, editFrequency } from 'redux/thunks/frequencyThunks';
import { editModule } from 'redux/thunks/modulesThunks';
import isEmpty from 'helpers/isEmpty';
import Input from 'components/Input';
import { statusList } from 'helpers/Filters/filtersAreaHandler';
import styles from './styles.module.scss';

const ModuleSettings = ({ item = {}, deleteHandler = () => {} }) => {
    const systems = useSelector((state) => state.systems.DropdownList);
    const frequencyType = useSelector((state) => state.frequency.data);

    const { openModal, closeModal } = useContext(ModalContext);
    const dispatch = useDispatch();

    const [status, setStatus] = useState({});
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [username, setUsername] = useState('');
    const [pass, setPass] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [secretApiKey, setSecretApiKey] = useState('');
    const [time, setTime] = useState(null);
    const [frequency, setFrequency] = useState(null);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        dispatch(getSystems());
        dispatch(getFrequency());
    }, []);

    useEffect(() => {
        if (isEmpty(item)) {
            setValue();
        } else {
            resetSettingsField();
        }
    }, [item]);

    const setValue = () => {
        setStatus(statusList.find((elem) => elem.boolValue === item.isActive));
        setDescription(item.description);
        setUrl(item.url);
        setUsername(item.username);
        setPass(item.password);
        setApiKey(item.apiKey);
        setSecretApiKey(item.secretApiKey);
        setTime(item.timeout);
        setFrequency(
            frequencyType.find((elem) => elem.moduleId === item.id)?.frequency
        );
        setIsValid(item.isValid);
    };

    const resetSettingsField = () => {
        setDescription('');
        setUrl('');
        setUsername('');
        setPass('');
        setApiKey('');
        setSecretApiKey('');
        setTime('');
    };

    const handleClickDelete = (title, children) => {
        openModal({ title, children });
    };

    const dataForSaved = () => {
        const dateNow = new Date();

        const forSave = {
            module: {
                isActive: status.boolValue,
                description,
                url,
                username,
                password: pass,
                apiKey,
                secretApiKey,
                timeout: time,
                isValid,
                systemId: item.systemId,
            },
            frequency: {
                dateStart: dateNow,
                frequency: 100000,
                type: systems.find((elem) => elem.id === item.systemId).value,
                isActive: true,
            },
        };
        return forSave;
    };

    const handlerClickSave = () => {
        dispatch(editModule(item.id, dataForSaved()));
        dispatch(
            editFrequency(
                frequencyType.find((elem) => elem.moduleId === item.id).id,
                { frequency }
            )
        );
    };

    const header = (
        <div className={styles.header}>
            {!isEmpty(item) ? (
                <div className={styles.title}>Выберите модуль </div>
            ) : (
                <div className={styles.title}>Настройки модуля </div>
            )}
        </div>
    );
    const settingsField = (
        <div className={styles.settings_container}>
            {item.systemId && (
                <div className={styles.settings}>
                    <div className={styles.settings__inputs}>
                        <div className={styles.row}>
                            {isEmpty(item) && (
                                <div className={styles.dropDown}>
                                    <DropdownList
                                        title="Статус"
                                        items={statusList}
                                        withoutDelete
                                        selected={status}
                                        setSelected={setStatus}
                                    />
                                </div>
                            )}
                        </div>

                        <div className={styles.row}>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Описание"
                                    value={description}
                                    onChange={setDescription}
                                />
                            </div>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Адрес"
                                    value={url}
                                    onChange={setUrl}
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Имя пользователя"
                                    value={username}
                                    onChange={setUsername}
                                />
                            </div>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Пароль"
                                    value={pass}
                                    onChange={setPass}
                                    password
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Публичный ключ"
                                    value={apiKey}
                                    onChange={setApiKey}
                                    password
                                />
                            </div>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Токен"
                                    value={secretApiKey}
                                    onChange={setSecretApiKey}
                                    password
                                />
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Время ожидания сервера, мс"
                                    value={time}
                                    onChange={setTime}
                                />
                            </div>
                            <div className={styles.inputModule}>
                                <Input
                                    title="Частота синхронизации, мс"
                                    value={frequency}
                                    onChange={setFrequency}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.row_button}>
                        <Button
                            buttonStyle="outlined"
                            text="Удалить"
                            onClick={() => {
                                handleClickDelete(
                                    'Удалить',
                                    <DeleteModal
                                        closeModal={closeModal}
                                        id={item.id}
                                        tableType="modules"
                                        changeState={deleteHandler}
                                    />
                                );
                            }}
                        />
                        <div className={styles.row}>
                            <Button
                                buttonStyle="outlined"
                                text="Отмена"
                                onClick={setValue}
                            />
                            <Button
                                text="Сохранить"
                                onClick={handlerClickSave}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <div className={styles.container}>
            {header}
            {settingsField}
        </div>
    );
};

ModuleSettings.propTypes = {
    item: shape({}),
    deleteHandler: func,
};

export default ModuleSettings;
