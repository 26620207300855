import {
    ADD_BUDGET_DATA,
    DELETE_BUDGET_DATA,
    EDIT_BUDGET_DATA,
    GET_ALL_BUDGET_DATA,
} from 'redux/types';

export const addBudgetData = (data) => ({
    type: ADD_BUDGET_DATA,
    payload: data,
});

export const deleteBudgetData = (id) => ({
    type: DELETE_BUDGET_DATA,
    payload: id,
});

export const editBudgetData = (data) => ({
    type: EDIT_BUDGET_DATA,
    payload: data,
});

export const getBudgetData = (data) => ({
    type: GET_ALL_BUDGET_DATA,
    payload: data,
});
