import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { func, shape, string, bool } from 'prop-types';
import API from 'api/api';
import Button from 'components/Button';
import Input from 'components/Input';
import { editUnusedPersonData } from 'redux/actions/unusedPersons';
import { useToastify } from 'helpers/toastify/useToastify';
import { getValueOrNil } from 'helpers/editFormsValues';
import { editPlan } from 'redux/thunks/productionPlansThunks';
import { editUnusedPerson } from 'redux/thunks/unusedPersonsThunks';
import styles from './styles.module.scss';

const ProductionPlansEditContent = ({
    closeModal = () => {},
    getUnusedPersons = () => {},
    getProductionPlans = () => {},
    initialValues = {},
    isUnusedPersonsTable = false,
}) => {
    const { additionalData = {}, id, items = {} } = initialValues;
    const { plannedHours, factHours, note } = items;
    const { month, year, personId, projectId } = additionalData;

    const [plannedHoursValue, setPlannedHoursValue] = useState(
        getValueOrNil(plannedHours)
    );
    const [factHoursValue, setFactHoursValue] = useState(
        getValueOrNil(factHours)
    );
    const [noteValue, setNoteValue] = useState(getValueOrNil(note));

    const dispatch = useDispatch();

    const onSave = () => {
        if (isUnusedPersonsTable) {
            dispatch(
                editUnusedPerson(
                    id,
                    {
                        planHours: plannedHoursValue,
                        personId: id,
                        note: noteValue.trim(),
                    },
                    closeModal
                )
            );
        } else {
            dispatch(
                editPlan(
                    id,
                    {
                        personId,
                        month,
                        year,
                        planHours: plannedHoursValue,
                        factHours: factHoursValue,
                        note: noteValue.trim(),
                        projectId,
                    },
                    getProductionPlans,
                    getUnusedPersons,
                    closeModal
                )
            );
        }
    };

    return (
        <div className={styles.project_teams_content}>
            <Input
                onChange={setPlannedHoursValue}
                placeholder="Плановые часы"
                symbolLimit={6}
                type="float"
                value={plannedHoursValue}
            />
            {!isUnusedPersonsTable ? (
                <Input
                    onChange={setFactHoursValue}
                    placeholder="Фактические часы"
                    symbolLimit={6}
                    type="float"
                    value={factHoursValue}
                />
            ) : null}
            <Input
                value={noteValue}
                onChange={setNoteValue}
                placeholder="Примечание"
            />
            <div className={styles.buttons_container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                />
                <Button text="Сохранить" onClick={onSave} />
            </div>
        </div>
    );
};

ProductionPlansEditContent.propTypes = {
    initialValues: shape({ string }),
    closeModal: func,
    isUnusedPersonsTable: bool,
    getUnusedPersons: func,
    getProductionPlans: func,
};
export default ProductionPlansEditContent;
