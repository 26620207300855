import { GoogleLogin } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { setIsLoadingAuth, userData } from 'redux/actions/auth';
import { useToastify } from 'helpers/toastify/useToastify';
import { setUserInfo } from 'helpers/authHelpers';
import API from 'api/api';
import google from 'icons/google.svg';
import googleColor from 'icons/googleColor.svg';
import styles from './styles.module.scss';

const GoogleButton = () => {
    const dispatch = useDispatch();
    const theme = localStorage.getItem('app-theme');

    const handleLogin = (res) => {
        dispatch(setIsLoadingAuth(true));
        API.auth
            .login(res.tokenId, 'google')
            .then((r) => {
                setUserInfo(r, 'google');
                localStorage.setItem('userLoggedIn', true);
                const userDataInfo = {
                    userShortName: r.data.entity.userShortName,
                    userFullName: r.data.entity.userFullName,
                    userName: r.data.entity.userName,
                    userMiddleName: r.data.entity.userMiddleName,
                    userSurname: r.data.entity.userSurname,
                    personId: r.data.entity.personId,
                    userLogin: r.data.entity.userLogin,
                    userRole: r.data.entity.userRole,
                    userPosition: r.data.entity.userPosition,
                };
                dispatch(userData(userDataInfo));
            })
            .catch((r) => useToastify(r.message, 'error'))
            .finally(() => dispatch(setIsLoadingAuth(false)));
    };

    return (
        <GoogleLogin
            clientId={process.env.REACT_APP_CLIENT_ID}
            onSuccess={handleLogin}
            isSignedIn={false}
            render={(renderProps) => (
                <button
                    className={styles.loginpage__modal_button}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    type="button"
                >
                    <img
                        className={styles.loginpage__modal_icon}
                        src={theme === 'blue' ? googleColor : google}
                        alt="google_icon"
                    />
                    <span>Войти с помощью Google</span>
                </button>
            )}
        />
    );
};

export default GoogleButton;
