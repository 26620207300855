const AddPersonIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d=" M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z
            M10.35 14.01C7.62 13.91 2 15.27 2 18V20H11.54C9.07 17.24 10.31 14.11 10.35 14.01Z
            M16.6667 13H14.3333V15.3333H12V17.6667H14.3333V20H16.6667V17.6667H19V15.3333H16.6667V13Z"
            fill="#757575"
        />
    </svg>
);

export default AddPersonIcon;
