export const validDate = (date) =>
    date === undefined || date === '' ? null : new Date(date);

export const getYearStr = (value) => {
    if ((value > 9 && value <= 20) || Number(value) === 0) return ' лет';
    if (Number(value.slice(-1)) === 1) return ' год';
    if (Number(value.slice(-1)) < 5) return ' года';
    return ' лет';
};

export const agetostr = (age) => {
    let txt;
    let count = age % 100;

    if (count >= 5 && count <= 20) {
        txt = 'лет';
    } else {
        count %= 10;
        if (count === 1) {
            txt = 'год';
        } else if (count >= 2 && count <= 4) {
            txt = 'года';
        } else {
            txt = 'лет';
        }
    }
    return age !== null ? `${age} ${txt}` : '';
};
