import API from 'api/api';
import { logout } from 'redux/actions/auth';
import { useToastify } from 'helpers/toastify/useToastify';
import { toast } from 'react-toastify';
import {
    addProductionPlansData,
    editProductionPlansData,
    getProductionPlansData,
    getProductionPlansPerson,
} from 'redux/actions/productionPlans';
import { projectTypeRev } from 'helpers/tableHandler';
import { getPerson } from './personThunks';

export const editPlan =
    (
        id,
        data = {},
        getPersonPlan = () => {},
        getProductionPlans = () => {},
        getUnusedPersons = () => {},
        setEditId = () => {},
        closeModal = () => {}
    ) =>
    (dispatch) => {
        const query = { ...data };
        API.plans
            .editPlan(id, { ...query })
            .then((res) => {
                dispatch(editProductionPlansData(res.data));
                getPersonPlan();
                getProductionPlans();
                getUnusedPersons();
                setEditId();
                useToastify('План производства изменён', 'success');
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => closeModal());
    };
export function getAllPlans(
    filtersPlan = {},
    sortPlan = {},
    setIsLoading = () => {},
    closeModal = () => {},
    clearProductionPlans = () => {}
) {
    return (dispatch) => {
        setIsLoading(true);
        const queryPlan = { ...filtersPlan };

        if (sortPlan.property)
            queryPlan.sort = `${sortPlan.property},${sortPlan.order}`;

        API.plans
            .getAllPlans({ ...queryPlan })
            .then((res) => {
                if (queryPlan.personId) {
                    dispatch(getProductionPlansPerson(res.data.content));
                } else dispatch(getProductionPlansData(res.data.content));
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                dispatch(clearProductionPlans());
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => {
                setIsLoading(false);
                closeModal();
            });
    };
}
export const addPlan =
    (data = {}, closeModal = () => {}) =>
    (dispatch) => {
        const query = { ...data };
        API.plans
            .addPlan({ ...query })
            .then((res) => {
                dispatch(addProductionPlansData(res.data));
                useToastify('План производства добавлен', 'success');
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => closeModal());
    };
export const createPlan = (year, month) => (dispatch) => {
    API.plans
        .createPlan({ year, month: month + 1 })
        .then(() => {
            useToastify('План производства создан', 'success');
        })
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        });
};
export const resolvePlan =
    (id, getProductionPlans = () => {}, closeModal = () => {}) =>
    (dispatch) => {
        API.plans
            .resolvePlan(id)
            .then(() => {
                useToastify('Сброс выполнен', 'success');
                getProductionPlans();
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err.message, 'error');
            })
            .finally(() => closeModal());
    };
