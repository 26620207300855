import { toast } from 'react-toastify';

export function useToastify(message, variant, autoClose) {
    switch (variant) {
        case 'info': {
            return toast.info(message);
        }
        case 'success': {
            return toast.success(message, { autoClose });
        }
        case 'warning': {
            return toast.warn(message);
        }
        case 'error': {
            return toast.error(message);
        }
        default: {
            return toast(message);
        }
    }
}
