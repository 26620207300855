import API from 'api/api';
import { logout } from 'redux/actions/auth';
import { useToastify } from 'helpers/toastify/useToastify';
import {
    getPersonsData,
    editPersonData,
    editPersonStatus,
    addPersonData,
    getFilteredPersonsData,
    setPersonsFilterStatus,
    getAllPersonsDataForDropDown,
    getFilteredPersonsDataForDropDown,
} from 'redux/actions/persons';
import { postPersonImg } from 'redux/thunks/imagesThunks';
import { getCVPerson } from './cvThunks';

export const getAllPersonsForDropDown = () => async (dispatch) => {
    try {
        const res = await API.persons.getAllPersons({ sort: `fullName,asc` });
        dispatch(getAllPersonsDataForDropDown(res.data));
    } catch (err) {
        if (err.name === 'UNAUTHORIZED') {
            dispatch(logout());
            localStorage.clear();
        }
        useToastify(err?.message ?? 'Произошла ошибка', 'error');
    }
};

export const getAllPersonsInit = () => async (dispatch) => {
    try {
        const res = await API.persons.getAllPersons({ sort: `fullName,asc` });
        dispatch(getAllPersonsDataForDropDown(res.data));
        dispatch(
            getFilteredPersonsData(
                res.data.filter((elem) => elem.isActive === true)
            )
        );
    } catch (err) {
        if (err.name === 'UNAUTHORIZED') {
            dispatch(logout());
            localStorage.clear();
        }
        useToastify(err?.message ?? 'Произошла ошибка', 'error');
    }
};

export const getFilteredPersonsForDropDown =
    (filter, setIsLoadingManagers = () => {}) =>
    async (dispatch) => {
        const query = { ...filter, sort: `fullName,asc` };
        try {
            const res = await API.persons.getAllPersons(query);
            dispatch(getFilteredPersonsDataForDropDown(res.data));
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoadingManagers(false);
        }
    };

export const getAllPersons =
    (filters = {}, sort = {}, closeModal = () => {}, setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        const query = { ...filters };
        if (sort.property) {
            query.sort = `${sort.property},${sort.order}`;
        } else {
            query.sort = `fullName,asc`;
        }
        if (Object.prototype.hasOwnProperty.call(filters, 'isActive')) {
            dispatch(setPersonsFilterStatus(filters.isActive));
        }
        API.persons
            .getAllPersons({ ...query })
            .then((res) => {
                dispatch(getPersonsData(res.data));
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => {
                setIsLoading(false);
                closeModal();
            });
    };

export const getFilteredPersons =
    (filters = {}, sort = {}, closeModal = () => {}, setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        const query = { ...filters };
        if (sort.property) query.sort = `${sort.property},${sort.order}`;

        if (Object.prototype.hasOwnProperty.call(filters, 'isActive')) {
            dispatch(setPersonsFilterStatus(filters.isActive));
        }

        API.persons
            .getAllPersons({ ...query })
            .then((res) => {
                dispatch(getFilteredPersonsData(res.data));
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => {
                setIsLoading(false);
                closeModal();
            });
    };

export const getPerson = (id, callback, setIsLoading) => {
    API.persons
        .getPerson(id)
        .then((res) => callback(res.data))
        .catch(() => useToastify('Ошибка при получении профиля', 'error'))
        .finally(() => {
            setIsLoading(false);
        });
};

export const editPerson =
    (id, data, closeModal = () => {}, setIsLoading = () => {}) =>
    (dispatch) => {
        API.persons
            .editPerson(id, data)
            .then((res) => {
                dispatch(editPersonData(res.data));
                useToastify('Профиль изменён', 'success');
                closeModal();
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

export const addPerson =
    (data, closeModal = () => {}, file) =>
    (dispatch) => {
        API.persons
            .addPerson(data)
            .then((res) => {
                dispatch(addPersonData(res.data));
                if (file) {
                    dispatch(postPersonImg(res.data.id, file));
                }
                useToastify('Сотрудник добавлен', 'success');
                closeModal();
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            });
    };

export const getUserProfile =
    (user, callback, navigate, setIsLoading) => (dispatch) => {
        API.persons
            .getUserProfile({ email: user.userLogin })
            .then((res) => {
                callback(res.data);
                getCVPerson(
                    { personIds: res.data.id },
                    null,
                    dispatch,
                    navigate,
                    setIsLoading,
                    () => {}
                );
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify('Ошибка при получении профиля', 'error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
