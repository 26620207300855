import { func, shape, string } from 'prop-types';
import SortSvg from 'components/svg/SortIcon/sortIcon';

const SortButton = ({
    onSortHandler = () => {},
    sortShort = '',
    header = '',
    sort = {},
}) => {
    const getOrder = () => {
        return header
            ? header === sort.property
                ? sort.order
                : ''
            : sortShort;
    };
    return (
        <button type="button" onClick={() => onSortHandler(header)}>
            <SortSvg sort={getOrder()} />
        </button>
    );
};

export default SortButton;

SortButton.propTypes = {
    onSortHandler: func,
    sort: shape({}),
    header: string,
    sortShort: string,
};
