import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { getUserInfo } from 'redux/thunks/auth';
import MainRoutes from 'routes';
import Sidebar from 'components/Sidebar';
import LoginPage from 'components/LoginPage';
import ModalProvider from 'components/Contexts/ModalContext/ModalContextProvider';
import useTheme from 'hooks/useTheme';
import Loader from 'components/Loader';
import useLogout from 'hooks/useLogout';
import styles from './styles.module.scss';

const RouterWrapper = () => {
    const authToken = window.atob(localStorage.getItem('authToken'));
    const { signOut } = useLogout();

    useEffect(() => {
        if (!authToken) signOut();
    }, [authToken]);

    return (
        <div className={styles.homepage__main_page}>
            <Sidebar />
            <MainRoutes />
        </div>
    );
};

const Homepage = () => {
    useTheme();
    const isLoading = useSelector((state) => state.auth.isLoading);
    const dispatch = useDispatch();

    const userLoggedIn = localStorage.getItem('userLoggedIn');

    useEffect(() => {
        if (userLoggedIn) {
            dispatch(getUserInfo());
        }
    }, []);

    return (
        <LoginPage>
            <Router>
                <ModalProvider>
                    {isLoading ? <Loader /> : <RouterWrapper />}
                </ModalProvider>
            </Router>
        </LoginPage>
    );
};

export default Homepage;
