import {
    ADD_BASE_TARIFF_RATE_DATA,
    EDIT_BASE_TARIFF_RATE_DATA,
    GET_BASE_TARIFF_RATES_DATA,
    GET_BASE_TARIFF_RATES_FOR_SELECTED_PERSON,
} from 'redux/types';

const defaultState = {
    data: [],
    DropdownListValues: [],
    selectedPersonTariffRates: [],
};

export const baseTariffRates = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_BASE_TARIFF_RATE_DATA:
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case EDIT_BASE_TARIFF_RATE_DATA:
            return {
                ...state,
                data: [
                    ...state.data.map((item) => {
                        if (item.id === action.payload.id) {
                            return { ...action.payload.data };
                        }
                        return item;
                    }),
                ],
            };
        case GET_BASE_TARIFF_RATES_DATA:
            return {
                ...state,
                data: action.payload,
                DropdownListValues: action.payload.map((el) => ({
                    id: el.id,
                    value: el.personFullName,
                })),
            };
        case GET_BASE_TARIFF_RATES_FOR_SELECTED_PERSON:
            return {
                ...state,
                selectedPersonTariffRates: action.payload,
            };
        default:
            return state;
    }
};
