import { combineReducers } from 'redux';
import { projectTeams } from 'redux/reducers/projectTeams';
import { organizations } from 'redux/reducers/organizations';
import { specialtiesAndCompetence } from 'redux/reducers/specialtiesAndCompetence';
import { persons } from 'redux/reducers/persons';
import { personsCv } from 'redux/reducers/personsCv';
import { baseTariffRates } from 'redux/reducers/baseTariffRates';
import { teamPeriods } from 'redux/reducers/teamPeriods';
import { productionPlans } from 'redux/reducers/productionPlans';
import { projects } from 'redux/reducers/projects';
import { budgets } from 'redux/reducers/budget';
import { auth } from 'redux/reducers/auth';
import { reports } from 'redux/reducers/reports';
import { unusedPersons } from 'redux/reducers/unusedPersons';
import { users } from 'redux/reducers/users';
import { calendar } from 'redux/reducers/calendar';
import { modules } from './modules';
import { notifications } from './notifications';
import { systems } from './systems';
import { frequency } from './frequency';
import { jiraProjects } from './jiraProjects';
import { jiraPersons } from './jiraPersons';
import { asyncTasks } from './asyncTasks';
import { positions } from './positions';

const CombinedReducer = () =>
    combineReducers({
        projectTeams,
        organizations,
        specialtiesAndCompetence,
        persons,
        personsCv,
        baseTariffRates,
        teamPeriods,
        productionPlans,
        projects,
        budgets,
        auth,
        notifications,
        reports,
        unusedPersons,
        users,
        calendar,
        modules,
        systems,
        frequency,
        jiraProjects,
        jiraPersons,
        asyncTasks,
        positions,
    });

export default CombinedReducer;
