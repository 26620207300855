import React, { useState } from 'react';
import { shape } from 'prop-types';
import styles from 'components/Tabs/styles.module.scss';

// Для отображения табов необходимо передать сюда проп tabs - объект вида:
// {tab1: {id: 1, title: "title1", component: <Component1/>}, tab2: {id: 2, title: "title2", component: <Component2/>}, ...}
const Tabs = ({ tabs = {} }) => {
    const [activeTab, setActiveTab] = useState(Object.values(tabs)[0]);
    const updatedActiveTab = Object.values(tabs).find(
        (tab) => tab.id === activeTab.id
    );

    return (
        <div className={styles.tabs}>
            <ul className={styles.nav}>
                {Object.values(tabs).map((tab) => (
                    <li
                        key={tab.id}
                        className={
                            activeTab.id === tab.id
                                ? styles.active
                                : styles.not_active
                        }
                        onClick={() => {
                            setActiveTab(tab);
                        }}
                    >
                        {tab.title}
                    </li>
                ))}
            </ul>
            {updatedActiveTab.component}
        </div>
    );
};

Tabs.propTypes = {
    tabs: shape({}),
};

export default Tabs;
