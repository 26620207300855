import { Fragment } from 'react';
import { shape } from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const TableBody = ({ items = {} }) => {
    const cx = classNames.bind(styles);

    return (
        <tbody className={styles.table__body}>
            {items?.infoByCompetences &&
                items.infoByCompetences.map(
                    (infoByCompetencesItem, infoByCompetencesIndex) => (
                        <Fragment key={infoByCompetencesIndex}>
                            <tr>
                                <td
                                    className={cx('left_border')}
                                    rowSpan={
                                        infoByCompetencesItem.infoBySpecialities
                                            .length + 2
                                    }
                                >
                                    <div>
                                        {infoByCompetencesItem.competenceName}
                                    </div>
                                </td>
                            </tr>
                            {infoByCompetencesItem.infoBySpecialities.map(
                                (
                                    infoBySpecialitiesItem,
                                    infoBySpecialitiesIndex
                                ) => (
                                    <tr key={infoBySpecialitiesIndex}>
                                        <td>
                                            <div>
                                                {
                                                    infoBySpecialitiesItem.specialityName
                                                }
                                            </div>
                                        </td>
                                        {infoBySpecialitiesItem.infoByPersons.map(
                                            (
                                                infoByPersonsItem,
                                                infoByPersonsIndex
                                            ) => (
                                                <tr
                                                    className={cx(
                                                        'personName_cell'
                                                    )}
                                                >
                                                    <td
                                                        key={infoByPersonsIndex}
                                                        className={cx(
                                                            'personName_cell'
                                                        )}
                                                    >
                                                        <div>
                                                            {
                                                                infoByPersonsItem.personName
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tr>
                                )
                            )}
                            <tr
                                className={cx(
                                    'highlighted_cell',
                                    'big_text',
                                    'active_text'
                                )}
                            >
                                <td>
                                    <div>Всего:</div>
                                </td>
                                <td>
                                    <div className={cx('result_value')}>
                                        {infoByCompetencesItem.personsCount}
                                    </div>
                                </td>
                            </tr>
                        </Fragment>
                    )
                )}
        </tbody>
    );
};
export default TableBody;

TableBody.propTypes = {
    items: shape({}),
};
