import { shape } from 'prop-types';
import { Fragment, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { accessByRole } from 'helpers/roleHelpers';
import { months, numbersDepth } from 'helpers/tableHandler';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import DeleteIcon from 'components/svg/deleteIcon';
import TeamsPeriodEditContent from 'components/ModalsContent/TeamsPeriodEditContent';
import { ButtonsForTable } from '../Table/TableBody/helpers';
import KebabMenu from '../Table/TableBody/KebabMenu';
import styles from './styles.module.scss';

const TeamPersonsTable = ({ team = [], projectsInStatus = {} }) => {
    const cx = classNames.bind(styles);
    const { openModal, closeModal } = useContext(ModalContext);
    const handleClick = (title, children) => {
        openModal({ title, children });
    };
    const currentRole = useSelector((state) => state.auth.userData.userRole);
    const editPersonButton = accessByRole(
        'ProjectTeams',
        'editPersonButton',
        currentRole
    );
    const deletePersonButton = accessByRole(
        'ProjectTeams',
        'deletePersonButton',
        currentRole
    );
    const tariffRate = accessByRole('ProjectTeams', 'tariffRate', currentRole);

    return (
        <table className={styles.table}>
            <thead>
                <tr className={styles.table__header_row}>
                    <th className={styles.table__header_cell}>Сотрудник</th>
                    <th className={styles.table__header_cell}>
                        Уровень
                        <br />
                        компетенции
                    </th>
                    <th className={styles.table__header_cell}>Период</th>
                    <th className={styles.table__header_cell}>Организация</th>
                    <th className={styles.table__header_cell}>
                        План. часы
                        <br />
                        месяц / период
                    </th>
                    {tariffRate && (
                        <th className={styles.table__header_cell}>
                            Тариф. ставка <br /> час / день
                        </th>
                    )}
                    {(editPersonButton || deletePersonButton) && (
                        <th
                            className={`${styles.table__header_cell} ${styles.table__header_cell_icon}`}
                        >
                            {' '}
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {team.persons.map((person) => (
                    <Fragment key={person.personId}>
                        <tr className={styles.table__body_row}>
                            <td
                                rowSpan={
                                    person.periods.length
                                        ? person.periods.length + 1
                                        : 2
                                }
                                className={styles.table__body_cell}
                            >
                                {person.personShortName}
                            </td>
                        </tr>
                        {person.periods.length ? (
                            person.periods.map((el) => (
                                <tr className={styles.table__body_row}>
                                    <td
                                        className={`${styles.table__body_cell} ${styles.noBorder}`}
                                    >
                                        <span>
                                            {el.competenceLevel?.competenceName}
                                        </span>
                                    </td>
                                    <td className={styles.table__body_cell}>
                                        <span>
                                            {months[el.monthPeriod - 1]}{' '}
                                            {el.yearPeriod}
                                        </span>
                                    </td>
                                    <td className={styles.table__body_cell}>
                                        <span>{el.organizationName}</span>
                                    </td>
                                    <td className={styles.table__body_cell}>
                                        <span>
                                            {el.personPlannedHours} /{' '}
                                            {el.workingHoursPerMonth}
                                        </span>
                                    </td>
                                    {tariffRate && (
                                        <td className={styles.table__body_cell}>
                                            <span>
                                                {numbersDepth(
                                                    el.tariffRateHour
                                                )}{' '}
                                                /{' '}
                                                {numbersDepth(el.tariffRateDay)}
                                            </span>
                                        </td>
                                    )}

                                    {(editPersonButton ||
                                        deletePersonButton) && (
                                        <td className={styles.table__body_cell}>
                                            <KebabMenu>
                                                {editPersonButton && (
                                                    <ButtonsForTable
                                                        text="Редактировать"
                                                        iconName="edit"
                                                        className="table__body_icon"
                                                        cklick={() => {
                                                            handleClick(
                                                                'Редактировать период сотрудника в команде проектов',
                                                                <TeamsPeriodEditContent
                                                                    items={el}
                                                                    closeModal={
                                                                        closeModal
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {deletePersonButton && (
                                                    <button
                                                        type="button"
                                                        className={cx(
                                                            'del-btn'
                                                        )}
                                                        onClick={() => {
                                                            handleClick(
                                                                `Вы действительно хотите удалить период №${el.teamPeriodId}`,
                                                                <DeleteModal
                                                                    closeModal={
                                                                        closeModal
                                                                    }
                                                                    id={
                                                                        el.teamPeriodId
                                                                    }
                                                                    tableType="teamPeriods"
                                                                    projectsInStatus={
                                                                        projectsInStatus
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                        Удалить
                                                    </button>
                                                )}
                                            </KebabMenu>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr className={styles.table__body_row}>
                                <td
                                    colSpan={6}
                                    className={`${styles.table__body_cell} ${styles.noBorder} ${styles.noData}`}
                                >
                                    <span>
                                        Отсутствуют данные по этому сотруднику
                                    </span>
                                </td>
                            </tr>
                        )}
                    </Fragment>
                ))}
            </tbody>
        </table>
    );
};

export default TeamPersonsTable;

TeamPersonsTable.propTypes = {
    team: shape({}),
    projectsInStatus: shape({}),
};
