import {
    ADD_PRODUCTION_PLANS_DATA,
    DELETE_PRODUCTION_PLANS_DATA,
    EDIT_PRODUCTION_PLANS_DATA,
    GET_PRODUCTION_PLANS_DATA,
    CLEAR_PLANS_DATA,
    GET_PRODUCTION_PLANS_DATA_PERSON,
    SET_CURRENT_DATE,
    SET_INITIAL_SUMS,
    SET_SIZE_PRODUCTION_PLANS_TABLES,
    SET_UNLOAD_PRODUCTION_PLAN_ERROR,
} from 'redux/types';

const savedDate = localStorage.getItem('prodPlanDate')
    ? new Date(localStorage.getItem('prodPlanDate'))
    : new Date();

const defaultState = {
    data: [],
    personData: [],
    DropdownListValues: [],
    date: savedDate,
    sumPlan: 0,
    sumFact: 0,
    toggleSize: { id: 1, value: 'open' },
    unloadErrorData: [],
};

export const productionPlans = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_PRODUCTION_PLANS_DATA:
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case DELETE_PRODUCTION_PLANS_DATA:
            return {
                ...state,
                data: state.data.filter((item) => item.id !== action.payload),
            };
        case EDIT_PRODUCTION_PLANS_DATA:
            return {
                ...state,
                data: [
                    ...state.data.filter(
                        (item) => item.id !== action.payload.id
                    ),
                    action.payload,
                ],
            };
        case GET_PRODUCTION_PLANS_DATA:
            return {
                ...state,
                data: action.payload,
                DropdownListValues: action.payload.map((el) => ({
                    id: el.id,
                    value: el.name,
                })),
                toggleSize: action.payload.length
                    ? { id: 1, value: 'open' }
                    : { id: 0, value: 'close' },
            };
        case GET_PRODUCTION_PLANS_DATA_PERSON:
            return {
                ...state,
                personData: action.payload,
                sumPlan: action.payload.reduce(
                    (acc, item) => acc + item.planHours,
                    0
                ),
                sumFact: action.payload.reduce(
                    (acc, item) => acc + item.factHours,
                    0
                ),
            };
        case SET_CURRENT_DATE:
            return {
                ...state,
                date: action.payload,
            };
        case SET_INITIAL_SUMS:
            return {
                ...state,
                sumPlan: 0,
                sumFact: 0,
            };
        case CLEAR_PLANS_DATA:
            return defaultState;
        case SET_SIZE_PRODUCTION_PLANS_TABLES:
            return {
                ...state,
                toggleSize: action.payload,
            };
        case SET_UNLOAD_PRODUCTION_PLAN_ERROR:
            return {
                ...state,
                unloadErrorData: [...action.payload],
            };
        default:
            return state;
    }
};
