import { bool, func, number, oneOfType, string } from 'prop-types';
import { animated, useTransition, config } from 'react-spring';
import CheckBoxBorderIcon from 'components/svg/checkBox';
import styles from './styles.module.scss';

const CheckBox = ({
    checked = true,
    setChecked = () => {},
    text = '',
    isCell = false,
}) => {
    const transitions = useTransition(checked, {
        from: {
            opacity: 0,
            transform: 'scale(0.5)',
        },

        enter: {
            opacity: 1,
            transform: 'scale(1)',
        },
        leave: {
            opacity: 0,
            transform: 'scale(0.5)',
        },
        config: config.wobbly,
    });

    const onEnterPressed = (e) => {
        if (e.key === 'Enter') {
            setChecked(!checked);
        }
    };
    return (
        <div
            role="button"
            tabIndex={0}
            className={
                isCell ? styles.checkbox_cell : styles.checkbox_container
            }
            onClick={() => {
                setChecked(!checked);
            }}
            onKeyPress={onEnterPressed}
        >
            <div
                className={`${styles.checkbox} ${
                    checked ? styles.checkbox_active : ''
                }`}
            >
                {transitions(
                    (stylesTransition, item) =>
                        item && (
                            <animated.svg
                                width="14"
                                height="11"
                                viewBox="0 0 14 11"
                                fill="#fff"
                                xmlns="http://www.w3.org/2000/svg"
                                style={stylesTransition}
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.7072 1.70718L5.00008 10.4143L0.292969 5.70718L1.70718 4.29297L5.00008 7.58586L12.293 0.292969L13.7072 1.70718Z"
                                    fill="white"
                                />
                            </animated.svg>
                        )
                )}
            </div>
            <span className={styles.text}>{text}</span>
        </div>
    );
};

CheckBox.propTypes = {
    checked: bool,
    setChecked: func,
    text: oneOfType([string, number]),
    isCell: bool,
};

export default CheckBox;
