import { useContext, useEffect, useState } from 'react';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import PageHeader from 'components/PageHeader';
import SpecialityContent from 'components/ModalsContent/SpecialityContent';
import ShortTable from 'components/Tables/ShortTable';
import Loader from 'components/Loader';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSpecialties } from 'redux/thunks/specialtiesThunks';
import Input from 'components/Input';
import { accessByRole } from 'helpers/roleHelpers';
import styles from './styles.module.scss';

const Specialties = () => {
    const { openModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [sort, setSort] = useState('asc');
    const [specialties, setSpecialties] = useState([]);

    const currentRole = useSelector((state) => state.auth.userData.userRole);

    const dispatch = useDispatch();
    const data = useSelector(
        (state) => state.specialtiesAndCompetence.specialties
    );

    useEffect(() => {
        if (accessByRole('Specialties', 'getAllSpecialties', currentRole)) {
            dispatch(
                getAllSpecialties(
                    {},
                    { property: 'name', order: `${sort}` },
                    setIsLoading
                )
            );
        }
    }, [dispatch, sort]);

    useEffect(() => {
        setSpecialties(data.filter((elem) => elem.name.includes(searchValue)));
    }, [data, searchValue]);

    const handleClick = (title, children) => {
        openModal({ title, children });
    };

    const onSortHandler = () => {
        if (sort === 'asc') setSort('desc');
        else setSort('asc');
    };

    return (
        <>
            <PageHeader
                title="Справочник специализаций"
                addButton={accessByRole(
                    'Specialties',
                    'addButton',
                    currentRole
                )}
                addButtonText="Добавить специализацию"
                openAddModal={() => {
                    handleClick(
                        'Добавление специализации',
                        <SpecialityContent closeModal={closeModal} />
                    );
                }}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.search_container}>
                        <Input
                            placeholder="Название специализации"
                            isSearch
                            value={searchValue}
                            onChange={setSearchValue}
                            type="text"
                        />
                    </div>
                    <ShortTable
                        items={specialties}
                        header="Специализации"
                        editIcon={accessByRole(
                            'Specialties',
                            'editIcon',
                            currentRole
                        )}
                        deleteIcon={accessByRole(
                            'Specialties',
                            'deleteIcon',
                            currentRole
                        )}
                        openEditModal={(initial) => {
                            handleClick(
                                'Редактирование специализации',
                                <SpecialityContent
                                    closeModal={closeModal}
                                    initialValues={initial}
                                />
                            );
                        }}
                        openDeleteModal={(initial) => {
                            handleClick(
                                `Вы действительно хотите удалить специализацию ${initial.name}?`,
                                <DeleteModal
                                    closeModal={closeModal}
                                    data={data}
                                    id={initial.id}
                                    tableType="specialties"
                                />
                            );
                        }}
                        onSortHandler={onSortHandler}
                        sortShort={sort}
                    />
                </>
            )}
        </>
    );
};

export default Specialties;
