const LogoutIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_4660_5286)">
            <path
                d="M9.3 6.7C8.91 7.09 8.91 7.71 9.3 8.1L12.2 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13H12.2L9.3 15.9C8.91 16.29 8.91 16.91 9.3 17.3C9.69 17.69 10.31 17.69 10.7 17.3L15.29 12.71C15.68 12.32 15.68 11.69 15.29 11.3L10.7 6.7C10.31 6.31 9.69 6.31 9.3 6.7ZM19 20H12C11.45 20 11 20.45 11 21C11 21.55 11.45 22 12 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2H12C11.45 2 11 2.45 11 3C11 3.55 11.45 4 12 4H19V20Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_4660_5286">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default LogoutIcon;
