import { Fragment } from 'react';
import classNames from 'classnames/bind';
import { numbersDepth, projectType } from 'helpers/tableHandler';
import { shape } from 'prop-types';
import styles from './styles.module.scss';

const TableBody = ({ items = {} }) => {
    const cx = classNames.bind(styles);

    return (
        <tbody className={styles.table__body}>
            {items.infoByTypes.map((infoByTypesItem, infoByTypesIndex) => (
                <Fragment key={infoByTypesIndex}>
                    <tr>
                        <td
                            className={cx('left_border')}
                            rowSpan={infoByTypesItem.infoByProjects.length + 1}
                        >
                            <div>{infoByTypesItem.typeName}</div>
                        </td>
                    </tr>
                    {infoByTypesItem.infoByProjects.map(
                        (infoByProjectsItem, infoByProjectsIndex) => (
                            <tr key={infoByProjectsIndex}>
                                <td>
                                    <div>{infoByProjectsItem.projectName}</div>
                                </td>
                                <td>
                                    <span>
                                        {numbersDepth(
                                            infoByProjectsItem.totalPlanExpenses
                                        )}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {numbersDepth(
                                            infoByProjectsItem.totalFactExpenses
                                        )}
                                    </span>
                                </td>
                                {infoByProjectsItem.projectInfoByPeriods.map(
                                    (
                                        projectInfoByPeriodsItem,
                                        projectInfoByPeriodsIndex
                                    ) => (
                                        <Fragment
                                            key={projectInfoByPeriodsIndex}
                                        >
                                            <td>
                                                <span>
                                                    {numbersDepth(
                                                        projectInfoByPeriodsItem.planExpenses
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {numbersDepth(
                                                        projectInfoByPeriodsItem.factExpenses
                                                    )}
                                                </span>
                                            </td>
                                        </Fragment>
                                    )
                                )}
                            </tr>
                        )
                    )}
                </Fragment>
            ))}
            <tr className={cx('highlighted_cell', 'active_text')}>
                <td className={cx('left_border')} colSpan="2">
                    <div>Всего</div>
                </td>
                <td>
                    <span>{numbersDepth(items?.totalPlanExpenses)}</span>
                </td>
                <td>
                    <span>{numbersDepth(items?.totalFactExpenses)}</span>
                </td>
                {items.totalInfoByPeriods.map(
                    (totalInfoByPeriodsItem, totalInfoByPeriodsIndex) => (
                        <Fragment key={totalInfoByPeriodsIndex}>
                            <td>
                                <span>
                                    {numbersDepth(
                                        totalInfoByPeriodsItem.planExpenses
                                    )}
                                </span>
                            </td>
                            <td>
                                <span>
                                    {numbersDepth(
                                        totalInfoByPeriodsItem.factExpenses
                                    )}
                                </span>
                            </td>
                        </Fragment>
                    )
                )}
            </tr>
        </tbody>
    );
};

export default TableBody;

TableBody.propTypes = {
    items: shape({}),
};
