import { Fragment, useEffect, useState } from 'react';
import {
    arrayOf,
    bool,
    func,
    string,
    oneOfType,
    object,
    array,
} from 'prop-types';
import classNames from 'classnames/bind';
import CVInput from 'components/Pages/CVPage/CVInput';
import CvTag from 'components/Pages/CVPage/ToolTag';
import { findTags } from 'redux/thunks/cvPageThunks';
import { randomId } from 'helpers/editFormsValues';
import ComponentTitle from 'components/ComponentTitle';
import styles from './styles.module.scss';

const CVChangeForm = ({
    existingTagsList = [],
    info = {},
    title = '',
    fieldName = '',
    setInfo = () => {},
    isEditing = false,
    grayTags = false,
    onlyInput = false,
    initValue = '',
    placeholder = '',
}) => {
    const cx = classNames.bind(styles);
    const [inputValue, setInputValue] = useState(initValue);
    const [foundedTags, setFoundedTags] = useState([]);

    useEffect(() => {
        if (inputValue.length > 0) {
            findTags(inputValue, existingTagsList, setFoundedTags);
        } else {
            setFoundedTags([]);
        }
    }, [inputValue, existingTagsList]);

    const addNewTag = (tagName) => {
        if (tagName.trim().length) {
            if (fieldName) {
                const copyArrayPersonField = [...info[fieldName]];

                const lowerArr = info[fieldName].map((tool) =>
                    tool.trim().toLowerCase()
                );

                const indexNewTool = lowerArr.indexOf(
                    tagName.trim().toLowerCase()
                );

                const newToolsArr = [];
                const lowToolsArr = [];

                const addStingAsTags = (arr) => {
                    arr.forEach((el) => {
                        if (
                            lowToolsArr.length &&
                            lowToolsArr.includes(el.trim().toLowerCase())
                        ) {
                            newToolsArr.splice(
                                lowToolsArr.indexOf(el.trim().toLowerCase()),
                                1,
                                el.trim()
                            );
                        } else if (
                            el.trim().length !== 0 &&
                            !lowToolsArr.includes(el.trim().toLowerCase())
                        ) {
                            newToolsArr.push(el.trim());
                            lowToolsArr.push(el.trim().toLowerCase());
                        }
                    });
                };

                if (tagName.includes(',') && tagName.includes(';')) {
                    const tagsArr = tagName.split(',').join(';').split(';');

                    addStingAsTags(tagsArr);

                    newToolsArr.forEach((el) => {
                        if (lowerArr.indexOf(el.toLowerCase()) !== -1) {
                            copyArrayPersonField.splice(
                                lowerArr.indexOf(el.toLowerCase()),
                                1,
                                el
                            );
                        } else copyArrayPersonField.push(el);
                    });
                } else if (tagName.indexOf(',') && !tagName.includes(';')) {
                    const tagsArr = tagName.split(',');

                    addStingAsTags(tagsArr);

                    newToolsArr.forEach((el) => {
                        if (lowerArr.indexOf(el.toLowerCase()) !== -1) {
                            copyArrayPersonField.splice(
                                lowerArr.indexOf(el.toLowerCase()),
                                1,
                                el
                            );
                        } else copyArrayPersonField.push(el);
                    });
                } else if (tagName.includes(';') && !tagName.includes(',')) {
                    const tagsArr = tagName.split(';');

                    addStingAsTags(tagsArr);

                    newToolsArr.forEach((el) => {
                        if (lowerArr.indexOf(el.toLowerCase()) !== -1) {
                            copyArrayPersonField.splice(
                                lowerArr.indexOf(el.toLowerCase()),
                                1,
                                el
                            );
                        } else copyArrayPersonField.push(el);
                    });
                } else if (!(tagName.includes(',') || tagName.includes(';'))) {
                    if (indexNewTool !== -1) {
                        copyArrayPersonField.splice(indexNewTool, 1, tagName);
                    } else {
                        copyArrayPersonField.push(tagName);
                    }
                }

                setInfo({
                    ...info,
                    [fieldName]: copyArrayPersonField,
                });
            } else {
                const copyArrayPersonField = [...info];

                const lowerArr = info.map((tool) => tool.trim().toLowerCase());

                const indexNewTool = lowerArr.indexOf(
                    tagName.trim().toLowerCase()
                );

                if (indexNewTool !== -1) {
                    copyArrayPersonField.splice(indexNewTool, 1, tagName);
                } else {
                    copyArrayPersonField.push(tagName);
                }

                setInfo(copyArrayPersonField);
            }

            setInputValue('');
        }
    };

    const delTag = (tagName) => {
        if (fieldName) {
            const newTools = info[fieldName]?.filter(
                (elem) => elem !== tagName
            );
            setInfo({ ...info, [fieldName]: newTools });
        } else {
            const newTools = info?.filter((elem) => elem !== tagName);
            setInfo(newTools);
        }
    };

    return (
        <div className={cx('cv-skills_list-tools')}>
            {title && <ComponentTitle title={title} />}
            {isEditing && (
                <CVInput
                    foundedTags={foundedTags}
                    addNewTag={addNewTag}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    placeholder={placeholder}
                />
            )}
            {!onlyInput && (
                <div className={cx('cv-tools')}>
                    {existingTagsList?.map((elem) => (
                        <Fragment key={randomId()}>
                            <CvTag
                                grayTags={grayTags}
                                isEditing={isEditing}
                                delTag={delTag}
                                tagName={elem}
                            />
                        </Fragment>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CVChangeForm;

CVChangeForm.propTypes = {
    isEditing: bool,
    grayTags: bool,
    onlyInput: bool,
    initValue: string,
    existingTagsList: arrayOf(string),
    info: oneOfType([object, array]),
    fieldName: string,
    title: string,
    placeholder: string,
    setInfo: func,
};
