import { Fragment } from 'react';
import { shape } from 'prop-types';
import { quarters, numbersDepth } from 'helpers/tableHandler';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const TableBody = ({ items = {} }) => {
    const cx = classNames.bind(styles);

    return (
        <tbody className={styles.table__body}>
            {items.infoByProjects.map(
                (infoByProjectsItem, infoByProjectsIndex) => (
                    <tr key={infoByProjectsIndex}>
                        <td className={cx('left_border')}>
                            <div>{infoByProjectsItem.projectName}</div>
                        </td>
                        {quarters.map((quarter, i) => (
                            <Fragment key={i}>
                                <td>
                                    <span>
                                        {infoByProjectsItem.infoByQuarters.map(
                                            (infoByQuartersItem) => {
                                                return infoByQuartersItem.quarter -
                                                    1 ===
                                                    i
                                                    ? numbersDepth(
                                                          infoByQuartersItem.planExpenses
                                                      )
                                                    : '';
                                            }
                                        )}
                                    </span>
                                </td>
                                <td className={styles.table__body_cell}>
                                    <span className={styles.period}>
                                        {infoByProjectsItem.infoByQuarters.map(
                                            (infoByQuartersItem) => {
                                                return infoByQuartersItem.quarter -
                                                    1 ===
                                                    i
                                                    ? numbersDepth(
                                                          infoByQuartersItem.factExpenses
                                                      )
                                                    : '';
                                            }
                                        )}
                                    </span>
                                </td>
                            </Fragment>
                        ))}
                        <td className={cx('highlighted_cell')}>
                            <span className={styles.period}>
                                {numbersDepth(
                                    infoByProjectsItem.totalPlanExpenses
                                )}
                            </span>
                        </td>
                        <td className={cx('highlighted_cell')}>
                            <span>
                                {numbersDepth(
                                    infoByProjectsItem.totalFactExpenses
                                )}
                            </span>
                        </td>
                    </tr>
                )
            )}
            <tr className={cx('highlighted_cell', 'active_text')}>
                <td className={cx('left_border')}>
                    <div>Всего</div>
                </td>
                {items.totalInfoByQuarter.map(
                    (totalInfoByQuarterItem, totalInfoByQuarterIndex) => (
                        <Fragment key={totalInfoByQuarterIndex}>
                            <td>
                                <span className={styles.period}>
                                    {numbersDepth(
                                        totalInfoByQuarterItem.planExpenses
                                    )}
                                </span>
                            </td>
                            <td>
                                <span className={styles.period}>
                                    {numbersDepth(
                                        totalInfoByQuarterItem.factExpenses
                                    )}
                                </span>
                            </td>
                        </Fragment>
                    )
                )}
                <td>
                    <span>{numbersDepth(items?.totalPlanExpenses)}</span>
                </td>
                <td>
                    <span>{numbersDepth(items?.totalFactExpenses)}</span>
                </td>
            </tr>
        </tbody>
    );
};
export default TableBody;

TableBody.propTypes = {
    items: shape({}),
};
