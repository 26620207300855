import {
    ADD_SPECIALITY_DATA,
    DELETE_SPECIALITY_DATA,
    EDIT_SPECIALITY_DATA,
    GET_SPECIALTIES_DATA,
    GET_COMPETENCES_DATA,
} from 'redux/types';

const defaultState = {
    specialties: [],
    DropdownSpecialtiesListValues: [],
    competenceList: [],
    DropdownCompetenceListValues: [],
};

export const specialtiesAndCompetence = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_SPECIALITY_DATA:
            return {
                ...state,
                specialties: [...state.specialties, action.payload],
            };
        case DELETE_SPECIALITY_DATA:
            return {
                ...state,
                specialties: state.specialties.filter(
                    (item) => item.id !== action.payload
                ),
            };
        case EDIT_SPECIALITY_DATA:
            return {
                ...state,
                specialties: [
                    ...state.specialties.filter(
                        (item) => item.id !== action.payload.id
                    ),
                    action.payload,
                ],
            };
        case GET_SPECIALTIES_DATA:
            return {
                ...state,
                specialties: action.payload,
                DropdownSpecialtiesListValues: action.payload.map((el) => ({
                    id: el.id,
                    value: el.name,
                })),
            };
        case GET_COMPETENCES_DATA:
            return {
                ...state,
                competenceList: action.payload,
                DropdownCompetenceListValues: action.payload.map((el) => ({
                    id: el.id,
                    value: el.competenceName,
                })),
            };
        default:
            return state;
    }
};
