export const validNumber = (str) => {
    let phoneFormated = '';
    if (str) {
        const phone = str.replace(/\D+/g, '');
        if (phone.length > 0) {
            phoneFormated = phone.replace(/(\d{0,13})(\d{0,})/, '+$1');
        }
    }
    return phoneFormated;
};
