import { arrayOf, func, number, shape, string, oneOfType } from 'prop-types';
import { useDispatch } from 'react-redux';
import API from 'api/api';
import Button from 'components/Button';
import { useToastify } from 'helpers/toastify/useToastify';
import isEmpty from 'helpers/isEmpty';
import {
    deleteProjectTeamsData,
    getProjectTeamsData,
} from 'redux/actions/projectTeams';
import { deleteSpecialityData } from 'redux/actions/specialties';
import { deletePositionData } from 'redux/actions/positions';
import { deleteProjectsData } from 'redux/actions/projects';
import { deleteOrganizationsData } from 'redux/actions/organizations';
import { deleteProductionPlansData } from 'redux/actions/productionPlans';
import { editPersonStatus } from 'redux/actions/persons';
import { deleteTeamPeriodData } from 'redux/actions/teamPeriods';
import { deleteBudgetData } from 'redux/actions/budget';
import { deleteUnusedPersonData } from 'redux/actions/unusedPersons';
import { deleteUserData } from 'redux/actions/users';
import { setCurrentDateCalendar } from 'redux/actions/calendar';
import { deletePersonCv } from 'redux/actions/personsCv';
import { deleteModule } from 'redux/thunks/modulesThunks';
import styles from './styles.module.scss';

const actions = {
    organizations: deleteOrganizationsData,
    persons: editPersonStatus,
    personsCv: deletePersonCv,
    productionPlans: deleteProductionPlansData,
    projects: deleteProjectsData,
    projectTeams: deleteProjectTeamsData,
    specialties: deleteSpecialityData,
    positions: deletePositionData,
    teamPeriods: deleteTeamPeriodData,
    budgets: deleteBudgetData,
    unusedPersons: deleteUnusedPersonData,
    users: deleteUserData,
};

const deleteRequest = (id, status) => {
    const organizations = () => API.organizations.deleteOrganization(id);
    const productionPlans = () => API.plans.deletePlan(id);
    const projects = () => API.projects.deleteProject(id);
    const persons = () =>
        status.isActive === false
            ? API.persons.deletePerson(id)
            : API.persons.editStatusPerson(id, status);
    const personsCv = () => API.cvPersons.deleteCvPerson(id);
    const projectTeams = () => API.teams.deleteTeam(id);
    const specialties = () => API.specialties.deleteSpeciality(id);
    const positions = () => API.positions.deletePosition(id);
    const teamPeriods = () => API.periods.deleteTeamPeriod(id);
    const budgets = () => API.budget.deleteBudget(id);
    const unusedPersons = () => API.unusedPersons.deleteUnusedPerson(id);
    const users = () => API.users.deleteUser(id);
    const modules = () => API.modules.deleteModule(id);
    const calendar = () => API.UsersCalendar.deleteUserCalendar(id);

    return {
        organizations,
        productionPlans,
        projects,
        persons,
        personsCv,
        projectTeams,
        specialties,
        positions,
        teamPeriods,
        budgets,
        unusedPersons,
        users,
        modules,
        calendar,
    };
};

const buttonOnStatus = (status, onDelete) => {
    let button = null;
    if (!status.isActive) {
        button = <Button text="Заблокировать" onClick={onDelete} />;
    } else {
        button = <Button text="Разблокировать" onClick={onDelete} />;
    }
    return button;
};

const setStatusPerson = (data, id) => {
    const personForEditStatus = data.find((person) => person.id === id);
    const status = {};

    for (const key in personForEditStatus) {
        if (key === 'isActive') {
            status[key] = !personForEditStatus[key];
        }
    }
    return status;
};

const DeleteModal = ({
    closeModal,
    data,
    id,
    tableType,
    projectsInStatus = {},
    changeState = () => {},
}) => {
    const dispatch = useDispatch();
    let statusPerson = null;
    if (tableType === 'persons') {
        statusPerson = setStatusPerson(data, id);
    }
    const onDelete = () => {
        if (tableType === 'loacalProjectCv') {
            changeState(data.localId);
            closeModal();
        } else if (tableType === 'projectCv') {
            changeState();
            closeModal();
        } else {
            deleteRequest(id, statusPerson)
                [tableType]()
                .then(() => {
                    if (tableType === 'calendar') {
                        dispatch(setCurrentDateCalendar(new Date()));
                    } else if (tableType === 'modules') {
                        dispatch(deleteModule(id, changeState));
                    } else if (tableType === 'teamPeriods') {
                        API.reports
                            .getTeamPeriods({
                                projectAndTeamIsActive: isEmpty(
                                    projectsInStatus
                                )
                                    ? projectsInStatus.boolValue
                                    : '',
                            })
                            .then((res) =>
                                dispatch(getProjectTeamsData(res.data))
                            );
                    } else {
                        dispatch(actions[tableType](id, data, statusPerson));
                    }
                    if (tableType === 'persons') {
                        if (!statusPerson.isActive) {
                            useToastify(
                                'Блокировка выполнена успешно',
                                'success'
                            );
                        } else {
                            useToastify(
                                'Разблокировка выполнена успешно',
                                'success'
                            );
                        }
                    } else {
                        useToastify('Удаление выполнено успешно', 'success');
                    }
                })
                .catch((err) =>
                    useToastify(err?.message ?? 'Произошла ошибка', 'error')
                )
                .finally(() => closeModal());
        }
    };

    return (
        <div className={styles.modal__delete_content}>
            <div className={styles.buttons_container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                />
                {tableType === 'persons' ? (
                    buttonOnStatus(statusPerson, onDelete)
                ) : (
                    <Button text="Удалить" onClick={onDelete} />
                )}
            </div>
        </div>
    );
};

DeleteModal.propTypes = {
    closeModal: func,
    changeState: func,
    id: number,
    tableType: string,
    projectsInStatus: shape({}),
    data: oneOfType([shape({}), arrayOf(shape({}))]),
};

export default DeleteModal;
