import { useState } from 'react';
import { func, instanceOf, bool } from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    clearProductionPlans,
    setCurrentDate,
} from 'redux/actions/productionPlans';
import { getAllUnusedPersons } from 'redux/thunks/unusedPersonsThunks';
import API from 'api/api';
import { useToastify } from 'helpers/toastify/useToastify';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import { months } from 'helpers/tableHandler';
import { createPlan, getAllPlans } from 'redux/thunks/productionPlansThunks';
import styles from './styles.module.scss';

const ProductionPlansCalendar = ({
    changeSelectedPeriod = () => {},
    closeModal,
    date,
    isCreatePlan = false,
    clearFiltersAndSorting = () => {},
}) => {
    const [selectedPeriod, setSelectedPeriod] = useState(date);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const year = selectedPeriod.getFullYear();
    const month = selectedPeriod.getMonth();

    const onCreatePlan = () => {
        dispatch(createPlan(year, month));
        closeModal();
        changeSelectedPeriod(selectedPeriod);
    };

    const onSaveAndSyncTimeCrawler = async () => {
        try {
            setLoading(true);
            await API.synchronize.postSynchronize({
                yearFrom: year,
                monthFrom: month + 1,
                yearTo: year,
                monthTo: month + 1,
                synchronizeSteps: {
                    1: 'PRODUCTION_PLANS_ALL',
                    2: 'UNUSED_PERSON_ALL_TIMESHEET',
                    3: 'IMPORT_TIMECRAWLER_PERSONS_HOURS',
                    4: 'PRODUCTION_PLANS_ALL_TIMESHEET',
                },
            });
            useToastify(
                `Синхронизация успешна ${months[month]} ${year} г.`,
                'success'
            );
        } catch (err) {
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setLoading(false);
        }
    };

    const disableClick = (action) => (loading ? () => {} : action);

    return (
        <div className={styles.prodPlans__calendar_wrapper}>
            <DatePicker
                title="*Выберите период"
                showMonthOnly
                dateDrop={selectedPeriod}
                setDateDrop={setSelectedPeriod}
                isRemoveButton={false}
                isRemoveButtonModal={false}
            />
            <div className={styles.prodPlans__calendar_container}>
                {isCreatePlan ? (
                    <div className={styles.prodPlans__calendar_buttons}>
                        <Button
                            buttonStyle="outlined"
                            text="Отмена"
                            onClick={closeModal}
                        />
                        <Button text="Создать" onClick={onCreatePlan} />
                    </div>
                ) : (
                    <div className={styles.bts_with_sync}>
                        <div className={styles.btn_container_sync}>
                            <Button
                                text="Выбрать и синхронизировать с TC"
                                onClick={disableClick(onSaveAndSyncTimeCrawler)}
                                buttonIconName="sync"
                                buttonStyle="outlined"
                                wide
                            />
                        </div>
                        <div className={styles.prodPlans__calendar_buttons}>
                            <div className={styles.prodPlans__calendar_buttons}>
                                <Button
                                    disabled={loading}
                                    buttonStyle="outlined"
                                    text="Отмена"
                                    onClick={disableClick(closeModal)}
                                />
                                <Button
                                    disabled={loading}
                                    text="Выбрать"
                                    onClick={() => {}}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductionPlansCalendar;

ProductionPlansCalendar.propTypes = {
    changeSelectedPeriod: func,
    closeModal: func,
    date: instanceOf(Date),
    isCreatePlan: bool,
    clearFiltersAndSorting: func,
};
