import { useEffect, useState, useContext } from 'react';
import { bool, func, shape } from 'prop-types';
import classNames from 'classnames/bind';
import CVTextarea from 'components/Pages/CVPage/CVTextarea';
import CVChangeForm from 'components/Pages/CVPage/CVChangeForm';
import CVInput from 'components/Pages/CVPage/CVInput';
import CVButtons from 'components/Pages/CVPage/CVButtons';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import styles from './styles.module.scss';

const CVProjectInfo = ({
    setSaveProjects = () => {},
    setOnSave = () => {},
    setOnRemove = () => {},
    submit = () => {},
    removeProject = () => {},
    setRemoveProjectId = () => {},
    removeNewProject = () => {},
    onSave = false,
    onRemove = false,
    cvProject = null,
    isEditing = false,
}) => {
    const cx = classNames.bind(styles);
    const { openModal, closeModal } = useContext(ModalContext);
    const handleClick = (title, children) => {
        openModal({ title, children });
    };
    const [projectInfo, setProjectInfo] = useState(cvProject);

    useEffect(() => {
        if (onSave) {
            setOnSave(false);
            submit(projectInfo);
        }
        if (onRemove) {
            setOnRemove(false);
            removeProject(projectInfo);
        }
        if (!isEditing) setSaveProjects([]);
    }, [onSave, onRemove]);

    const changeDescription = (e) => {
        setProjectInfo({ ...projectInfo, description: e.target.value });
    };

    const changeName = (value) => {
        setProjectInfo({ ...projectInfo, name: value });
    };

    const changeParticipant = (value) => {
        setProjectInfo({ ...projectInfo, participant: value });
    };

    const deleteProject = () => {
        if (projectInfo.localId) {
            handleClick(
                'Вы действительно хотите удалить этот проект?',
                <DeleteModal
                    closeModal={closeModal}
                    data={projectInfo}
                    id={projectInfo.localId}
                    tableType="loacalProjectCv"
                    changeState={removeNewProject}
                />
            );
        } else {
            const remove = () => {
                setRemoveProjectId(projectInfo.id);
                setOnRemove(true);
            };
            handleClick(
                'Вы действительно хотите удалить этот проект?',
                <DeleteModal
                    closeModal={closeModal}
                    data={projectInfo}
                    id={projectInfo.id}
                    tableType="projectCv"
                    changeState={remove}
                />
            );
        }
    };

    return (
        <div className={cx('project_form')}>
            <div className={cx('project_form__buttons')}>
                {isEditing && (
                    <CVButtons
                        isEdit={!isEditing}
                        isDelete={!!projectInfo.id || !!projectInfo.localId}
                        onDelete={deleteProject}
                    />
                )}
            </div>
            <div className={cx('project_name', 'project_edit')}>
                {isEditing ? (
                    <>
                        <span>Название проекта</span>
                        <CVInput
                            inputValue={projectInfo.name}
                            setInputValue={changeName}
                            hidePlus
                        />
                    </>
                ) : (
                    <h4>{projectInfo.name}</h4>
                )}
                {/* //! должны предлагаться проекты
                    <CVInput
                        newTool={projectName}
                        foundedTools={projectsList}
                        onAddNewTool={onAddNewTool}
                        setNewTool={setProjectName}
                        onAddFoundTool={onAddFoundTool}
                        hidePlus
                    /> */}
            </div>
            <div className={cx('project_participant')}>
                {isEditing ? (
                    <>
                        <span>Роль в проекте</span>
                        <CVInput
                            inputValue={projectInfo.participant}
                            setInputValue={changeParticipant}
                            hidePlus
                        />
                    </>
                ) : (
                    <span className={cx('project_chapter')}>
                        {projectInfo.participant}
                    </span>
                )}
            </div>
            <div className={cx('project_description')}>
                {isEditing ? (
                    <>
                        <span>Описание</span>
                        <CVTextarea
                            type="string"
                            text={projectInfo.description}
                            onChange={changeDescription}
                        />
                    </>
                ) : (
                    <span>{projectInfo.description}</span>
                )}
            </div>
            <div className={cx('project_tools')}>
                <div>
                    <span className={cx({ project_chapter: !isEditing })}>
                        Технологии
                    </span>
                    <CVChangeForm
                        existingTagsList={projectInfo.technologies}
                        info={projectInfo}
                        fieldName="technologies"
                        setInfo={setProjectInfo}
                        isEditing={isEditing}
                        grayTags
                    />
                </div>
            </div>
        </div>
    );
};

export default CVProjectInfo;

CVProjectInfo.propTypes = {
    submit: func,
    cvProject: shape({}),
    setSaveProjects: func,
    setOnSave: func,
    onRemove: bool,
    setOnRemove: func,
    setRemoveProjectId: func,
    removeProject: func,
    removeNewProject: func,
    isEditing: bool,
    onSave: bool,
};
