import { useRef } from 'react';
import { config, useSpring } from 'react-spring';
import { bool, element, func, number, oneOfType, string } from 'prop-types';
import classNames from 'classnames/bind';
import { ArrowDown } from 'icons/content/arrows';
import useOnClickOutside from 'hooks/useOnClickOutside';
import ComponentTitle from 'components/ComponentTitle';
import Input from 'components/Input';
import CloseIcon from 'components/svg/ButtonIcon/closeIcon';
import styles from 'components/Dropdown/styles.module.scss';

const DropdownWrapper = (props) => {
    const {
        children,
        disabled = false,
        error = false,
        isOpen = false,
        isReport = false,
        isReportYear = false,
        selected = null,
        isCell = false,
        isInHeader = false,
        setIsOpen,
        title = '',
        searchValue = '',
        setSearchValue = () => {},
        setSelected = () => {},
        listLength = 0,
        multiselect = false,
        withoutDelete = false,
        noMargin = false,
        placeholderInput = '',
        dropdownManualInput = false,
        handleDropdownManualInput = () => {},
    } = props;

    const toggleDrop = (e) => {
        if (e.target.value === undefined) {
            setIsOpen(!isOpen);
        } else setIsOpen(true);
    };

    const closeDrop = () => {
        setIsOpen(false);
    };

    const onEnterPressed = (e) => {
        if (e.key === 'Enter') {
            toggleDrop();
        }
    };

    const springProps = useSpring({
        icon: isOpen ? <arrowUp /> : <arrowDown />,
        config: config.wobbly,
    });

    const ref = useRef();
    useOnClickOutside(ref, closeDrop);
    const cx = classNames.bind(styles);

    const removeAll = (e) => {
        if (multiselect) {
            setSelected([]);
            e.stopPropagation();
        } else {
            setSelected({});
        }
    };

    return (
        <div
            className={cx('wrapper', {
                isReportYear,
                noMargin: isReport || noMargin,
                isCell,
            })}
            ref={ref}
        >
            <ComponentTitle title={title} error={error} />
            <div
                className={cx('title', {
                    error,
                    isCell,
                })}
                onClick={(e) => toggleDrop(e)}
                onKeyPress={onEnterPressed}
                tabIndex={0}
                role="button"
            >
                {(!selected || dropdownManualInput) && (
                    <Input
                        isTableCell={isCell}
                        value={dropdownManualInput ? selected : searchValue}
                        onChange={
                            dropdownManualInput
                                ? handleDropdownManualInput
                                : setSearchValue
                        }
                        isSelectSearchField
                        placeholder={
                            !disabled ? placeholderInput : 'Идёт загрузка'
                        }
                        disabled={disabled}
                    />
                )}
                {selected && !dropdownManualInput && (
                    <div className={styles.selected}>
                        <span
                            className={`${styles.text} ${
                                selected ? styles.value : styles.placeholder
                            } ${isReport ? styles.reports : ''} ${
                                isReportYear ? styles.isReportYear : ''
                            } ${multiselect ? styles.text_multiselect : ''}`}
                        >
                            {disabled ? 'Идёт загрузка' : selected || ''}
                        </span>
                        {!isReport &&
                        !isReportYear &&
                        !withoutDelete &&
                        (selected || selected === 0) &&
                        !disabled ? (
                            <button
                                className={styles.selected_btn}
                                type="button"
                                onClick={(e) => removeAll(e)}
                            >
                                <CloseIcon />
                            </button>
                        ) : null}
                    </div>
                )}
                <div
                    className={`${styles.arrow_icon} ${
                        isOpen ? styles.animated_up : styles.animated_down
                    }`}
                >
                    <ArrowDown />
                </div>
            </div>
            <div
                className={cx('list__block', { isOpen, closed: !isOpen })}
                style={{
                    display: listLength ? 'block' : 'none',
                    position: isInHeader ? 'absolute' : 'relative',
                }}
            >
                {children}
            </div>
        </div>
    );
};

DropdownWrapper.propTypes = {
    children: element,
    disabled: bool,
    error: bool,
    isOpen: bool,
    isReport: bool,
    isReportYear: bool,
    selected: oneOfType([number, string]),
    setIsOpen: func,
    title: string,
    searchValue: string,
    setSearchValue: func,
    setSelected: func,
    listLength: number,
    multiselect: bool,
    withoutDelete: bool,
    noMargin: bool,
    isCell: bool,
    isInHeader: bool,
    placeholderInput: string,
    dropdownManualInput: bool,
    handleDropdownManualInput: func,
};

export default DropdownWrapper;
