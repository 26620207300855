import API from 'api/api';
import { useToastify } from 'helpers/toastify/useToastify';
import { logout } from 'redux/actions/auth';

export const addDistributionFactHours =
    (data, handleSuccessResult) => async (dispatch) => {
        try {
            const res = await API.upload.distributionFactHours(data);
            useToastify('Распредленные часы успешно загружены', 'success');
            handleSuccessResult();
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(
                err?.message ?? 'Ошибка при загрузки компетенций',
                'error'
            );
        }
    };
