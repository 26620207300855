import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useValidator from 'hooks/useValidator';
import { func, shape, string } from 'prop-types';
import DropdownList from 'components/DropdownList';
import { projectTypesDropdownValues, projectTypesList } from 'mock/mockData';
import { getInitialValueObj, toDate } from 'helpers/editFormsValues';
import * as dayjs from 'dayjs';
import Input from 'components/Input';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import { useToastify } from 'helpers/toastify/useToastify';
import { fullDate } from 'helpers/tableHandler';
import { getFilteredPersonsForDropDown } from 'redux/thunks/personThunks';
import CVChangeForm from 'components/Pages/CVPage/CVChangeForm';
import { addProject, editProject } from 'redux/thunks/projectThunks';
import styles from 'components/ModalsContent/ProjectContent/styles.module.scss';

const ProjectContent = ({ initialValues = {}, closeModal = () => {} }) => {
    const { id, items, tools } = initialValues;

    const dispatch = useDispatch();
    const managers = useSelector(
        (state) => state.persons.DropdownListValuesFiltered
    );

    const [isPending, setIsPending] = useState(false);
    const [isLoadingManagers, setIsLoadingManagers] = useState(false);
    const [projectName, setProjectName] = useState(items?.name);
    const [projectTypes, setProjectTypes] = useState(
        projectTypesDropdownValues
    );
    const [projectType, setProjectType] = useState({});
    const [startDate, setStartDate] = useState(toDate(items?.dateStart));
    const [endDate, setEndDate] = useState(toDate(items?.dateEnd));
    const [manager, setManager] = useState({});
    const [startBudget, setStartBudget] = useState(items?.budget);
    const [submitTouched, setSubmitTouched] = useState(false);
    const [technologies, setTechnologies] = useState(tools ?? []);

    useEffect(() => {
        if (id) {
            setProjectType(
                getInitialValueObj(items?.type, projectTypesDropdownValues)
            );
        }
        setProjectTypes(
            projectTypesDropdownValues.filter((el) => el.value !== 'Бенч')
        );
    }, []);

    useEffect(() => {
        if (projectType.id >= 0) {
            setIsLoadingManagers(true);
            if (projectType.id === 4) {
                dispatch(
                    getFilteredPersonsForDropDown(
                        {
                            isActive: '',
                            specialityIds: '14',
                        },
                        setIsLoadingManagers
                    )
                );
            } else {
                dispatch(
                    getFilteredPersonsForDropDown(
                        {
                            isActive: '',
                            specialityIds: '1,14',
                        },
                        setIsLoadingManagers
                    )
                );
            }
        }
    }, [projectType]);

    useEffect(() => {
        if (startDate && endDate) {
            if (startDate.getTime() > endDate.getTime()) {
                setEndDate('');
                useToastify(
                    'Дата окончания периода не может быть меньше даты начала',
                    'warning'
                );
            }
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (id) {
            setManager(getInitialValueObj(items?.manager, managers));
        }
    }, [managers]);

    const onSave = () => {
        if (isValid && !isPending) {
            setIsPending(true);
            const data = {
                name: projectName,
                type: projectTypesList[projectType.id],
                initialBudget: startBudget?.replace(/\ /g, ''),
                dateStart: fullDate(dayjs(startDate).format('DD.MM.YYYY')),
                dateEnd: fullDate(dayjs(endDate).format('DD.MM.YYYY')),
                managerId: manager.id,
                technologies,
            };
            if (id) {
                dispatch(editProject(id, data, closeModal, setIsPending));
            } else {
                dispatch(addProject(data, closeModal, setIsPending));
            }
        }
        setSubmitTouched(true);
    };

    const { isValid, validator } = useValidator({
        projectName,
        projectType: projectType?.value,
        startDate,
        endDate,
        manager: manager?.value,
        ...(projectType?.value === 'Фикспрайс' && { startBudget }),
    });

    return (
        <div className={styles.project_content}>
            <Input
                value={projectName}
                onChange={setProjectName}
                placeholder="Наименование проекта"
                error={!validator.projectName && submitTouched}
                noMargin
            />
            {projectType.id === 4 ? (
                <Input
                    disabled
                    placeholder="Тип проекта"
                    value={projectType.value}
                />
            ) : (
                <DropdownList
                    placeholderInput="Тип проекта"
                    selected={projectType}
                    setSelected={setProjectType}
                    items={projectTypes}
                    error={!validator.projectType && submitTouched}
                    noMargin
                />
            )}
            <DatePicker
                dateDrop={startDate}
                setDateDrop={setStartDate}
                placeholder="Дата начала"
                error={!validator.startDate && submitTouched}
            />
            <DatePicker
                dateDrop={endDate}
                setDateDrop={setEndDate}
                placeholder="Дата окончания"
                error={!validator.endDate && submitTouched}
            />
            <DropdownList
                selected={manager}
                setSelected={setManager}
                placeholderInput="Менеджер"
                items={managers}
                error={!validator.manager && submitTouched}
                noMargin
                disabled={isLoadingManagers}
            />
            <Input
                onChange={setStartBudget}
                placeholder="Первоначальный бюджет"
                symbolLimit={12}
                type="budget"
                value={startBudget}
                error={
                    !validator.startBudget &&
                    submitTouched &&
                    projectType?.value === 'Фикспрайс'
                }
                noMargin
            />
            <div className={styles.tools}>
                <CVChangeForm
                    placeholder="Технологии"
                    existingTagsList={technologies}
                    info={technologies}
                    setInfo={setTechnologies}
                    isEditing
                    grayTags
                />
            </div>
            <div className={styles.buttons_container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                />
                <Button text="Сохранить" onClick={onSave} />
            </div>
        </div>
    );
};

ProjectContent.propTypes = {
    initialValues: shape({ string }),
    closeModal: func,
};
export default ProjectContent;
