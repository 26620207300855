import { bool, func } from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const Switcher = ({ isActive = true, setChecked = () => {} }) => {
    const cx = classNames.bind(styles);

    return (
        <div className={cx('container', { isActive, isDisable: !isActive })}>
            <div
                className={cx('value', {
                    value__isActive: isActive,
                    value__hover: !isActive,
                })}
                onClick={() => {
                    setChecked(true);
                }}
            >
                Активен
            </div>
            <div
                className={cx('value', { value__hover: isActive })}
                onClick={() => {
                    setChecked(false);
                }}
            >
                Неактивен
            </div>
        </div>
    );
};

Switcher.propTypes = {
    isActive: bool,
    setChecked: func,
};

export default Switcher;
