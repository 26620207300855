import {
    GET_FREQUENCY_DATA,
    ADD_FREQUENCY_DATA,
    EDIT_FREQUENCY_DATA,
} from 'redux/types';

export const getFrequencyData = (data) => ({
    type: GET_FREQUENCY_DATA,
    payload: data,
});

export const addFrequencyData = (data) => ({
    type: ADD_FREQUENCY_DATA,
    payload: data,
});

export const editFrequencyData = (data) => ({
    type: EDIT_FREQUENCY_DATA,
    payload: data,
});
