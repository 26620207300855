import {
    ADD_SPECIALITY_DATA,
    DELETE_SPECIALITY_DATA,
    EDIT_SPECIALITY_DATA,
    GET_SPECIALTIES_DATA,
    GET_COMPETENCES_DATA,
} from 'redux/types';

export const addSpecialityData = (data) => ({
    type: ADD_SPECIALITY_DATA,
    payload: data,
});

export const deleteSpecialityData = (id) => ({
    type: DELETE_SPECIALITY_DATA,
    payload: id,
});

export const editSpecialityData = (data) => ({
    type: EDIT_SPECIALITY_DATA,
    payload: data,
});

export const getSpecialtiesData = (data) => ({
    type: GET_SPECIALTIES_DATA,
    payload: data,
});

export const getCompetencesData = (data) => ({
    type: GET_COMPETENCES_DATA,
    payload: data,
});
