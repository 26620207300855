import { USER_DATA, LOGOUT, SET_IS_LOADING_AUTH } from 'redux/types';

const initialData = {
    personId: '',
    userFullName: '',
    userLogin: '',
    userMiddleName: '',
    userName: '',
    userPosition: '',
    userRole: '',
    userShortName: '',
    userSurname: '',
};

const defaultState = {
    userData: initialData,
    isLoading: false,
};

export const auth = (state = defaultState, action) => {
    switch (action.type) {
        case USER_DATA:
            return {
                ...state,
                userData: action.payload,
            };
        case SET_IS_LOADING_AUTH:
            return {
                ...state,
                isLoading: action.payload,
            };
        case LOGOUT:
            return {
                ...state,
                userData: initialData,
            };
        default:
            return state;
    }
};
