//ORGANIZATIONS
export const ADD_ORGANIZATION_DATA = 'ADD_ORGANIZATION_DATA';
export const DELETE_ORGANIZATION_DATA = 'DELETE_ORGANIZATION_DATA';
export const EDIT_ORGANIZATION_DATA = 'EDIT_ORGANIZATION_DATA';
export const GET_ORGANIZATIONS_DATA = 'GET_ORGANIZATIONS_DATA';

//ASYNC_TASKS
export const GET_ASYNC_TASKS = 'GET_ASYNC_TASKS';

//PERSONS
export const ADD_PERSON_DATA = 'ADD_PERSON_DATA';
export const EDIT_PERSON_DATA = 'EDIT_PERSON_DATA';
export const EDIT_PERSON_STATUS = 'EDIT_PERSON_STATUS';
export const GET_PERSONS_DATA = 'GET_PERSONS_DATA';
export const GET_FILTERED_PERSONS_DATA = 'GET_FILTERED_PERSONS_DATA';
export const SET_PERSONS_FILTER_STATUS = 'SET_PERSONS_FILTER_STATUS';
export const SET_PERSON_PHOTO = 'SET_PERSON_PHOTO';
export const DELETE_PERSON_PHOTO = 'DELETE_PERSON_PHOTO';
export const GET_PERSON_DATA_DROPDOWN = 'GET_PERSON_DATA_DROPDOWN';
export const GET_PERSON_DATA_DROPDOWN_FILTERED =
    'GET_PERSON_DATA_DROPDOWN_FILTERED';

//PERSON_JIRA
export const GET_PERSONS_JIRA = 'GET_PERSONS_JIRA';
export const ADD_PERSONS_JIRA = 'ADD_PERSONS_JIRA';

//PERSONS_CV
export const GET_PERSONS_CV_DATA = 'GET_PERSONS_CV_DATA';
export const SET_PERSONS_CV_FILTER_STATUS = 'SET_PERSONS_CV_FILTER_STATUS';
export const ADD_PERSON_CV = 'ADD_PERSON_CV';
export const DELETE_PERSON_CV = 'DELETE_PERSON_CV';
export const RESET_DATA_PESONS_CV = 'RESET_DATA_PESONS_CV';
export const GET_PEROSN_CV_DATA_DROPDOWN = 'GET_PEROSN_CV_DATA_DROPDOWN';

//MODULES
export const GET_MODULES_DATA = 'GET_MODULES_DATA';
export const ADD_MODULE_DATA = 'ADD_MODULE_DATA';
export const DELETE_MODULE_DATA = 'DELETE_MODULE_DATA';
export const EDIT_MODULE_DATA = 'EDIT_MODULE_DATA';

//SYSTEMS
export const GET_SYSTEMS_DATA = 'GET_SYSTEMS_DATA';

//FREQUENCY
export const GET_FREQUENCY_DATA = 'GET_FREQUENCY_DATA';
export const ADD_FREQUENCY_DATA = 'ADD_FREQUENCY_DATA';
export const EDIT_FREQUENCY_DATA = 'EDIT_FREQUENCY_DATA';

//PROJECT_TEAMS
export const ADD_PROJECT_TEAM_DATA = 'ADD_PROJECT_TEAM_DATA';
export const DELETE_PROJECT_TEAMS_DATA = 'DELETE_PROJECT_TEAMS_DATA';
export const EDIT_PROJECT_TEAM_DATA = 'EDIT_PROJECT_TEAM_DATA';
export const GET_PROJECT_TEAMS_DATA = 'GET_PROJECT_TEAMS_DATA';

//PROJECTS
export const DELETE_PROJECTS_DATA = 'DELETE_PROJECTS_DATA';
export const GET_PROJECTS_DATA = 'GET_PROJECTS_DATA';
export const EDIT_PROJECT_DATA = 'EDIT_PROJECT_DATA';
export const ADD_PROJECT_DATA = 'ADD_PROJECT_DATA';
export const SET_PROJECTS_FILTER_STATUS = 'SET_PROJECTS_FILTER_STATUS';
export const SET_PROJECTS_FROM_TIME_CRAWLER = 'SET_PROJECTS_FROM_TIME_CRAWLER';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const GET_FILTERED_PROJECTS_DATA = 'GET_FILTERED_PROJECTS_DATA';
export const GET_PROJECTS_FOR_DROPDOWN = 'GET_PROJECTS_FOR_DROPDOWN';
export const GET_PROJECTS_FOR_DROPDOWN_FILTERED =
    'GET_PROJECTS_FOR_DROPDOWN_FILTERED';

//JIRA_PROJECTS
export const GET_JIRA_PROJECTS_DATA = 'GET_JIRA_PROJECTS_DATA';
export const ADD_JIRA_PROJECTS_DATA = 'ADD_JIRA_PROJECTS_DATA';

//SPECIALTIES
export const ADD_SPECIALITY_DATA = 'ADD_SPECIALITY_DATA';
export const DELETE_SPECIALITY_DATA = 'DELETE_SPECIALITY_DATA';
export const EDIT_SPECIALITY_DATA = 'EDIT_SPECIALITY_DATA';
export const GET_SPECIALTIES_DATA = 'GET_SPECIALTIES_DATA';
export const GET_COMPETENCES_DATA = 'GET_COMPETENCES_DATA';

//POSITIONS
export const ADD_POSITION_DATA = 'ADD_POSITION_DATA';
export const DELETE_POSITION_DATA = 'DELETE_POSITION_DATA';
export const EDIT_POSITION_DATA = 'EDIT_POSITION_DATA';
export const GET_POSITIONS_DATA = 'GET_POSITIONS_DATA';

//BASE TARIFF_RATES
export const ADD_BASE_TARIFF_RATE_DATA = 'ADD_BASE_TARIFF_RATE_DATA';
export const EDIT_BASE_TARIFF_RATE_DATA = 'EDIT_BASE_TARIFF_RATES_DATA';
export const GET_BASE_TARIFF_RATES_DATA = 'GET_BASE_TARIFF_RATES_DATA';
export const GET_BASE_TARIFF_RATES_FOR_SELECTED_PERSON =
    'GET_BASE_TARIFF_RATES_FOR_SELECTED_PERSON';

//TEAM PERIODS
export const ADD_TEAM_PERIOD_DATA = 'ADD_TEAM_PERIOD_DATA';
export const DELETE_TEAM_PERIOD_DATA = 'DELETE_TEAM_PERIOD_DATA';
export const EDIT_TEAM_PERIOD_DATA = 'EDIT_TEAM_PERIOD_DATA';
export const GET_TEAM_PERIOD_DATA = 'GET_TEAM_PERIOD_DATA';

//PRODUCTION PLANS
export const ADD_PRODUCTION_PLANS_DATA = 'ADD_PRODUCTION_PLANS_DATA';
export const DELETE_PRODUCTION_PLANS_DATA = 'DELETE_PRODUCTION_PLANS_DATA';
export const DELETE_PRODUCTION_PLAN_BY_DATE_DATA =
    'DELETE_PRODUCTION_PLAN_BY_DATE_DATA';
export const EDIT_PRODUCTION_PLANS_DATA = 'EDIT_PRODUCTION_PLANS_DATA';
export const GET_PRODUCTION_PLANS_DATA = 'GET_PRODUCTION_PLANS_DATA';
export const GET_PRODUCTION_PLANS_DATA_PERSON =
    'GET_PRODUCTION_PLANS_DATA_PERSON';
export const CLEAR_PLANS_DATA = 'CLEAR_PLANS_DATA';
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';
export const SET_INITIAL_SUMS = 'SET_INITIAL_SUMS';
export const SET_SIZE_PRODUCTION_PLANS_TABLES =
    'SET_SIZE_PRODUCTION_PLANS_TABLES';
export const SET_UNLOAD_PRODUCTION_PLAN_ERROR =
    'SET_UNLOAD_PRODUCTION_PLAN_ERROR';

//BUDGET
export const ADD_BUDGET_DATA = 'ADD_BUDGET_DATA';
export const DELETE_BUDGET_DATA = 'DELETE_BUDGET_DATA';
export const EDIT_BUDGET_DATA = 'EDIT_BUDGET_DATA';
export const GET_ALL_BUDGET_DATA = 'GET_ALL_BUDGET_DATA';
export const GET_BUDGET_DATA = 'GET_BUDGET_DATA';

//NOTIFICATIONS
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

//AUTHORIZATION
export const USER_DATA = 'USER_DATA';
export const LOGOUT = 'LOGOUT';
export const SET_IS_LOADING_AUTH = 'SET_IS_LOADING_AUTH';

//REPORTS
export const GET_REPORTS_PROJECTS_DATA = 'GET_REPORTS_PROJECTS_DATA';
export const GET_REPORTS_PERSONS_DATA = 'GET_REPORTS_PERSONS_DATA';
export const GET_REPORTS_PERSONS_WORKLOAD_DATA =
    'GET_REPORTS_PERSONS_WORKLOAD_DATA';
export const GET_REPORTS_PROJECT_WORKLOAD_DATA =
    'GET_REPORTS_PROJECT_WORKLOAD_DATA';
export const GET_REPORTS_PROJECT_TYPE_DATA = 'GET_REPORTS_PROJECT_TYPE_DATA';
export const GET_REPORTS_COMPETENCE_LEVEL_DATA =
    'GET_REPORTS_COMPETENCE_LEVEL_DATA';
export const GET_REPORTS_MANAGER_KPI_DATA = 'GET_REPORTS_MANAGER_KPI_DATA';
export const GET_PROJECTS_QUARTERS_DATA = 'GET_PROJECTS_QUARTERS_DATA';
export const GET_UNUSED_PERSONS_REPORT_DATA = 'GET_UNUSED_PERSONS_REPORT_DATA';
export const GET_PERSONS_EXPERIENCE_REPORT_DATA =
    'GET_PERSONS_EXPERIENCE_REPORT_DATA';
export const GET_BALANCE_BUDGETS_REPORT_DATA =
    'GET_BALANCE_BUDGETS_REPORT_DATA';
export const SET_MONTH_FROM = 'SET_MONTH_FROM';
export const SET_MONTH_TO = 'SET_MONTH_TO';
export const GET_TEAM_PERIOD_PERSONS_DATA = 'GET_TEAM_PERIOD_PERSON_DATA';

//UNUSED_PERSONS
export const GET_ALL_UNUSED_PERSONS = 'GET_ALL_UNUSED_PERSONS';
export const DELETE_UNUSED_PERSON = 'DELETE_UNUSED_PERSON';
export const EDIT_UNUSED_PERSON = 'EDIT_UNUSED_PERSON';
export const CLEAR_UNUSED_PERSONS = 'CLEAR_UNUSED_PERSONS';
export const SET_SIZE_UNUSED_PERSONS_TABLES = 'SET_SIZE_UNUSED_PERSONS_TABLES';

//USERS
export const DELETE_USER_DATA = 'DELETE_USER_DATA';
export const GET_USERS_DATA = 'GET_USERS_DATA';
export const EDIT_USER_DATA = 'EDIT_USER_DATA';
export const ADD_USER_DATA = 'ADD_USER_DATA';
export const GET_FILTER_USERS_DATA = 'GET_FILTERED_USERS_DATA';

//CALENDAR
export const GET_CALENDAR = 'GET_CALENDAR';
export const SET_CURRENT_DATE_CALENDAR = 'SET_CURRENT_DATE_CALENDAR';
export const CLEAR_CALENDAR = 'CLEAR_CALENDAR';
