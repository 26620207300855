export const deleteProjectTeamsData = (id) => ({
    type: 'DELETE_PROJECT_TEAMS_DATA',
    payload: id,
});

export const getProjectTeamsData = (data) => ({
    type: 'GET_PROJECT_TEAMS_DATA',
    payload: data,
});

export const addProjectTeamData = (data) => ({
    type: 'ADD_PROJECT_TEAM_DATA',
    payload: data,
});

export const editProjectTeamData = (data) => ({
    type: 'EDIT_PROJECT_TEAM_DATA',
    payload: data,
});
