import {
    ADD_NOTIFICATION,
    CLOSE_NOTIFICATION,
    REMOVE_NOTIFICATION,
} from 'redux/types';

const defaultState = {
    notifications: [],
};

export const notifications = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            };
        case CLOSE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    action.dismissAll || notification.key === action.key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                ),
            };
        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.key !== action.key
                ),
            };

        default:
            return state;
    }
};
