import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { shape } from 'prop-types';
import { numbersDepth } from 'helpers/tableHandler';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const TableBody = ({ items = {} }) => {
    const monthsArr = useSelector((state) => state.reports.monthsArr);
    const cx = classNames.bind(styles);

    return (
        <tbody className={styles.table__body}>
            {items.infoByPersons.map(
                (infoByPersonsItem, infoByPersonsIndex) => (
                    <tr key={infoByPersonsIndex}>
                        <td className={cx('left_border')}>
                            <div>{infoByPersonsItem.personName}</div>
                        </td>
                        <td className={cx('highlighted_cell')}>
                            <span>
                                {numbersDepth(
                                    infoByPersonsItem.totalPlanExpenses
                                )}
                            </span>
                        </td>
                        <td className={cx('highlighted_cell')}>
                            <span>
                                {numbersDepth(
                                    infoByPersonsItem.totalFactExpenses
                                )}
                            </span>
                        </td>
                        {monthsArr.map((month) => (
                            <Fragment key={month.value}>
                                <td>
                                    <span>
                                        {infoByPersonsItem.personInfoByPeriods.map(
                                            (personInfoByPeriodsItem) => {
                                                return personInfoByPeriodsItem.month -
                                                    1 ===
                                                    month.id
                                                    ? numbersDepth(
                                                          personInfoByPeriodsItem.planExpenses
                                                      )
                                                    : '';
                                            }
                                        )}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {infoByPersonsItem.personInfoByPeriods.map(
                                            (personInfoByPeriodsItem) => {
                                                return personInfoByPeriodsItem.month -
                                                    1 ===
                                                    month.id
                                                    ? numbersDepth(
                                                          personInfoByPeriodsItem.factExpenses
                                                      )
                                                    : '';
                                            }
                                        )}
                                    </span>
                                </td>
                            </Fragment>
                        ))}
                    </tr>
                )
            )}
            <tr className={cx('highlighted_cell', 'active_text')}>
                <td className={cx('left_border')}>
                    <div>Всего</div>
                </td>
                <td>
                    <span>{numbersDepth(items?.totalPlanExpenses)}</span>
                </td>
                <td>
                    <span>{numbersDepth(items?.totalFactExpenses)}</span>
                </td>
                {items.totalInfoByPeriods.map(
                    (totalInfoByPeriodsItem, totalInfoByPeriodsIndex) => (
                        <Fragment key={totalInfoByPeriodsIndex}>
                            <td>
                                <span>
                                    {numbersDepth(
                                        totalInfoByPeriodsItem.planExpenses
                                    )}
                                </span>
                            </td>
                            <td
                                className={cx(
                                    'table__body_cell',
                                    'blue_cell',
                                    'total-values'
                                )}
                            >
                                <span>
                                    {numbersDepth(
                                        totalInfoByPeriodsItem.factExpenses
                                    )}
                                </span>
                            </td>
                        </Fragment>
                    )
                )}
            </tr>
        </tbody>
    );
};
export default TableBody;

TableBody.propTypes = {
    items: shape({}),
};
