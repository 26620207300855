import API from 'api/api';
import { logout, setIsLoadingAuth, userData } from 'redux/actions/auth';

import { useToastify } from 'helpers/toastify/useToastify';

export const getUserInfo = () => (dispatch) => {
    dispatch(setIsLoadingAuth(true));
    API.userInfo
        .getUserInfo()
        .then((res) => {
            dispatch(userData(res.data.entity));
        })
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        })
        .finally(() => {
            dispatch(setIsLoadingAuth(false));
        });
};
