import { string } from 'prop-types';
import styles from './styles.module.scss';

const SortSvg = ({ sort }) => {
    const isSort =
        sort === 'asc'
            ? styles.sort_asc
            : sort === 'desc'
            ? styles.sort_desc
            : styles.sort;

    return (
        <span className={isSort}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_4319_10986)">
                    <path id="1" d="M12 3L8 10H16L12 3Z" fill="#696C80" />
                    <path id="2" d="M12 21L16 14L8 14L12 21Z" fill="#696C80" />
                </g>
                <defs>
                    <clipPath id="clip0_4319_10986">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );
};

export default SortSvg;

SortSvg.propTypes = {
    sort: string,
};
