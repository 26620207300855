import { Fragment } from 'react';
import { quarters } from 'helpers/tableHandler';
import styles from './styles.module.scss';

const TableHeader = () => (
    <thead className={styles.table__head}>
        <tr>
            <td rowSpan="2">Проект</td>
            {quarters.map((item, ind) => (
                <td key={ind} colSpan="2">
                    {item}
                </td>
            ))}
            <td colSpan="2">Итого</td>
        </tr>
        <tr>
            {quarters.map((item, ind) => (
                <Fragment key={ind}>
                    <td>План</td>
                    <td>Факт</td>
                </Fragment>
            ))}
            <td>План</td>
            <td>Факт</td>
        </tr>
    </thead>
);

export default TableHeader;
