import { Fragment } from 'react';
import { shape } from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const TableBody = ({ items = {} }) => {
    const cx = classNames.bind(styles);

    return (
        <tbody className={styles.table__body}>
            {items.infoByPersons.map((item, index) => (
                <Fragment key={index}>
                    <tr>
                        <td
                            className={cx('left_border')}
                            rowSpan={item.infoByProjects.length + 2}
                        >
                            <div>{item.personName}</div>
                        </td>
                    </tr>

                    {item.infoByProjects.map((byProject, ind) => (
                        <tr key={ind}>
                            <td>
                                <div key={ind}>{byProject.projectName}</div>
                            </td>
                            {byProject.projectInfoByPeriods.map(
                                (projectPeriod, i) => (
                                    <td key={i}>
                                        <span>{projectPeriod.factHours}</span>
                                    </td>
                                )
                            )}
                        </tr>
                    ))}
                    <tr className={cx('highlighted_cell')}>
                        <td>
                            <div>Всего</div>
                        </td>
                        {item.totalInfoByPeriods?.map((personPeriod, ind) => (
                            <td key={ind}>
                                <span>{personPeriod.factHours}</span>
                            </td>
                        ))}
                    </tr>
                </Fragment>
            ))}
        </tbody>
    );
};
export default TableBody;

TableBody.propTypes = {
    items: shape({}),
};
