import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import PageHeader from 'components/PageHeader';
import Table from 'components/Tables/Table';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import ProfileModal from 'components/ModalsContent/ProfileModal';
import Loader from 'components/Loader';
import { headers } from 'helpers/tableHandler';
import { editSort } from 'helpers/editFormsValues';
import {
    getAllPersonsInit,
    getFilteredPersons,
} from 'redux/thunks/personThunks';
import { getAllCompetences } from 'redux/thunks/competenceLevelsThunks';
import { getAllSpecialties } from 'redux/thunks/specialtiesThunks';
import { getAllPositions } from 'redux/thunks/positionsThunks';
import FiltersModal from 'components/Filters/FiltersModal';
import { useNavigate } from 'react-router';
import {
    filtersAreaHandler,
    statusList,
} from 'helpers/Filters/filtersAreaHandler';
import { initPlaceholder } from 'helpers/Filters/FiltersModalHelpers';
import { accessByRole } from 'helpers/roleHelpers';
import isEmpty from 'helpers/isEmpty';
import styles from 'components/Pages/Directories/styles.module.scss';

const Persons = () => {
    const dispatch = useDispatch();
    const { openModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const data = useSelector((state) => state.persons.data);
    const initStatus = useSelector((state) => state.persons.isActive);
    const currentRole = useSelector((state) => state.auth.userData.userRole);

    const personsList = useSelector(
        (state) => state.persons.DropdownListValues
    );
    const specialityList = useSelector(
        (state) => state.specialtiesAndCompetence.DropdownSpecialtiesListValues
    );
    const positionsList = useSelector(
        (state) => state.positions.DropdownPositionsListValues
    );
    const competencesList = useSelector(
        (state) => state.specialtiesAndCompetence.DropdownCompetenceListValues
    );

    const [fullName, setFullName] = useState([]);
    const [speciality, setSpeciaity] = useState([]);
    const [position, setPosition] = useState([]);
    const [competence, setCompetence] = useState([]);
    const [status, setStatus] = useState({});
    const [tabelFilter, setTabelFilter] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });

    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState({ property: 'fullName', order: 'asc' });

    useEffect(() => {
        if (accessByRole('Persons', 'getAllPersons', currentRole)) {
            dispatch(getAllCompetences());
            dispatch(getAllSpecialties());
            dispatch(getAllPositions());
            dispatch(getAllPersonsInit());
        }
    }, [dispatch]);

    useEffect(() => {
        if (accessByRole('Persons', 'getAllFilteredPersons', currentRole)) {
            if (!isFirstLoad) {
                dispatch(
                    getFilteredPersons(filters, sort, closeModal, setIsLoading)
                );
            } else {
                setIsFirstLoad(false);
            }
        }
    }, [closeModal, dispatch, filters, sort]);

    const initValue = useCallback(() => {
        setStatus(() => {
            if (initStatus !== '') {
                return statusList.find((elem) => elem.boolValue === initStatus);
            }
            return [];
        });
        const tabelNumbers = data
            .filter((elem) => elem.personNumber)
            .map((elem) => elem.personNumber);

        initPlaceholder(
            tabelNumbers,
            {
                min: tabelFilter.placeholderMin,
                max: tabelFilter.placeholderMax,
            },
            setTabelFilter
        );
    }, [data, initStatus]);

    useEffect(() => {
        if (isOpenFilters) {
            initValue();
        }
    }, [initValue, isOpenFilters]);

    const dataForFilters = useMemo(
        () => ({
            status,
            changeStatus: setStatus,
            fullName,
            personsList,
            changeFullName: setFullName,
            specialityList,
            speciality,
            changeSpeciality: setSpeciaity,
            positionsList,
            position,
            changePosition: setPosition,
            competencesList,
            competence,
            changeCompetence: setCompetence,
            tabelFilter,
            changeTabelFilter: setTabelFilter,
        }),
        [
            status,
            fullName,
            personsList,
            specialityList,
            speciality,
            positionsList,
            position,
            competencesList,
            competence,
            tabelFilter,
        ]
    );

    const filtersForModal = useMemo(
        () => filtersAreaHandler('PERSONS', dataForFilters),
        [dataForFilters]
    );

    const handleClick = (title, children) => {
        openModal({ title, children });
    };

    const handleClickSaveFilters = () => {
        setFilters(() => ({
            ids: fullName.map((elem) => elem?.id),
            specialityIds: speciality.map((elem) => elem?.id),
            positionIds: position.map((elem) => elem?.id),
            competenceLevel: competence.map((elem) => elem?.value),
            personNumberFrom: tabelFilter.from || '',
            personNumberTo: tabelFilter.to || '',
            isActive: isEmpty(status) ? status.boolValue : '',
        }));
    };

    const handleClickFilters = () => {
        setIsOpenFilters(true);
    };

    const onSortHandler = (header) => {
        editSort(header, sort, setSort);
    };

    const titleOnStatus = useCallback((initial) => {
        let title = null;
        if (initial.items.status) {
            title = `Вы действительно хотите заблокировать сотрудника ${initial.items.fullName} ?`;
        } else {
            title = `Вы действительно хотите разблокировать сотрудника ${initial.items.fullName} ?`;
        }
        return title;
    }, []);

    return (
        <>
            <PageHeader
                title="Справочник сотрудников"
                addButton={accessByRole('Persons', 'addButton', currentRole)}
                addButtonText="Добавить сотрудника"
                filtersButton={accessByRole(
                    'Persons',
                    'filtersButton',
                    currentRole
                )}
                handlerClickFiltersButton={handleClickFilters}
                openAddModal={() => {
                    handleClick(
                        'Добавление сотрудника',
                        <ProfileModal
                            statusModal="add"
                            closeModal={closeModal}
                        />
                    );
                }}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <FiltersModal
                        isActive={isOpenFilters}
                        setIsActive={setIsOpenFilters}
                        filters={filtersForModal}
                        onSave={handleClickSaveFilters}
                    />
                    {!data?.length ? (
                        <div className={styles.empty_block}>
                            Список сотрудников пуст
                        </div>
                    ) : (
                        <Table
                            header={headers.persons}
                            items={data}
                            caseName="PERSONS"
                            lockPersonIcon={accessByRole(
                                'Persons',
                                'lockPersonIcon',
                                currentRole
                            )}
                            addPersonIcon={accessByRole(
                                'Persons',
                                'addPersonIcon',
                                currentRole
                            )}
                            editIcon={accessByRole(
                                'Persons',
                                'editIcon',
                                currentRole
                            )}
                            deleteIcon={accessByRole(
                                'Persons',
                                'deleteIcon',
                                currentRole
                            )}
                            openEditModal={(item) => {
                                handleClick(
                                    'Профиль сотрудника',
                                    <ProfileModal
                                        statusModal="edit"
                                        closeModal={closeModal}
                                        id={item.id}
                                    />
                                );
                            }}
                            openDeleteModal={(initial) => {
                                handleClick(
                                    titleOnStatus(initial),
                                    <DeleteModal
                                        closeModal={closeModal}
                                        data={data}
                                        id={initial.id}
                                        tableType="persons"
                                    />
                                );
                            }}
                            onSortHandler={onSortHandler}
                            sort={sort}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Persons;
