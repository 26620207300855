import PageHeader from 'components/PageHeader';
import ReportCardErrorTable from 'components/Tables/ReportCardErrorTable';
import { headers } from 'helpers/tableHandler';
import { useToastify } from 'helpers/toastify/useToastify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { addDistributionFactHours } from 'redux/thunks/fileUploadThunks';
import { getAllPersonsForDropDown } from 'redux/thunks/personThunks';
import { getAllPlans } from 'redux/thunks/productionPlansThunks';

const ReportCardPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector((state) => state.productionPlans.unloadErrorData);
    const personList = useSelector((state) => state.persons.DropdownListValues);
    const prodPalns = useSelector((state) => state.productionPlans.data);
    const [additionalData, setAdditionalData] = useState([]);

    useEffect(() => {
        dispatch(getAllPersonsForDropDown());
        dispatch(
            getAllPlans({
                yearIs: data[0].timesheetYear,
                monthIs: data[0].timesheetMonth,
            })
        );
    }, []);

    useEffect(() => {
        if (prodPalns?.length) {
            setAdditionalData([
                ...data.map((item, index) => ({
                    ...item,
                    id: index,
                    availableProjectIds: prodPalns.reduce(
                        (acc, plan) =>
                            plan.person.id === item.accountingPersonId
                                ? [
                                      ...acc,
                                      {
                                          projectId: plan.project.id,
                                          personId: plan.person.id,
                                          name: plan.project.name,
                                          factHours: 0,
                                          isErrorFactHours: true,
                                      },
                                  ]
                                : [...acc],
                        []
                    ),
                })),
            ]);
        }
    }, [data, prodPalns]);

    const handleClickSend = () => {
        let dataForRequest = additionalData.filter(
            (el) => el.availableInAccounting
        );
        const isDistributionFactHours = dataForRequest.every(
            (el) =>
                el.availableProjectIds.length > 0 &&
                el.availableProjectIds.every(
                    (project) => !project?.isErrorFactHours
                )
        );
        if (isDistributionFactHours) {
            dataForRequest = [
                ...dataForRequest.map((el) => ({
                    personId: el.accountingPersonId,
                    personNumber: el.timesheetPersonNumber,
                    allFactHours: el.timesheetFactHours,
                    month: el.timesheetMonth,
                    year: el.timesheetYear,
                    factHoursForProjectId: {
                        ...el.availableProjectIds.reduce(
                            (projectsIds, project) => {
                                return {
                                    ...projectsIds,
                                    [project.factHours]: project.projectId,
                                };
                            },
                            {}
                        ),
                    },
                })),
            ];
            dispatch(
                addDistributionFactHours(dataForRequest, handleSuccessResult)
            );
        } else {
            useToastify(
                'Распределенные часы по проектам не соотвествуют часам из табеля',
                'warning'
            );
        }
    };

    const fillHoursHandler = (person, addFactHours, ignoreProjectId) => {
        const result = person.availableProjectIds.reduce(
            ([isError, sumFactHours], project) => {
                if (project.projectId === ignoreProjectId) {
                    if (+sumFactHours === +person.timesheetFactHours) {
                        return [false, +sumFactHours];
                    }
                    return [true, +sumFactHours];
                }
                if (
                    +sumFactHours + +project.factHours ===
                    +person.timesheetFactHours
                ) {
                    return [false, +sumFactHours + +project.factHours];
                }
                return [true, +sumFactHours + +project.factHours];
            },
            [false, +addFactHours]
        )[0];
        return result;
    };

    const handleSelect = (item, person) => {
        setAdditionalData((prev) => [
            ...prev.map((el) => {
                if (el.id === item.id) {
                    return {
                        ...el,
                        accountingPersonId: person.id,
                        accountingFullName: person.value,
                        availableProjectIds: prodPalns.reduce(
                            (acc, plan) =>
                                plan.person.id === person.id
                                    ? [
                                          ...acc,
                                          {
                                              projectId: plan.project.id,
                                              personId: plan.person.id,
                                              name: plan.project.name,
                                              factHours: 0,
                                              isErrorFactHours: true,
                                          },
                                      ]
                                    : [...acc],
                            []
                        ),
                    };
                }
                return el;
            }),
        ]);
    };

    const handleChangeInput = (value, info) => {
        setAdditionalData((prev) => [
            ...prev.map((el) =>
                el.accountingPersonId === info.personId
                    ? {
                          ...el,
                          availableProjectIds: [
                              ...el.availableProjectIds.map((project) =>
                                  project.projectId === info.projectId
                                      ? {
                                            ...project,
                                            factHours: value,
                                            isErrorFactHours: fillHoursHandler(
                                                el,
                                                value,
                                                info.projectId
                                            ),
                                        }
                                      : {
                                            ...project,
                                            isErrorFactHours: fillHoursHandler(
                                                el,
                                                value,
                                                info.projectId
                                            ),
                                        }
                              ),
                          ],
                      }
                    : { ...el }
            ),
        ]);
    };

    const handleChecked = (id) => {
        setAdditionalData((prev) => [
            ...prev.map((el) =>
                el.id === id
                    ? {
                          ...el,
                          availableInAccounting: !el.availableInAccounting,
                      }
                    : { ...el }
            ),
        ]);
    };

    const handleCheckedAll = (value) => {
        setAdditionalData((prev) => [
            ...prev.map((el) => ({ ...el, availableInAccounting: value })),
        ]);
    };

    const handleSuccessResult = () => {
        navigate('/production-plans');
    };

    return (
        <>
            <PageHeader
                title="Исправление ошибки в загрузке табеля"
                backButton
                button
                buttonText="Применить"
                handlerClick={handleClickSend}
            />

            <ReportCardErrorTable
                header={headers.reportCardError}
                caseName="REPORT_CARD_ERROR"
                items={additionalData}
                dropDownItems={personList}
                handleSelect={handleSelect}
                onChangeInput={handleChangeInput}
                setCheckedHandler={handleChecked}
                setCheckedHandlerAll={handleCheckedAll}
            />

            <div />
        </>
    );
};

export default ReportCardPage;
