import {
    ADD_POSITION_DATA,
    DELETE_POSITION_DATA,
    EDIT_POSITION_DATA,
    GET_POSITIONS_DATA,
} from 'redux/types';

const defaultState = {
    positions: [],
    DropdownPositionsListValues: [],
};

export const positions = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_POSITION_DATA:
            return {
                ...state,
                positions: [...state.positions, action.payload],
            };
        case DELETE_POSITION_DATA:
            return {
                ...state,
                positions: state.positions.filter(
                    (item) => item.id !== action.payload
                ),
            };
        case EDIT_POSITION_DATA:
            return {
                ...state,
                positions: [
                    ...state.positions.filter(
                        (item) => item.id !== action.payload.id
                    ),
                    action.payload,
                ],
            };
        case GET_POSITIONS_DATA:
            return {
                ...state,
                positions: action.payload,
                DropdownPositionsListValues: action.payload.map((el) => ({
                    id: el.id,
                    value: el.name,
                })),
            };
        default:
            return state;
    }
};
