import { arrayOf, bool, node, oneOfType } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import styles from 'components/KebabButton/styles.module.scss';
import Button from 'components/Button';

const KebabButton = ({ isProjectsTeams = false, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    const popup = useRef(null);
    const button = children.filter((child) => !!child);

    useEffect(() => {
        const onClick = (e) => {
            if (isOpen)
                if (buttonRef.current.contains(e.target)) {
                    if (isOpen) {
                        setIsOpen(false);
                    } else {
                        setIsOpen(true);
                    }
                } else {
                    setIsOpen(false);
                }
        };
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, [isOpen]);

    return (
        <div
            ref={buttonRef}
            className={`${styles.main_icon} ${
                isOpen ? styles.main_icon_active : ''
            }`}
        >
            <Button
                buttonStyle="outlined"
                onClick={(event) => {
                    event.stopPropagation();
                    setIsOpen(!isOpen);
                }}
                buttonIconName="kebab"
                isProjectsTeams={isProjectsTeams}
            />
            <ul
                ref={popup}
                className={`${styles.dropDown} ${isOpen ? styles.active : ''} ${
                    isProjectsTeams ? styles.dropDown_projects_teams : ''
                }`}
            >
                {button.map((child, index) => (
                    <li key={index}>{child}</li>
                ))}
            </ul>
        </div>
    );
};
KebabButton.propTypes = {
    isProjectsTeams: bool,
    children: oneOfType([arrayOf(node), node]),
};

export default KebabButton;
