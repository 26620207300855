import { YandexLogin } from 'react-yandex-login';
import { useDispatch } from 'react-redux';
import { setIsLoadingAuth, userData } from 'redux/actions/auth';
import { useToastify } from 'helpers/toastify/useToastify';
import { setUserInfo } from 'helpers/authHelpers';
import API from 'api/api';
import yandex from 'icons/yandex.svg';
import yandexColor from 'icons/yandexColor.svg';
import styles from './styles.module.scss';

const YandexButton = () => {
    const dispatch = useDispatch();
    const theme = localStorage.getItem('app-theme');

    const handleLogin = (res) => {
        dispatch(setIsLoadingAuth(true));
        API.auth
            .login(res.access_token, 'yandex')
            .then((r) => {
                setUserInfo(r, 'yandex');
                localStorage.setItem('userLoggedIn', true);
                const userDataInfo = {
                    userShortName: r.data.entity.userShortName,
                    userFullName: r.data.entity.userFullName,
                    userName: r.data.entity.userName,
                    userMiddleName: r.data.entity.userMiddleName,
                    userSurname: r.data.entity.userSurname,
                    personId: r.data.entity.personId,
                    userLogin: r.data.entity.userLogin,
                    userRole: r.data.entity.userRole,
                    userPosition: r.data.entity.userPosition,
                };
                dispatch(userData(userDataInfo));
            })
            .catch((r) => useToastify(r.message, 'error'))
            .finally(() => dispatch(setIsLoadingAuth(false)));
    };

    return (
        <YandexLogin
            clientID={process.env.REACT_APP_YANDEX_CLIENT_ID}
            onSuccess={handleLogin}
        >
            <button className={styles.loginpage__modal_button} type="button">
                <img
                    className={styles.loginpage__modal_icon}
                    src={theme === 'blue' ? yandexColor : yandex}
                    alt="yandex_icon"
                />
                <span>Войти с помощью Yandex</span>
            </button>
        </YandexLogin>
    );
};

export default YandexButton;
