import API from 'api/api';
import { logout } from 'redux/actions/auth';
import { useToastify } from 'helpers/toastify/useToastify';
import { setPersonPhoto, deletePersonPhoto } from 'redux/actions/persons';
import useLogout from 'hooks/useLogout';

export const getPersonImg =
    (id, callback, setIsLoadingImage, setImageWasLoading) => (dispatch) => {
        setIsLoadingImage(true);
        API.images
            .getPersonImg(id)
            .then((res) => {
                setImageWasLoading(true);
                dispatch(setPersonPhoto(res.data.id, res.data.pictureByte));
                callback(res.data.id, res.data.pictureByte);
            })
            .catch(
                (err) => {
                    if (err.name === 'UNAUTHORIZED') {
                        dispatch(logout());
                        localStorage.clear();
                    }
                    setImageWasLoading(false);
                }
                // useToastify(err?.message ?? 'Произошла ошибка', 'warning') временно
            )
            .finally(() => setIsLoadingImage(false));
    };

export const postPersonImg = (personId, file) => (dispatch) => {
    const formData = new FormData();
    formData.append('file', file);

    API.images
        .postPersonImg(personId, formData)
        .then((res) => dispatch(setPersonPhoto(res.data.pictureByte)))
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        });
};
export const deletePersonImg = (id, callback) => (dispatch) => {
    API.images
        .deletePersonImg(id)
        .then(() => {
            dispatch(deletePersonPhoto());
            callback(null);
        })
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        });
};
