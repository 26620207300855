const SyncIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3521_21562)">
                <path
                    d="M16.71 5.29L19 3H11V11L13.3 8.7C15.27 10.16 16.55 12.48 16.55 15.12C16.55 16.43 16.23 17.66 15.67 18.75C18 17.23 19.55 14.61 19.55 11.62C19.55 9.1 18.44 6.85 16.71 5.29Z"
                    fill="#696C80"
                />
                <path
                    d="M7.46001 8.88C7.46001 7.57 7.78001 6.34 8.34001 5.25C6.00001 6.77 4.46001 9.39 4.46001 12.38C4.46001 14.9 5.56001 17.15 7.30001 18.71L5.00001 21H13V13L10.7 15.3C8.74001 13.84 7.46001 11.52 7.46001 8.88Z"
                    fill="#696C80"
                />
            </g>
            <defs>
                <clipPath id="clip0_3521_21562">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SyncIcon;
