import API from 'api/api';
import { useToastify } from 'helpers/toastify/useToastify';
import { logout } from 'redux/actions/auth';
import { getSystemsData } from 'redux/actions/systems';

export const getSystems =
    (setIsLoading = () => {}) =>
    (dispatch) => {
        API.systems
            .getSystems()
            .then((res) => dispatch(getSystemsData(res.data.content)))
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => setIsLoading(false));
    };
