import {
    ADD_ORGANIZATION_DATA,
    DELETE_ORGANIZATION_DATA,
    EDIT_ORGANIZATION_DATA,
    GET_ORGANIZATIONS_DATA,
} from 'redux/types';

export const addOrganizationsData = (data) => ({
    type: ADD_ORGANIZATION_DATA,
    payload: data,
});

export const deleteOrganizationsData = (id) => ({
    type: DELETE_ORGANIZATION_DATA,
    payload: id,
});

export const editOrganizationsData = (data) => ({
    type: EDIT_ORGANIZATION_DATA,
    payload: data,
});

export const getOrganizationsData = (data) => ({
    type: GET_ORGANIZATIONS_DATA,
    payload: data,
});
