import classNames from 'classnames/bind';
import { shape } from 'prop-types';
import { numbersDepth } from 'helpers/tableHandler';
import styles from './styles.module.scss';

const TableBody = ({ items = {} }) => {
    const cx = classNames.bind(styles);
    return (
        <tbody className={cx('table__body')}>
            {items?.infoByPersons?.map(
                (infoByPersonsItem, infoByPersonsIndex) => (
                    <tr key={infoByPersonsIndex}>
                        <td className={cx('left_border')}>
                            <div>{infoByPersonsItem.personName}</div>
                        </td>
                        <td className={cx('highlighted_cell')}>
                            <span>
                                {numbersDepth(infoByPersonsItem.totalFactHours)}
                            </span>
                        </td>
                        {infoByPersonsItem.personInfoByPeriods.map(
                            (
                                personInfoByPeriodsItem,
                                personInfoByPeriodsIndex
                            ) => (
                                <td key={personInfoByPeriodsIndex}>
                                    <span>
                                        {personInfoByPeriodsItem.factHours}
                                    </span>
                                </td>
                            )
                        )}
                    </tr>
                )
            )}
            <tr className={cx('highlighted_cell', 'active_text')}>
                <td className={cx('left_border')}>
                    <div>Итого</div>
                </td>
                <td>
                    <span>{numbersDepth(items.totalFactHours)}</span>
                </td>
                {items?.totalInfoByPeriods.map(
                    (totalInfoByPeriodsItem, totalInfoByPeriodsIndex) => (
                        <td key={totalInfoByPeriodsIndex}>
                            <span>
                                {numbersDepth(totalInfoByPeriodsItem.factHours)}
                            </span>
                        </td>
                    )
                )}
            </tr>
        </tbody>
    );
};

export default TableBody;

TableBody.propTypes = {
    items: shape({}),
};
