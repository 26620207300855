import {
    ADD_PROJECT_DATA,
    DELETE_PROJECTS_DATA,
    EDIT_PROJECT_DATA,
    GET_PROJECTS_DATA,
    SET_PROJECTS_FILTER_STATUS,
    SET_PROJECTS_FROM_TIME_CRAWLER,
    CLEAR_PROJECTS,
    GET_FILTERED_PROJECTS_DATA,
    GET_PROJECTS_FOR_DROPDOWN,
    GET_PROJECTS_FOR_DROPDOWN_FILTERED,
} from 'redux/types';

export const addProjectData = (data) => ({
    type: ADD_PROJECT_DATA,
    payload: data,
});

export const deleteProjectsData = (id) => ({
    type: DELETE_PROJECTS_DATA,
    payload: id,
});

export const editProjectData = (data) => ({
    type: EDIT_PROJECT_DATA,
    payload: data,
});

export const getProjectsData = (data) => ({
    type: GET_PROJECTS_DATA,
    payload: data,
});

export const getProjectsForDropDown = (data) => ({
    type: GET_PROJECTS_FOR_DROPDOWN,
    payload: data,
});

export const getProjectsForDropDownFiltered = (data) => ({
    type: GET_PROJECTS_FOR_DROPDOWN_FILTERED,
    payload: data,
});

export const getFilteredProjectsData = (data) => ({
    type: GET_FILTERED_PROJECTS_DATA,
    payload: data,
});

export const setProjectsFilterStatus = (data) => ({
    type: SET_PROJECTS_FILTER_STATUS,
    payload: data,
});

export const setProjectsTimeCrawler = (data) => ({
    type: SET_PROJECTS_FROM_TIME_CRAWLER,
    payload: data,
});

export const clearProjects = () => ({
    type: CLEAR_PROJECTS,
});
