import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import ComponentTitle from 'components/ComponentTitle';
import classNames from 'classnames/bind';
import CloseIcon from 'components/svg/ButtonIcon/closeIcon';
import styles from 'components/DatePicker/styles.module.scss';
import CalendarIcon from 'components/svg/calendar';

const Calendar = ({
    isRemoveButton = true,
    isRemoveButtonModal = true,
    dateDrop = null,
    title = '',
    setDateDrop = () => {},
    showMonthOnly = false,
    showYearsOnly = false,
    error = false,
    noMargin = false,
    modal = false,
    disabled = false,
    rangeStartDate = '',
    rangeEndDate = '',
    placeholder = '',
    isHeader = false,
}) => {
    const cx = classNames.bind(styles);
    const currentRole = useSelector((state) => state.auth.userData.userRole);
    const isUser = currentRole === 'USER';
    const validDate =
        dateDrop === undefined || dateDrop === '' || dateDrop === null
            ? null
            : new Date(dateDrop);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isFullDate, setIsFullDate] = useState(false);

    const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const months = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];
    const locale = {
        localize: {
            day: (n) => days[n],
            month: (n) => months[n],
        },
        formatLong: {
            date: () => 'dd.MM.yyyy',
        },
        match: {},
    };

    const endDate = rangeEndDate ? new Date(rangeEndDate) : null;
    const startDate = rangeStartDate ? new Date(rangeStartDate) : null;

    const getFormat = () => {
        if (showMonthOnly) return 'MMMM yyyy';
        if (showYearsOnly) return 'yyyy';
        return 'dd.MM.yyyy';
    };
    const removeDate = () => {
        setDateDrop('');
    };

    const calendarOnChangeRaw = (e) => {
        setIsFullDate(false);
        if (e.target.value) {
            if (
                !e.nativeEvent.data?.match(/[0-9]|\./) &&
                e.nativeEvent.inputType !== 'deleteContentBackward'
            )
                e.target.value = e.target.value.slice(0, -1);
            if (e.target.value?.match(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/)) {
                setIsFullDate(true);
            } else {
                setIsFullDate(false);
            }
        } else {
            setIsFullDate(true);
        }
    };

    const calendarOnChange = (calendarDate) => {
        if (calendarDate !== null) {
            const date = new Date(0);
            date.setFullYear(calendarDate?.getFullYear());
            date.setMonth(calendarDate?.getMonth());
            date.setDate(calendarDate?.getDate());
            setSelectedDate(date);
        }
    };

    useEffect(() => {
        if (isFullDate) setDateDrop(selectedDate);
    }, [isFullDate, selectedDate]);

    return (
        <div
            className={`${styles.datePicker_block} ${
                isHeader ? styles.header : ''
            }`}
        >
            {title && <ComponentTitle title={title} error={error} />}
            <DatePicker
                selected={validDate}
                onChange={(dateCalendar) => {
                    calendarOnChange(dateCalendar);
                }}
                onChangeRaw={(e) => {
                    calendarOnChangeRaw(e);
                }}
                startDate={startDate}
                endDate={endDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText={placeholder}
                dateFormat={getFormat()}
                showMonthYearPicker={showMonthOnly}
                showYearPicker={showYearsOnly}
                showFullMonthYearPicker={showMonthOnly}
                locale={locale}
                calendarStartDay={1}
                customInput={
                    <input
                        type="text"
                        className={cx('customInput', {
                            error,
                            noMargin,
                            modal,
                            placeholder: !dateDrop,
                        })}
                        disabled={disabled}
                    />
                }
            />
            {validDate && !isUser && isRemoveButton ? (
                <button
                    className={
                        isRemoveButtonModal
                            ? styles.datePickerModal_btn
                            : styles.datePicker_btn
                    }
                    type="button"
                    onClick={removeDate}
                >
                    <CloseIcon />
                </button>
            ) : (
                <button
                    className={cx('customInput_icon', { error })}
                    type="button"
                >
                    <CalendarIcon />
                </button>
            )}
        </div>
    );
};
Calendar.propTypes = {
    dateDrop: oneOfType([instanceOf(Date), string]),
    title: string,
    rangeStartDate: string,
    rangeEndDate: string,
    setDateDrop: func,
    showMonthOnly: bool,
    error: bool,
    noMargin: bool,
    modal: bool,
    disabled: bool,
    showYearsOnly: bool,
    isRemoveButton: bool,
    isRemoveButtonModal: bool,
    placeholder: string,
    isHeader: bool,
};

export default Calendar;
