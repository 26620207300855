import { array, arrayOf, func, shape, string } from 'prop-types';
import classNames from 'classnames/bind';
import { removeNumbersSpacing } from 'helpers/tableHandler';
import Input from 'components/Input';
import DropdownList from 'components/DropdownList';
import CheckBox from 'components/CheckBox';
import styles from './styles.module.scss';

const TableBody = ({
    data = [],
    caseName = '',
    dropDownItems = [],
    handleSelect = () => {},
    onChangeInput = () => {},
    setCheckedHandler = () => {},
}) => {
    const cx = classNames.bind(styles);

    return (
        <tbody>
            {data.map((rows) =>
                rows.items.map((row, index) =>
                    !Array.isArray(row) ? (
                        <tr key={index}>
                            {Object.values(row)?.map((cell, i) => (
                                <td
                                    key={i}
                                    rowSpan={cell.rowSpan}
                                    className={cx('table__body_cell', {
                                        validity_yellow:
                                            caseName === 'PRODUCTION_PLANS' &&
                                            rows?.additionalData?.validity ===
                                                1,
                                        validity_orange:
                                            caseName === 'PRODUCTION_PLANS' &&
                                            rows?.additionalData?.validity ===
                                                2,

                                        table__body_cell__deviation:
                                            (caseName === 'PRODUCTION_PLANS' &&
                                                removeNumbersSpacing(
                                                    cell.value
                                                ) < 0 &&
                                                (i === 11 || i === 12)) ||
                                            (caseName === 'UNUSED_PERSONS' &&
                                                removeNumbersSpacing(cell) <
                                                    0 &&
                                                (i === 6 || i === 7)) ||
                                            (caseName === 'REPORT_CARD_ERROR' &&
                                                cell?.additionalData?.isError),

                                        table__body_cell__deviation_plus:
                                            (caseName === 'PRODUCTION_PLANS' &&
                                                removeNumbersSpacing(cell) >
                                                    0 &&
                                                (i === 11 || i === 12)) ||
                                            (caseName === 'UNUSED_PERSONS' &&
                                                removeNumbersSpacing(cell) >
                                                    0 &&
                                                (i === 6 || i === 7)),
                                    })}
                                >
                                    <div
                                        className={cx(
                                            {
                                                table__body_cell_text:
                                                    Object.keys(row)[i] !==
                                                    'status',
                                            },
                                            {
                                                'table__body_cell_text-status':
                                                    Object.keys(row)[i] ===
                                                    'status',
                                            },
                                            {
                                                'table__body_cell_text-checkbox':
                                                    Object.keys(row)[i] ===
                                                    'synchronize',
                                            },
                                            {
                                                'table__body_cell_text-budget':
                                                    Object.keys(row)[i] ===
                                                    'budget',
                                            },
                                            {
                                                'table__body_cell_text-comment':
                                                    Object.keys(row)[i] ===
                                                    'comment',
                                            },
                                            {
                                                'table__body_cell_text-description':
                                                    caseName === 'ERROR_MODAL',
                                            }
                                        )}
                                    >
                                        {caseName === 'REPORT_CARD_ERROR' &&
                                        Object.keys(row)[i] ===
                                            'accountingPersonId' ? (
                                            <DropdownList
                                                isCell
                                                items={dropDownItems}
                                                selected={dropDownItems.find(
                                                    (elem) =>
                                                        elem.id ===
                                                        row.accountingPersonId
                                                            .value
                                                )}
                                                setSelected={(event) => {
                                                    handleSelect(rows, event);
                                                }}
                                            />
                                        ) : caseName === 'REPORT_CARD_ERROR' &&
                                          Object.keys(row)[i] ===
                                              'availableProjectFactHours' ? (
                                            <Input
                                                isTableCell
                                                value={
                                                    row
                                                        .availableProjectFactHours
                                                        ?.value
                                                }
                                                onChange={(event) => {
                                                    onChangeInput(
                                                        event,
                                                        row
                                                            .availableProjectFactHours
                                                            .additionalData
                                                    );
                                                }}
                                            />
                                        ) : caseName === 'REPORT_CARD_ERROR' &&
                                          Object.keys(row)[i] ===
                                              'synchronize' ? (
                                            <div
                                                className={
                                                    styles.table__body_cell_checkbox
                                                }
                                            >
                                                <CheckBox
                                                    isCell
                                                    checked={
                                                        row.synchronize.value
                                                    }
                                                    setChecked={() => {
                                                        setCheckedHandler(
                                                            rows.id
                                                        );
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            cell.value
                                        )}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ) : (
                        row.map((secondRow, indexSecondRow) => (
                            <tr key={data.length + indexSecondRow}>
                                {Object.values(secondRow)?.map((cell, i) => (
                                    <td
                                        key={i}
                                        rowSpan={cell.rowSpan}
                                        className={cx(
                                            'table__body_cell_second',
                                            {
                                                validity_yellow:
                                                    caseName ===
                                                        'PRODUCTION_PLANS' &&
                                                    rows?.additionalData
                                                        ?.validity === 1,
                                                validity_orange:
                                                    caseName ===
                                                        'PRODUCTION_PLANS' &&
                                                    rows?.additionalData
                                                        ?.validity === 2,

                                                table__body_cell__deviation:
                                                    (caseName ===
                                                        'PRODUCTION_PLANS' &&
                                                        removeNumbersSpacing(
                                                            cell.value
                                                        ) < 0 &&
                                                        (i === 11 ||
                                                            i === 12)) ||
                                                    (caseName ===
                                                        'UNUSED_PERSONS' &&
                                                        removeNumbersSpacing(
                                                            cell
                                                        ) < 0 &&
                                                        (i === 6 || i === 7)) ||
                                                    (caseName ===
                                                        'REPORT_CARD_ERROR' &&
                                                        cell?.additionalData
                                                            ?.isError),

                                                table__body_cell__deviation_plus:
                                                    (caseName ===
                                                        'PRODUCTION_PLANS' &&
                                                        removeNumbersSpacing(
                                                            cell
                                                        ) > 0 &&
                                                        (i === 11 ||
                                                            i === 12)) ||
                                                    (caseName ===
                                                        'UNUSED_PERSONS' &&
                                                        removeNumbersSpacing(
                                                            cell
                                                        ) > 0 &&
                                                        (i === 6 || i === 7)),
                                            }
                                        )}
                                    >
                                        <div
                                            className={cx(
                                                {
                                                    table__body_cell_text:
                                                        Object.keys(row)[i] !==
                                                        'status',
                                                },
                                                {
                                                    'table__body_cell_text-status':
                                                        Object.keys(row)[i] ===
                                                        'status',
                                                },
                                                {
                                                    'table__body_cell_text-checkbox':
                                                        Object.keys(row)[i] ===
                                                        'synchronize',
                                                },
                                                {
                                                    'table__body_cell_text-budget':
                                                        Object.keys(row)[i] ===
                                                        'budget',
                                                },
                                                {
                                                    'table__body_cell_text-comment':
                                                        Object.keys(row)[i] ===
                                                        'comment',
                                                },
                                                {
                                                    'table__body_cell_text-description':
                                                        caseName ===
                                                        'ERROR_MODAL',
                                                }
                                            )}
                                        >
                                            {caseName === 'REPORT_CARD_ERROR' &&
                                            Object.keys(secondRow)[i] ===
                                                'availableProjectFactHours' ? (
                                                <Input
                                                    isTableCell
                                                    value={
                                                        secondRow
                                                            .availableProjectFactHours
                                                            ?.value
                                                    }
                                                    onChange={(event) => {
                                                        onChangeInput(
                                                            event,
                                                            secondRow
                                                                .availableProjectFactHours
                                                                .additionalData
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                cell.value
                                            )}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))
                    )
                )
            )}
        </tbody>
    );
};

TableBody.propTypes = {
    data: arrayOf(shape({})),
    caseName: string,
    dropDownItems: arrayOf(shape({})),
    handleSelect: func,
    onChangeInput: func,
    setCheckedHandler: func,
};

export default TableBody;
