import { arrayOf, bool, func, shape, string } from 'prop-types';
import classNames from 'classnames/bind';
import SortButton from 'components/SortButton';
import styles from './styles.module.scss';

const TabelHeaderForProdPlan = ({
    header = [],
    tableIcons = false,
    sort = {},
    onSortHandler = () => {},
}) => {
    const cx = classNames.bind(styles);

    return (
        // поменять стили, добавить иконки, header// одинаковые ключи
        <thead className={cx('table__header')}>
            <tr className={cx('table__header_row-plan')}>
                {header[0].map((item) => (
                    <td
                        className={cx('table__header_cell-plan')}
                        key={item.id}
                        rowSpan={item.rowSpan}
                        colSpan={item.columnSpan}
                    >
                        <div className={cx('table__header_cell_content-plan')}>
                            <div className={cx('table__header_cell_text-plan')}>
                                {item.sortable ? (
                                    <button
                                        type="button"
                                        onClick={() =>
                                            onSortHandler(item?.dtoKey)
                                        }
                                    >
                                        {item.fullName ?? item.name}
                                    </button>
                                ) : (
                                    <span>{item.fullName ?? item.name}</span>
                                )}
                            </div>
                            {item.sortable ? (
                                <span className={cx('table__header_cell_sort')}>
                                    <SortButton
                                        onSortHandler={onSortHandler}
                                        sort={sort}
                                        header={item.dtoKey}
                                    />
                                </span>
                            ) : null}
                        </div>
                    </td>
                ))}
                {tableIcons && (
                    <td
                        rowSpan="2"
                        className={cx(`table__header_cell-button`)}
                    />
                )}
            </tr>
            <tr className={cx('table__header_row-plan')}>
                {header[1].map((item) => (
                    <td
                        className={cx('table__header_cell-plan')}
                        key={item.id}
                        rowSpan={item.rowSpan}
                        colSpan={item.columnSpan}
                    >
                        <div className={cx('table__header_cell_content-plan')}>
                            <div className={cx('table__header_cell_text-plan')}>
                                {item.sortable ? (
                                    <button
                                        type="button"
                                        onClick={() =>
                                            onSortHandler(item?.dtoKey)
                                        }
                                    >
                                        {item.fullName ?? item.name}
                                    </button>
                                ) : (
                                    <span>{item.fullName ?? item.name}</span>
                                )}
                            </div>
                            {item.sortable ? (
                                <span className={cx('table__header_cell_sort')}>
                                    <SortButton
                                        onSortHandler={onSortHandler}
                                        sort={sort}
                                        header={item.dtoKey}
                                    />
                                </span>
                            ) : null}
                        </div>
                    </td>
                ))}
            </tr>
        </thead>
    );
};

export default TabelHeaderForProdPlan;

TabelHeaderForProdPlan.propTypes = {
    header: arrayOf(arrayOf(shape({})), arrayOf(shape({}))),
    tableIcons: bool,
    sort: shape({}),
    onSortHandler: func,
};
