import { Provider } from 'react-redux';
import store from 'redux/store';
import Homepage from 'components/Homepage';
import { ToastContainer } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
    <Provider store={store}>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />

        <Tooltip id="directories" style={{ zIndex: 5 }} />
        <Homepage />
    </Provider>
);

export default App;
