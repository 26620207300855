import { useState } from 'react';
import { func, shape } from 'prop-types';
import Button from 'components/Button';
import { changeCvComment } from 'redux/thunks/cvThunks';
import Textarea from 'components/Textarea';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

const ChangeCvCommentModel = ({
    personInfo,
    closeModal = () => {},
    getCv = () => {},
}) => {
    const dispatch = useDispatch();
    const [textareaValue, setTexareaValue] = useState(
        personInfo?.items.comment ?? ''
    );

    const handleClick = () => {
        dispatch(changeCvComment(personInfo.id, textareaValue, getCv));
        closeModal();
    };

    return (
        <div className={styles.container}>
            <div>
                <h2>{`CV: ${personInfo.items.fullName}`}</h2>
                <span>{personInfo.items.corpEmail}</span>
                <Textarea
                    placeholder="Комментарий"
                    value={textareaValue}
                    onChange={setTexareaValue}
                />
            </div>
            <div className={styles.container_buttons}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                />
                <Button text="Сохранить" onClick={handleClick} />
            </div>
        </div>
    );
};

export default ChangeCvCommentModel;

ChangeCvCommentModel.propTypes = {
    personInfo: shape({}),
    closeModal: func,
    getCv: func,
};
