import { arrayOf, bool, func, shape } from 'prop-types';
import EditIcon from 'components/svg/editIcon';
import DeleteIcon from 'components/svg/deleteIcon';
import classNames from 'classnames/bind';
import KebabMenu from 'components/Tables/Table/TableBody/KebabMenu';
import styles from './styles.module.scss';

const ShortTableBody = ({
    deleteIcon,
    editIcon,
    items,
    openEditModal = () => {},
    openDeleteModal = () => {},
}) => {
    const cx = classNames.bind(styles);

    return (
        <tbody className={styles.table__body}>
            {items.map((item) => (
                <tr key={item.id} className={styles.table__body_row}>
                    <td className={styles.table__body_cell}>
                        <div className={styles.table__body_cell_text}>
                            {item.name}
                        </div>
                    </td>
                    {(editIcon || deleteIcon) && (
                        <td className={cx('table__body_cell', 'buttons')}>
                            <div className={styles.table__body_cell_icons}>
                                <KebabMenu>
                                    {editIcon && (
                                        <button
                                            className={styles.table__body_icon}
                                            type="button"
                                            onClick={() => {
                                                openEditModal(item);
                                            }}
                                        >
                                            <EditIcon />
                                            Редактировать
                                        </button>
                                    )}
                                    {deleteIcon && (
                                        <button
                                            className={styles.table__body_icon}
                                            type="button"
                                            onClick={() => {
                                                openDeleteModal(item);
                                            }}
                                            onKeyPress={() => {}}
                                        >
                                            <DeleteIcon />
                                            Удалить
                                        </button>
                                    )}
                                </KebabMenu>
                            </div>
                        </td>
                    )}
                </tr>
            ))}
        </tbody>
    );
};
export default ShortTableBody;

ShortTableBody.propTypes = {
    deleteIcon: bool,
    editIcon: bool,
    items: arrayOf(shape([])),
    openEditModal: func,
    openDeleteModal: func,
};
