import PageHeader from 'components/PageHeader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getAllModules } from 'redux/thunks/modulesThunks';
import { addJiraPersons, getJiraPersons } from 'redux/thunks/ImportThunks';
import isEmpty from 'helpers/isEmpty';
import Table from 'components/Tables/Table';
import { headers } from 'helpers/tableHandler';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { shape } from 'prop-types';
import styles from './styles.module.scss';

const ImportPersons = ({ selectedModule }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const systems = useSelector((state) => state.modules.DropdownListValues);
    const person = useSelector((state) => state.jiraPersons.data);

    const [isLoadingModules, setIsLoadingModules] = useState(false);
    const [isLoadingPerson, setIsLoadingPerson] = useState(false);
    const [typeModule, setTypeModule] = useState(selectedModule);
    const [data, setData] = useState([]);

    useEffect(() => setTypeModule(selectedModule), [selectedModule]);

    useEffect(() => {
        dispatch(getAllModules(setIsLoadingModules));
    }, []);

    useEffect(() => {
        if (typeModule?.systemId === 2) {
            dispatch(getJiraPersons(setIsLoadingPerson, typeModule?.id));
        }
    }, [dispatch, typeModule]);

    useEffect(() => {
        if (typeModule?.systemId === 2) {
            setData(person);
        } else {
            setData([]);
        }
    }, [person, typeModule.systemId]);

    const onChange = (value, mail) => {
        setData((prev) =>
            prev.map((elem) => {
                if (elem.emailAddress === mail) {
                    return {
                        ...elem,
                        russianName: value,
                    };
                }
                return elem;
            })
        );
    };

    const setChecked = (mail) => {
        setData((prev) =>
            prev.map((elem) => {
                if (elem.emailAddress === mail) {
                    return { ...elem, synchronize: !elem.synchronize };
                }
                return elem;
            })
        );
    };

    const handleClickCancel = () => {
        if (typeModule?.systemId === 2) {
            setData(person);
        }
    };

    const handelClickImport = () => {
        const onSave = data.filter((elem) => elem.synchronize);
        dispatch(addJiraPersons(onSave));
    };

    return isLoadingModules ? (
        <Loader />
    ) : (
        <div className={styles.container}>
            <PageHeader title="Импорт сотрудников" />

            <div className={styles.content}>
                {!isEmpty(selectedModule) ? (
                    <div>Не выбран модуль</div>
                ) : (
                    <div className={styles.module}>
                        <div>
                            Найдено сотрудников:{' '}
                            {isEmpty(typeModule) ? data.length : 0}
                        </div>
                        <div>
                            Выбрано для импорта:
                            {isEmpty(typeModule)
                                ? data.filter((elem) => elem.synchronize).length
                                : 0}
                        </div>
                    </div>
                )}
                {typeModule.id &&
                    (isLoadingPerson ? (
                        <Loader />
                    ) : (
                        <>
                            <Table
                                header={headers.personImport}
                                caseName="PERSON_IMPORT"
                                items={data}
                                onChangeInput={onChange}
                                setChecked={setChecked}
                                changeData={setData}
                            />
                            <div className={styles.footer}>
                                <Button
                                    text="Отмена"
                                    onClick={handleClickCancel}
                                    buttonStyle="outlined"
                                />
                                <Button
                                    text="Импорт"
                                    onClick={handelClickImport}
                                />
                            </div>
                        </>
                    ))}
            </div>
        </div>
    );
};

ImportPersons.propTypes = {
    selectedModule: shape({}),
};

export default ImportPersons;
