import {
    GET_CALENDAR,
    SET_CURRENT_DATE_CALENDAR,
    CLEAR_CALENDAR,
} from 'redux/types';

export const getCalendarData = (data) => ({
    type: GET_CALENDAR,
    payload: data,
});

export const setCurrentDateCalendar = (data) => ({
    type: SET_CURRENT_DATE_CALENDAR,
    payload: data,
});

export const clearCalendar = () => ({
    type: CLEAR_CALENDAR,
});
