import {
    GET_PERSONS_CV_DATA,
    SET_PERSONS_CV_FILTER_STATUS,
    ADD_PERSON_CV,
    DELETE_PERSON_CV,
    RESET_DATA_PESONS_CV,
    GET_PEROSN_CV_DATA_DROPDOWN,
} from 'redux/types';

export const getPersonsInfoData = (data) => ({
    type: GET_PERSONS_CV_DATA,
    payload: data,
});
export const getPresonInfoDropDown = (data) => ({
    type: GET_PEROSN_CV_DATA_DROPDOWN,
    payload: data,
});
export const setPersonsCvFilterStatus = (data) => ({
    type: SET_PERSONS_CV_FILTER_STATUS,
    payload: data,
});
export const addPersonCv = (data) => ({
    type: ADD_PERSON_CV,
    payload: data,
});
export const deletePersonCv = (id) => ({
    type: DELETE_PERSON_CV,
    payload: id,
});
export const resetDataPersonCv = () => ({
    type: RESET_DATA_PESONS_CV,
});
