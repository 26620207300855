// ----- Функция, определяющая, разрешён ли доступ к конкретному элементу конкретной страницы: -----
// Есть объект всех разрешённых ролей (allAllowedRoles). Его свойствами являются страницы (к примеру, Persons). А в них свойствами являются элементы страницы (к примеру, addPersonIcon), и для них в массивах указаны разрешённые роли.
// Из этого общего объекта по названию страницы и названию элемента вытягивается массив с разрешёнными ролями для этого конкретного элемента этой конкретной страницы (allowedRolesForElement).
// И если текущая роль пользователя (currentRole) входит в этот список разрешённых ролей (allowedRolesForElement), то функция возвращает true.
export const accessByRole = (page, element, currentRole) => {
    let access = false;

    const allAllowedRoles = {
        Persons: {
            getAllPersons: [
                'ADMIN',
                'SUPERVISOR',
                'MANAGER',
                'ACCOUNTANT',
                'HR',
                'PS',
            ],
            getAllFilteredPersons: [
                'ADMIN',
                'SUPERVISOR',
                'MANAGER',
                'ACCOUNTANT',
                'HR',
                'PS',
            ],
            filtersButton: [
                'ADMIN',
                'SUPERVISOR',
                'MANAGER',
                'ACCOUNTANT',
                'HR',
                'PS',
            ],
            editIcon: ['ADMIN', 'HR'],
            addButton: ['ADMIN', 'HR'],
            deleteIcon: [],
            lockPersonIcon: ['ADMIN', 'HR'],
            addPersonIcon: ['ADMIN', 'HR'],
        },
        PersonsCV: {
            getAllPersons: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
            filtersButton: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
            editIcon: ['ADMIN', 'HR'],
            addButton: ['ADMIN', 'HR'],
            deleteIcon: ['ADMIN', 'HR'],
        },
        Specialties: {
            getAllSpecialties: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
            editIcon: ['ADMIN', 'SUPERVISOR', 'HR'],
            addButton: ['ADMIN', 'SUPERVISOR', 'HR'],
            deleteIcon: ['ADMIN', 'SUPERVISOR', 'HR'],
        },
        Positions: {
            getAllPositions: [
                'ADMIN',
                'SUPERVISOR',
                'MANAGER',
                'ACCOUNTANT',
                'HR',
                'PS',
            ],
            editIcon: ['ADMIN', 'SUPERVISOR', 'HR', 'PS'],
            addButton: ['ADMIN', 'SUPERVISOR', 'HR', 'PS'],
            deleteIcon: ['ADMIN', 'SUPERVISOR', 'HR', 'PS'],
        },
        BaseTariffRates: {
            getAllBaseTariffs: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'ACCOUNTANT'],
            filtersButton: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'ACCOUNTANT'],
            editIcon: ['ADMIN', 'SUPERVISOR'],
            addButton: ['ADMIN', 'SUPERVISOR'],
        },
        Organizations: {
            getAllOrganizations: [
                'ADMIN',
                'SUPERVISOR',
                'MANAGER',
                'ACCOUNTANT',
            ],
            editIcon: ['ADMIN', 'SUPERVISOR'],
            addButton: ['ADMIN', 'SUPERVISOR'],
            deleteIcon: ['ADMIN', 'SUPERVISOR'],
        },
        ProjectTeams: {
            addButton: ['ADMIN', 'SUPERVISOR'],
            addPersonButton: [],
            editPersonButton: ['ADMIN', 'SUPERVISOR'],
            deletePersonButton: ['ADMIN', 'SUPERVISOR'],
            deleteTeamButton: ['ADMIN', 'SUPERVISOR'],
            tariffRate: ['ADMIN', 'SUPERVISOR', 'MANAGER'],
        },
        Projects: {
            filtersButton: ['ADMIN', 'SUPERVISOR', 'MANAGER'],
            editIcon: ['ADMIN', 'SUPERVISOR'],
            addButton: ['ADMIN', 'SUPERVISOR'],
            deleteIcon: ['ADMIN', 'SUPERVISOR'],
        },
        Modules: {
            moduleSettings: ['ADMIN', 'SUPERVISOR'],
            importProjects: ['ADMIN', 'SUPERVISOR'],
            getAllModules: ['ADMIN', 'SUPERVISOR'],
            addButton: ['ADMIN', 'SUPERVISOR'],
        },
        ProductionPlans: {
            filtersButton: ['ADMIN', 'SUPERVISOR', 'ACCOUNTANT'],
            syncButton: ['ADMIN', 'SUPERVISOR'],
            createPlan: ['ADMIN', 'SUPERVISOR'],
            addPlan: ['ADMIN', 'SUPERVISOR'],
            downloadPlan: ['ADMIN', 'SUPERVISOR'],
            uploadPlan: ['ADMIN', 'SUPERVISOR'],
            loockProdPlanIcon: ['ADMIN', 'SUPERVISOR'],
            infoIcon: ['ADMIN', 'SUPERVISOR', 'ACCOUNTANT'],
            productionPlansEditIcon: ['ADMIN', 'SUPERVISOR'],
            productionPlansDeleteIcon: ['ADMIN', 'SUPERVISOR'],
            unusedPersonsEditIcon: ['ADMIN', 'SUPERVISOR'],
            unusedPersonsDeleteIcon: ['ADMIN', 'SUPERVISOR'],
        },
        Reports: {
            syncButton: ['ADMIN', 'SUPERVISOR'],
            getAllModules: ['ADMIN', 'SUPERVISOR'],
            filtersButton: [
                'ADMIN',
                'SUPERVISOR',
                'MANAGER',
                'ACCOUNTANT',
                'HR',
            ],
            getReportByProjects: [
                'ADMIN',
                'SUPERVISOR',
                'ACCOUNTANT',
                'MANAGER',
            ],
            getReportByPersons: [
                'ADMIN',
                'SUPERVISOR',
                'ACCOUNTANT',
                'MANAGER',
            ],
            getReportWorkLoadByPersons: [
                'ADMIN',
                'SUPERVISOR',
                'ACCOUNTANT',
                'HR',
            ],
            getReportWorkLoadByProjects: [
                'ADMIN',
                'SUPERVISOR',
                'ACCOUNTANT',
                'HR',
            ],
            getReportByTypeProjects: ['ADMIN', 'SUPERVISOR', 'ACCOUNTANT'],
            getReportByManagers: ['ADMIN', 'SUPERVISOR', 'ACCOUNTANT'],
            getReportByCompetenceLevel: [
                'ADMIN',
                'SUPERVISOR',
                'ACCOUNTANT',
                'HR',
            ],
            getReportByQuarters: ['ADMIN', 'SUPERVISOR', 'ACCOUNTANT'],
            getReportByUnusedPersons: [
                'ADMIN',
                'SUPERVISOR',
                'ACCOUNTANT',
                'HR',
            ],
            getReportByPersonsExperience: [
                'ADMIN',
                'SUPERVISOR',
                'ACCOUNTANT',
                'HR',
            ],
            getReportByBalanceBudgets: ['ADMIN', 'SUPERVISOR', 'ACCOUNTANT'],
            uploadButton: [
                'ADMIN',
                'SUPERVISOR',
                'MANAGER',
                'ACCOUNTANT',
                'HR',
            ],
        },
        UsersCalendar: {
            getTasks: ['ADMIN', 'SUPERVISOR', 'HR', 'PS'],
            addButton: ['ADMIN', 'HR', 'PS'],
            editTask: ['ADMIN', 'HR', 'PS'],
            deleteTask: ['ADMIN', 'HR', 'PS'],
        },
        AdminPanel: {
            filtersButton: ['ADMIN'],
            getAllUsers: ['ADMIN'],
            getAllFilteredUsers: ['ADMIN'],
            addButton: ['ADMIN'],
            editIcon: ['ADMIN'],
            deleteIcon: ['ADMIN'],
        },
    };

    const allowedRolesForElement = allAllowedRoles[page][element];

    if (allowedRolesForElement.includes(currentRole)) access = true;
    return access;
};
