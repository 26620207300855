import {
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import PageHeader from 'components/PageHeader';
import Loader from 'components/Loader';
import Table from 'components/Tables/Table';
import FiltersCvTable from 'components/Pages/Directories/DirectoriesPages/PersonsCV/FiltersCVTable';
import ChangeCvCommentModel from 'components/ModalsContent/ChangeCvComment';
import { headers } from 'helpers/tableHandler';
import { editSort } from 'helpers/editFormsValues';
import { getAllCVPersons } from 'redux/thunks/cvThunks';
import classNames from 'classnames/bind';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import СvPersonAddContent from 'components/ModalsContent/CvPersonAddContent';
import Toggle from 'components/Toggle';
import FiltersModal from 'components/Filters/FiltersModal';
import {
    filtersAreaHandler,
    statusList,
} from 'helpers/Filters/filtersAreaHandler';
import { accessByRole } from 'helpers/roleHelpers';
import isEmpty from 'helpers/isEmpty';
import { getAllCompetences } from 'redux/thunks/competenceLevelsThunks';
import { getAllSpecialties } from 'redux/thunks/specialtiesThunks';
import { initPlaceholder } from 'helpers/Filters/FiltersModalHelpers';
import styles from './styles.module.scss';

const PersonsCV = () => {
    const cx = classNames.bind(styles);
    const dispatch = useDispatch();
    const loaction = useLocation();
    const navigate = useNavigate();

    const data = useSelector((state) => state.personsCv.data);
    const dropDownListValues = useSelector((state) => state.personsCv);
    const specialityList = useSelector(
        (state) => state.specialtiesAndCompetence.DropdownSpecialtiesListValues
    );
    const competencesList = useSelector(
        (state) => state.specialtiesAndCompetence.DropdownCompetenceListValues
    );

    const initFilter = loaction.state?.fullName
        ? {
              fullName: loaction.state.fullName,
          }
        : {};

    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const { openModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [filters, setFilters] = useState(initFilter);

    const currentRole = useSelector((state) => state.auth.userData.userRole);

    const [sort, setSort] = useState({
        property: 'fullName',
        order: 'asc',
    });

    const [status, setStatus] = useState({});
    const [selectedPersons, setSelectedPersons] = useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = useState([]);
    const [selectedСompetences, setSelectedСompetences] = useState([]);
    const [selectedProgrammingLanguage, setSelectedProgrammingLanguage] =
        useState([]);
    const [selectedProfSkills, setSelectedProfSkills] = useState([]);
    const [selectedTools, setSelectedTools] = useState([]);
    const [selectedOS, setSelectedOS] = useState([]);
    const [selectedForeignLanguages, setSelectedForeignLanguages] = useState(
        []
    );
    const [experience, setExperience] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });
    const [corpExperience, setCorpExperience] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });

    const tableScrollRef = useRef({});
    const changeTableScroll = (newTableScrollLeft, newTableScrollTop) => {
        tableScrollRef.current.left = newTableScrollLeft;
        tableScrollRef.current.top = newTableScrollTop;
    };

    useEffect(() => {
        if (accessByRole('PersonsCV', 'getAllPersons', currentRole)) {
            dispatch(
                getAllCVPersons(
                    {
                        ...filters,
                        isActive: '',
                    },
                    sort,
                    setIsLoading
                )
            );
            dispatch(getAllCompetences());
            dispatch(getAllSpecialties());
        }
    }, [dispatch]);

    const getCv = useCallback(() => {
        const finalyFilter = {
            ...filters,
            isActive: isEmpty(status) ? status.boolValue : '',
        };
        if (accessByRole('PersonsCV', 'getAllPersons', currentRole)) {
            dispatch(getAllCVPersons(finalyFilter, sort, setIsLoading));
        }
    });

    useEffect(() => {
        if (accessByRole('PersonsCV', 'getAllPersons', currentRole)) {
            if (!isFirstLoad) {
                dispatch(
                    getAllCVPersons(
                        {
                            ...filters,
                            isActive: isEmpty(status) ? status.boolValue : '',
                        },
                        sort,
                        setIsLoading
                    )
                );
            } else {
                setIsFirstLoad(false);
            }
        }
    }, [filters, sort]);

    const handleClickSaveFilters = () => {
        setFilters((prev) => ({
            ...prev,
            personIds: selectedPersons.map((elem) => elem?.id),
            specialityIds: selectedSpeciality.map((elem) => elem?.id),
            competenceLevelIds: selectedСompetences.map((elem) => elem?.id),
            programmingLanguage: selectedProgrammingLanguage.map(
                (elem) => elem?.value
            ),
            professionalSkills: selectedProfSkills.map((elem) => elem?.value),
            tools: selectedTools.map((elem) => elem?.value),
            operatingSystems: selectedOS.map((elem) => elem?.value),
            foreignLanguages: selectedTools.map((elem) => elem?.value),
            experienceFrom: experience.from || '',
            experienceTo: experience.to || '',
            corpExperienceFrom: corpExperience.from || '',
            corpExperienceTo: corpExperience.to || '',
        }));
    };

    const initValue = useCallback(() => {
        if (dropDownListValues.isActive === '') {
            setStatus({ boolValue: '' });
        } else {
            setStatus(
                statusList.find(
                    (elem) => elem.boolValue === dropDownListValues.isActive
                )
            );
        }

        const experienceNumber = data
            .filter((elem) => elem.experience)
            .map((elem) => elem.experience);
        const corpExperienceNumber = data
            .filter((elem) => elem.corpExperience)
            .map((elem) => elem.corpExperience);

        initPlaceholder(
            experienceNumber,
            {
                min: experience.placeholderMin,
                max: experience.placeholderMax,
            },
            setExperience
        );
        initPlaceholder(
            corpExperienceNumber,
            {
                min: corpExperience.placeholderMin,
                max: corpExperience.placeholderMax,
            },
            setCorpExperience
        );
    }, [data]);

    const dataForFilters = useMemo(
        () => ({
            status,
            changeStatus: setStatus,
            personsCVList: dropDownListValues.personsCVDropDown,
            selectedPersons,
            changeSelectedPersons: setSelectedPersons,
            specialityList,
            selectedSpeciality,
            changeSelectedSpeciality: setSelectedSpeciality,
            competencesList,
            selectedСompetences,
            changeSelectedСompetences: setSelectedСompetences,
            programmingLanguageList:
                dropDownListValues.programmingLanguageDropDown,
            selectedProgrammingLanguage,
            changeSelectedProgrammingLanguage: setSelectedProgrammingLanguage,
            profSkillsList: dropDownListValues.profSkillsDropDown,
            selectedProfSkills,
            changeProfSkills: setSelectedProfSkills,
            toolsList: dropDownListValues.toolsDropDown,
            selectedTools,
            changeSelectedTools: setSelectedTools,
            OSList: dropDownListValues.OSDropDown,
            selectedOS,
            changeSelectedOS: setSelectedOS,
            foreignLanguagesList: dropDownListValues.foreignLanguagesDropDown,
            selectedForeignLanguages,
            changeSelectedForeignLanguages: setSelectedForeignLanguages,
            experience,
            changeExperience: setExperience,
            corpExperience,
            changeCorpExperience: setCorpExperience,
        }),
        [
            competencesList,
            corpExperience,
            dropDownListValues.OSDropDown,
            dropDownListValues.foreignLanguagesDropDown,
            dropDownListValues.personsCVDropDown,
            dropDownListValues.profSkillsDropDown,
            dropDownListValues.programmingLanguageDropDown,
            dropDownListValues.toolsDropDown,
            experience,
            selectedForeignLanguages,
            selectedOS,
            selectedPersons,
            selectedProfSkills,
            selectedProgrammingLanguage,
            selectedSpeciality,
            selectedTools,
            selectedСompetences,
            specialityList,
            status,
        ]
    );

    useEffect(() => {
        if (isOpenFilters) {
            initValue();
        }
    }, [initValue, isOpenFilters]);

    const onSortHandler = (header) => {
        editSort(header, sort, setSort);
    };

    const openCvPerson = (item) => {
        const url = `/CV/${item.id}`;
        return navigate(url, {
            replace: true,
            state: { isEdit: false },
        });
    };

    const editCvPerson = (item) => {
        const url = `/CV/${item.id}`;
        return navigate(url, {
            replace: false,
            state: { isEdit: true },
        });
    };

    const openAddModal = () =>
        handleClick('Новое CV', <СvPersonAddContent closeModal={closeModal} />);

    const handleClick = (title, children) => {
        openModal({ title, children });
    };

    const handleClickFilters = () => {
        setIsOpenFilters(true);
    };

    const filtersForModal = useMemo(
        () => filtersAreaHandler('PERSONS_CV', dataForFilters),
        [dataForFilters]
    );

    const deleteCvPerson = (initial) => {
        handleClick(
            `Вы действительно хотите удалить cv №${initial.id} ?`,
            <DeleteModal
                closeModal={closeModal}
                data={data}
                id={initial.id}
                tableType="personsCv"
            />
        );
    };

    const changeCommentCv = (personInfo) =>
        handleClick(
            'Изменить комментарий',
            <ChangeCvCommentModel
                personInfo={personInfo}
                closeModal={closeModal}
                getCv={getCv}
            />
        );

    return (
        <>
            <PageHeader
                title="Справочник CV сотрудников"
                addButton={accessByRole('PersonsCV', 'addButton', currentRole)}
                addButtonText="Добавить CV"
                openAddModal={openAddModal}
                filtersButton={accessByRole(
                    'PersonsCV',
                    'filtersButton',
                    currentRole
                )}
                handlerClickFiltersButton={handleClickFilters}
            />
            <FiltersModal
                isActive={isOpenFilters}
                setIsActive={setIsOpenFilters}
                filters={filtersForModal}
                onSave={handleClickSaveFilters}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <div className={cx('tables_personsCv')}>
                    {!data?.length ? (
                        <div className={styles.empty_block}>
                            Список сотрудников пуст
                        </div>
                    ) : (
                        <Table
                            header={headers.personsInfo}
                            items={data}
                            caseName="PERSONS_CV"
                            loockIcon
                            editIcon={accessByRole(
                                'PersonsCV',
                                'editIcon',
                                currentRole
                            )}
                            deleteIcon={accessByRole(
                                'PersonsCV',
                                'deleteIcon',
                                currentRole
                            )}
                            changeComment
                            openCvPerson={(item) => openCvPerson(item)}
                            openEditModal={(item) => editCvPerson(item)}
                            openDeleteModal={(id) => deleteCvPerson(id)}
                            changeCvCommentModel={(personInfo) =>
                                changeCommentCv(personInfo)
                            }
                            filters={filters}
                            setFilters={setFilters}
                            onSortHandler={onSortHandler}
                            sort={sort}
                            tableScroll={tableScrollRef.current}
                            changeTableScroll={changeTableScroll}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default PersonsCV;
