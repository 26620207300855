import API from 'api/api';
import { logout } from 'redux/actions/auth';
import {
    getFrequencyData,
    addFrequencyData,
    editFrequencyData,
} from 'redux/actions/frequency';
import { useToastify } from 'helpers/toastify/useToastify';

export const getFrequency =
    (setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        API.frequency
            .getFrequency()
            .then((res) => dispatch(getFrequencyData(res.data.content)))
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => setIsLoading(false));
    };
export const addFrequency =
    (data, setIsLoading = () => {}) =>
    (dispatch) => {
        API.frequency
            .addFrequency(data)
            .then((res) => dispatch(addFrequencyData(res.data)))
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибки', 'error');
            })
            .finally(() => setIsLoading(false));
    };
export const editFrequency =
    (id, data, setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        API.frequency
            .editFrequency(id, data)
            .then((res) => {
                dispatch(editFrequencyData(res.data));
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => setIsLoading(false));
    };
