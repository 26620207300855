const CalendarIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.5556 13.1H13V18.6H18.5556V13.1ZM16.4444 1V3.2H7.55556V1H5.33333V3.2H3.22222C1.98889 3.2 1.01111 4.19 1.01111 5.4L1 20.8C1 22.01 1.98889 23 3.22222 23H20.7778C22 23 23 22.01 23 20.8V5.4C23 4.19 22 3.2 20.7778 3.2H18.6667V1H16.4444ZM20.7778 20.8H3.22222V8.7H20.7778V20.8Z"
            fill="#696C80"
        />
    </svg>
);

export default CalendarIcon;
