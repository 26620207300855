import { useDispatch, useSelector } from 'react-redux';
import { toggleValues } from 'mock/mockData';
import { OpenMenuArrow } from 'components/svg/SidebarIcon/sidebarIcons';
import { setSizeProductionPlansTables } from 'redux/actions/productionPlans';
import { setSizeUnusedPersonTables } from 'redux/actions/unusedPersons';
import { shape, string, func } from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const action = {
    PRODUCTION_PLANS: setSizeProductionPlansTables,
    UNUSED_PERSONS: setSizeUnusedPersonTables,
};

const Toggle = ({
    caseName = '',
    currentStatus,
    setStatus,
    title = '',
    handleCHange = () => {},
}) => {
    const dispatch = useDispatch();
    const cx = classNames.bind(styles);
    const statusProductionPlansTable = useSelector(
        (state) => state.productionPlans.toggleSize
    );
    const statusUnusedPersonsTable = useSelector(
        (state) => state.unusedPersons.toggleSize
    );

    const openSecondTable = (typeTable) => {
        if (
            typeTable === 'PRODUCTION_PLANS' &&
            statusProductionPlansTable.id === 1
        ) {
            dispatch(setSizeUnusedPersonTables(toggleValues[1]));
        } else if (
            typeTable === 'UNUSED_PERSONS' &&
            statusUnusedPersonsTable.id === 1
        ) {
            dispatch(setSizeProductionPlansTables(toggleValues[1]));
        }
    };

    return (
        <div className={cx('toggle')}>
            <span
                className={cx(
                    'toggle_icon',
                    {
                        'toggle_icon-open': currentStatus.id === 1,
                    },
                    {
                        'toggle_icon-close': currentStatus.id === 0,
                    }
                )}
            >
                <OpenMenuArrow />
            </span>
            <h2 className={cx('toggle_title')}>{title}</h2>
        </div>
    );
};

export default Toggle;

Toggle.propTypes = {
    currentStatus: shape({}),
    title: string,
    caseName: string,
    setStatus: func,
    handleCHange: func,
};
