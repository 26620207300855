import Input from 'components/Input';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import DropdownList from 'components/DropdownList';
import classNames from 'classnames/bind';
import Button from 'components/Button';
import {
    setFromToInputValue,
    setFromToDateValue,
} from 'helpers/Filters/FiltersModalHelpers';
import DatePicker from 'components/DatePicker';
import { useToastify } from 'helpers/toastify/useToastify';
import { useState } from 'react';
import styles from './styles.module.scss';

const FiltersModal = ({
    filters = [{}],
    currentRole = '',
    isActive,
    onSave = () => {},
    onClear = () => {},
    setIsActive = () => {},
}) => {
    const cx = classNames.bind(styles);
    const [isValid, setIsValid] = useState(true);

    const handleClickClear = () => {
        filters.forEach((filter) => {
            if (filter.typeFilter === 'dropDown') {
                if (filter.multiselect) {
                    filter.changeValue([]);
                } else {
                    filter.changeValue({});
                }
            }
            if (filter.typeFilter === 'inputRange') {
                setFromToInputValue('from', null, filter.changeValue);
                setFromToInputValue('to', null, filter.changeValue);
            }
            if (filter.typeFilter === 'datePicker') {
                setFromToDateValue('from', null, filter.changeDate);
                setFromToDateValue('to', null, filter.changeDate);
            }
        });
        onClear();
    };

    const onBlurHandler = (filter) => {
        if (filter.typeFilter === 'inputRange') {
            setIsValid(true);
            if (!!filter.inputRange.from && !!filter.inputRange.to) {
                let from = null;
                let to = null;
                if (filter.type === 'budget') {
                    from = Number(
                        filter.inputRange.from.replace(/[^0-9]/g, '')
                    );
                    to = Number(filter.inputRange.to.replace(/[^0-9]/g, ''));
                } else {
                    from = Number(filter.inputRange.from);
                    to = Number(filter.inputRange.to);
                }
                if (from > to) {
                    setIsValid(false);
                    useToastify(
                        `Поле "${filter.text} От" не должно быть больше поля "${filter.text} До".`,
                        'warning'
                    );
                }
            }
        }
        if (filter.typeFilter === 'datePicker') {
            useToastify(
                `Поле "${filter.text} От" не должно быть больше поля "${filter.text} До".`,
                'warning'
            );
        }
    };

    const closeModal = () => {
        setIsActive(false);
    };

    const handleClick = () => {
        onSave();
        closeModal();
    };

    const filter = (initialValues) => {
        switch (initialValues.typeFilter) {
            case 'dropDown':
                if (
                    currentRole === 'MANAGER' &&
                    initialValues.text === 'Менеджер'
                ) {
                    return null;
                }
                return (
                    <div className={styles.dropDown} key={initialValues.id}>
                        <DropdownList
                            placeholderInput={initialValues.text}
                            items={initialValues.items}
                            selected={initialValues.value}
                            setSelected={initialValues.changeValue}
                            noMargin
                            multiselect={initialValues?.multiselect}
                        />
                    </div>
                );
            case 'inputRange':
                return (
                    <div className={styles.inputRange} key={initialValues.id}>
                        <div className={styles.inputRange_title}>
                            {initialValues.text}
                        </div>
                        <div className={styles.inputRange_value}>
                            <div className={styles.inputRange_value_min}>
                                <span>От</span>
                                <Input
                                    type={
                                        initialValues?.type
                                            ? initialValues.type
                                            : 'number'
                                    }
                                    value={initialValues.inputRange.from}
                                    symbolLimit={
                                        initialValues?.symbolLimit
                                            ? initialValues.symbolLimit
                                            : 27
                                    }
                                    noMargin
                                    onBlur={() => {
                                        onBlurHandler(initialValues);
                                    }}
                                    placeholder={
                                        initialValues.inputRange.placeholderMin
                                    }
                                    onChange={(value) => {
                                        setFromToInputValue(
                                            'from',
                                            value,
                                            initialValues.changeValue
                                        );
                                    }}
                                />
                            </div>
                            <div className={styles.inputRange_value_max}>
                                <span>До</span>
                                <Input
                                    type={
                                        initialValues?.type
                                            ? initialValues.type
                                            : 'number'
                                    }
                                    value={initialValues.inputRange.to}
                                    noMargin
                                    symbolLimit={
                                        initialValues?.symbolLimit
                                            ? initialValues.symbolLimit
                                            : 27
                                    }
                                    placeholder={
                                        initialValues.inputRange.placeholderMax
                                    }
                                    onBlur={() => {
                                        onBlurHandler(initialValues);
                                    }}
                                    onChange={(value) => {
                                        setFromToInputValue(
                                            'to',
                                            value,
                                            initialValues.changeValue
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                );
            case 'datePicker':
                return (
                    <div className={styles.datePicker} key={initialValues.id}>
                        <div className={styles.inputRange_title}>
                            {initialValues.text}
                        </div>
                        <div className={styles.datePicker__value}>
                            <div className={styles.datePicker__value_from}>
                                <span>От</span>
                                <DatePicker
                                    isRemoveButton
                                    dateDrop={initialValues.date.from}
                                    setDateDrop={(value) => {
                                        setFromToDateValue(
                                            'from',
                                            value,
                                            initialValues.changeDate,
                                            () => onBlurHandler(initialValues)
                                        );
                                    }}
                                    placeholder="Начало"
                                    noMargin
                                />
                            </div>
                            <div className={styles.datePicker__value_to}>
                                <span>До</span>
                                <DatePicker
                                    isRemoveButton
                                    dateDrop={initialValues.date.to}
                                    setDateDrop={(value) => {
                                        setFromToDateValue(
                                            'to',
                                            value,
                                            initialValues.changeDate,
                                            () => onBlurHandler(initialValues)
                                        );
                                    }}
                                    placeholder="Конец"
                                    noMargin
                                />
                            </div>
                        </div>
                    </div>
                );
            default:
                return '';
        }
    };

    return (
        <div
            className={cx('container', {
                container_active: isActive,
            })}
            onClick={closeModal}
        >
            <div
                className={cx('content', {
                    content_active: isActive,
                })}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.filters_container}>
                    {filters.map((elem) => filter(elem))}
                </div>

                <div className={styles.button_container}>
                    <Button text="Очистить" onClick={handleClickClear} />
                    <Button
                        text="Применить"
                        onClick={handleClick}
                        disabled={!isValid}
                    />
                </div>
            </div>
        </div>
    );
};

FiltersModal.propTypes = {
    filters: arrayOf(shape({})),
    currentRole: string,
    isActive: bool,
    setIsActive: func,
    onSave: func,
    onClear: func,
};

export default FiltersModal;
