import API from 'api/api';
import { useToastify } from 'helpers/toastify/useToastify';
import { logout } from 'redux/actions/auth';
import {
    getBalanceBudgetsReportData,
    getPersonsExperienceReportData,
    getProjectsQuartersData,
    getReportsCompetenceLevelData,
    getReportsManagerKpiData,
    getReportsPersonsData,
    getReportsPersonsWorkloadData,
    getReportsProjectTypeData,
    getReportsProjectWorkloadData,
    getReportsProjectsData,
    getTeamPeriodPersons,
    getUnusedPersonsReportData,
} from 'redux/actions/reports';

export const getTeamPersons =
    (projectIds, setIsLoading = () => {}) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getTeamPeriodPersons({ projectIds });
            dispatch(getTeamPeriodPersons(res.data));
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const balanceBudgetsUnload =
    (
        selectedYear,
        selectedMonth,
        isActive,
        projectType,
        selectedManager,
        params,
        setIsLoading = () => {},
        exportReport = () => {}
    ) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.unload.balanceBudgets({
                yearFrom: selectedYear.value,
                yearTo: selectedYear.value,
                monthFrom: selectedMonth.from + 1,
                monthTo: selectedMonth.to + 1,
                isActive,
                projectTypes: projectType,
                managerIds: selectedManager,
                ...params,
            });
            exportReport(res);
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getReportByProjects =
    (
        selectedYear,
        monthFrom,
        monthTo,
        projectIsActive,
        params,
        format,
        setIsLoading = () => {},
        exportReport = () => {}
    ) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getProjectReport({
                yearFrom: selectedYear.value,
                yearTo: selectedYear.value,
                monthFrom: monthFrom + 1,
                monthTo: monthTo + 1,
                projectIsActive,
                ...params,
            });
            if (format === 'csvFormat') {
                exportReport(res);
            } else {
                dispatch(getReportsProjectsData(res.data));
            }
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getReportByPersons =
    (
        selectedYear,
        monthFrom,
        monthTo,
        projectIsActive,
        params,
        format,
        setIsLoading = () => {},
        exportReport = () => {}
    ) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getPersonsReport({
                yearFrom: selectedYear.value,
                yearTo: selectedYear.value,
                monthFrom: monthFrom + 1,
                monthTo: monthTo + 1,
                projectIsActive,
                ...params,
            });
            if (format === 'csvFormat') {
                exportReport(res);
            } else {
                dispatch(getReportsPersonsData(res.data));
            }
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getReportWorkLoadByPersons =
    (
        selectedYear,
        monthFrom,
        monthTo,
        projectIsActive,
        params,
        format,
        setIsLoading = () => {},
        exportReport = () => {}
    ) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getPersonsWorkloadReport({
                yearFrom: selectedYear.value,
                yearTo: selectedYear.value,
                monthFrom: monthFrom + 1,
                monthTo: monthTo + 1,
                projectIsActive,
                ...params,
            });
            if (format === 'csvFormat') {
                exportReport(res);
            } else {
                dispatch(getReportsPersonsWorkloadData(res.data));
            }
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getReportWorkLoadByProjects =
    (
        selectedYear,
        monthFrom,
        monthTo,
        projectIsActive,
        params,
        format,
        setIsLoading = () => {},
        exportReport = () => {}
    ) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getProjectWorkloadReport({
                yearFrom: selectedYear.value,
                yearTo: selectedYear.value,
                monthFrom: monthFrom + 1,
                monthTo: monthTo + 1,
                projectIsActive,
                ...params,
            });
            if (format === 'csvFormat') {
                exportReport(res);
            } else {
                dispatch(getReportsProjectWorkloadData(res.data));
            }
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getReportByTypeProjects =
    (
        selectedYear,
        monthFrom,
        monthTo,
        projectIsActive,
        params,
        format,
        setIsLoading = () => {},
        exportReport = () => {}
    ) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getProjectTypeReport({
                yearFrom: selectedYear.value,
                yearTo: selectedYear.value,
                monthFrom: monthFrom + 1,
                monthTo: monthTo + 1,
                projectIsActive,
                ...params,
            });
            if (format === 'csvFormat') {
                exportReport(res);
            } else {
                dispatch(getReportsProjectTypeData(res.data));
            }
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getReportByManagers =
    (
        selectedYear,
        monthFrom,
        monthTo,
        personIsActive,
        params,
        format,
        setIsLoading = () => {},
        exportReport = () => {}
    ) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getManagerKpiReport({
                yearFrom: selectedYear.value,
                yearTo: selectedYear.value,
                monthFrom: monthFrom + 1,
                monthTo: monthTo + 1,
                personIsActive,
                ...params,
            });
            if (format === 'csvFormat') {
                exportReport(res);
            } else {
                dispatch(getReportsManagerKpiData(res.data));
            }
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getReportByCompetenceLevel =
    (params, format, setIsLoading = () => {}, exportReport = () => {}) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getCompetenceLevel({
                ...params,
            });
            if (format === 'csvFormat') {
                exportReport(res);
            } else {
                dispatch(getReportsCompetenceLevelData(res.data));
            }
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getReportByQuarters =
    (
        selectedYear,
        projectIsActive,
        params,
        format,
        setIsLoading = () => {},
        exportReport = () => {}
    ) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getProjectsQuartersReport({
                yearFrom: selectedYear.value,
                yearTo: selectedYear.value,
                projectIsActive,
                ...params,
            });
            if (format === 'csvFormat') {
                exportReport(res);
            } else {
                dispatch(getProjectsQuartersData(res.data));
            }
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getReportByUnusedPersons =
    (
        selectedYear,
        monthFrom,
        monthTo,
        projectIsActive,
        params,
        format,
        setIsLoading = () => {},
        exportReport = () => {}
    ) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getUnusedPersonsReport({
                yearFrom: selectedYear.value,
                yearTo: selectedYear.value,
                monthFrom: monthFrom + 1,
                monthTo: monthTo + 1,
                projectIsActive,
                ...params,
            });
            if (format === 'csvFormat') {
                exportReport(res);
            } else {
                dispatch(getUnusedPersonsReportData(res.data));
            }
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getReportByPersonsExperience =
    (
        specialityNames,
        competenceNames,
        params,
        format,
        setIsLoading = () => {},
        exportReport = () => {}
    ) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getPersonsExperienceReport({
                specialityNames,
                competenceNames,
                ...params,
            });
            if (format === 'csvFormat') {
                exportReport(res);
            } else {
                dispatch(getPersonsExperienceReportData(res.data));
            }
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getReportByBalanceBudgets =
    (
        selectedYear,
        selectedMonth,
        isActive,
        projectType,
        selectedManager,
        params,
        format,
        setIsLoading = () => {},
        exportReport = () => {}
    ) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.reports.getBalanceBudgetsReport({
                yearFrom: selectedYear.value,
                yearTo: selectedYear.value,
                monthFrom: selectedMonth.from + 1,
                monthTo: selectedMonth.to + 1,
                isActive,
                projectTypes: projectType,
                managerIds: selectedManager,
                ...params,
            });
            if (format === 'csvFormat') {
                dispatch(
                    balanceBudgetsUnload(
                        selectedYear,
                        selectedMonth,
                        isActive,
                        projectType,
                        selectedManager,
                        params,
                        format,
                        setIsLoading,
                        exportReport
                    )
                );
            } else {
                dispatch(getBalanceBudgetsReportData(res.data));
            }
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };
