import API from 'api/api';
import { fullDate, projectTypeRev } from 'helpers/tableHandler';
import { useToastify } from 'helpers/toastify/useToastify';
import { logout } from 'redux/actions/auth';
import {
    addProjectData,
    editProjectData,
    getProjectsData,
    setProjectsTimeCrawler,
    getFilteredProjectsData,
    getProjectsForDropDown,
    getProjectsForDropDownFiltered,
    setProjectsFilterStatus,
} from 'redux/actions/projects';

export const getAllProjects =
    (filters = {}, sort = {}, closeModal = () => {}, setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        const query = { ...filters };

        if (query.type) query.type = projectTypeRev[query.type];
        if (query.dateStart) query.dateStart = fullDate(query.dateStart);
        if (query.dateEnd) query.dateEnd = fullDate(query.dateEnd);
        if (sort.property) query.sort = `${sort.property},${sort.order}`;
        if (sort.property === 'managerName') {
            query.sort = `managerSurname,${sort.order}`;
        }

        API.projects
            .getAllProjects(query)
            .then((res) => {
                if (res.data.content.length === 0) {
                    const message =
                        query.isActive === true
                            ? 'На данный момент нет активных проектов'
                            : 'На данный момент нет неактивных проектов';
                    useToastify(message, 'info');
                }
                dispatch(getProjectsData(res.data.content));
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => {
                closeModal();
                setIsLoading(false);
            });
    };

export const getAllProjectsForDropDown = () => async (dispatch) => {
    const query = { sort: `name,asc` };

    try {
        const res = await API.projects.getAllProjects(query);
        dispatch(getProjectsForDropDown(res.data.content));
        dispatch(
            getFilteredProjectsData(
                res.data.content.filter((elem) => elem.isActive === true)
            )
        );
    } catch (err) {
        if (err.name === 'UNAUTHORIZED') {
            dispatch(logout());
            localStorage.clear();
        }
        useToastify(err?.message ?? 'Произошла ошибка', 'error');
    }
};

export const getAllProjectsForDropDownFiltered =
    (filters = {}, sort = {}) =>
    async (dispatch) => {
        const query = { ...filters, ...{ sort: `name,asc` } };
        if (query.type) query.type = projectTypeRev[query.type];
        if (query.dateStart) query.dateStart = fullDate(query.dateStart);
        if (query.dateEnd) query.dateEnd = fullDate(query.dateEnd);
        if (sort.property) query.sort = `${sort.property},${sort.order}`;
        if (sort.property === 'managerName') {
            query.sort = `managerSurname,${sort.order}`;
        }

        try {
            const res = await API.projects.getAllProjects(query);
            dispatch(getProjectsForDropDownFiltered(res.data.content));
            dispatch(
                getFilteredProjectsData(
                    res.data.content.filter((elem) => elem.isActive === true)
                )
            );
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        }
    };

export const getAllProjectsInit =
    (setIsLoading = () => {}) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.projects.getAllProjects({ sort: `name,asc` });
            dispatch(getProjectsForDropDown(res.data.content));
            dispatch(
                getFilteredProjectsData(
                    res.data.content.filter((elem) => elem.isActive === true)
                )
            );
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const getFilteredProjects =
    (filters = {}, sort = {}, closeModal = () => {}, setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        const query = { ...filters };
        if (sort.property) query.sort = `${sort.property},${sort.order}`;

        if (Object.prototype.hasOwnProperty.call(filters, 'isActive')) {
            dispatch(setProjectsFilterStatus(filters.isActive));
        }

        API.projects
            .getAllProjects(query)
            .then((res) => {
                // if (res.data.content.length === 0) {
                //     useToastify(
                //         'По заданным фильтрам проекты не найдены',
                //         'warning'
                //     );
                // }
                dispatch(getFilteredProjectsData(res.data.content));
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => {
                closeModal();
                setIsLoading(false);
            });
    };

export const editProject =
    (id, data, closeModal, changeIsPending) => (dispatch) => {
        changeIsPending(true);
        API.projects
            .editProject(id, data)
            .then((res) => {
                dispatch(editProjectData(res.data));
                useToastify('Проект изменён', 'success');
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => {
                closeModal();
                changeIsPending(false);
            });
    };

export const addProject = (data, closeModal, changeIsPending) => (dispatch) => {
    changeIsPending(true);
    API.projects
        .addProject(data)
        .then((res) => {
            dispatch(addProjectData(res.data));
            useToastify('Проект добавлен', 'success');
        })
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        })
        .finally(() => {
            closeModal();
            changeIsPending(false);
        });
};
// TimeCrawler

export const getProjectsTimeCrawler = () => (dispatch) => {
    API.projectsTimeCrawler
        .getProjectsTimeCrawler({
            page: 0,
            size: 1000,
        })
        .then((res) => {
            dispatch(setProjectsTimeCrawler(res.data.content));
        })
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
        });
};
