import {
    DELETE_PROJECTS_DATA,
    GET_PROJECTS_DATA,
    ADD_PROJECT_DATA,
    EDIT_PROJECT_DATA,
    SET_PROJECTS_FILTER_STATUS,
    SET_PROJECTS_FROM_TIME_CRAWLER,
    CLEAR_PROJECTS,
    GET_FILTERED_PROJECTS_DATA,
    GET_PROJECTS_FOR_DROPDOWN,
    GET_PROJECTS_FOR_DROPDOWN_FILTERED,
} from 'redux/types';

const defaultState = {
    data: [],
    DropdownListValues: [],
    DropdownListValuesFiltered: [],
    DropdownListTypeProjectsValues: [],
    DropdownListManagerValues: [],
    isActive: true,
    DropdownListProjectsTimeCrawler: [],
};

export const projects = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_PROJECT_DATA:
            return {
                ...state,
                data: [action.payload, ...state.data],
            };
        case DELETE_PROJECTS_DATA:
            return {
                ...state,
                data: state.data.filter((item) => item.id !== action.payload),
            };
        case EDIT_PROJECT_DATA:
            return {
                ...state,
                data: state.data.map((item) =>
                    item.id === action.payload.id ? action.payload : item
                ),
            };
        case GET_FILTERED_PROJECTS_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case GET_PROJECTS_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case GET_PROJECTS_FOR_DROPDOWN:
            return {
                ...state,
                DropdownListValues: action.payload.map((el) => ({
                    id: el.id,
                    value: el.name,
                    isSelected: el.isSelected,
                })),
                DropdownListTypeProjectsValues: action.payload.map((el) => ({
                    id: el.id,
                    value: el.type,
                })),
                DropdownListManagerValues: action.payload.reduce(
                    (
                        [acc, ManagerIds],
                        { manager: { id, fullName: value } }
                    ) => {
                        if (!ManagerIds.has(id)) {
                            ManagerIds.add(id);
                            return [[...acc, { id, value }], ManagerIds];
                        }
                        return [acc, ManagerIds];
                    },
                    [[], new Set()]
                )[0],
            };
        case GET_PROJECTS_FOR_DROPDOWN_FILTERED:
            return {
                ...state,
                DropdownListValuesFiltered: action.payload.map((el) => ({
                    id: el.id,
                    value: el.name,
                    isSelected: el.isSelected,
                })),
            };
        case SET_PROJECTS_FILTER_STATUS:
            return {
                ...state,
                isActive: action.payload,
            };
        case SET_PROJECTS_FROM_TIME_CRAWLER:
            return {
                ...state,
                DropdownListProjectsTimeCrawler: action.payload.map((el) => ({
                    id: el.id,
                    value: el.title,
                })),
            };
        case CLEAR_PROJECTS:
            return defaultState;
        default:
            return state;
    }
};
