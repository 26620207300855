import { bool, func } from 'prop-types';
import SaveIcon from 'components/svg/saveIcon';
import EditIcon from 'components/svg/editIcon';
import DeleteIcon from 'components/svg/deleteIcon';
import PlusIcon from 'components/svg/ButtonIcon/plus';
import styles from './styles.module.scss';

const CVButtons = ({
    isCancel = false,
    isEditing = false,
    isEdit = false,
    isSave = false,
    isAdd = false,
    isDelete = false,
    onSave = () => {},
    onAdd = () => {},
    onCansel = () => {},
    onDelete = () => {},
    setIsEdit = () => {},
}) =>
    isEdit ? (
        <div className={styles.buttons}>
            {isSave && (
                <button
                    type="button"
                    className={styles.buttons_save}
                    onClick={onSave}
                >
                    <SaveIcon />
                </button>
            )}
            {isAdd && (
                <button
                    type="button"
                    className={styles.buttons_add}
                    onClick={onAdd}
                >
                    <PlusIcon />
                </button>
            )}
            {isCancel && (
                <button
                    type="button"
                    className={styles.buttons_cancel}
                    onClick={() => onCansel()}
                >
                    &#10006;
                </button>
            )}
        </div>
    ) : (
        <div className={styles.buttons}>
            {isEditing && (
                <button
                    type="button"
                    className={styles.buttons_edit}
                    onClick={() => setIsEdit(true)}
                >
                    <EditIcon />
                </button>
            )}

            {isDelete && (
                <button
                    type="button"
                    className={styles.buttons_del}
                    onClick={onDelete}
                >
                    <DeleteIcon />
                </button>
            )}
        </div>
    );

export default CVButtons;

CVButtons.propTypes = {
    isCancel: bool,
    isEditing: bool,
    isEdit: bool,
    isDelete: bool,
    isAdd: bool,
    isSave: bool,
    onSave: func,
    onCansel: func,
    onAdd: func,
    onDelete: func,
    setIsEdit: func,
};
