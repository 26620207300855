import React, { useContext } from 'react';
import { element, shape, string } from 'prop-types';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import classNames from 'classnames/bind';
import styles from 'components/Popup/styles.module.scss';

const Popup = (props) => {
    const cx = classNames.bind(styles);
    const { closeModal } = useContext(ModalContext);
    const { content } = props;
    const { children, title, getProductionPlans, isNotScrollable } = content;
    const handlerClick = () => {
        closeModal();
        if (title === 'Ошибки загруженного файла:') {
            getProductionPlans();
        }
    };
    const handlePress = (e) => {
        if (e.key === 'Esc') {
            handlerClick();
        }
    };
    return (
        <div className={styles.popup_container}>
            <div
                className={styles.background}
                onKeyPress={handlePress}
                onClick={handlerClick}
            />
            <div className={cx('card', { not_scrollable: isNotScrollable })}>
                <h2>{title}</h2>
                {children}
            </div>
        </div>
    );
};

Popup.propTypes = {
    content: shape({ children: element, string, getProductionPlans: () => {} }),
};

export default Popup;
