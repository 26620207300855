import { useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { element } from 'prop-types';
import GoogleButton from 'components/LoginPage/GoogleButton';
import GitLabButton from 'components/LoginPage/GitLabButton';
import YandexButton from 'components/LoginPage/YandexButton';
import Loader from 'components/Loader';
import { useToastify } from 'helpers/toastify/useToastify';
import loginLogo from 'icons/loginLogo.svg';
import styles from './styles.module.scss';

const LoginPage = ({ children }) => {
    const userTheme = localStorage.getItem('app-theme');

    const userData = useSelector((state) => state.auth.userData);
    const userLoggedIn = localStorage.getItem('userLoggedIn');
    const isLoading = useSelector((state) => state.auth.isLoading);
    const intervalRef = useRef();

    useEffect(() => {
        if (userTheme === null) {
            localStorage.setItem('app-theme', 'yellow');
        }
    }, [userTheme]);

    if (userLoggedIn) {
        if (userData.userRole === 'PERSONA_NON_GRATA') {
            useToastify('Отсутствуют права для доступа в систему', 'error');
        } else {
            return children;
        }
    }

    return (
        <div className={styles.loginpage__wrapper}>
            <div className={styles.loginpage__background}>
                {isLoading ? (
                    <Loader authPage />
                ) : (
                    <div className={styles.loginpage__modal}>
                        <img src={loginLogo} alt="login-logo" />
                        <div className={styles.loginpage__modal_header}>
                            Добро пожаловать
                        </div>
                        <div className={styles.loginpage__modal_text}>
                            Пожалуйста, войдите в систему
                        </div>
                        <div className={styles.loginpage__btns}>
                            <GoogleButton />
                            <GitLabButton />
                            <YandexButton />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginPage;

LoginPage.propTypes = {
    children: element,
};
