import { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import PageHeader from 'components/PageHeader';
import Table from 'components/Tables/Table';
import ProjectContent from 'components/ModalsContent/ProjectContent';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import Loader from 'components/Loader';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import { headers, projectType } from 'helpers/tableHandler';
import { editSort } from 'helpers/editFormsValues';
import { useDispatch, useSelector } from 'react-redux';
import { clearProjects } from 'redux/actions/projects';
import {
    getAllProjectsInit,
    getFilteredProjects,
} from 'redux/thunks/projectThunks';
import { getFilteredPersonsForDropDown } from 'redux/thunks/personThunks';
import FiltersModal from 'components/Filters/FiltersModal';
import {
    filtersAreaHandler,
    statusList,
} from 'helpers/Filters/filtersAreaHandler';
import { initPlaceholder } from 'helpers/Filters/FiltersModalHelpers';
import { accessByRole } from 'helpers/roleHelpers';
import isEmpty from 'helpers/isEmpty';
import { projectTypesList } from 'mock/mockData';
import styles from './styles.module.scss';

const Projects = () => {
    const dispatch = useDispatch();
    const { openModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const projects = useSelector((state) => state.projects);
    const managersList = useSelector(
        (state) => state.persons.DropdownListValuesFiltered
    );

    const currentRole = useSelector((state) => state.auth.userData.userRole);

    const typeProjectItems = projectTypesList.map((type, id) => ({
        id,
        value: projectType[type],
        valueRequest: type,
    }));

    const [status, setStatus] = useState({});
    const [projectsFilter, setProjectsFilter] = useState([]);
    const [typeProject, setTypeProject] = useState([]);
    const [manager, setManager] = useState([]);
    const [date, setDate] = useState({
        from: null,
        to: null,
    });
    const [budget, setBudget] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });

    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState({ property: 'name', order: 'asc' });

    useEffect(() => {
        dispatch(getAllProjectsInit(setIsLoading));
        dispatch(
            getFilteredPersonsForDropDown({
                specialityIds: '1,14',
            })
        );
        return () => dispatch(clearProjects());
    }, []);

    useEffect(() => {
        setBudget((prev) => ({
            ...prev,
            placeholderMax: '',
            placeholderMin: '',
        }));
    }, [projects.isActive]);

    useEffect(() => {
        if (!isFirstLoad) {
            dispatch(getFilteredProjects(filters, sort, setIsLoading));
        } else {
            setIsFirstLoad(false);
        }
    }, [filters, sort, dispatch]);

    const initValue = useCallback(() => {
        setStatus(() => {
            if (projects.isActive !== '') {
                return statusList.find(
                    (elem) => elem.boolValue === projects.isActive
                );
            }
            return {};
        });

        const budgetNumbers = projects.data
            .filter((elem) => elem.initialBudget)
            .map((elem) => elem.initialBudget);

        initPlaceholder(
            budgetNumbers,
            {
                min: budget.placeholderMin,
                max: budget.placeholderMax,
            },
            setBudget
        );
    }, [projects.data, projects.isActive]);

    useEffect(() => {
        if (isOpenFilters) {
            initValue();
        }
    }, [isOpenFilters]);

    const dataForFilters = useMemo(
        () => ({
            status,
            changeStatus: setStatus,
            projects: projectsFilter,
            changeProjects: setProjectsFilter,
            projectsItems: projects.DropdownListValues,
            typeProject,
            changeTypeProject: setTypeProject,
            typeProjectItems,
            manager,
            changeManager: setManager,
            managerList: managersList,
            date,
            changeDate: setDate,
            budget,
            changeBudget: setBudget,
        }),
        [
            budget,
            date,
            manager,
            managersList,
            projects.DropdownListValues,
            projectsFilter,
            status,
            typeProject,
            typeProjectItems,
        ]
    );

    const handleClickSaveFilters = () => {
        setFilters((prev) => ({
            ...prev,
            ids: projectsFilter.map((elem) => elem?.id),
            type: typeProject.map((elem) => elem?.valueRequest),
            managerIds: manager.map((elem) => elem?.id),
            dateStartFrom: date.from ? date.from.toISOString() : '',
            dateEndTo: date.to ? date.to.toISOString() : '',
            initialBudgetFrom: budget.from || '',
            initialBudgetTo: budget.to || '',
            isActive: isEmpty(status) ? status.boolValue : '',
        }));
    };

    const handleClick = (title, children) => {
        openModal({ title, children });
    };

    const handleClickFilters = () => {
        setIsOpenFilters(true);
    };

    const onSortHandler = (header) => {
        editSort(header, sort, setSort);
    };

    return (
        <>
            <PageHeader
                title="Проекты компании"
                caseName="PROJECTS"
                addButton={accessByRole('Projects', 'addButton', currentRole)}
                addButtonText="Добавить проект"
                filtersButton={accessByRole(
                    'Projects',
                    'filtersButton',
                    currentRole
                )}
                handlerClickFiltersButton={handleClickFilters}
                openAddModal={() => {
                    handleClick(
                        'Добавление проекта',
                        <ProjectContent
                            projects={projects}
                            closeModal={closeModal}
                        />
                    );
                }}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <FiltersModal
                        filters={filtersAreaHandler('PROJECTS', dataForFilters)}
                        currentRole={currentRole}
                        isActive={isOpenFilters}
                        setIsActive={setIsOpenFilters}
                        onSave={handleClickSaveFilters}
                    />
                    {!projects.data.length ? (
                        <div className={styles.empty_block}>
                            Список проектов пуст
                        </div>
                    ) : (
                        <Table
                            header={headers.projects}
                            items={projects.data}
                            caseName="PROJECTS"
                            deleteIcon={accessByRole(
                                'Projects',
                                'deleteIcon',
                                currentRole
                            )}
                            editIcon={accessByRole(
                                'Projects',
                                'editIcon',
                                currentRole
                            )}
                            openEditModal={(initial) => {
                                handleClick(
                                    'Редактирование проекта',
                                    <ProjectContent
                                        projects={projects}
                                        closeModal={closeModal}
                                        initialValues={initial}
                                    />
                                );
                            }}
                            openDeleteModal={(initial) => {
                                handleClick(
                                    `Вы действительно хотите удалить проект ${initial.items.name}?`,
                                    <DeleteModal
                                        closeModal={closeModal}
                                        data={projects.data}
                                        id={initial.id}
                                        tableType="projects"
                                    />
                                );
                            }}
                            filters={filters}
                            setFilters={setFilters}
                            onSortHandler={onSortHandler}
                            sort={sort}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Projects;
