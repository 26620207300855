import { arrayOf, shape } from 'prop-types';
import Table from 'components/Tables/Table';
import { headers } from 'helpers/tableHandler';
import styles from './styles.module.scss';

const ProductionPlansErrorModalContent = ({
    notFound,
    noTariffRate,
    noProductionPlan,
}) => (
    <div>
        {notFound.length > 0 && (
            <div>
                <span className={styles.prodPlans__modal_header}>
                    Не найден:
                </span>
                <div>
                    <Table
                        header={headers.productionPlansErrorModal}
                        items={notFound}
                        caseName="ERROR_MODAL"
                    />
                </div>
            </div>
        )}
        {noTariffRate.length > 0 && (
            <div>
                <span className={styles.prodPlans__modal_header}>
                    Без тарифной ставки:
                </span>
                <div>
                    <Table
                        header={headers.productionPlansErrorModal}
                        items={noTariffRate}
                        caseName="ERROR_MODAL"
                    />
                </div>
            </div>
        )}
        {noProductionPlan.length > 0 && (
            <div>
                <span className={styles.prodPlans__modal_header}>
                    Без плана производства:
                </span>
                <div>
                    <Table
                        header={headers.productionPlansErrorModal}
                        items={noProductionPlan}
                        caseName="ERROR_MODAL"
                    />
                </div>
            </div>
        )}
    </div>
);

const ProductionPlansErrorHandler = (items, openModal, getProductionPlans) => {
    const notFound = [];
    const noTariffRate = [];
    const noProductionPlan = [];

    items.map((item) => {
        if (item.description === 'Данный сотрудник не найден')
            notFound.push(item);
        if (
            item.description.indexOf(
                'Данный сотрудник не имеет тарифной ставки'
            ) !== -1
        )
            noTariffRate.push(item);
        if (
            item.description ===
            'Для данного сотрудника не был найден план производства'
        )
            noProductionPlan.push(item);
        return null;
    });
    if (notFound.length || noTariffRate.length || noProductionPlan.length)
        return openModal({
            title: `Ошибки загруженного файла:`,
            children: (
                <ProductionPlansErrorModalContent
                    notFound={notFound}
                    noTariffRate={noTariffRate}
                    noProductionPlan={noProductionPlan}
                />
            ),
            getProductionPlans,
        });
    return null;
};

export default ProductionPlansErrorHandler;

ProductionPlansErrorHandler.propTypes = {};

ProductionPlansErrorModalContent.propTypes = {
    notFound: arrayOf(shape({})),
    noTariffRate: arrayOf(shape({})),
    noProductionPlan: arrayOf(shape({})),
};
