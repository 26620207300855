import { GET_JIRA_PROJECTS_DATA } from 'redux/types';

const defaultState = {
    data: [],
    DropdownListValues: [],
};

export const jiraProjects = (state = defaultState, action) => {
    switch (action.type) {
        case GET_JIRA_PROJECTS_DATA:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};
