import { bool, string } from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const Loader = ({ authPage = false, text = '' }) => {
    const cx = classNames.bind(styles);
    return (
        <>
            <div className={cx('loader__box')}>
                <span className={cx('loader', { authPage })} />
            </div>
            <div className={cx('loader__text')}>{text}</div>
        </>
    );
};

Loader.propTypes = { authPage: bool, text: string };

export default Loader;
