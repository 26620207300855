import API from 'api/api';
import { logout } from 'redux/actions/auth';
import { useToastify } from 'helpers/toastify/useToastify';
import { getUsersData, getUsersDropDownData } from 'redux/actions/users';

export const getAllUsers =
    (setIsLoading = () => {}) =>
    async (dispatch) => {
        setIsLoading(false);
        try {
            const res = await API.users.getAllUsers({ size: 1000 });
            dispatch(getUsersData(res.data.content));
            dispatch(getUsersDropDownData(res.data.content));
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify('Ошибка при загрузки пользователей', 'error');
        }
    };

export const getAllFilteredUsers =
    (filter = {}, sort = {}, setIsLoading = () => {}) =>
    async (dispatch) => {
        setIsLoading(false);
        const query = { ...filter };
        if (sort.property) query.sort = `${sort.property},${sort.order}`;
        query.size = 1000;

        try {
            const res = await API.users.getAllUsers(query);
            dispatch(getUsersData(res.data.content));
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify('Ошибка при загрузки пользователей', 'error');
        }
    };
