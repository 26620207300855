import API from 'api/api';
import { logout } from 'redux/actions/auth';
import {
    getBaseTariffRatesData,
    editBaseTariffRateData,
    getBaseTariffRatesForSelectedPerson,
} from 'redux/actions/baseTariffRates';
import { useToastify } from 'helpers/toastify/useToastify';

export const getBaseTariff =
    (id, setIsLoading = () => {}, closeModal = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        API.baseTariffs
            .getAllBaseTariffs(id)
            .then((res) => {
                dispatch(getBaseTariffRatesData(res.data.content));
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => {
                setIsLoading(false);
                closeModal();
            });
    };

export const editBaseTariff =
    (
        id,
        selectedPerson,
        ratePerHourValue,
        closeModal = () => {},
        setIsLoading = () => {}
    ) =>
    (dispatch) => {
        setIsLoading(true);
        closeModal();
        API.baseTariffs
            .editBaseTariff(id, {
                personId: selectedPerson.id,
                tariffRateHour: ratePerHourValue,
            })
            .then((res) => {
                dispatch(editBaseTariffRateData(res.data, id));
                useToastify('Тариф изменён', 'success');
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => setIsLoading(false));
    };

export const addBaseTariff =
    (
        selectedPerson,
        ratePerHourValue,
        closeModal = () => {},
        setIsLoading = () => {}
    ) =>
    (dispatch) => {
        setIsLoading(true);
        closeModal();
        API.baseTariffs
            .addBaseTariff({
                personId: selectedPerson.id,
                tariffRateHour: ratePerHourValue,
            })
            .then(() => {
                dispatch(getAllBaseTariffs());
                useToastify('Тариф добавлен', 'success');
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(
                    err?.message ?? `Произошла ошибка, ${err.message}`,
                    'error'
                );
            })
            .finally(() => setIsLoading(false));
    };

export const getAllBaseTariffs =
    (sort = {}, filters = {}, setIsLoading = () => {}, closeModal = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        const query = { ...filters };
        if (sort.property) query.sort = `${sort.property},${sort.order}`;
        API.baseTariffs
            .getAllBaseTariffs(query)
            .then((res) => {
                dispatch(getBaseTariffRatesData(res.data.content));
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => {
                setIsLoading(false);
                closeModal();
            });
    };

export const getBaseTariffByPerson = (personId) => (dispatch) => {
    API.baseTariffs
        .getBaseTariffByPerson(personId)
        .then((res) => {
            const uniqueTariffRates = [
                ...new Set(res.data.map((el) => el.tariffRateHour)),
            ];
            const tariffRates = uniqueTariffRates.map((el, index) => {
                return { id: index, value: el };
            });
            dispatch(getBaseTariffRatesForSelectedPerson(tariffRates));
        })
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        });
};
