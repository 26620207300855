export const PersonDirectorisIcon = () => (
    <svg
        width="193"
        height="102"
        viewBox="0 0 203 102"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M101.5 57.3125C115.219 57.3125 127.339 60.595 137.187 64.8875C146.277 68.9275 152 78.0175 152 87.865V101.5H51V87.9492C51 78.0175 56.7233 68.9275 65.8133 64.9717C75.6608 60.595 87.7808 57.3125 101.5 57.3125ZM34.1667 59.4167C43.425 59.4167 51 51.8417 51 42.5833C51 33.325 43.425 25.75 34.1667 25.75C24.9083 25.75 17.3333 33.325 17.3333 42.5833C17.3333 51.8417 24.9083 59.4167 34.1667 59.4167ZM43.6775 68.675C40.5633 68.17 37.4492 67.8333 34.1667 67.8333C25.8342 67.8333 17.9225 69.6008 10.7683 72.715C4.54 75.4083 0.5 81.4683 0.5 88.2858V101.5H38.375V87.9492C38.375 80.9633 40.3108 74.3983 43.6775 68.675ZM168.833 59.4167C178.092 59.4167 185.667 51.8417 185.667 42.5833C185.667 33.325 178.092 25.75 168.833 25.75C159.575 25.75 152 33.325 152 42.5833C152 51.8417 159.575 59.4167 168.833 59.4167ZM202.5 88.2858C202.5 81.4683 198.46 75.4083 192.232 72.715C185.078 69.6008 177.166 67.8333 168.833 67.8333C165.551 67.8333 162.437 68.17 159.323 68.675C162.689 74.3983 164.625 80.9633 164.625 87.9492V101.5H202.5V88.2858ZM101.5 0.5C115.472 0.5 126.75 11.7783 126.75 25.75C126.75 39.7217 115.472 51 101.5 51C87.5283 51 76.25 39.7217 76.25 25.75C76.25 11.7783 87.5283 0.5 101.5 0.5Z"
            fill="#9E9E9E"
        />
    </svg>
);

export const OrganizationsDirectoriesIcon = () => (
    <svg
        width="140"
        height="152"
        viewBox="0 0 153 152"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M118.583 67.5833V0.25H34.4167V33.9167H0.75V151.75H68.0833V118.083H84.9167V151.75H152.25V67.5833H118.583ZM34.4167 134.917H17.5833V118.083H34.4167V134.917ZM34.4167 101.25H17.5833V84.4167H34.4167V101.25ZM34.4167 67.5833H17.5833V50.75H34.4167V67.5833ZM68.0833 101.25H51.25V84.4167H68.0833V101.25ZM68.0833 67.5833H51.25V50.75H68.0833V67.5833ZM68.0833 33.9167H51.25V17.0833H68.0833V33.9167ZM101.75 101.25H84.9167V84.4167H101.75V101.25ZM101.75 67.5833H84.9167V50.75H101.75V67.5833ZM101.75 33.9167H84.9167V17.0833H101.75V33.9167ZM135.417 134.917H118.583V118.083H135.417V134.917ZM135.417 101.25H118.583V84.4167H135.417V101.25Z"
            fill="#9E9E9E"
        />
    </svg>
);

export const SpecialtiesDirectoriesIcon = () => (
    <svg
        width="156"
        height="170"
        viewBox="0 0 169 170"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M151.833 42.9168H109.75V17.6668C109.75 8.4085 102.175 0.833496 92.9168 0.833496H76.0835C66.8252 0.833496 59.2502 8.4085 59.2502 17.6668V42.9168H17.1668C7.9085 42.9168 0.333496 50.4918 0.333496 59.7502V152.333C0.333496 161.592 7.9085 169.167 17.1668 169.167H151.833C161.092 169.167 168.667 161.592 168.667 152.333V59.7502C168.667 50.4918 161.092 42.9168 151.833 42.9168ZM59.2502 85.0002C66.236 85.0002 71.8752 90.6393 71.8752 97.6252C71.8752 104.611 66.236 110.25 59.2502 110.25C52.2643 110.25 46.6252 104.611 46.6252 97.6252C46.6252 90.6393 52.2643 85.0002 59.2502 85.0002ZM84.5002 135.5H34.0002V129.188C34.0002 120.771 50.8335 116.563 59.2502 116.563C67.6668 116.563 84.5002 120.771 84.5002 129.188V135.5ZM92.9168 59.7502H76.0835V17.6668H92.9168V59.7502ZM135 122.875H101.333V110.25H135V122.875ZM135 97.6252H101.333V85.0002H135V97.6252Z"
            fill="#9E9E9E"
        />
    </svg>
);

export const BaseTariffDirectoriesIcon = () => (
    <svg
        width="159"
        height="110"
        viewBox="0 0 169 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.9585 109.624L63.4585 59.0401L97.1252 92.7068L168.667 12.2435L156.799 0.375977L97.1252 67.4568L63.4585 33.7901L0.333496 96.9993L12.9585 109.624Z"
            fill="#9E9E9E"
        />
    </svg>
);

export const PersonsCVDirectoriesIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="192"
        height="203"
        fill="none"
        viewBox="0 0 202 203"
    >
        <path
            d="M176.75 42.584h16.833v117.833H176.75V42.583Zm-33.667 0h16.834v117.833h-16.834V42.583Zm-25.25 0h-101c-4.629 0-8.416 3.787-8.416 8.416v101c0 4.629 3.787 8.417 8.416 8.417h101c4.63 0 8.417-3.788 8.417-8.417V51c0-4.629-3.787-8.416-8.417-8.416Zm-8.416 100.999H25.25V59.417h84.167v84.166Z"
            fill="#9E9E9E"
        />
        <path
            d="M67.333 100.574c9.065 0 16.413-7.348 16.413-16.412 0-9.065-7.348-16.413-16.413-16.413-9.064 0-16.412 7.348-16.412 16.413 0 9.064 7.348 16.412 16.412 16.412Zm32.741 29.122c0-10.942-21.799-16.413-32.74-16.413-10.942 0-32.742 5.471-32.742 16.413v5.471h65.482v-5.471Z"
            fill="#9E9E9E"
        />
    </svg>
);
