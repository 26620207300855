import { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { addPosition, editPosition } from 'redux/thunks/positionsThunks';
import useValidator from 'hooks/useValidator';
import Input from 'components/Input';
import Button from 'components/Button';
import styles from 'components/ModalsContent/PositionContent/styles.module.scss';

const PositionContent = ({ initialValues = {}, closeModal = () => {} }) => {
    const { id, name = '' } = initialValues;
    const [positionValue, setPositionValue] = useState(name);
    const [submitTouched, setSubmitTouched] = useState(false);
    const dispatch = useDispatch();

    const onSave = () => {
        if (isValid) {
            if (id) {
                dispatch(editPosition(id, positionValue, closeModal));
            } else {
                dispatch(addPosition(positionValue, closeModal));
            }
        }
        setSubmitTouched(true);
    };

    const { isValid, validator } = useValidator({
        positionValue,
    });

    return (
        <div className={styles.position_content}>
            <Input
                value={positionValue}
                onChange={setPositionValue}
                placeholder="Наименование должности"
                error={!validator.positionValue && submitTouched}
            />
            <div className={styles.buttons_container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                />
                <Button text="Сохранить" onClick={onSave} />
            </div>
        </div>
    );
};

PositionContent.propTypes = {
    initialValues: shape({ string }),
    closeModal: func,
};
export default PositionContent;
