import dayjs from 'dayjs';
import { projectType, shortDate, numbersDepth } from './tableHandler';

export const randomId = () => +`${Math.random()}`.split('.')[1];

export const getCalendarContent = (data) => {
    const aggregatedEntity = {};
    Object.entries(data)?.forEach((item) => {
        // eslint-disable-next-line prefer-destructuring
        aggregatedEntity[dayjs(new Date(item[0])).toISOString()] = item[1];
    });

    return aggregatedEntity;
};

export const countQuarters = (value, qrt) => {
    if (qrt === 'firstQuarter') {
        return value < 4;
    }
    if (qrt === 'secondQuarter') {
        return value > 3 && value < 7;
    }
    if (qrt === 'thirdQuarter') {
        return value > 6 && value < 10;
    }
    if (qrt === 'fourthQuarter') {
        return value > 9;
    }
    if (qrt === 'firstHalfYear') {
        return value < 7;
    }
    if (qrt === 'secondHalfYear') {
        return value > 6;
    }
    return value;
};

export const divideIntoQuarters = (data, quart) => {
    return {
        ...data,
        projectTypes: data.projectTypes.map((typeProj) => ({
            ...typeProj,
            projects: typeProj.projects.map((project) => ({
                ...project,
                totalByPeriods: project.totalByPeriods.filter((item) =>
                    countQuarters(item.month, quart)
                ),
                planTotal: project.totalByPeriods.reduce(
                    (prev, item) =>
                        countQuarters(item.month, quart)
                            ? prev + item.sumPlanMonth
                            : prev + 0,
                    0
                ),
                factTotal: project.totalByPeriods.reduce(
                    (prev, item) =>
                        countQuarters(item.month, quart)
                            ? prev + item.sumFactMonth
                            : prev + 0,
                    0
                ),
            })),
            totalByType: typeProj.totalByType.filter((item) =>
                countQuarters(item.month, quart)
            ),
            totalPlanSum: typeProj.totalByType.reduce(
                (prev, item) =>
                    countQuarters(item.month, quart)
                        ? prev + item.sumPlanMonth
                        : prev + 0,
                0
            ),
            totalFactSum: typeProj.totalByType.reduce(
                (prev, item) =>
                    countQuarters(item.month, quart)
                        ? prev + item.sumFactMonth
                        : prev + 0,
                0
            ),
        })),
        totalByTypes: data.totalByTypes.filter((item) =>
            countQuarters(item.month, quart)
        ),
        totalPlanSumByAllTypes: data.totalByTypes.reduce(
            (prev, item) =>
                countQuarters(item.month, quart)
                    ? prev + item.sumFactMonth
                    : prev + 0,
            0
        ),
        totalFactSumByAllTypes: data.totalByTypes.reduce(
            (prev, item) =>
                countQuarters(item.month, quart)
                    ? prev + item.sumFactMonth
                    : prev + 0,
            0
        ),
    };
};

export const toDate = (dateStr) => {
    if (dateStr) {
        const [day, month, year] = dateStr.split('.');
        return new Date(year, month - 1, day);
    }
    return null;
};

export const getInitialValueObj = (value, list) => {
    return list?.length ? list.find((el) => el.value === value) : {};
};

export const getYearsValues = () => {
    const years = [];
    for (let i = 2000; i <= new Date().getFullYear(); i++) {
        years.unshift({ id: i, value: i });
    }
    return years;
};

export const getValueOrNil = (value) => {
    return value || value === 0 ? value : '';
};

export const getFio = (value, onlyName) => {
    const reg = /[^a-zA-ZА-Яа-яЁё\s-]/gi;

    let newValueArr = value.split(' ').map((item) => {
        if (item[0] === '-') {
            return '';
        }
        return item
            ? item
                  .split('-')
                  .map((el) =>
                      el[0]
                          ? el[0].toUpperCase() + el.slice(1).toLowerCase()
                          : el.toLowerCase()
                  )
                  .join('-')
            : '';
    });

    if (newValueArr[0] === '') newValueArr = newValueArr.slice(1);
    if (onlyName) {
        if (newValueArr.length > 1) newValueArr = newValueArr.slice(0, 1);
    } else if (newValueArr.length > 2) newValueArr = newValueArr.slice(0, 2);

    return newValueArr.join(' ').replace(reg, '');
};

export const isEmail = (value) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(value);
};

export const editSort = (header, sort, setSort) => {
    if (sort.property === header) {
        if (sort.order === '') setSort({ property: header, order: 'asc' });
        else if (sort.order === 'asc')
            setSort({ property: header, order: 'desc' });
        else setSort({ property: '', order: '' });
    } else {
        setSort({ property: header, order: 'asc' });
    }
};

export const saveFile = (filename, data, type) => {
    const blob = new Blob([data], { type });
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
};

const getNestedProperty = (arr, param, caseName) => {
    switch (`${caseName}_${param}`) {
        case 'PRODUCTION_PLANS_organizationName':
            return arr.map((el) => ({
                ...el,
                organizationName: el.person.organizationName,
            }));
        case 'PRODUCTION_PLANS_specialityName':
            return arr.map((el) => ({
                ...el,
                specialityName: el.person.specialityName,
            }));
        case 'PRODUCTION_PLANS_managerFullName':
            return arr.map((el) => ({
                ...el,
                managerFullName: el.project.managerFullName,
            }));
        case 'PRODUCTION_PLANS_projectTypes':
            return arr.map((el) => ({
                ...el,
                projectTypes: projectType[el.project.type],
            }));
        case 'PRODUCTION_PLANS_personFullName':
            return arr.map((el) => ({
                ...el,
                personFullName: el.person.fullName,
            }));
        case 'PRODUCTION_PLANS_projectName':
            return arr.map((el) => ({
                ...el,
                projectName: el.project.name,
            }));
        case 'PRODUCTION_PLANS_initialBudget':
            return arr.map((el) => ({
                ...el,
                initialBudget: numbersDepth(el.project.initialBudget),
            }));
        case 'PRODUCTION_PLANS_tariffRateHour':
            return arr.map((el) => ({
                ...el,
                tariffRateHour: numbersDepth(el.tariffRateHour),
            }));
        case 'PRODUCTION_PLANS_tariffRateDay':
            return arr.map((el) => ({
                ...el,
                tariffRateDay: numbersDepth(el.tariffRateDay),
            }));
        case 'PROJECTS_type':
            return arr.map((el) => ({
                ...el,
                type: projectType[el.type],
            }));
        case 'PROJECTS_initialBudget':
            return arr.map((el) => ({
                ...el,
                initialBudget: numbersDepth(el.initialBudget),
            }));
        case 'PROJECTS_managerName':
            return arr.map((el) => ({
                ...el,
                managerName: el.manager.fullName,
            }));
        case 'PROJECTS_dateStart':
            return arr.map((el) => ({
                ...el,
                dateStart: shortDate(el.dateStart),
            }));
        case 'PROJECTS_dateEnd':
            return arr.map((el) => ({
                ...el,
                dateEnd: shortDate(el.dateEnd),
            }));
        case 'PERSONS_CV_competenceLevel':
            return arr.map((el) => ({
                ...el,
                competenceLevel: el.person.competenceLevel,
            }));
        case 'PERSONS_CV_specialityName':
            return arr.map((el) => ({
                ...el,
                specialityName: el.person.specialityName,
            }));
        default:
            return arr;
    }
};

export const getUniqArr = (arr, param, caseName) => {
    const newArr = [...getNestedProperty(arr, param, caseName)];
    return newArr.filter((item, pos, array) => {
        return (
            array
                .map((mapItem) => {
                    return mapItem[param];
                })
                .indexOf(item[param]) === pos &&
            (item[param] || item[param] === 0)
        );
    });
    // Возвращает массив по уникальному не пустому свойству входящих объектов
};

export const getSortMethod = (a, b, fieldType, key, order) => {
    if (
        fieldType === 'number' ||
        fieldType === 'workload' ||
        fieldType === 'deviation'
    ) {
        return order === 'asc' ? a[key] - b[key] : b[key] - a[key];
    }
    if (fieldType === 'budget') {
        return order === 'asc'
            ? Number(a[key].replace(/\ /g, '')) -
                  Number(b[key].replace(/\ /g, ''))
            : Number(b[key].replace(/\ /g, '')) -
                  Number(a[key].replace(/\ /g, ''));
    }
    if (fieldType === 'date') {
        return order === 'asc'
            ? new Date(a[key].replace(/(\d+[.])(\d+[.])/, '$2$1')) -
                  new Date(b[key].replace(/(\d+[.])(\d+[.])/, '$2$1'))
            : new Date(b[key].replace(/(\d+[.])(\d+[.])/, '$2$1')) -
                  new Date(a[key].replace(/(\d+[.])(\d+[.])/, '$2$1'));
    }
    return strSorting(a, b, key, order);
};

export const strSorting = (a, b, key, order) => {
    const nameA = a[key]?.toLowerCase();
    const nameB = b[key]?.toLowerCase();
    if (order === 'asc') {
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    }
    if (nameA > nameB) return -1;
    if (nameA < nameB) return 1;
    return 0;
};
