import {
    ADD_USER_DATA,
    DELETE_USER_DATA,
    EDIT_USER_DATA,
    GET_USERS_DATA,
    GET_FILTER_USERS_DATA,
} from 'redux/types';

export const addUserData = (data) => ({
    type: ADD_USER_DATA,
    payload: data,
});

export const deleteUserData = (id) => ({
    type: DELETE_USER_DATA,
    payload: id,
});

export const editUserData = (data) => ({
    type: EDIT_USER_DATA,
    payload: data,
});

export const getUsersData = (data) => ({
    type: GET_USERS_DATA,
    payload: data,
});

export const getUsersDropDownData = (data) => ({
    type: GET_FILTER_USERS_DATA,
    payload: data,
});
