import classNames from 'classnames/bind';
import { func, string } from 'prop-types';
import EditIcon from 'components/svg/editIcon';
import PersonIcon from 'components/svg/personIcon';
import DeleteIcon from 'components/svg/deleteIcon';
import InfoIcon from 'components/svg/infoIcon';
import LoockIcon from 'components/svg/loock';
import styles from 'components/Tables/Table/TableBody/styles.module.scss';
import LockPersonIcon from 'components/svg/lockPersonIcon';
import AddPersonIcon from 'components/svg/addPerson';

const cx = classNames.bind(styles);

export const ButtonsForTable = ({
    iconName = '',
    className = '',
    cklick = () => {},
    text = '',
}) => {
    const inconButton = (icon) => {
        switch (icon) {
            case 'edit': {
                return <EditIcon />;
            }
            case 'delete': {
                return <DeleteIcon />;
            }
            case 'info': {
                return <InfoIcon />;
            }
            case 'loockProdPlan': {
                return <PersonIcon />;
            }
            case 'loock': {
                return <LoockIcon />;
            }
            case 'comment': {
                return <EditIcon />;
            }
            case 'lockPerson': {
                return <LockPersonIcon />;
            }
            case 'addPerson': {
                return <AddPersonIcon />;
            }
            default:
                return null;
        }
    };
    return (
        <button className={cx(className)} type="button" onClick={cklick}>
            {inconButton(iconName)} {text}
        </button>
    );
};

ButtonsForTable.propTypes = {
    iconName: string,
    className: string,
    cklick: func,
    text: string,
};
