const BlockIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        viewBox="1 0 22 22"
    >
        <path d="M12 21q-1.875 0-3.512-.712-1.638-.713-2.85-1.926-1.213-1.212-1.926-2.85Q3 13.875 3 12t.712-3.513q.713-1.637 1.926-2.85 1.212-1.212 2.85-1.925Q10.125 3 12 3t3.513.712q1.637.713 2.85 1.925 1.212 1.213 1.925 2.85Q21 10.125 21 12t-.712 3.512q-.713 1.638-1.925 2.85-1.213 1.213-2.85 1.926Q13.875 21 12 21Zm0-1q3.35 0 5.675-2.325Q20 15.35 20 12q0-1.475-.525-2.825T18 6.725L6.725 18q1.1.975 2.45 1.488Q10.525 20 12 20Zm-6-2.725L17.275 6Q16.2 5 14.838 4.5 13.475 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 1.475.513 2.825.512 1.35 1.487 2.45Z" />
    </svg>
);

export default BlockIcon;
