import {
    ADD_USER_DATA,
    DELETE_USER_DATA,
    EDIT_USER_DATA,
    GET_USERS_DATA,
    GET_FILTER_USERS_DATA,
} from 'redux/types';

const defaultState = {
    data: [],
    dropDownValuesFullName: [],
    dropDownValuesUserName: [],
    dropDownValuesRole: [],
    dropDownValuesPosition: [],
};

export const users = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_USER_DATA:
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case DELETE_USER_DATA:
            return {
                ...state,
                data: state.data.filter((item) => item.id !== action.payload),
            };
        case EDIT_USER_DATA:
            return {
                ...state,
                data: [
                    ...state.data.filter(
                        (item) => item.id !== action.payload.id
                    ),
                    action.payload,
                ],
            };
        case GET_USERS_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case GET_FILTER_USERS_DATA:
            return {
                ...state,
                dropDownValuesFullName: action.payload.map((elem) => ({
                    id: elem.id,
                    value: elem.fullName,
                })),
                dropDownValuesUserName: action.payload.map((elem) => ({
                    id: elem.id,
                    value: elem.username,
                })),
                dropDownValuesRole: action.payload.reduce(
                    ([acc, RoleUniq], { role }) => {
                        if (!RoleUniq.has(role)) {
                            RoleUniq.add(role);
                            return [
                                [...RoleUniq].map((elem, index) => ({
                                    id: index,
                                    value: elem,
                                })),
                                RoleUniq,
                            ];
                        }
                        return [acc, RoleUniq];
                    },
                    [[], new Set()]
                )[0],
                dropDownValuesPosition: action.payload.reduce(
                    ([acc, PositionUniq], { position }) => {
                        if (!PositionUniq.has(position)) {
                            PositionUniq.add(position);
                            return [
                                [...PositionUniq].map((elem, index) => ({
                                    id: index,
                                    value: elem,
                                })),
                                PositionUniq,
                            ];
                        }
                        return [acc, PositionUniq];
                    },
                    [[], new Set()]
                )[0],
            };
        default:
            return state;
    }
};
