import {
    ADD_POSITION_DATA,
    DELETE_POSITION_DATA,
    EDIT_POSITION_DATA,
    GET_POSITIONS_DATA,
} from 'redux/types';

export const addPositionData = (data) => ({
    type: ADD_POSITION_DATA,
    payload: data,
});

export const deletePositionData = (id) => ({
    type: DELETE_POSITION_DATA,
    payload: id,
});

export const editPositionData = (data) => ({
    type: EDIT_POSITION_DATA,
    payload: data,
});

export const getPositionsData = (data) => ({
    type: GET_POSITIONS_DATA,
    payload: data,
});
