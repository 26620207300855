import API from 'api/api';
import { logout } from 'redux/actions/auth';
import { useToastify } from 'helpers/toastify/useToastify';
import { getJiraProjectsData } from 'redux/actions/jiraProjects';
import { getPersonsJira } from 'redux/actions/jiraPersons';

export const getJiraPersons =
    (setIsLoading = () => {}, id) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            const res = await API.jiraPersons.getPersons(id);
            dispatch(getPersonsJira(res.data.content));
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoading(false);
        }
    };

export const addJiraPersons =
    (data, setIsLoading = () => {}) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            let isImport = false;
            const res = await API.jiraPersons.postPerson(data);
            for (const keys in res.data) {
                if (res.data[keys] !== 0) {
                    isImport = true;
                }
            }
            if (isImport)
                useToastify('Произведен импорт сотрудников', 'success');
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify('Импорт не произведен', 'error');
        }
    };

export const getJiraProjects =
    (setIsLoading = () => {}, id) =>
    (dispatch) => {
        setIsLoading(true);
        API.jiraProjects
            .getProjects(id)
            .then((res) => {
                dispatch(getJiraProjectsData(res.data.content));
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            });
    };

export const addJiraProjects =
    (data, setIsLoading = () => {}) =>
    async (dispatch) => {
        setIsLoading(true);
        try {
            let isImport = false;
            const res = await API.jiraProjects.postProjects(data);
            for (const keys in res.data) {
                if (res.data[keys] !== 0) {
                    isImport = true;
                }
            }
            if (isImport) useToastify('Произведен импорт проектов', 'success');
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify('Импорт не произведен', 'error');
        }
        setIsLoading(false);
    };
