import { Fragment } from 'react';
import { bool, func, shape } from 'prop-types';
import { agetostr } from 'helpers/dateHelper';
import { cvModel } from 'mock/mockData';
import CvTag from 'components/Pages/CVPage/ToolTag';
import CVChangeForm from 'components/Pages/CVPage/CVChangeForm';
import { randomId } from 'helpers/editFormsValues';
import classNames from 'classnames/bind';
import CVInput from 'components/Pages/CVPage/CVInput';
import styles from './styles.module.scss';

const CVPersonInfo = ({ personInfo, setPersonInfo = () => {}, isEditing }) => {
    const cx = classNames.bind(styles);

    const notEditingFields = (field) =>
        field === 'experience' ? (
            agetostr(personInfo[field])
        ) : field === 'tools' ? (
            personInfo[field].map((tag) => (
                <Fragment key={randomId()}>
                    <CvTag grayTags tagName={tag} />
                </Fragment>
            ))
        ) : (
            <span>{personInfo[field].join(', ')}</span>
        );

    const changeExperience = (value) => {
        setPersonInfo({ ...personInfo, experience: value });
    };

    return (
        <div className={cx('person-info')}>
            {Object.keys(cvModel).map((field) => (
                <div className={cx('person-info__field')} key={field}>
                    <div className={cx('field_block')}>
                        <div className={cx('field_block_title')}>
                            {cvModel[field].title}
                        </div>
                        <div className={cx('field_block_value')}>
                            {isEditing ? (
                                field !== 'experience' ? (
                                    <CVChangeForm
                                        existingTagsList={personInfo[field]}
                                        info={personInfo}
                                        fieldName={field}
                                        setInfo={setPersonInfo}
                                        isEditing={isEditing}
                                        grayTags={field === 'tools'}
                                    />
                                ) : (
                                    <CVInput
                                        inputValue={`${personInfo.experience}`}
                                        setInputValue={changeExperience}
                                        hidePlus
                                    />
                                )
                            ) : (
                                notEditingFields(field)
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CVPersonInfo;

CVPersonInfo.propTypes = {
    personInfo: shape({}),
    setPersonInfo: func,
    isEditing: bool,
};
