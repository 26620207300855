import {
    GET_PERSONS_CV_DATA,
    SET_PERSONS_CV_FILTER_STATUS,
    DELETE_PERSON_CV,
    ADD_PERSON_CV,
    RESET_DATA_PESONS_CV,
    GET_PEROSN_CV_DATA_DROPDOWN,
} from 'redux/types';

const defaultState = {
    data: [],
    isActive: true,
    personsCVDropDown: [],
    programmingLanguageDropDown: [],
    profSkillsDropDown: [],
    toolsDropDown: [],
    OSDropDown: [],
    foreignLanguagesDropDown: [],
};

export const personsCv = (state = defaultState, action) => {
    switch (action.type) {
        case GET_PERSONS_CV_DATA:
            return {
                ...state,
                data: action.payload.map((personInfo) => ({
                    ...personInfo,
                    fullName: personInfo.person.fullName,
                })),
            };
        case GET_PEROSN_CV_DATA_DROPDOWN:
            return {
                ...state,
                personsCVDropDown: action.payload.reduce(
                    ([acc, personIds], { person: { id, fullName: value } }) => {
                        if (!personIds.has(id)) {
                            personIds.add(id);
                            return [[...acc, { id, value }], personIds];
                        }
                        return [acc, personIds];
                    },
                    [[], new Set()]
                )[0],
                programmingLanguageDropDown: action.payload.reduce(
                    ([acc, programmLanguageUniq], { programmingLanguage }) => {
                        if (programmingLanguage.length > 0) {
                            programmingLanguage.forEach((elem) => {
                                if (!programmLanguageUniq.has(elem)) {
                                    programmLanguageUniq.add(elem);
                                }
                            });
                            return [
                                [...programmLanguageUniq].map(
                                    (elem, index) => ({
                                        id: index,
                                        value: elem,
                                    })
                                ),
                                programmLanguageUniq,
                            ];
                        }
                        return [acc, programmLanguageUniq];
                    },
                    [[], new Set()]
                )[0],
                profSkillsDropDown: action.payload.reduce(
                    ([acc, profSkills], { professionalSkills }) => {
                        if (professionalSkills.length > 0) {
                            professionalSkills.forEach((elem) => {
                                if (!profSkills.has(elem)) {
                                    profSkills.add(elem);
                                }
                            });
                            return [
                                [...profSkills].map((elem, index) => ({
                                    id: index,
                                    value: elem,
                                })),
                                profSkills,
                            ];
                        }
                        return [acc, profSkills];
                    },
                    [[], new Set()]
                )[0],
                toolsDropDown: action.payload.reduce(
                    ([acc, toolsUniq], { tools }) => {
                        if (tools.length > 0) {
                            tools.forEach((elem) => {
                                if (!toolsUniq.has(elem)) {
                                    toolsUniq.add(elem);
                                }
                            });
                            return [
                                [...toolsUniq].map((elem, index) => ({
                                    id: index,
                                    value: elem,
                                })),
                                toolsUniq,
                            ];
                        }
                        return [acc, toolsUniq];
                    },
                    [[], new Set()]
                )[0],
                OSDropDown: action.payload.reduce(
                    ([acc, OSUniq], { operatingSystems }) => {
                        if (operatingSystems.length > 0) {
                            operatingSystems.forEach((elem) => {
                                if (!OSUniq.has(elem)) {
                                    OSUniq.add(elem);
                                }
                            });
                            return [
                                [...OSUniq].map((elem, index) => ({
                                    id: index,
                                    value: elem,
                                })),
                                OSUniq,
                            ];
                        }
                        return [acc, OSUniq];
                    },
                    [[], new Set()]
                )[0],
                foreignLanguagesDropDown: action.payload.reduce(
                    ([acc, FLUniq], { foreignLanguages }) => {
                        if (foreignLanguages.length > 0) {
                            foreignLanguages.forEach((elem) => {
                                if (!FLUniq.has(elem)) {
                                    FLUniq.add(elem);
                                }
                            });
                            return [
                                [...FLUniq].map((elem, index) => ({
                                    id: index,
                                    value: elem,
                                })),
                                FLUniq,
                            ];
                        }
                        return [acc, FLUniq];
                    },
                    [[], new Set()]
                )[0],
            };
        case SET_PERSONS_CV_FILTER_STATUS:
            return {
                ...state,
                isActive: action.payload,
            };
        case ADD_PERSON_CV:
            return {
                ...state,
                data: [action.payload, ...state.data],
            };
        case DELETE_PERSON_CV:
            return {
                ...state,
                data: state.data.filter((item) => item.id !== action.payload),
            };
        case RESET_DATA_PESONS_CV:
            return defaultState;
        default:
            return state;
    }
};
