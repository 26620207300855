import React, { useEffect } from 'react';
import { element, string, func } from 'prop-types';

function checkAccessKey() {
    const parts = window.location.href;
    const result = {};
    parts
        .replace(`${getCurrentUrl()}/#`, '')
        .split('&')
        .forEach((el) => {
            // eslint-disable-next-line prefer-destructuring
            result[decodeURIComponent(el).split('=')[0]] =
                decodeURIComponent(el).split('=')[1];
        });

    return result;
}

function getAuthUrl(clientID, redirectUrl, type) {
    const yLink = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${clientID}&redirect_uri=${encodeURIComponent(
        redirectUrl
    )}&display=popup`;
    const glLink = `https://gitlab.codeinside.ru/oauth/authorize?client_id=${clientID}&redirect_uri=${encodeURIComponent(
        redirectUrl
    )}&response_type=code&scope=api`;

    switch (type) {
        case 'yandex':
            return yLink;
        default:
            return glLink;
    }
}

export function getCurrentUrl() {
    let currentUrl = window.location.origin;
    if (currentUrl[currentUrl.length - 1] === '/') {
        currentUrl = currentUrl.slice(0, currentUrl.length - 1);
    }

    return currentUrl;
}

function Login(props) {
    const handleMessageFromPopup = (event) => {
        if (event.data.source === 'login') {
            props.onSuccess(event.data.payload);
        }
    };

    const onClick = function onClick() {
        const currentUrl = getCurrentUrl();
        const requestUrl = getAuthUrl(props.clientID, currentUrl, props.type);
        const h = 650;
        const w = 550;
        const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
        const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
        window.open(
            requestUrl,
            'popup',
            `width=${w},height=${h},top=${y},left=${x}`
        );
        window.addEventListener('message', handleMessageFromPopup, {
            once: true,
        });
    };

    const aki = checkAccessKey();
    const receiver = window.parent !== window ? window.parent : window.opener;

    useEffect(() => {
        if (aki && receiver) {
            receiver?.postMessage(
                {
                    source: 'login',
                    payload: aki,
                },
                window.location.origin
            );
            window.close();
        }
    });

    return React.createElement(
        'div',
        null,
        React.cloneElement(props.children, {
            onClick,
        }),
        null
    );
}
export default Login;
Login.propTypes = {
    children: element,
    type: string,
    clientID: string,
    onSuccess: func,
};
