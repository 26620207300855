import { bool, func, string } from 'prop-types';
import ComponentTitle from 'components/ComponentTitle';
import classNames from 'classnames/bind';
import errorIcon from 'icons/error.svg';
import styles from './styles.module.scss';

const Textarea = ({
    disabled = false,
    onChange = () => {},
    title = '',
    value = '',
    error = false,
    success = false,
    placeholder = '',
}) => {
    const cx = classNames.bind(styles);

    const handleChange = (e) => {
        onChange(e.currentTarget.value);
    };

    return (
        <div className={cx('textarea_container')}>
            <ComponentTitle title={title} error={error} success={success} />
            <div className={cx('textarea_block')}>
                <textarea
                    readOnly={disabled}
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                />
                {error ? (
                    <div className={cx('error_icon')}>
                        <img src={errorIcon} alt="error" />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

Textarea.propTypes = {
    title: string,
    disabled: bool,
    success: bool,
    error: bool,
    onChange: func,
    value: string,
    placeholder: string,
};
export default Textarea;
