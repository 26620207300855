const JiraIcon = () => (
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M26.5008 35.8354C26.5008 39.0866 26.5008 42.3378 26.5008 45.589C26.5008 46.0312 26.5008 46.0096 26.0692 45.9933C24.7367 45.9434 23.4774 45.6022 22.3152 44.9482C19.6146 43.4272 18.009 41.0944 17.6401 37.9536C17.501 36.7691 17.5837 35.5567 17.5667 34.3575C17.5631 34.1036 17.5544 33.8486 17.5688 33.5952C17.5821 33.3633 17.4939 33.2913 17.2717 33.2929C16.2541 33.3018 15.2366 33.2871 14.2191 33.2981C12.028 33.3218 10.0468 32.6898 8.33658 31.2751C6.43134 29.6989 5.3435 27.6448 5.05102 25.1517C5.02075 24.8914 5.01613 24.628 5.00074 24.3662C4.99201 24.2137 5.06077 24.137 5.21266 24.1407C5.28963 24.1428 5.3666 24.1396 5.44356 24.1396C9.84723 24.1375 14.2509 24.138 18.6546 24.1317C20.4756 24.1291 22.2962 24.1107 24.1173 24.1028C24.3477 24.1018 24.5786 24.1123 24.8085 24.1312C25.8383 24.2164 26.4941 24.9345 26.5013 26.0039C26.51 27.2831 26.5034 28.5627 26.5039 29.8424C26.5039 31.8402 26.5039 33.8386 26.5039 35.8364H26.5003L26.5008 35.8354Z"
            fill="#0000FF"
        />
        <path
            d="M24.8172 14.0484C28.0068 14.0484 31.1964 14.0511 34.386 14.0463C34.9782 14.0453 35.4703 14.2435 35.8577 14.7104C36.163 15.0789 36.2605 15.5105 36.26 15.9806C36.2574 20.3537 36.2569 24.7264 36.2559 29.0995C36.2554 31.2467 36.2554 33.3938 36.2543 35.5405C36.2543 35.9348 36.2528 35.9458 35.8679 35.9285C33.8898 35.8386 32.1185 35.1656 30.594 33.8717C28.718 32.2798 27.6056 30.2383 27.3793 27.7426C27.289 26.7426 27.3341 25.73 27.3208 24.7232C27.3156 24.3378 27.3208 23.9519 27.3197 23.5666C27.3192 23.2133 27.3182 23.2117 26.9811 23.2117C25.9548 23.2106 24.9286 23.2001 23.9028 23.2138C21.4609 23.2453 19.3391 22.4257 17.5672 20.7023C15.8175 19.001 14.8938 16.9043 14.7989 14.4307C14.7846 14.06 14.7979 14.0542 15.1715 14.0542C18.3867 14.0542 21.602 14.0542 24.8172 14.0542V14.0484Z"
            fill="#0000FF"
        />
        <path
            d="M34.6031 4.00034C37.8009 4.00034 40.9992 3.99929 44.197 4.00087C45.035 4.00087 45.7282 4.51873 45.9165 5.30209C45.9771 5.55445 45.9966 5.82258 45.9966 6.08335C46.0007 12.5506 46.0002 19.0173 45.9997 25.4845C45.9997 25.8783 45.9725 25.8804 45.5825 25.8588C44.4423 25.7952 43.3499 25.5355 42.3113 25.0502C40.6036 24.2516 39.271 23.0177 38.312 21.3668C37.4417 19.8684 37.0476 18.2397 37.0712 16.4979C37.0846 15.5168 37.0738 14.5353 37.0733 13.5537C37.0733 13.2894 36.9477 13.1569 36.6967 13.1562C35.6535 13.1562 34.6103 13.1568 33.5671 13.1562C31.4771 13.1541 29.5878 12.5274 27.9478 11.1936C26.0144 9.6211 24.9034 7.56228 24.6017 5.04973C24.5694 4.78108 24.5668 4.50874 24.5499 4.23798C24.5396 4.07185 24.614 3.99403 24.7772 4.00087C24.8624 4.00455 24.9481 4.00087 25.0338 4.00087C28.2234 4.00087 31.4125 4.00087 34.6021 4.00087L34.6031 4.00034Z"
            fill="#0000FF"
        />
    </svg>
);
export default JiraIcon;
