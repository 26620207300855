import {
    ADD_PRODUCTION_PLANS_DATA,
    DELETE_PRODUCTION_PLANS_DATA,
    DELETE_PRODUCTION_PLAN_BY_DATE_DATA,
    EDIT_PRODUCTION_PLANS_DATA,
    GET_PRODUCTION_PLANS_DATA,
    CLEAR_PLANS_DATA,
    GET_PRODUCTION_PLANS_DATA_PERSON,
    SET_CURRENT_DATE,
    SET_INITIAL_SUMS,
    SET_SIZE_PRODUCTION_PLANS_TABLES,
    SET_UNLOAD_PRODUCTION_PLAN_ERROR,
} from 'redux/types';

export const addProductionPlansData = (data) => ({
    type: ADD_PRODUCTION_PLANS_DATA,
    payload: data,
});

export const deleteProductionPlansData = (id) => ({
    type: DELETE_PRODUCTION_PLANS_DATA,
    payload: id,
});

export const deleteProductionPlansByDateData = (id) => ({
    type: DELETE_PRODUCTION_PLAN_BY_DATE_DATA,
    payload: id,
});

export const editProductionPlansData = (data) => ({
    type: EDIT_PRODUCTION_PLANS_DATA,
    payload: data,
});

export const getProductionPlansData = (data) => ({
    type: GET_PRODUCTION_PLANS_DATA,
    payload: data,
});

export const getProductionPlansPerson = (data) => ({
    type: GET_PRODUCTION_PLANS_DATA_PERSON,
    payload: data,
});

export const clearProductionPlans = () => ({
    type: CLEAR_PLANS_DATA,
});

export const setCurrentDate = (data) => ({
    type: SET_CURRENT_DATE,
    payload: data,
});

export const setInitialSums = () => ({
    type: SET_INITIAL_SUMS,
});

export const setSizeProductionPlansTables = (data) => ({
    type: SET_SIZE_PRODUCTION_PLANS_TABLES,
    payload: data,
});

export const setUnlodaProductionPlanEroror = (data) => ({
    type: SET_UNLOAD_PRODUCTION_PLAN_ERROR,
    payload: data,
});
