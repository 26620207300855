import { useState, useEffect } from 'react';
import { func, number, string } from 'prop-types';
import API from 'api/api';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { resolvePlan } from 'redux/thunks/productionPlansThunks';

import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

const JournalModal = ({
    closeModal = () => {},
    id,
    referenceTable = '',
    getProductionPlans = () => {},
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorsList, setErrorsList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        API.errorJour
            .getErrorJour({
                referenceTable,
                referenceIds: id,
            })
            .then((res) => setErrorsList(res.data))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const resolveErrors = () => {
        dispatch(resolvePlan(id, getProductionPlans, closeModal));
    };

    return (
        <div className={styles.modal__row}>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    {errorsList.length ? (
                        <table className={styles.table}>
                            <thead>
                                <tr className={styles.table__header_row}>
                                    <th className={styles.table__header_cell}>
                                        Источник ошибки
                                    </th>
                                    <th className={styles.table__header_cell}>
                                        Комментарий к ошибке
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {errorsList.map((error, index) => (
                                    <tr
                                        key={index}
                                        className={styles.table__body_row}
                                    >
                                        <td className={styles.table__body_cell}>
                                            {error.object}
                                        </td>
                                        <td className={styles.table__body_cell}>
                                            {error.comment}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className={styles.time_modal__no_content}>
                            Нет данных
                        </div>
                    )}
                    <div className={styles.buttons_container}>
                        <Button
                            buttonStyle="outlined"
                            text="Закрыть"
                            onClick={closeModal}
                            wide={!errorsList.length}
                        />
                        {errorsList.length ? (
                            <Button text="Сброс" onClick={resolveErrors} />
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
};

JournalModal.propTypes = {
    closeModal: func,
    id: number,
    referenceTable: string,
    getProductionPlans: func,
};

export default JournalModal;
