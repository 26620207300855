import {
    GET_ALL_UNUSED_PERSONS,
    DELETE_UNUSED_PERSON,
    EDIT_UNUSED_PERSON,
    CLEAR_UNUSED_PERSONS,
    SET_SIZE_UNUSED_PERSONS_TABLES,
} from 'redux/types';

export const deleteUnusedPersonData = (id) => ({
    type: DELETE_UNUSED_PERSON,
    payload: id,
});

export const editUnusedPersonData = (data) => ({
    type: EDIT_UNUSED_PERSON,
    payload: data,
});

export const getUnusedPersonsData = (data) => ({
    type: GET_ALL_UNUSED_PERSONS,
    payload: data,
});

export const clearUnusedPersonsData = () => ({
    type: CLEAR_UNUSED_PERSONS,
});

export const setSizeUnusedPersonTables = (data) => ({
    type: SET_SIZE_UNUSED_PERSONS_TABLES,
    payload: data,
});
