export const setUserInfo = (authResult, provider) => {
    const authTokenEncoded = window.btoa(authResult?.data?.entity?.token);
    localStorage.setItem('authToken', authTokenEncoded);
    localStorage.setItem('authType', provider);
    const refreshTokenEncoded = window.btoa(
        authResult?.data?.entity?.refreshToken
    );
    localStorage.setItem('refreshToken', refreshTokenEncoded);
    localStorage.setItem('isRefresh', false);
};
