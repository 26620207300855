import API from 'api/api';
import { logout } from 'redux/actions/auth';
import {
    getPersonsInfoData,
    addPersonCv,
    getPresonInfoDropDown,
    setPersonsCvFilterStatus,
} from 'redux/actions/personsCv';
import { useToastify } from 'helpers/toastify/useToastify';

export const getAllCVPersons =
    (filters, sort, setIsLoading = () => {}, closeModal = () => {}) =>
    async (dispatch) => {
        setIsLoading(true);
        const query = { ...filters };

        if (sort?.property === 'atCreated' || sort?.property === 'atUpdated') {
            query.sort = `${sort.property},${sort.order}`;
        } else if (sort?.property || sort?.order) {
            query.sort = `person.${sort.property},${sort.order}`;
        }
        try {
            const res = await API.cvPersons.getAllPersonInfo({ ...query });
            dispatch(setPersonsCvFilterStatus(filters?.isActive));
            dispatch(getPersonsInfoData(res.data.content));
            dispatch(getPresonInfoDropDown(res.data.content));
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
            }
        }
        setIsLoading(false);
        closeModal();
    };

export const getCVPerson =
    (id, setIsLoading = () => {}, closeModal = () => {}) =>
    async (dispatch) => {
        setIsLoading(true);
        const query = { ids: id };
        try {
            const res = await API.cvPersons.getAllPersonInfo({ ...query });
            dispatch(getPersonsInfoData(res.data.content));
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
            }
            useToastify(err?.message ?? 'Ошибка загрузки списка cv', 'error');
        }
        setIsLoading(false);
        closeModal();
    };

export const changeCvComment = (id, comment, getCv) => (dispatch) => {
    API.cvPersons
        .editCommentCV(id, comment)
        .then(() => getCv())
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
            }
            useToastify(
                err?.message ?? `Не удалось изменить комментарий cv №${id}`,
                'error'
            );
        });
};

export const postPersonCv = (data, dispatch, closeModal) => {
    API.cvPersons
        .postPersonCv(data)
        .then((res) => {
            dispatch(addPersonCv(res.data));
            closeModal();
        })
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? `Не удалось добавить cv`, 'error');
        });
};
