import API from 'api/api';
import {
    addPositionData,
    editPositionData,
    getPositionsData,
} from 'redux/actions/positions';
import { useToastify } from 'helpers/toastify/useToastify';
import { logout } from 'redux/actions/auth';

export const getPosition =
    (id, setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        API.positions
            .getAllPositions(id)
            .then((res) => dispatch(getPositionsData(res.data.content)))
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => setIsLoading(false));
    };
export const editPosition =
    (id, positionValue, closeModal = () => {}) =>
    (dispatch) => {
        API.positions
            .editPosition(id, positionValue)
            .then((res) => {
                dispatch(editPositionData(res.data));
                useToastify('Должность изменена', 'success');
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => closeModal());
    };
export const getAllPositions =
    (filters = {}, sort = {}, setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        const query = { ...filters };
        if (sort.property) query.sort = `${sort.property},${sort.order}`;
        API.positions
            .getAllPositions({ ...query })
            .then((res) => dispatch(getPositionsData(res.data.content)))
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => setIsLoading(false));
    };
export const addPosition =
    (positionValue, closeModal = () => {}) =>
    (dispatch) => {
        API.positions
            .addPosition({
                name: positionValue,
            })
            .then((res) => {
                dispatch(addPositionData(res.data));
                useToastify('Должность добавлена', 'success');
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    localStorage.clear();
                    dispatch(logout());
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => closeModal());
    };
