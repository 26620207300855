import {
    ADD_TEAM_PERIOD_DATA,
    DELETE_TEAM_PERIOD_DATA,
    EDIT_TEAM_PERIOD_DATA,
    GET_TEAM_PERIOD_DATA,
} from 'redux/types';

export const addTeamPeriodData = (data) => ({
    type: ADD_TEAM_PERIOD_DATA,
    payload: data,
});

export const deleteTeamPeriodData = (id) => ({
    type: DELETE_TEAM_PERIOD_DATA,
    payload: id,
});

export const editTeamPeriodData = (data) => ({
    type: EDIT_TEAM_PERIOD_DATA,
    payload: data,
});

export const getTeamPeriodData = (data) => ({
    type: GET_TEAM_PERIOD_DATA,
    payload: data,
});
