import { func, number, shape } from 'prop-types';
import JiraLogo from 'components/svg/JiraIcon';
import GitLabIcon from 'components/svg/GitLabIcon';
import styles from './styles.module.scss';

const ModuleCard = ({
    item = {},
    handleClick = () => {},
    activeModule = null,
}) => {
    const getCard = () => {
        if (item.systemId === 2) {
            return (
                <div className={`${styles.card} `}>
                    <div>
                        <JiraLogo />
                    </div>
                    <div className={styles.card__description}>
                        <div
                            className={`${styles.card__description_type} ${
                                item.isActive
                                    ? styles.card_active
                                    : styles.card_disable
                            }`}
                        >
                            Jira
                        </div>
                        <div
                            className={`${styles.card__description_text} ${
                                !item.isActive ? styles.card_disable : ''
                            }`}
                        >
                            {item.description}
                        </div>
                    </div>
                </div>
            );
        }
        if (item.systemId === 1) {
            return (
                <div className={`${styles.card} `}>
                    <GitLabIcon />
                    <div className={styles.card__description}>
                        <div
                            className={`${styles.card__description_type} ${
                                item.isActive
                                    ? styles.card_active
                                    : styles.card_disable
                            }`}
                        >
                            GitLab
                        </div>
                        <div
                            className={`${styles.card__description_text} ${
                                !item.isActive ? styles.card_disable : ''
                            }`}
                        >
                            {item.description}
                        </div>
                    </div>
                </div>
            );
        }
        return '';
    };

    return (
        <div>
            <div
                className={
                    activeModule === item.id ? styles.container_active : ''
                }
                onClick={() => {
                    handleClick(item);
                }}
            >
                {getCard()}
            </div>
        </div>
    );
};
ModuleCard.propTypes = {
    item: shape({}),
    handleClick: func,
    activeModule: number,
};
export default ModuleCard;
