import {
    array,
    arrayOf,
    bool,
    func,
    number,
    oneOfType,
    shape,
    string,
} from 'prop-types';
import { useEffect, useState } from 'react';
import DropdownWrapper from 'components/Dropdown';
import styles from 'components/DropdownList/styles.module.scss';

const DropdownList = ({
    disabled = false,
    error = false,
    isReport = false,
    isReportYear = false,
    withoutDelete = false,
    handelOnOpenChange = () => {},
    items = [],
    isCell = false,
    isInHeader = false,
    multiselect = false,
    selected = {},
    setSelected = () => {},
    title = '',
    selectedOnly = false,
    noMargin = false,
    placeholderInput = '',
    dropdownManualInput = false,
    handleDropdownManualInput = () => {},
}) => {
    const [listsDropIsOpen, setListsDropIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [list, setList] = useState(items);

    useEffect(() => {
        handelOnOpenChange(listsDropIsOpen);
    }, [listsDropIsOpen]);

    useEffect(() => setFilteredList(), [searchValue, items]);
    useEffect(() => setSearchValue(''), [listsDropIsOpen]);

    const isSelected = (id) => {
        if (multiselect) {
            const selectedIds = selected.map((item) => item?.id);
            return selectedIds.includes(id);
        }
        return selected?.id === id;
    };

    const select = (item) => () => {
        if (!isSelected(item?.id)) {
            if (multiselect) {
                setSelected([...selected, item]);
            } else {
                setSelected(item);
                setListsDropIsOpen(false);
            }
        } else if (multiselect) {
            removeSelection(item?.id);
        } else {
            if (!selectedOnly) setSelected({});
            setListsDropIsOpen(false);
        }
    };

    const removeSelection = (id) =>
        setSelected([...selected.filter((item) => item?.id !== id)]);

    const setFilteredList = () => {
        const newList =
            items && searchValue && searchValue.length > 0
                ? items.filter((el) =>
                      String(el.value)
                          .toLowerCase()
                          .includes(String(searchValue).toLowerCase())
                  )
                : items;
        setList(newList);
    };

    const selectedToString = () =>
        typeof selected === 'object'
            ? multiselect
                ? selected.map((item) => item?.value).join('; ')
                : selected?.value
            : selected;

    return (
        <DropdownWrapper
            isOpen={listsDropIsOpen}
            title={title}
            setSelected={setSelected}
            setIsOpen={setListsDropIsOpen}
            selected={selectedToString()}
            initSelected={selected}
            disabled={disabled}
            error={error}
            isReport={isReport}
            isReportYear={isReportYear}
            withoutDelete={withoutDelete}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            listLength={list.length}
            multiselect={multiselect}
            noMargin={noMargin}
            isCell={isCell}
            isInHeader={isInHeader}
            placeholderInput={placeholderInput}
            dropdownManualInput={dropdownManualInput}
            handleDropdownManualInput={handleDropdownManualInput}
        >
            <div className={styles.list}>
                {!disabled &&
                    list.map((item) => (
                        <div
                            key={item.id}
                            onClick={select(item)}
                            className={`${styles.item} ${
                                isSelected(item.id) ? styles.selected : ''
                            }`}
                        >
                            <span>{item.value}</span>
                        </div>
                    ))}
            </div>
        </DropdownWrapper>
    );
};

DropdownList.propTypes = {
    disabled: bool,
    error: bool,
    isReport: bool,
    isReportYear: bool,
    withoutDelete: bool,
    handelOnOpenChange: func,
    items: arrayOf(shape({})),
    isCell: bool,
    isInHeader: bool,
    multiselect: bool,
    title: string,
    selected: oneOfType([
        number,
        string,
        array,
        shape({
            id: oneOfType([number, string]),
            value: oneOfType([number, string]),
        }),
    ]),
    setSelected: func,
    selectedOnly: bool,
    noMargin: bool,
    placeholderInput: string,
    dropdownManualInput: bool,
    handleDropdownManualInput: func,
};

export default DropdownList;
