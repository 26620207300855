import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const Legend = () => {
    const cx = classNames.bind(styles);
    return (
        <div className={cx('legend')}>
            <div className={cx('legend__container')}>
                <div className={cx('legend__color', 'certification')} />
                <span> Плановая аттестация</span>
            </div>
            <div className={cx('legend__container')}>
                <div className={cx('legend__color', 'sick_leave')} />
                <span> Больничный</span>
            </div>
            <div className={cx('legend__container')}>
                <div className={cx('legend__color', 'vacation')} />
                <span> Отпуск</span>
            </div>
            <div className={cx('legend__container')}>
                <div className={cx('legend__color', 'time_off')} />
                <span> Отгул</span>
            </div>
        </div>
    );
};

export default Legend;
