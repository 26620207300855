export const Admin = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19.6462 13.0281C19.689 12.7 19.7104 12.3609 19.7104 12C19.7104 11.65 19.689 11.3 19.6355 10.9719L21.8094 9.24375C22.0021 9.09062 22.0557 8.79531 21.9379 8.57656L19.8818 4.94531C19.7533 4.70469 19.4855 4.62812 19.2499 4.70469L16.6905 5.75469C16.1551 5.33906 15.5875 4.98906 14.9557 4.72656L14.5701 1.94844C14.5273 1.68594 14.3131 1.5 14.0561 1.5H9.94388C9.68687 1.5 9.4834 1.68594 9.44056 1.94844L9.05504 4.72656C8.42322 4.98906 7.84493 5.35 7.3202 5.75469L4.76076 4.70469C4.52517 4.61719 4.25744 4.70469 4.12894 4.94531L2.08353 8.57656C1.95502 8.80625 1.99786 9.09062 2.21204 9.24375L4.38595 10.9719C4.33241 11.3 4.28957 11.6609 4.28957 12C4.28957 12.3391 4.31099 12.7 4.36453 13.0281L2.19062 14.7563C1.99786 14.9094 1.94431 15.2047 2.06211 15.4234L4.11823 19.0547C4.24673 19.2953 4.51446 19.3719 4.75005 19.2953L7.30949 18.2453C7.84493 18.6609 8.41251 19.0109 9.04433 19.2734L9.42986 22.0516C9.4834 22.3141 9.68687 22.5 9.94388 22.5H14.0561C14.3131 22.5 14.5273 22.3141 14.5594 22.0516L14.945 19.2734C15.5768 19.0109 16.1551 18.6609 16.6798 18.2453L19.2392 19.2953C19.4748 19.3828 19.7426 19.2953 19.8711 19.0547L21.9272 15.4234C22.0557 15.1828 22.0021 14.9094 21.7987 14.7563L19.6462 13.0281ZM12 15.9375C9.87963 15.9375 8.14478 14.1656 8.14478 12C8.14478 9.83438 9.87963 8.0625 12 8.0625C14.1204 8.0625 15.8552 9.83438 15.8552 12C15.8552 14.1656 14.1204 15.9375 12 15.9375Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const Positions = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20 0H4V2H20V0ZM4 24H20V22H4V24ZM20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM12 6.75C13.24 6.75 14.25 7.76 14.25 9C14.25 10.24 13.24 11.25 12 11.25C10.76 11.25 9.75 10.24 9.75 9C9.75 7.76 10.76 6.75 12 6.75ZM17 17H7V15.5C7 13.83 10.33 13 12 13C13.67 13 17 13.83 17 15.5V17Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const ProfileIcon = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const BaseTariffRatesIcon = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.4082 1.5C17.0487 1.5 20 4.45125 20 8.0918C20 11.7323 17.0488 14.6836 13.4082 14.6836H10.0603V16.3056L15.2 16.3056V18.8111H10.0603V22H7.47449V18.8111H4L4.00017 16.3056L7.47449 16.3056V14.6836H4.00017L4.00017 12.2866H6.90923L6.90923 1.5H13.4082ZM10.0603 11.8586V4.32507L12.7589 4.32507C14.8392 4.32507 16.5257 6.0115 16.5257 8.09182C16.5257 10.1721 14.8392 11.8586 12.7589 11.8586H10.0603Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const Organizations = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M23 14.4V11.8667L21.7778 3H2.22222L1 11.8667V14.4H2.22222V22H14.4444V14.4H19.3333V22H21.7778V14.4H23ZM12 19.4667H4.66667V14.4H12V19.4667Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const Specialties = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21.6698 18.17L16.3698 12.87H15.3798L12.8398 15.41V16.4L18.1398 21.7C18.5298 22.09 19.1598 22.09 19.5498 21.7L21.6698 19.58C22.0598 19.2 22.0598 18.56 21.6698 18.17Z"
                    fill="#696C80"
                />
                <path
                    d="M17.34 10.19L18.75 8.78L20.87 10.9C22.04 9.73 22.04 7.83 20.87 6.66L17.33 3.12L15.92 4.53V1.71L15.22 1L11.68 4.54L12.39 5.25H15.22L13.81 6.66L14.87 7.72L11.98 10.61L7.85 6.48V5.06L4.83 2.04L2 4.87L5.03 7.9H6.44L10.57 12.03L9.72 12.88H7.6L2.3 18.18C1.91 18.57 1.91 19.2 2.3 19.59L4.42 21.71C4.81 22.1 5.44 22.1 5.83 21.71L11.13 16.41V14.29L16.28 9.14L17.34 10.19Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const CVIcon = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM14 17H7V15H14V17ZM17 13H7V11H17V13ZM17 9H7V7H17V9Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const DirectoriesIcon = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_4189_75907)">
                    <path
                        d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19 15L16.5 13.5L14 15V7H19V15Z"
                        fill="#696C7F"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_4189_75907">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );
};

export const Workers = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3 4H21C22.1 4 23 4.9 23 6V18C23 19.1 22.1 20 21 20H3C1.89 20 1 19.1 1 18V6C1 4.9 1.89 4 3 4ZM11 10C11 8.34 9.66 7 8 7C6.34 7 5 8.34 5 10C5 11.66 6.34 13 8 13C9.66 13 11 11.66 11 10ZM8 13.9C6 13.9 3 15 3 17H13C13 15 10 13.9 8 13.9ZM20.5 8H13.5V10H20.5V8ZM20.5 12H13.5V14H20.5V12Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const Projects = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.8 16.4444V15.3333H2.111L2.1 19.7778C2.1 21.0111 3.079 22 4.3 22H19.7C20.921 22 21.9 21.0111 21.9 19.7778V15.3333H14.2V16.4444H9.8ZM20.8 6.44444H16.389V4.22222L14.189 2H9.789L7.589 4.22222V6.44444H3.2C1.99 6.44444 1 7.44444 1 8.66667V12C1 13.2333 1.979 14.2222 3.2 14.2222H9.8V12H14.2V14.2222H20.8C22.01 14.2222 23 13.2222 23 12V8.66667C23 7.44444 22.01 6.44444 20.8 6.44444ZM14.2 6.44444H9.8V4.22222H14.2V6.44444Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const Groups = () => {
    return (
        <span>
            <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z"
                    fill="black"
                />
            </svg>
        </span>
    );
};

export const OpenMenuArrow = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#ccc"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z"
                fill="#696C80"
            />
        </svg>
    );
};

export const Plans = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19 1L14 6V17L19 12.5V1ZM1 6V20.65C1 20.9 1.25 21.15 1.5 21.15C1.6 21.15 1.65 21.1 1.75 21.1C3.1 20.45 5.05 20 6.5 20C8.45 20 10.55 20.4 12 21.5V6C10.55 4.9 8.45 4.5 6.5 4.5C4.55 4.5 2.45 4.9 1 6ZM23 19.5V6C22.4 5.55 21.75 5.25 21 5V18.5C19.9 18.15 18.7 18 17.5 18C15.8 18 13.35 18.65 12 19.5V21.5C13.35 20.65 15.8 20 17.5 20C19.15 20 20.85 20.3 22.25 21.05C22.35 21.1 22.4 21.1 22.5 21.1C22.75 21.1 23 20.85 23 20.6V19.5Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const Reports = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20.8 5.25H12L9.8 3H3.2C1.99 3 1.011 4.0125 1.011 5.25L1 18.75C1 19.9875 1.99 21 3.2 21H20.8C22.01 21 23 19.9875 23 18.75V7.5C23 6.2625 22.01 5.25 20.8 5.25ZM14.2 16.5H5.4V14.25H14.2V16.5ZM18.6 12H5.4V9.75H18.6V12Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const Teams = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.6699 13.13C18.0399 14.06 18.9999 15.32 18.9999 17V20H22.9999V17C22.9999 14.82 19.4299 13.53 16.6699 13.13Z"
                    fill="#696C80"
                />
                <path
                    d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z"
                    fill="#696C80"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.9999 12C17.2099 12 18.9999 10.21 18.9999 8C18.9999 5.79 17.2099 4 14.9999 4C14.5299 4 14.0899 4.1 13.6699 4.24C14.4999 5.27 14.9999 6.58 14.9999 8C14.9999 9.42 14.4999 10.73 13.6699 11.76C14.0899 11.9 14.5299 12 14.9999 12Z"
                    fill="#696C80"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 13C6.33 13 1 14.34 1 17V20H17V17C17 14.34 11.67 13 9 13Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const Calendar = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.5556 13.1H13V18.6H18.5556V13.1ZM16.4444 1V3.2H7.55556V1H5.33333V3.2H3.22222C1.98889 3.2 1.01111 4.19 1.01111 5.4L1 20.8C1 22.01 1.98889 23 3.22222 23H20.7778C22 23 23 22.01 23 20.8V5.4C23 4.19 22 3.2 20.7778 3.2H18.6667V1H16.4444ZM20.7778 20.8H3.22222V8.7H20.7778V20.8Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};

export const ModulesIcon = () => {
    return (
        <span>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13 13V21H21V13H13ZM3 21H11V13H3V21ZM3 3V11H11V3H3ZM16.66 1.69L11 7.34L16.66 13L22.32 7.34L16.66 1.69Z"
                    fill="#696C80"
                />
            </svg>
        </span>
    );
};
