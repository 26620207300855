import { useRef } from 'react';
import { string, func } from 'prop-types';
import styles from './styles.module.scss';

const CVTextarea = ({ text, onChange, type = 'string' }) => {
    const textareaRef = useRef(null);

    return type === 'string' ? (
        <textarea
            ref={textareaRef}
            value={text}
            onChange={onChange}
            className={styles.cv_text}
            cols="40"
            maxLength={225}
        />
    ) : (
        <input
            className={styles.cv_input}
            type={type}
            value={text}
            onChange={onChange}
        />
    );
};
CVTextarea.propTypes = {
    text: string,
    onChange: func,
    type: string,
};

export default CVTextarea;
