import {
    GET_MODULES_DATA,
    ADD_MODULE_DATA,
    DELETE_MODULE_DATA,
    EDIT_MODULE_DATA,
} from 'redux/types';

const defaultState = {
    data: [],
    DropdownListValues: [],
};

export const modules = (state = defaultState, action) => {
    switch (action.type) {
        case GET_MODULES_DATA:
            return {
                ...state,
                data: action.payload,
                DropdownListValues: action.payload
                    .filter((el) => el.isActive)
                    .map((el) => ({
                        id: el.id,
                        value: el.description,
                    })),
            };
        case ADD_MODULE_DATA:
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case EDIT_MODULE_DATA:
            return {
                ...state,
                data: [
                    ...state.data.map((item) => {
                        if (item.id === action.payload.id) {
                            return { ...action.payload };
                        }
                        return { ...item };
                    }),
                ],
            };
        case DELETE_MODULE_DATA: {
            return {
                ...state,
                data: state.data.filter((item) => item.id !== action.payload),
            };
        }
        default:
            return state;
    }
};
