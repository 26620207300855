import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func, number, shape, string, bool } from 'prop-types';
import Loader from 'components/Loader';
import CVPersonInfo from 'components/Pages/CVPage/CVPerson/CVPersonInfo';
import { getCvInformation, saveCvPersonInfo } from 'redux/thunks/cvPageThunks';
import CVButtons from 'components/Pages/CVPage/CVButtons';
import classNames from 'classnames/bind';
import DropdownList from 'components/DropdownList';
import { getInitialValueObj } from 'helpers/editFormsValues';
import { getAllSpecialties } from 'redux/thunks/specialtiesThunks';
import styles from './styles.module.scss';

const CVPerson = ({
    cvId,
    currentRole,
    personInfo = {},
    setPersonInfo = () => {},
    isEdit = false,
}) => {
    const dispatch = useDispatch();
    const cx = classNames.bind(styles);
    const specialityList = useSelector(
        (state) => state.specialtiesAndCompetence.DropdownSpecialtiesListValues
    );

    const [isEditing, setIsEditing] = useState(isEdit);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(getAllSpecialties());
    }, []);

    const savePersonInfo = () => {
        saveCvPersonInfo(cvId, personInfo, setIsEditing, setIsLoading);
    };

    const cancelPersonInfo = () => {
        getCvInformation(cvId, setIsLoading, setPersonInfo, () => {});
        setIsEditing(false);
    };

    const selectedSpeciality =
        typeof personInfo?.specialityName === 'string'
            ? getInitialValueObj(personInfo?.specialityName, specialityList)
            : personInfo?.specialityName;
    const changeSpecialityName = (specialityName) => {
        setPersonInfo({ ...personInfo, specialityName });
    };

    return (
        <div className={cx('cv-person')}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={cx('cv-person__header')}>
                        <div className={cx('cv-person__header_title')}>
                            <div className={cx('name')}>
                                {personInfo?.fullName}
                            </div>
                            {currentRole !== 'USER' && (
                                <CVButtons
                                    isEdit={isEditing}
                                    isEditing
                                    isCancel
                                    isSave
                                    onSave={savePersonInfo}
                                    onCansel={cancelPersonInfo}
                                    setIsEdit={setIsEditing}
                                />
                            )}
                        </div>
                        <div className={cx('specialityName')}>
                            {isEditing ? (
                                <div className={cx('field_block')}>
                                    <div className={cx('field_block_title')}>
                                        Специализация
                                    </div>
                                    <div className={cx('field_block_value')}>
                                        <DropdownList
                                            setSelected={changeSpecialityName}
                                            selected={selectedSpeciality}
                                            items={specialityList}
                                        />
                                    </div>
                                </div>
                            ) : (
                                selectedSpeciality?.value
                            )}
                        </div>
                    </div>
                    <CVPersonInfo
                        setPersonInfo={setPersonInfo}
                        personInfo={personInfo}
                        isEditing={isEditing}
                    />
                </>
            )}
        </div>
    );
};

export default CVPerson;

CVPerson.propTypes = {
    currentRole: string,
    personInfo: shape({}),
    setPersonInfo: func,
    cvId: number,
    isEdit: bool,
};
