import {
    ADD_TEAM_PERIOD_DATA,
    DELETE_TEAM_PERIOD_DATA,
    EDIT_TEAM_PERIOD_DATA,
    GET_TEAM_PERIOD_DATA,
} from 'redux/types';

const defaultState = {
    data: [],
};

export const teamPeriods = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_TEAM_PERIOD_DATA:
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case DELETE_TEAM_PERIOD_DATA:
            return {
                ...state,
                data: state.data.filter((item) => item.id !== action.payload),
            };
        case EDIT_TEAM_PERIOD_DATA:
            return {
                ...state,
                data: [
                    ...state.data.filter(
                        (item) => item.id !== action.payload.id
                    ),
                    action.payload,
                ],
            };
        case GET_TEAM_PERIOD_DATA:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};
