import { useContext, useEffect, useMemo, useState } from 'react';
import API from 'api/api';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import PageHeader from 'components/PageHeader';
import ProjectTeamsContent from 'components/ModalsContent/ProjectTeamsContent';
import TeamPersonsTable from 'components/Tables/TeamPersonsTable';
import Loader from 'components/Loader';
import DeleteIcon from 'components/svg/deleteIcon';
import { getProjectTeamsData } from 'redux/actions/projectTeams';
import PlusIcon from 'components/svg/ButtonIcon/plus';
import Toggle from 'components/Toggle';
import FiltersModal from 'components/Filters/FiltersModal';
import { accessByRole } from 'helpers/roleHelpers';
import {
    filtersAreaHandler,
    statusList,
} from 'helpers/Filters/filtersAreaHandler';
import isEmpty from 'helpers/isEmpty';
import KebabButton from 'components/KebabButton';
import styles from './styles.module.scss';

const ProjectTeams = () => {
    const cx = classNames.bind(styles);
    const dispatch = useDispatch();
    const { openModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const data = useSelector((state) => state.projectTeams.data);
    const currentRole = useSelector((state) => state.auth.userData.userRole);
    const addPersonButton = accessByRole(
        'ProjectTeams',
        'addPersonButton',
        currentRole
    );
    const deleteTeamButton = accessByRole(
        'ProjectTeams',
        'deleteTeamButton',
        currentRole
    );

    const [isShowTablesEntity, setIsShowTablesEntity] = useState({});
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [status, setStatus] = useState({
        id: 0,
        value: 'Активен',
        boolValue: true,
    });
    const [toggleTeam, setToggleTeam] = useState({
        id: 0,
        value: 'close',
    });

    useEffect(() => {
        setIsLoading(true);
        API.reports
            .getTeamPeriods({
                projectAndTeamIsActive: true,
            })
            .then((res) => dispatch(getProjectTeamsData(res.data)))
            .finally(() => setIsLoading(false));
    }, []);

    const handleClick = (title, children) => {
        openModal({ title, children });
    };

    const onHiddenPersonsClick = (id) => {
        setIsShowTablesEntity({ ...isShowTablesEntity, [id]: false });
    };

    const onShowPersonsClick = (id) => {
        setIsShowTablesEntity({ ...isShowTablesEntity, [id]: true });
    };
    const handelShowTable = (teamId) =>
        isShowTablesEntity[teamId]
            ? onHiddenPersonsClick(teamId)
            : onShowPersonsClick(teamId);

    const handleClickFilters = () => {
        setIsOpenFilters(true);
    };

    const handleClickSaveFilters = () => {
        setIsLoading(true);
        API.reports
            .getTeamPeriods({
                projectAndTeamIsActive: isEmpty(status) ? status.boolValue : '',
            })
            .then((res) => dispatch(getProjectTeamsData(res.data)))
            .finally(() => setIsLoading(false));
    };

    const dataForFilters = useMemo(
        () => ({
            status,
            changeStatus: setStatus,
        }),
        [status]
    );

    return (
        <div>
            <PageHeader
                title="Команды проектов"
                addButton={accessByRole(
                    'ProjectTeams',
                    'addButton',
                    currentRole
                )}
                addButtonText="Добавить команду"
                filtersButton
                handlerClickFiltersButton={handleClickFilters}
                openAddModal={() => {
                    handleClick(
                        'Создание команды проекта',
                        <ProjectTeamsContent
                            closeModal={closeModal}
                            changeIsActiveFlag={() =>
                                setStatus(
                                    statusList.find(
                                        (elem) => elem.boolValue === true
                                    )
                                )
                            }
                            data={data}
                        />
                    );
                }}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <FiltersModal
                        filters={filtersAreaHandler(
                            'PROJECTS_TEAM',
                            dataForFilters
                        )}
                        isActive={isOpenFilters}
                        setIsActive={setIsOpenFilters}
                        onSave={handleClickSaveFilters}
                    />
                    {!data?.length || !Array.isArray(data) ? (
                        <div className={styles.empty_block}>
                            Список команд пуст
                        </div>
                    ) : (
                        <div className={cx('teams')}>
                            {data?.map((team) => {
                                return (
                                    <div
                                        key={team.teamId}
                                        className={cx('team_container')}
                                    >
                                        <div
                                            className={cx('project_container')}
                                            onClick={() => {
                                                handelShowTable(team.teamId);
                                            }}
                                        >
                                            <Toggle
                                                title={team.projectName}
                                                currentStatus={{
                                                    id: Number(
                                                        isShowTablesEntity[
                                                            team.teamId
                                                        ]
                                                    ),
                                                    value: Number(
                                                        isShowTablesEntity[
                                                            team.teamId
                                                        ]
                                                    )
                                                        ? 'open'
                                                        : 'close',
                                                }}
                                                setStatus={setToggleTeam}
                                                handleCHange={() => {
                                                    handelShowTable(
                                                        team.teamId
                                                    );
                                                }}
                                                caseName="TEAMS"
                                            />
                                            {(addPersonButton ||
                                                deleteTeamButton) && (
                                                <KebabButton isProjectsTeams>
                                                    {addPersonButton && (
                                                        <button
                                                            type="button"
                                                            className={cx(
                                                                'addButton'
                                                            )}
                                                            onClick={() => {
                                                                handleClick(
                                                                    'Добавление сотрудника в команду проекта',
                                                                    <ProjectTeamsContent
                                                                        initProjectName={
                                                                            team.projectName
                                                                        }
                                                                        teamId={
                                                                            team.teamId
                                                                        }
                                                                        closeModal={
                                                                            closeModal
                                                                        }
                                                                        changeIsActiveFlag={() =>
                                                                            setStatus(
                                                                                statusList.find(
                                                                                    (
                                                                                        elem
                                                                                    ) =>
                                                                                        elem.boolValue ===
                                                                                        true
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                );
                                                            }}
                                                        >
                                                            <PlusIcon />
                                                            Добавить сотрудника
                                                        </button>
                                                    )}
                                                    {deleteTeamButton && (
                                                        <button
                                                            type="button"
                                                            className={cx(
                                                                'del-btn'
                                                            )}
                                                            onClick={() => {
                                                                handleClick(
                                                                    `Вы действительно хотите удалить команду ${team.projectName}?`,
                                                                    <DeleteModal
                                                                        closeModal={
                                                                            closeModal
                                                                        }
                                                                        data={
                                                                            data
                                                                        }
                                                                        id={
                                                                            team.teamId
                                                                        }
                                                                        tableType="projectTeams"
                                                                    />
                                                                );
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                            Удалить
                                                        </button>
                                                    )}
                                                </KebabButton>
                                            )}
                                        </div>
                                        {isShowTablesEntity[team.teamId] ? (
                                            <TeamPersonsTable
                                                team={team}
                                                projectsInStatus={status}
                                            />
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
export default ProjectTeams;
