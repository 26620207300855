import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { func, number, string } from 'prop-types';
import Button from 'components/Button';
import Input from 'components/Input';
import useValidator from 'hooks/useValidator';
import { getInitialValueObj } from 'helpers/editFormsValues';
import { useToastify } from 'helpers/toastify/useToastify';
import DropdownList from 'components/DropdownList';
import Loader from 'components/Loader';
import DatePicker from 'components/DatePicker';
import { validDate } from 'helpers/dateHelper';
import UploadAvatar from 'components/UploadAvatar';
import {
    getPersonImg,
    postPersonImg,
    deletePersonImg,
} from 'redux/thunks/imagesThunks';
import { editPerson, getPerson, addPerson } from 'redux/thunks/personThunks';
import { validNumber } from 'helpers/validationHelpers';
import Switcher from 'components/Switcher';
import isEmpty from 'helpers/isEmpty';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const imageMimeType = /image\/(png|jpg|jpeg)/i;

const ProfileModal = ({ closeModal, id, statusModal }) => {
    const cx = classNames.bind(styles);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const positions = useSelector(
        (state) => state.positions.DropdownPositionsListValues
    );
    const specialties = useSelector(
        (state) => state.specialtiesAndCompetence.DropdownSpecialtiesListValues
    );
    const competences = useSelector(
        (state) => state.specialtiesAndCompetence.DropdownCompetenceListValues
    );
    const personPhoto = useSelector((state) => state.persons.personPhoto);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [imageWasLoading, setImageWasLoading] = useState(false);
    const [isImageChanged, setIsImageChanged] = useState(false);

    const [isValidDate, setIsValidDate] = useState({
        birth: false,
        startExperience: false,
        startCorpExperience: false,
        endCorpExperience: false,
    });

    const [currentProfile, setCurrentProfile] = useState(null);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [birthDate, setBirthDate] = useState(null);
    const [corpEmail, setCorpEmail] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [telephoneAlert, setTelephoneAlert] = useState('');
    const [selectedPosition, setSelectedPosition] = useState({});
    const [tabelNumber, setTabelNumber] = useState('');
    const [startExperienceDate, setStartExperienceDate] = useState(null);
    const [startCorpExperienceDate, setStartCorpExperienceDate] =
        useState(null);
    const [endCorpExperienceDate, setEndCorpExperienceDate] = useState(null);
    const [selectedCompetence, setSelectedCompetence] = useState({});
    const [selectedSpeciality, setSelectedSpeciality] = useState({});
    const [file, setFile] = useState({});
    const [status, setStatus] = useState(true);
    const [submitTouched, setSubmitTouched] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [emailInputError, setEmailInputError] = useState(false);

    const [showExtraParams, setShowExtraParams] = useState(false);

    const initialValuesRef = useRef({});

    useEffect(() => {
        const currentPersonForm = {
            name,
            surname,
            middleName,
            birthDate,
            corpEmail,
            email,
            telephone,
            telephoneAlert,
            positionId: selectedPosition ? selectedPosition.id : null,
            isActive: status,
            personNumber: tabelNumber,
            specialityId: selectedSpeciality?.id,
            startExperienceDate,
            startCorpExperienceDate,
            endCorpExperienceDate,
            competenceLevel: selectedCompetence.value,
        };
        if (
            JSON.stringify(currentPersonForm) ===
                JSON.stringify(initialValuesRef.current) &&
            !isImageChanged
        ) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [
        name,
        surname,
        middleName,
        birthDate,
        corpEmail,
        email,
        telephone,
        telephoneAlert,
        selectedPosition?.id,
        status,
        tabelNumber,
        selectedSpeciality.id,
        startExperienceDate,
        startCorpExperienceDate,
        endCorpExperienceDate,
        selectedCompetence.value,
        isImageChanged,
    ]);

    useEffect(() => {
        if (statusModal === 'edit') {
            setShowExtraParams(true);
            setIsLoading(true);
            getPerson(id, setProfile, setIsLoading);
            dispatch(
                getPersonImg(
                    id,
                    setFileHandler,
                    setIsLoadingImage,
                    setImageWasLoading
                )
            );
        }
    }, []);

    const setFileHandler = (idPhoto, photo) => {
        setFile({ id: idPhoto, photo });
    };

    useEffect(() => {
        const currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() - 14);
        const startCodeInside = new Date(2009, 3, 6);

        if (birthDate) {
            if (birthDate?.getTime() >= currentDate.getTime()) {
                setIsValidDate((prev) => ({
                    ...prev,
                    birth: true,
                }));
                useToastify(
                    'Сотруднику должно быть не менее 14 лет',
                    'warning'
                );
            } else {
                setIsValidDate((prev) => ({
                    ...prev,
                    birth: false,
                }));
            }
        }

        if (startCorpExperienceDate) {
            if (
                startCorpExperienceDate?.getTime() <
                    startCodeInside.getTime() ||
                startExperienceDate?.getTime() >
                    startCorpExperienceDate?.getTime()
            ) {
                setIsValidDate((prev) => ({
                    ...prev,
                    startCorpExperience: true,
                }));
                if (
                    startExperienceDate?.getTime() >
                    startCorpExperienceDate?.getTime()
                ) {
                    useToastify(
                        'Дата начала работы в компании не может быть меньше даты начала стажа работы',
                        'warning'
                    );
                }
                if (
                    startCorpExperienceDate?.getTime() <
                    startCodeInside.getTime()
                ) {
                    useToastify(
                        'Дата начала работы в компании должна быть больше даты создания компании',
                        'warning'
                    );
                }
            } else {
                setIsValidDate((prev) => ({
                    ...prev,
                    startCorpExperience: false,
                }));
            }
        }

        if (birthDate && startExperienceDate) {
            const startExperienceCheck = new Date(startExperienceDate);
            startExperienceCheck.setFullYear(
                startExperienceCheck.getFullYear() - 14
            );
            if (birthDate?.getTime() > startExperienceCheck.getTime()) {
                setIsValidDate((prev) => ({
                    ...prev,
                    startExperience: true,
                }));
                useToastify(
                    'На момент начала стажа работы сотруднику должно быть не менее 14 лет',
                    'warning'
                );
            } else {
                setIsValidDate((prev) => ({
                    ...prev,
                    startExperience: false,
                }));
            }
        }

        if (startCorpExperienceDate && endCorpExperienceDate) {
            if (
                startCorpExperienceDate?.getTime() >
                endCorpExperienceDate?.getTime()
            ) {
                setIsValidDate((prev) => ({
                    ...prev,
                    endCorpExperience: true,
                }));
                useToastify(
                    'Дата окончания работы в компании не может быть меньше даты начала работы в компании',
                    'warning'
                );
            } else {
                setIsValidDate((prev) => ({
                    ...prev,
                    endCorpExperience: false,
                }));
            }
        }
    }, [
        birthDate,
        startCorpExperienceDate,
        endCorpExperienceDate,
        startExperienceDate,
    ]);

    const onSave = () => {
        if (isValid && !emailInputError) {
            const personForm = {
                name,
                surname,
                middleName,
                birthDate,
                corpEmail,
                email,
                telephone,
                telephoneAlert,
                positionId: selectedPosition ? selectedPosition.id : null,
                isActive: status,
                personNumber: tabelNumber,
                specialityId: selectedSpeciality.id,
                startExperienceDate,
                startCorpExperienceDate,
                endCorpExperienceDate,
                competenceLevel: selectedCompetence.value,
            };

            if (statusModal === 'edit') {
                dispatch(editPerson(id, personForm, closeModal));

                if (personPhoto.photo !== file.photo) {
                    if (file?.photo) {
                        dispatch(postPersonImg(id, file?.photo));
                    } else {
                        dispatch(deletePersonImg(personPhoto.id, setFile));
                    }
                }
            } else if (statusModal === 'add') {
                dispatch(addPerson(personForm, closeModal, file?.photo));
            }
        }
        setSubmitTouched(true);
    };

    const setProfile = (data) => {
        setCurrentProfile({ ...currentProfile, ...data });

        const initialName = data.name;
        const initialSurname = data.surname;
        const initialMiddleName = data.middleName ?? '';
        const initialBirthDate = validDate(data.birthDate ?? '');
        const initialCorpEmail = data.corpEmail;
        const initialEmail = data.email;
        const initialTelephone = validNumber(data.telephone);
        const initialTelephoneAlert = validNumber(data.telephoneAlert);
        const initialSelectedPosition =
            getInitialValueObj(data.position ?? '', positions) ?? null;
        const initialTabelNumber = data.personNumber;
        const initialStartExperienceDate = validDate(
            data.startExperienceDate ?? ''
        );
        const initialStartCorpExperienceDate = validDate(
            data.startCorpExperienceDate ?? ''
        );
        const initialEndCorpExperienceDate = validDate(
            data.endCorpExperienceDate ?? ''
        );
        const initialSelectedCompetence = getInitialValueObj(
            data.competenceLevel ?? '',
            competences
        );
        const initialSelectedSpeciality = getInitialValueObj(
            data.specialityName ?? '',
            specialties
        );
        const initialStatus = data.isActive;

        initialValuesRef.current = {
            name: initialName,
            surname: initialSurname,
            middleName: initialMiddleName,
            birthDate: initialBirthDate,
            corpEmail: initialCorpEmail,
            email: initialEmail,
            telephone: initialTelephone,
            telephoneAlert: initialTelephoneAlert,
            positionId: initialSelectedPosition
                ? initialSelectedPosition.id
                : null,
            isActive: initialStatus,
            personNumber: initialTabelNumber,
            specialityId: initialSelectedSpeciality?.id,
            startExperienceDate: initialStartExperienceDate,
            startCorpExperienceDate: initialStartCorpExperienceDate,
            endCorpExperienceDate: initialEndCorpExperienceDate,
            competenceLevel: initialSelectedCompetence.value,
        };

        setName(initialName);
        setSurname(initialSurname);
        setMiddleName(initialMiddleName);
        setBirthDate(initialBirthDate);
        setCorpEmail(initialCorpEmail);
        setEmail(initialEmail);
        setTelephone(initialTelephone);
        setTelephoneAlert(initialTelephoneAlert);
        setSelectedPosition(initialSelectedPosition);
        setTabelNumber(initialTabelNumber);
        setStartExperienceDate(initialStartExperienceDate);
        setStartCorpExperienceDate(initialStartCorpExperienceDate);
        setEndCorpExperienceDate(initialEndCorpExperienceDate);
        setSelectedCompetence(initialSelectedCompetence);
        setSelectedSpeciality(initialSelectedSpeciality);
        setStatus(initialStatus);
    };

    const { isValid, validator } = useValidator({
        name,
        surname,
        birthDate,
        telephone,
        corpEmail,
        selectedSpeciality: selectedSpeciality?.value,
        selectedCompetence: selectedCompetence?.value,
    });

    const openCvPerson = (idCv) => {
        const url = `/CV/${idCv}`;
        navigate(url, { replace: true });
        closeModal();
    };

    const selectedAvatarProfile = (e) => {
        setIsImageChanged(true);
        if (e !== null) {
            const eFile = e.target.files[0];

            if (!eFile?.type.match(imageMimeType)) {
                useToastify('Файл не верного формата', 'warning');
                return;
            }
            if (eFile?.size > 1000000) {
                useToastify('Фотография не должна превышать 1MB', 'warning');
                return;
            }

            setFile((prev) => {
                return { ...prev, photo: eFile };
            });
        } else {
            setFile((prev) => {
                return { ...prev, photo: {} };
            });
        }
    };

    return (
        <div className={styles.modal__profile_content}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div>
                        <div className={styles.modal__avatar}>
                            <UploadAvatar
                                isLoading={isLoadingImage}
                                onChange={selectedAvatarProfile}
                                file={file?.photo}
                                setFile={setFile}
                                setIsImageChanged={setIsImageChanged}
                            />
                        </div>
                        <Input
                            value={surname}
                            onChange={setSurname}
                            title="Фамилия"
                            placeholder="Фамилия"
                            type="nameOrSurname"
                            error={!validator.surname && submitTouched}
                        />
                        <Input
                            value={name}
                            onChange={setName}
                            title="Имя"
                            placeholder="Имя"
                            type="nameOrSurname"
                            error={!validator.name && submitTouched}
                        />
                        <Input
                            value={middleName}
                            onChange={setMiddleName}
                            title="Отчество"
                            placeholder="Отчество"
                            type="nameOrSurname"
                        />
                        <DatePicker
                            title="Дата рождения"
                            placeholder="Выберите дату"
                            dateDrop={birthDate}
                            setDateDrop={setBirthDate}
                            modal
                            error={
                                !isValidDate.birth
                                    ? !validator.birthDate && submitTouched
                                    : true
                            }
                        />
                        <Input
                            value={telephone}
                            onChange={(value) => {
                                setTelephone(validNumber(value));
                            }}
                            title="Телефон"
                            placeholder="Телефон"
                            symbolLimit={14}
                            error={
                                telephone?.length > 11 ||
                                telephone?.length === 0
                                    ? !validator.telephone && submitTouched
                                    : true
                            }
                        />
                        <Input
                            value={corpEmail}
                            onChange={setCorpEmail}
                            title="Корпоративная почта"
                            placeholder="Корпоративная почта"
                            type="email"
                            changeEmailInputError={setEmailInputError}
                            error={!validator.corpEmail && submitTouched}
                        />
                        <DropdownList
                            setSelected={setSelectedSpeciality}
                            selected={selectedSpeciality}
                            items={specialties}
                            title="Специализация"
                            placeholderInput="Выберите специализацию"
                            error={
                                !validator.selectedSpeciality && submitTouched
                            }
                        />
                        <DropdownList
                            setSelected={setSelectedPosition}
                            selected={selectedPosition}
                            items={positions}
                            title="Должность"
                            placeholderInput="Должность"
                        />
                        <DropdownList
                            setSelected={setSelectedCompetence}
                            selected={selectedCompetence}
                            items={competences}
                            title="Уровень компетенций"
                            placeholderInput="Выберите компетентность"
                            error={
                                !validator.selectedCompetence && submitTouched
                            }
                        />

                        <Button
                            className={cx('modal__column_button', {
                                modal__column_button__active: showExtraParams,
                            })}
                            buttonStyle="outlined"
                            buttonIconName="plus"
                            text="Дополнительные параметры"
                            onClick={() => {
                                setShowExtraParams(!showExtraParams);
                            }}
                        />
                        {showExtraParams && (
                            <div className={cx('extra__params__wrapper')}>
                                <Input
                                    value={telephoneAlert}
                                    onChange={(value) => {
                                        setTelephoneAlert(validNumber(value));
                                    }}
                                    title="Тел. для экстренной связи"
                                    placeholder="Телефон"
                                    symbolLimit={14}
                                    error={
                                        telephoneAlert?.length < 12 &&
                                        telephoneAlert?.length > 0
                                    }
                                />
                                <Input
                                    value={email}
                                    onChange={setEmail}
                                    title="Личная почта"
                                    placeholder="Личная почта"
                                    type="email"
                                    changeEmailInputError={setEmailInputError}
                                />
                                <Input
                                    value={tabelNumber}
                                    onChange={setTabelNumber}
                                    title="Табельный номер"
                                    placeholder="Табельный номер"
                                    symbolLimit={4}
                                    type="number"
                                />
                                <DatePicker
                                    title="Дата начала стажа работы"
                                    placeholder="Выберите дату"
                                    dateDrop={startExperienceDate}
                                    setDateDrop={setStartExperienceDate}
                                    modal
                                    error={
                                        startExperienceDate &&
                                        isValidDate.startExperience
                                    }
                                />
                                <DatePicker
                                    dateDrop={startCorpExperienceDate}
                                    setDateDrop={setStartCorpExperienceDate}
                                    title="Дата начала работы в компании"
                                    placeholder="Выберите дату"
                                    modal
                                    error={
                                        startCorpExperienceDate &&
                                        isValidDate.startCorpExperience
                                    }
                                />
                                <DatePicker
                                    isRemoveButton
                                    isRemoveButtonModal
                                    title="Дата окончания работы в компании"
                                    placeholder="Выберите дату"
                                    dateDrop={endCorpExperienceDate}
                                    setDateDrop={setEndCorpExperienceDate}
                                    error={isValidDate.endCorpExperience}
                                    modal
                                />
                            </div>
                        )}

                        <div className={styles.modal__status}>
                            <Switcher
                                isActive={status}
                                setChecked={setStatus}
                            />
                        </div>
                        {statusModal === 'edit' && (
                            <Button
                                className={styles.modal__column_button}
                                buttonStyle="outlined"
                                text="Просмотр CV"
                                onClick={() => {
                                    openCvPerson(
                                        currentProfile.personInfoIds[0]
                                    );
                                }}
                            />
                        )}
                    </div>
                    <div className={styles.buttons_container}>
                        <Button
                            buttonStyle="outlined"
                            text="Отмена"
                            onClick={closeModal}
                        />

                        <Button
                            text="Сохранить"
                            disabled={
                                submitDisabled ||
                                isValidDate.birth ||
                                isValidDate.endCorpExperience ||
                                isValidDate.startCorpExperience ||
                                isValidDate.startExperience
                            }
                            onClick={onSave}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

ProfileModal.propTypes = {
    closeModal: func,
    id: number,
    statusModal: string,
};

export default ProfileModal;
