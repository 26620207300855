import { bool, string } from 'prop-types';
import styles from './styles.module.scss';

const ComponentTitle = ({ title = '', error = false, success = false }) => (
    <span
        className={`${styles.title} ${error ? styles.error : ''} ${
            success ? styles.success : ''
        }`}
    >
        {title.split('*').join('')}{' '}
        {title.includes('*') && (
            <span className={styles.title_requiredField}>*</span>
        )}
    </span>
);

ComponentTitle.propTypes = {
    title: string,
    error: bool,
    success: bool,
};
export default ComponentTitle;
