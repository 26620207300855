import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func, number } from 'prop-types';
import API from 'api/api';
import Button from 'components/Button';
import Input from 'components/Input';
import EditIcon from 'components/svg/editIcon';
import SaveIcon from 'components/svg/saveIcon';
import BlockIcon from 'components/svg/blockIcon';
import Loader from 'components/Loader';
import { getValueOrNil } from 'helpers/editFormsValues';
import {
    getProductionPlansPerson,
    setInitialSums,
} from 'redux/actions/productionPlans';
import { editPlan, getAllPlans } from 'redux/thunks/productionPlansThunks';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const TimeModal = ({ closeModal, personId, month, year }) => {
    const [hoursAnalysis, setHoursAnalysis] = useState(0);
    const [tableValues, setTableValues] = useState({});
    const [editId, setEditId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const personPlan = useSelector((state) => state.productionPlans.personData);
    const sumPlan = useSelector((state) => state.productionPlans.sumPlan);
    const sumFact = useSelector((state) => state.productionPlans.sumFact);
    const date = useSelector((state) => state.productionPlans.date);

    const cx = classNames.bind(styles);

    useEffect(() => {
        getPersonPlan();

        return () => {
            dispatch(getProductionPlansPerson([]));
            dispatch(setInitialSums());
        };
    }, []);

    useEffect(() => {
        const dif = sumFact - sumPlan;
        const hours = Number.isInteger(dif) ? dif : dif.toFixed(2);
        setHoursAnalysis(hours);
    }, [sumFact, sumPlan]);

    useEffect(() => {
        const newValues = {};
        for (let n = 0; n < personPlan.length; n++) {
            newValues[personPlan[n].id] = {
                planHours: personPlan[n].planHours,
                factHours: personPlan[n].factHours,
            };
        }
        setTableValues({ ...newValues });
    }, [personPlan, editId]);

    const getPersonPlan = () => {
        dispatch(
            getAllPlans(
                {
                    personId,
                    year,
                    month: month + 1,
                },
                {},
                setIsLoading
            )
        );
    };

    const onEdit = (id) => {
        if (!editId || editId === id) setEditId(id);
    };

    const onSave = (planItem) => {
        dispatch(
            editPlan(
                planItem.id,
                {
                    personId,
                    month: month + 1,
                    year,
                    planHours: Number(tableValues[planItem.id].planHours),
                    factHours: Number(tableValues[planItem.id].factHours),
                    projectId: planItem.project.id,
                },
                getPersonPlan,
                setEditId(null)
            )
        );
    };

    const onChangeCell = (id, fromCell, value) => {
        const newValues = { ...tableValues };
        if (fromCell === 'plan') newValues[id].planHours = value;
        if (fromCell === 'fact') newValues[id].factHours = value;
        setTableValues({ ...newValues });
    };

    return (
        <div className={styles.modal__content}>
            {isLoading ? (
                <Loader />
            ) : (
                <div className={styles.modal__row}>
                    <div className={styles.modal__header}>
                        <span className={styles.modal__person_name}>
                            {personPlan[0]?.person?.surname}{' '}
                            {personPlan[0]?.person?.name}
                        </span>
                    </div>
                    {personPlan?.length ? (
                        <table className={styles.table}>
                            <thead>
                                <tr className={styles.table__header_row}>
                                    <th className={styles.table__header_cell}>
                                        Проект
                                    </th>
                                    <th className={styles.table__header_cell}>
                                        План. часы
                                    </th>
                                    <th className={styles.table__header_cell}>
                                        Факт. часы
                                    </th>
                                    <th className={styles.table__header_cell}>
                                        {' '}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {personPlan.map((item) => (
                                    <tr
                                        key={item.id}
                                        className={styles.table__body_row}
                                    >
                                        <td className={styles.table__body_cell}>
                                            {item.project.name}
                                        </td>
                                        <td
                                            className={cx('table__body_cell', {
                                                cell_disabled:
                                                    !editId ||
                                                    editId !== item.id,
                                            })}
                                        >
                                            <Input
                                                value={getValueOrNil(
                                                    tableValues[item.id]
                                                        ?.planHours
                                                )}
                                                onChange={(value) =>
                                                    onChangeCell(
                                                        item.id,
                                                        'plan',
                                                        value
                                                    )
                                                }
                                                symbolLimit={4}
                                                type="number"
                                                isTableCell
                                                disabled={
                                                    !editId ||
                                                    editId !== item.id
                                                }
                                            />
                                        </td>
                                        <td
                                            className={cx('table__body_cell', {
                                                cell_disabled:
                                                    !editId ||
                                                    editId !== item.id,
                                            })}
                                        >
                                            <Input
                                                value={getValueOrNil(
                                                    tableValues[item.id]
                                                        ?.factHours
                                                )}
                                                onChange={(value) =>
                                                    onChangeCell(
                                                        item.id,
                                                        'fact',
                                                        value
                                                    )
                                                }
                                                symbolLimit={4}
                                                type="number"
                                                isTableCell
                                                disabled={
                                                    !editId ||
                                                    editId !== item.id
                                                }
                                            />
                                        </td>
                                        <td className={styles.table__body_cell}>
                                            {editId && editId === item.id ? (
                                                <div className={cx('buttons')}>
                                                    {' '}
                                                    <button
                                                        type="button"
                                                        className={cx(
                                                            'button_edit'
                                                        )}
                                                        onClick={() =>
                                                            onSave(item)
                                                        }
                                                    >
                                                        <SaveIcon />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={cx(
                                                            'button_dontSave'
                                                        )}
                                                        onClick={() =>
                                                            setEditId(null)
                                                        }
                                                    >
                                                        <SaveIcon />
                                                        <span>
                                                            <BlockIcon />
                                                        </span>
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className={cx('buttons')}>
                                                    <button
                                                        type="button"
                                                        className={cx(
                                                            'button_edit',
                                                            {
                                                                disabled:
                                                                    editId &&
                                                                    editId !==
                                                                        item.id,
                                                            }
                                                        )}
                                                        onClick={() =>
                                                            onEdit(item.id)
                                                        }
                                                    >
                                                        <EditIcon />
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className={styles.time_modal__no_content}>
                            Нет данных
                        </div>
                    )}
                    <div className={styles.modal__footer}>
                        <Input
                            value={hoursAnalysis}
                            title="Анализ часов"
                            disabled
                            error={hoursAnalysis < 0}
                            success={hoursAnalysis > 0}
                        />
                        <div className={styles.buttons_container}>
                            <Button
                                buttonStyle="outlined"
                                text="Закрыть"
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

TimeModal.propTypes = {
    closeModal: func,
    personId: number,
    month: number,
    year: number,
};

export default TimeModal;
