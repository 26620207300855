import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMonthFrom, setMonthTo } from 'redux/actions/reports';
import API from 'api/api';
import { accessByRole } from 'helpers/roleHelpers';
import PageHeader from 'components/PageHeader';
import ProjectsReportTable from 'components/Tables/ReportTables/ProjectsTable';
import PersonsReportTable from 'components/Tables/ReportTables/PersonsTable';
import PersonsWorkloadReportTable from 'components/Tables/ReportTables/PersonsWorkloadTable';
import ProjectWorkloadReportTable from 'components/Tables/ReportTables/ProjectWorkloadTable';
import CompetenceLevelTable from 'components/Tables/ReportTables/CompetenceLevelTable';
import ManagersTable from 'components/Tables/ReportTables/ManagersTable';
import ProjectsQuarterTable from 'components/Tables/ReportTables/ProjectsQuarterTable';
import UnusedPersonsTable from 'components/Tables/ReportTables/UnusedPersonsTable';
import ProjectsTypesReportTable from 'components/Tables/ReportTables/ProjectTypeTable';
import Loader from 'components/Loader';
import Table from 'components/Tables/Table';
import NoDataInfo from 'components/NoDataInfo';
import {
    reports,
    projectTypesDropdownValues,
    projectTypesList,
} from 'mock/mockData';
import {
    getInitialValueObj,
    getYearsValues,
    saveFile,
} from 'helpers/editFormsValues';
import { headers, monthsArr, monthsValues } from 'helpers/tableHandler';
import { useToastify } from 'helpers/toastify/useToastify';
import { getFilteredPersonsForDropDown } from 'redux/thunks/personThunks';
import { getAllProjects } from 'redux/thunks/projectThunks';
import FiltersModal from 'components/Filters/FiltersModal';
import { getAllSpecialties } from 'redux/thunks/specialtiesThunks';
import { getAllCompetences } from 'redux/thunks/competenceLevelsThunks';
import {
    filtersAreaHandler,
    statusList,
} from 'helpers/Filters/filtersAreaHandler';
import {
    getReportByBalanceBudgets,
    getReportByCompetenceLevel,
    getReportByManagers,
    getReportByPersons,
    getReportByPersonsExperience,
    getReportByProjects,
    getReportByQuarters,
    getReportByTypeProjects,
    getReportByUnusedPersons,
    getReportWorkLoadByPersons,
    getReportWorkLoadByProjects,
} from 'redux/thunks/reportsThunks';
import { getAllModules } from 'redux/thunks/modulesThunks';
import isEmpty from 'helpers/isEmpty';
import { synchronizeJira } from 'redux/thunks/synchronize';

const Reports = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isActive, setIsActive] = useState({});
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [isLoadingSync, setIsLoadingSync] = useState(false);
    const reportsData = useSelector((state) => state.reports);
    const monthFrom = useSelector((state) => state.reports.monthFrom);
    const monthTo = useSelector((state) => state.reports.monthTo);
    const managersList = useSelector(
        (state) => state.persons.DropdownListValuesFiltered
    );

    const systems = useSelector((state) => state.modules.data);
    useEffect(() => {
        if (accessByRole('Reports', 'getAllModules', currentRole)) {
            dispatch(getAllModules());
        }
    }, [dispatch]);

    const specialtiesAndCompetence = useSelector(
        (state) => state.specialtiesAndCompetence
    );

    const [selectedSpecialties, setSelectedSpecialties] = useState([]);
    const [selectedCompetence, setSelectedCompetence] = useState([]);
    const [projectType, setProjectType] = useState([]);
    const [selectedManager, setSelectedManager] = useState([]);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const initialSelectedYear = getInitialValueObj(
        new Date().getFullYear(),
        getYearsValues()
    );
    const [selectedYear, setSelectedYear] = useState(initialSelectedYear);
    const initialSelectedMonths = {
        from: 0,
        to: 11,
    };
    const [selectedMonth, setSelectedMonth] = useState(initialSelectedMonths);
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [filters, setFilters] = useState({});

    const loaderTextSync = 'Идёт синхронизация...';
    const resultLoaderText = () => {
        if (isLoadingSync) {
            return loaderTextSync;
        }
        return '';
    };
    const isShowLoader = isLoading || isLoadingSync;

    const currentRole = useSelector((state) => state.auth.userData.userRole);

    const allowedReportsFilter = (allReports) => {
        const allowedReportTypesForHR = [
            'PERSON_WORKLOAD',
            'PROJECT_WORKLOAD',
            'BY_COMPETENCE_LEVEL_TYPE',
            'UNUSED_PERSONS',
            'PERSONS_EXPERIENCE',
        ];
        const allowedReportTypesForManager = ['BY_PROJECT', 'BY_PERSON'];
        let allowedReportsList = allReports;
        if (currentRole === 'HR') {
            allowedReportsList = allReports.filter((el) =>
                allowedReportTypesForHR.includes(el.type)
            );
        }
        if (currentRole === 'MANAGER') {
            allowedReportsList = allReports.filter((el) =>
                allowedReportTypesForManager.includes(el.type)
            );
        }
        return allowedReportsList;
    };
    const allowedReports = allowedReportsFilter(reports);
    const [selectedReport, setSelectedReport] = useState(allowedReports[0]);

    const exportReport = useCallback(
        (res) => {
            const reportType = reports.find(
                (el) => el.id === selectedReport.id
            );
            const name =
                selectedReport.id === 9
                    ? `Отчёт ${reportType.value.toLowerCase()} ${
                          monthsArr[selectedMonth.getMonth()]
                      } ${selectedMonth.getFullYear()}г.`
                    : `Отчёт ${reportType.value.toLowerCase()} ${
                          selectedYear.value
                      }г.`;
            saveFile(name, res.data, 'text/csv');
        },
        [selectedMonth, selectedReport.id, selectedYear.value]
    );

    const getReport = (format = 'JSON') => {
        const params = {};
        if (format === 'csvFormat') {
            params.format = 'CSV';
        }
        if (isEmpty(selectedMonth)) {
            dispatch(setMonthFrom(selectedMonth.from));
            dispatch(setMonthTo(selectedMonth.to));
        }

        if (selectedYear.value) {
            if (selectedReport.id === 1) {
                if (
                    accessByRole('Reports', 'getReportByProjects', currentRole)
                ) {
                    dispatch(
                        getReportByProjects(
                            selectedYear,
                            selectedMonth.from,
                            selectedMonth.to,
                            isEmpty(isActive) ? isActive?.boolValue : '',
                            params,
                            format,
                            setIsLoading,
                            exportReport
                        )
                    );
                }
            }
            if (selectedReport.id === 2) {
                if (
                    accessByRole('Reports', 'getReportByPersons', currentRole)
                ) {
                    dispatch(
                        getReportByPersons(
                            selectedYear,
                            selectedMonth.from,
                            selectedMonth.to,
                            isEmpty(isActive) ? isActive?.boolValue : '',
                            params,
                            format,
                            setIsLoading,
                            exportReport
                        )
                    );
                }
            }
            if (selectedReport.id === 3) {
                if (
                    accessByRole(
                        'Reports',
                        'getReportWorkLoadByPersons',
                        currentRole
                    )
                ) {
                    dispatch(
                        getReportWorkLoadByPersons(
                            selectedYear,
                            selectedMonth.from,
                            selectedMonth.to,
                            isEmpty(isActive) ? isActive?.boolValue : '',
                            params,
                            format,
                            setIsLoading,
                            exportReport
                        )
                    );
                }
            }
            if (selectedReport.id === 4) {
                if (
                    accessByRole(
                        'Reports',
                        'getReportWorkLoadByProjects',
                        currentRole
                    )
                ) {
                    dispatch(
                        getReportWorkLoadByProjects(
                            selectedYear,
                            selectedMonth.from,
                            selectedMonth.to,
                            isEmpty(isActive) ? isActive?.boolValue : '',
                            params,
                            format,
                            setIsLoading,
                            exportReport
                        )
                    );
                }
            }
            if (selectedReport.id === 5) {
                if (
                    accessByRole(
                        'Reports',
                        'getReportByTypeProjects',
                        currentRole
                    )
                ) {
                    dispatch(
                        getReportByTypeProjects(
                            selectedYear,
                            selectedMonth.from,
                            selectedMonth.to,
                            isEmpty(isActive) ? isActive?.boolValue : '',
                            params,
                            format,
                            setIsLoading,
                            exportReport
                        )
                    );
                }
            }
            if (selectedReport.id === 6) {
                if (
                    accessByRole('Reports', 'getReportByManagers', currentRole)
                ) {
                    dispatch(
                        getReportByManagers(
                            selectedYear,
                            selectedMonth.from,
                            selectedMonth.to,
                            isEmpty(isActive) ? isActive?.boolValue : '',
                            params,
                            format,
                            setIsLoading,
                            exportReport
                        )
                    );
                }
            }
            if (selectedReport.id === 7) {
                if (
                    accessByRole(
                        'Reports',
                        'getReportByCompetenceLevel',
                        currentRole
                    )
                ) {
                    dispatch(
                        getReportByCompetenceLevel(
                            params,
                            format,
                            setIsLoading,
                            exportReport
                        )
                    );
                }
            }
            if (selectedReport.id === 8) {
                if (
                    accessByRole('Reports', 'getReportByQuarters', currentRole)
                ) {
                    dispatch(
                        getReportByQuarters(
                            selectedYear,
                            isEmpty(isActive) ? isActive?.boolValue : '',
                            params,
                            format,
                            setIsLoading,
                            exportReport
                        )
                    );
                }
            }
            if (selectedReport.id === 9) {
                if (
                    accessByRole(
                        'Reports',
                        'getReportByUnusedPersons',
                        currentRole
                    )
                ) {
                    dispatch(
                        getReportByUnusedPersons(
                            selectedYear,
                            monthFrom,
                            monthTo,
                            isEmpty(isActive) ? isActive?.boolValue : '',
                            params,
                            format,
                            setIsLoading,
                            exportReport
                        )
                    );
                }
            }
            if (selectedReport.id === 10) {
                if (
                    accessByRole(
                        'Reports',
                        'getReportByPersonsExperience',
                        currentRole
                    )
                ) {
                    dispatch(
                        getReportByPersonsExperience(
                            selectedSpecialties.map((elem) => elem?.value),
                            selectedCompetence.map((elem) => elem?.value),
                            params,
                            format,
                            setIsLoading,
                            exportReport
                        )
                    );
                }
            }
            if (selectedReport.id === 11) {
                if (
                    accessByRole(
                        'Reports',
                        'getReportByBalanceBudgets',
                        currentRole
                    )
                ) {
                    if (filters.projectName) {
                        params.projectName = filters.projectName;
                    }
                    dispatch(
                        getFilteredPersonsForDropDown({
                            specialityIds: '1,14',
                        })
                    );
                    dispatch(
                        getReportByBalanceBudgets(
                            selectedYear,
                            selectedMonth,
                            isEmpty(isActive) ? isActive.boolValue : '',
                            projectType.map(
                                (elem) => projectTypesList[elem.id]
                            ),
                            selectedManager.map((elem) => elem.id),
                            params,
                            format,
                            setIsLoading,
                            exportReport
                        )
                    );
                    dispatch(getAllProjects(filters));
                }
            }
        }
    };

    const handleClickOnSaveFilters = () => {
        getReport();
    };

    useEffect(() => {
        dispatch(getAllCompetences());
        dispatch(getAllSpecialties());
        setIsActive(statusList.find((elem) => elem.boolValue === true));
        return () => clearMonths();
    }, []);

    useEffect(() => {
        if (Object.keys(filters).length) setFilters({});
        getReport();
    }, [selectedReport]);

    const clearMonths = () => {
        dispatch(setMonthFrom(0));
        dispatch(setMonthTo(11));
    };

    const syncJira = () => {
        dispatch(
            synchronizeJira(
                {
                    moduleId: systems.find((elem) => elem.systemId === 2).id,
                    year: currentYear,
                    month: currentMonth,
                },
                getReport,
                setIsLoadingSync
            )
        );
    };

    const setMonthFromSelect = useCallback(
        (value) => {
            if (Object.keys(value).length) {
                if (value.id <= selectedMonth.to) {
                    setSelectedMonth((prev) => ({ ...prev, from: value.id }));
                } else {
                    useToastify(
                        'Конечный месяц должен быть больше или равен начальному',
                        'warning'
                    );
                }
            }
        },
        [selectedMonth.to]
    );

    const setMonthToSelect = useCallback(
        (value) => {
            if (Object.keys(value).length) {
                if (value.id >= selectedMonth.from) {
                    setSelectedMonth((prev) => ({ ...prev, to: value.id }));
                } else {
                    useToastify(
                        'Дата окончания периода не может быть меньше даты начала',
                        'warning'
                    );
                }
            }
        },
        [selectedMonth.from]
    );

    const getMonthSelectValue = (type) => {
        return monthsValues.find((el) => el.id === type);
    };

    useEffect(() => {
        if (selectedReport.id === 11) {
            if (dateFrom && dateTo && dateFrom > dateTo) {
                setDateTo('');
                useToastify(
                    'Дата окончания периода не может быть меньше даты начала',
                    'warning'
                );
            }
        }
    }, [selectedReport.id, dateFrom, dateTo]);

    const handleClickFilters = () => {
        setIsOpenFilters(true);
    };

    const dataForFilters = useMemo(() => {
        if (
            (selectedReport.id >= 1 && selectedReport.id < 7) ||
            selectedReport.id === 9
        ) {
            return {
                status: isActive,
                changeStatus: setIsActive,
                yearItems: getYearsValues(),
                selectedYear,
                changeSelectedYear: setSelectedYear,
                monthsValues,
                selectedMonthFrom: getMonthSelectValue(selectedMonth.from),
                changeSelectedMonthFrom: setMonthFromSelect,
                selectedMonthTo: getMonthSelectValue(selectedMonth.to),
                changeSelectedMonthTo: setMonthToSelect,
            };
        }
        if (selectedReport.id === 8) {
            return {
                status: isActive,
                changeStatus: setIsActive,
                yearItems: getYearsValues(),
                selectedYear,
                changeSelectedYear: setSelectedYear,
            };
        }
        if (selectedReport.id === 10) {
            return {
                selectedSpecialties,
                changeSelectedSpecialties: setSelectedSpecialties,
                specialitysList:
                    specialtiesAndCompetence.DropdownSpecialtiesListValues,
                selectedCompetence,
                changeSelectedCompetence: setSelectedCompetence,
                competenceList:
                    specialtiesAndCompetence.DropdownCompetenceListValues,
            };
        }
        if (selectedReport.id === 11) {
            return {
                status: isActive,
                changeStatus: setIsActive,
                projectType,
                changeProjectType: setProjectType,
                projectTypeList: projectTypesDropdownValues,
                selectedManager,
                changeSelectedManager: setSelectedManager,
                managersList,
                yearItems: getYearsValues(),
                selectedYear,
                changeSelectedYear: setSelectedYear,
                monthsValues,
                selectedMonthFrom: getMonthSelectValue(selectedMonth.from),
                changeSelectedMonthFrom: setMonthFromSelect,
                selectedMonthTo: getMonthSelectValue(selectedMonth.to),
                changeSelectedMonthTo: setMonthToSelect,
            };
        }

        return {};
    }, [
        isActive,
        managersList,
        projectType,
        selectedCompetence,
        selectedManager,
        selectedMonth.from,
        selectedMonth.to,
        selectedReport.id,
        selectedSpecialties,
        selectedYear,
        setMonthFromSelect,
        setMonthToSelect,
        specialtiesAndCompetence.DropdownCompetenceListValues,
        specialtiesAndCompetence.DropdownSpecialtiesListValues,
    ]);

    const filtersForModal = useMemo(
        () => filtersAreaHandler('REPORTS', dataForFilters, selectedReport.id),
        [dataForFilters, selectedReport.id]
    );

    const setInitialValues = () => {
        setIsActive(statusList.find((elem) => elem.boolValue === true));
        setSelectedYear(initialSelectedYear);
        setSelectedMonth(initialSelectedMonths);
    };

    return (
        <>
            <PageHeader
                title="Отчёты"
                reports={allowedReports}
                selectedReport={selectedReport}
                setSelectedReport={setSelectedReport}
                filtersButton={accessByRole(
                    'Reports',
                    'filtersButton',
                    currentRole
                )}
                handlerClickFiltersButton={handleClickFilters}
                uploadButton={accessByRole(
                    'Reports',
                    'uploadButton',
                    currentRole
                )}
                handlerUploadBtn={() => getReport('csvFormat')}
                syncButton={accessByRole('Reports', 'syncButton', currentRole)}
                syncJira={syncJira}
            />
            <FiltersModal
                filters={filtersForModal}
                isActive={isOpenFilters}
                setIsActive={setIsOpenFilters}
                onSave={handleClickOnSaveFilters}
                onClear={setInitialValues}
            />
            {isShowLoader ? (
                <Loader text={resultLoaderText()} />
            ) : (
                <div>
                    {selectedReport.id === 1 ? (
                        <ProjectsReportTable items={reportsData.projects} />
                    ) : null}
                    {selectedReport.id === 2 ? (
                        <PersonsReportTable items={reportsData.persons} />
                    ) : null}
                    {selectedReport.id === 3 ? (
                        <PersonsWorkloadReportTable
                            items={reportsData.personsWorkload}
                        />
                    ) : null}
                    {selectedReport.id === 4 ? (
                        <ProjectWorkloadReportTable
                            items={reportsData.projectWorkload}
                        />
                    ) : null}
                    {selectedReport.id === 5 ? (
                        <ProjectsTypesReportTable
                            items={reportsData.projectTypes}
                        />
                    ) : null}
                    {selectedReport.id === 6 ? (
                        <ManagersTable items={reportsData.managerKpi} />
                    ) : null}
                    {selectedReport.id === 7 ? (
                        <CompetenceLevelTable
                            items={reportsData.competenceLevel}
                        />
                    ) : null}
                    {selectedReport.id === 8 ? (
                        <ProjectsQuarterTable
                            items={reportsData.projectsQuarter}
                        />
                    ) : null}
                    {selectedReport.id === 9 ? (
                        <UnusedPersonsTable items={reportsData.unusedPersons} />
                    ) : null}
                    {selectedReport.id === 10 ? (
                        reportsData?.personsExperience?.infoByPersons
                            ?.length ? (
                            <Table
                                header={headers.personsExperience}
                                items={
                                    reportsData.personsExperience.infoByPersons
                                }
                                caseName="PERSONS_EXPERIENCE"
                                filters={filters}
                                setFilters={setFilters}
                            />
                        ) : (
                            <NoDataInfo />
                        )
                    ) : null}
                    {selectedReport.id === 11 ? (
                        reportsData?.balanceBudgets?.length ? (
                            <Table
                                header={headers.balanceBudgets}
                                items={reportsData.balanceBudgets}
                                caseName="BALANCE_BUDGETS"
                                filters={filters}
                                setFilters={setFilters}
                            />
                        ) : (
                            <NoDataInfo />
                        )
                    ) : null}
                </div>
            )}
        </>
    );
};

export default Reports;
