import { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { addSpeciality, editSpeciality } from 'redux/thunks/specialtiesThunks';
import useValidator from 'hooks/useValidator';
import Input from 'components/Input';
import Button from 'components/Button';
import styles from 'components/ModalsContent/SpecialityContent/styles.module.scss';

const SpecialityContent = ({ initialValues = {}, closeModal = () => {} }) => {
    const { id, name = '' } = initialValues;
    const [specialityValue, setSpecialityValue] = useState(name);
    const [submitTouched, setSubmitTouched] = useState(false);
    const dispatch = useDispatch();

    const onSave = () => {
        if (isValid) {
            if (id) {
                dispatch(editSpeciality(id, specialityValue, closeModal));
            } else {
                dispatch(addSpeciality(specialityValue, closeModal));
            }
        }
        setSubmitTouched(true);
    };

    const { isValid, validator } = useValidator({
        specialityValue,
    });

    return (
        <div className={styles.speciality_content}>
            <Input
                value={specialityValue}
                onChange={setSpecialityValue}
                placeholder="Наименование специализации"
                error={!validator.specialityValue && submitTouched}
            />
            <div className={styles.buttons_container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                />
                <Button text="Сохранить" onClick={onSave} />
            </div>
        </div>
    );
};

SpecialityContent.propTypes = {
    initialValues: shape({ string }),
    closeModal: func,
};
export default SpecialityContent;
