import miniLogo from 'icons/miniLogo.svg';

const PersonDefaultIcon = () => (
    <div
        style={{
            width: '128px',
            height: '128px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#12131A',
            borderRadius: '4px',
        }}
    >
        <img
            style={{ width: '54px', height: '48px' }}
            src={miniLogo}
            alt="miniLogo"
        />
    </div>
);

export default PersonDefaultIcon;
