import { GET_ASYNC_TASKS } from 'redux/types';

const defaultState = {
    data: {},
};

export const asyncTasks = (state = defaultState, action) => {
    switch (action.type) {
        case GET_ASYNC_TASKS:
            return {
                ...state,
                data: { ...action.payload },
            };
        default:
            return state;
    }
};
