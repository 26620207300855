import API from 'api/api';
import { useToastify } from 'helpers/toastify/useToastify';
import { logout } from 'redux/actions/auth';

export const synchronizeJira =
    (moduleId, callback = () => {}, setIsLoadingSync = () => {}) =>
    async (dispatch) => {
        setIsLoadingSync(true);
        try {
            const res = await API.synchronize.jiraSynchronize(moduleId);
            callback();
            useToastify('Синхронизация прошла успешно', 'success');
        } catch (err) {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        } finally {
            setIsLoadingSync(false);
        }
    };
