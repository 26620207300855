import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useValidator from 'hooks/useValidator';
import { func, shape, string } from 'prop-types';
import DropdownList from 'components/DropdownList';
import { addUserData, editUserData } from 'redux/actions/users';
import API from 'api/api';
import Input from 'components/Input';
import Button from 'components/Button';
import { useToastify } from 'helpers/toastify/useToastify';
import { isEmail } from 'helpers/editFormsValues';
import { systemRoles } from 'mock/mockData';
import styles from './styles.module.scss';

const UsersContent = ({ initialValues = {}, closeModal = () => {} }) => {
    const { id, items, additionalData } = initialValues;

    const [firstName, setFirstName] = useState(additionalData?.firstName);
    const [middleName, setMiddleName] = useState(additionalData?.middleName);
    const [lastName, setLastName] = useState(additionalData?.lastName);
    const [username, setUsername] = useState(items?.username);
    const [selectedPosition, setSelectedPosition] = useState(items?.position);
    const [selectedRole, setSelectedRole] = useState(
        systemRoles.find((el) => el.value === items?.role)
    );
    const [submitTouched, setSubmitTouched] = useState(false);
    const [emailInputError, setEmailInputError] = useState(false);

    const positions = useSelector(
        (state) => state.positions.DropdownPositionsListValues
    );

    const dispatch = useDispatch();

    const onSave = () => {
        if (isValid && isEmail(username) && !emailInputError) {
            if (id) {
                API.users
                    .editUser(id, {
                        firstName,
                        lastName,
                        middleName,
                        position: selectedPosition.value,
                        username,
                        role: selectedRole.value,
                    })
                    .then((res) => {
                        dispatch(editUserData(res.data));
                        useToastify('Пользователь изменён', 'success');
                        closeModal();
                    })
                    .catch((err) =>
                        useToastify(err?.message ?? 'Произошла ошибка', 'error')
                    );
            } else {
                API.users
                    .addUser({
                        firstName,
                        lastName,
                        middleName,
                        position: selectedPosition.value,
                        username,
                        role: selectedRole.value,
                    })
                    .then((res) => {
                        dispatch(addUserData(res.data));
                        useToastify('Пользователь добавлен', 'success');
                        closeModal();
                    })
                    .catch((err) =>
                        useToastify(err?.message ?? 'Произошла ошибка', 'error')
                    );
            }
        }
        setSubmitTouched(true);
    };

    const { isValid, validator } = useValidator({
        firstName,
        lastName,
        position: selectedPosition?.value,
        username,
        selectedRole: selectedRole?.value,
    });

    return (
        <div className={styles.users_content}>
            <div className={styles.users_inputs}>
                <Input
                    value={lastName}
                    onChange={setLastName}
                    placeholder="Фамилия"
                    error={!validator.lastName && submitTouched}
                />
                <Input
                    value={firstName}
                    onChange={setFirstName}
                    placeholder="Имя"
                    error={!validator.firstName && submitTouched}
                />
                <Input
                    value={middleName}
                    onChange={setMiddleName}
                    placeholder="Отчество"
                />
                <Input
                    value={username}
                    onChange={setUsername}
                    placeholder="Корпоративная почта"
                    type="email"
                    changeEmailInputError={setEmailInputError}
                    error={
                        (!validator.username || !isEmail(username)) &&
                        submitTouched
                    }
                />
                <DropdownList
                    setSelected={setSelectedRole}
                    selected={selectedRole}
                    items={systemRoles}
                    placeholderInput="Роль"
                    error={!validator.selectedRole && submitTouched}
                />
                <DropdownList
                    setSelected={setSelectedPosition}
                    selected={selectedPosition}
                    items={positions}
                    placeholderInput="Должность"
                    error={!validator.selectedPosition && submitTouched}
                />
            </div>
            <div className={styles.buttons_container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                />
                <Button text="Сохранить" onClick={onSave} />
            </div>
        </div>
    );
};

UsersContent.propTypes = {
    initialValues: shape({ string }),
    closeModal: func,
};
export default UsersContent;
