import { bool, func, string } from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const CvTag = ({
    isEditing = false,
    grayTags = false,
    tagName,
    delTag = () => {},
}) => {
    const cx = classNames.bind(styles);

    return (
        <span className={cx('cv-tag', { gray: grayTags })}>
            <span>{tagName}</span>
            {isEditing && (
                <button
                    type="button"
                    className={cx('cv-tag__btn')}
                    onClick={() => delTag(tagName)}
                >
                    &#10006;
                </button>
            )}
        </span>
    );
};

export default CvTag;

CvTag.propTypes = {
    grayTags: bool,
    isEditing: bool,
    delTag: func,
    tagName: string,
};
