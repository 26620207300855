import { bool } from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const ActiveStatus = ({ isActive }) => {
    const cx = classNames.bind(styles);

    return (
        <div
            className={cx(
                { activeStatus: isActive },
                { activeStatus_disabled: !isActive }
            )}
        >
            <p>{isActive ? 'Активен' : 'Неактивен'}</p>
        </div>
    );
};
export default ActiveStatus;

ActiveStatus.propTypes = {
    isActive: bool,
};
