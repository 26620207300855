const GitLabIcon = () => (
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25 46L33.4699 20.1625H16.5301L25 46Z"
            fill="#E24329"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25 46L16.5301 20.1625H4.65968L25 46Z"
            fill="#FC6D26"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.65968 20.1625L2.08582 28.0143C1.97139 28.3633 1.97139 28.7394 2.08582 29.0884C2.20025 29.4375 2.42324 29.7417 2.72282 29.9575L25.0002 46L4.65968 20.1625Z"
            fill="#FCA326"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.65968 20.1627H16.5301L11.4286 4.60055C11.1662 3.79971 10.0235 3.79992 9.76092 4.60055L4.65968 20.1627Z"
            fill="#E24329"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25 46L33.4699 20.1625H45.3403L25 46Z"
            fill="#FC6D26"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.3403 20.1625L47.9142 28.0143C48.0286 28.3633 48.0286 28.7394 47.9142 29.0884C47.7997 29.4375 47.5768 29.7417 47.2772 29.9575L25 46L45.3403 20.1625Z"
            fill="#FCA326"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.3403 20.1627H33.4699L38.5714 4.60055C38.8338 3.79971 39.9767 3.79992 40.2391 4.60055L45.3403 20.1627Z"
            fill="#E24329"
        />
    </svg>
);
export default GitLabIcon;
