import { GET_PERSONS_JIRA } from 'redux/types';

const defaultState = {
    data: [],
};

export const jiraPersons = (state = defaultState, action) => {
    switch (action.type) {
        case GET_PERSONS_JIRA:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};
