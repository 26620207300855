import { arrayOf, bool, func, shape, string } from 'prop-types';
import ShortTableHeader from 'components/Tables/ShortTable/ShortTableHeader';
import ShortTableBody from 'components/Tables/ShortTable/ShortTableBody';
import styles from './styles.module.scss';

const ShortTable = ({
    deleteIcon = false,
    editIcon = false,
    sortShort = '',
    header = '',
    items = [],
    openEditModal = () => {},
    openDeleteModal = () => {},
    onSortHandler = () => {},
}) => (
    <div className={styles.table__wrapper}>
        <table className={styles.table_main}>
            <ShortTableHeader
                header={header}
                tableIcons={editIcon || deleteIcon}
                onSortHandler={onSortHandler}
                sortShort={sortShort}
            />
            <ShortTableBody
                deleteIcon={deleteIcon}
                editIcon={editIcon}
                items={items}
                openEditModal={openEditModal}
                openDeleteModal={openDeleteModal}
            />
        </table>
    </div>
);

export default ShortTable;

ShortTable.propTypes = {
    deleteIcon: bool,
    editIcon: bool,
    header: string,
    sortShort: string,
    items: arrayOf(shape([])),
    openEditModal: func,
    openDeleteModal: func,
    onSortHandler: func,
};
