export const initPlaceholder = (arr, currentValue, setPlaceholder) => {
    const placeholder = { min: -1, max: -1 };
    if (currentValue.min === '') {
        placeholder.min = 0;
    }
    if (currentValue.max === '') {
        placeholder.max = 0;
    }
    if (arr.length) {
        placeholder.max = Math.max(...arr);
        placeholder.min = Math.min(...arr);
    }
    if (
        (placeholder.min === -1 || currentValue.min < placeholder.min) &&
        currentValue.min !== ''
    ) {
        placeholder.min = currentValue.min;
    }
    if (currentValue.max > placeholder.max && currentValue.max !== '') {
        placeholder.max = currentValue.max;
    }

    setPlaceholder((prev) => ({
        ...prev,
        placeholderMin: placeholder.min.toString(),
        placeholderMax: placeholder.max.toString(),
    }));
};

export const setFromToInputValue = (field, value, changeValue) => {
    if (field === 'from') {
        changeValue((prev) => ({ ...prev, from: value }));
    }
    if (field === 'to') {
        changeValue((prev) => ({ ...prev, to: value }));
    }
};

export const setFromToDateValue = (field, value, changeValue, callback) => {
    if (field === 'from') {
        changeValue((prev) => {
            if (prev.to && value) {
                if (prev.to.getTime() < value.getTime()) {
                    callback();
                    return { ...prev };
                }
            }
            return { ...prev, from: value };
        });
    }
    if (field === 'to') {
        changeValue((prev) => {
            if (prev.from && value) {
                if (prev.from.getTime() > value.getTime()) {
                    callback();
                    return { ...prev };
                }
            }
            return { ...prev, to: value };
        });
    }
};

export const valueForRequest = (valueFromInput) => {
    if (valueFromInput !== null && valueFromInput !== '') {
        return valueFromInput;
    }
    return '';
};
