import {
    ADD_ORGANIZATION_DATA,
    DELETE_ORGANIZATION_DATA,
    EDIT_ORGANIZATION_DATA,
    GET_ORGANIZATIONS_DATA,
} from 'redux/types';

const defaultState = {
    data: [],
    DropdownListValues: [],
};

export const organizations = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_ORGANIZATION_DATA:
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case DELETE_ORGANIZATION_DATA:
            return {
                ...state,
                data: state.data.filter((item) => item.id !== action.payload),
            };
        case EDIT_ORGANIZATION_DATA:
            return {
                ...state,
                data: [
                    ...state.data.filter(
                        (item) => item.id !== action.payload.id
                    ),
                    action.payload,
                ],
            };
        case GET_ORGANIZATIONS_DATA:
            return {
                ...state,
                data: action.payload,
                DropdownListValues: action.payload.map((el) => ({
                    id: el.id,
                    value: el.name,
                })),
            };
        default:
            return state;
    }
};
