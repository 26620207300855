import { useContext, useEffect, useState } from 'react';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import PageHeader from 'components/PageHeader';
import PositionContent from 'components/ModalsContent/PositionContent';
import ShortTable from 'components/Tables/ShortTable';
import Loader from 'components/Loader';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPositions } from 'redux/thunks/positionsThunks';
import Input from 'components/Input';
import { accessByRole } from 'helpers/roleHelpers';
import styles from './styles.module.scss';

const Positions = () => {
    const { openModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [sort, setSort] = useState('asc');
    const [positions, setPositions] = useState([]);

    const currentRole = useSelector((state) => state.auth.userData.userRole);
    const dispatch = useDispatch();
    const data = useSelector((state) => state.positions.positions);

    useEffect(() => {
        if (accessByRole('Positions', 'getAllPositions', currentRole)) {
            dispatch(
                getAllPositions(
                    {},
                    { property: 'name', order: `${sort}` },
                    setIsLoading
                )
            );
        }
    }, [dispatch, sort]);

    useEffect(() => {
        setPositions(data.filter((elem) => elem.name.includes(searchValue)));
    }, [data, searchValue]);

    const handleClick = (title, children) => {
        openModal({ title, children });
    };

    const onSortHandler = () => {
        if (sort === 'asc') setSort('desc');
        else setSort('asc');
    };

    return (
        <>
            <PageHeader
                title="Справочник должностей"
                addButton={accessByRole('Positions', 'addButton', currentRole)}
                addButtonText="Добавить должность"
                openAddModal={() => {
                    handleClick(
                        'Добавление должности',
                        <PositionContent closeModal={closeModal} />
                    );
                }}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.search_container}>
                        <Input
                            placeholder="Название должности"
                            isSearch
                            value={searchValue}
                            onChange={setSearchValue}
                            type="text"
                        />
                    </div>
                    <ShortTable
                        items={positions}
                        header="Должности"
                        editIcon={accessByRole(
                            'Positions',
                            'editIcon',
                            currentRole
                        )}
                        deleteIcon={accessByRole(
                            'Positions',
                            'deleteIcon',
                            currentRole
                        )}
                        openEditModal={(initial) => {
                            handleClick(
                                'Редактирование должности',
                                <PositionContent
                                    closeModal={closeModal}
                                    initialValues={initial}
                                />
                            );
                        }}
                        openDeleteModal={(initial) => {
                            handleClick(
                                `Вы действительно хотите удалить должность ${initial.name}?`,
                                <DeleteModal
                                    closeModal={closeModal}
                                    data={data}
                                    id={initial.id}
                                    tableType="positions"
                                />
                            );
                        }}
                        onSortHandler={onSortHandler}
                        sortShort={sort}
                    />
                </>
            )}
        </>
    );
};

export default Positions;
