export const statusList = [
    { id: 0, value: 'Активен', boolValue: true },
    { id: 1, value: 'Неактивен', boolValue: false },
];

const personsFilters = (body) => [
    {
        id: 0,
        typeFilter: 'dropDown',
        text: 'Статус',
        items: statusList,
        value: body.status,
        changeValue: body.changeStatus,
        multiselect: false,
    },
    {
        id: 1,
        typeFilter: 'dropDown',
        text: 'Сотрудник',
        items: body.personsList,
        value: body.fullName,
        changeValue: body.changeFullName,
        multiselect: true,
    },
    {
        id: 2,
        typeFilter: 'dropDown',
        text: 'Должность',
        items: body.positionsList,
        value: body.position,
        changeValue: body.changePosition,
        multiselect: true,
    },
    {
        id: 3,
        typeFilter: 'dropDown',
        text: 'Специализация',
        items: body.specialityList,
        value: body.speciality,
        changeValue: body.changeSpeciality,
        multiselect: true,
    },
    {
        id: 4,
        typeFilter: 'dropDown',
        text: 'Уровень компетенций',
        items: body.competencesList,
        value: body.competence,
        changeValue: body.changeCompetence,
        multiselect: true,
    },
    {
        id: 5,
        typeFilter: 'inputRange',
        text: 'Табельный номер',
        symbolLimit: 10,
        inputRange: body.tabelFilter,
        changeValue: body.changeTabelFilter,
    },
];
const personsCVFilters = (body) => [
    {
        id: 0,
        typeFilter: 'dropDown',
        text: 'Статус',
        items: statusList,
        value: body.status,
        changeValue: body.changeStatus,
        multiselect: false,
    },
    {
        id: 1,
        typeFilter: 'dropDown',
        text: 'Сотрудник',
        items: body.personsCVList,
        value: body.selectedPersons,
        changeValue: body.changeSelectedPersons,
        multiselect: true,
    },
    {
        id: 2,
        typeFilter: 'dropDown',
        text: 'Специализация',
        items: body.specialityList,
        value: body.selectedSpeciality,
        changeValue: body.changeSelectedSpeciality,
        multiselect: true,
    },
    {
        id: 3,
        typeFilter: 'dropDown',
        text: 'Уровень компетенций',
        items: body.competencesList,
        value: body.selectedСompetences,
        changeValue: body.changeSelectedСompetences,
        multiselect: true,
    },
    {
        id: 4,
        typeFilter: 'dropDown',
        text: 'Языки программирования',
        items: body.programmingLanguageList,
        value: body.selectedProgrammingLanguage,
        changeValue: body.changeSelectedProgrammingLanguage,
        multiselect: true,
    },
    {
        id: 5,
        typeFilter: 'dropDown',
        text: 'Профессиональные навыки',
        items: body.profSkillsList,
        value: body.selectedProfSkills,
        changeValue: body.changeProfSkills,
        multiselect: true,
    },
    {
        id: 6,
        typeFilter: 'dropDown',
        text: 'Инструменты',
        items: body.toolsList,
        value: body.selectedTools,
        changeValue: body.changeSelectedTools,
        multiselect: true,
    },
    {
        id: 7,
        typeFilter: 'dropDown',
        text: 'Операционные системы',
        items: body.OSList,
        value: body.selectedOS,
        changeValue: body.changeSelectedOS,
        multiselect: true,
    },
    {
        id: 8,
        typeFilter: 'dropDown',
        text: 'Иностранные языки',
        items: body.foreignLanguagesList,
        value: body.selectedForeignLanguages,
        changeValue: body.changeSelectedForeignLanguages,
        multiselect: true,
    },
    {
        id: 9,
        typeFilter: 'inputRange',
        text: 'Общий стаж',
        inputRange: body.experience,
        changeValue: body.changeExperience,
    },
    {
        id: 10,
        typeFilter: 'inputRange',
        text: 'Стаж в компании',
        inputRange: body.corpExperience,
        changeValue: body.changeCorpExperience,
    },
];
const baseTariffRatesFilters = (body) => [
    {
        id: 0,
        typeFilter: 'dropDown',
        text: 'Сотрудник',
        items: body.personsList,
        value: body.persons,
        changeValue: body.changePersons,
        multiselect: true,
    },
    {
        id: 1,
        typeFilter: 'inputRange',
        text: 'Тарифная ставка в час',
        type: 'budget',
        inputRange: body.tariffRateHour,
        changeValue: body.changeTariffRateHour,
    },
    {
        id: 2,
        typeFilter: 'inputRange',
        text: 'Тарифная ставка в день',
        type: 'budget',
        inputRange: body.tariffRateDay,
        changeValue: body.changeTariffRateDay,
    },
];

const projectsFilters = (body) => [
    {
        id: 0,
        typeFilter: 'dropDown',
        text: 'Статус',
        items: statusList,
        value: body.status,
        changeValue: body.changeStatus,
        multiselect: false,
    },
    {
        id: 1,
        typeFilter: 'dropDown',
        text: 'Проект',
        items: body.projectsItems,
        value: body.projects,
        changeValue: body.changeProjects,
        multiselect: true,
    },
    {
        id: 2,
        typeFilter: 'dropDown',
        text: 'Тип проекта',
        items: body.typeProjectItems,
        value: body.typeProject,
        changeValue: body.changeTypeProject,
        multiselect: true,
    },
    {
        id: 3,
        typeFilter: 'dropDown',
        text: 'Менеджер',
        items: body.managerList,
        value: body.manager,
        changeValue: body.changeManager,
        multiselect: true,
    },
    {
        id: 4,
        typeFilter: 'datePicker',
        text: 'Дата',
        date: body.date,
        changeDate: body.changeDate,
    },
    {
        id: 5,
        typeFilter: 'inputRange',
        type: 'budget',
        text: 'Бюджет',
        inputRange: body.budget,
        changeValue: body.changeBudget,
    },
];

const projectsTeamsFilters = (body) => [
    {
        id: 0,
        typeFilter: 'dropDown',
        text: 'Статус',
        items: statusList,
        value: body.status,
        changeValue: body.changeStatus,
        multiselect: false,
    },
];

const adminPanel = (body) => [
    {
        id: 0,
        typeFilter: 'dropDown',
        text: 'Пользователь',
        items: body.fullNameList,
        value: body.fullName,
        changeValue: body.changeFullName,
        multiselect: true,
    },
    {
        id: 1,
        typeFilter: 'dropDown',
        text: 'Корпоративная почта',
        items: body.usernameList,
        value: body.username,
        changeValue: body.changeUsername,
        multiselect: true,
    },
    {
        id: 2,
        typeFilter: 'dropDown',
        text: 'Роль',
        items: body.roleList,
        value: body.role,
        changeValue: body.changeRole,
        multiselect: true,
    },
    {
        id: 3,
        typeFilter: 'dropDown',
        text: 'Должность',
        items: body.positionList,
        value: body.position,
        changeValue: body.changePosition,
        multiselect: true,
    },
];

const productionPlansFilters = (body) => [
    {
        id: 0,
        typeFilter: 'dropDown',
        text: 'Сотрудник',
        items: body.personsList,
        value: body.selectedPersons,
        changeValue: body.changePersons,
        multiselect: true,
    },
    {
        id: 1,
        typeFilter: 'dropDown',
        text: 'Специализация',
        items: body.specialityList,
        value: body.selectedSpeciality,
        changeValue: body.changeSpeciality,
        multiselect: true,
    },
    {
        id: 2,
        typeFilter: 'dropDown',
        text: 'Организация',
        items: body.organizationsList,
        value: body.selectedOrganizations,
        changeValue: body.changeOrganizations,
        multiselect: true,
    },
    {
        id: 3,
        typeFilter: 'dropDown',
        text: 'Менеджер',
        items: body.managerList,
        value: body.selectedManagers,
        changeValue: body.changeManagers,
        multiselect: true,
    },
    {
        id: 4,
        typeFilter: 'dropDown',
        text: 'Проект',
        items: body.projectsList,
        value: body.selectedProjects,
        changeValue: body.changeProjects,
        multiselect: true,
    },
    {
        id: 5,
        typeFilter: 'dropDown',
        text: 'Тип проекта',
        items: body.typesProject,
        value: body.selectedTypesProject,
        changeValue: body.changeTypesProject,
        multiselect: true,
    },
    {
        id: 6,
        typeFilter: 'inputRange',
        text: 'Плановые часы',
        inputRange: body.planHours,
        changeValue: body.changePlanHours,
    },
    {
        id: 7,
        typeFilter: 'inputRange',
        text: 'Фактические часы',
        inputRange: body.factHours,
        changeValue: body.changeFactHours,
    },
    {
        id: 8,
        typeFilter: 'inputRange',
        text: 'Плановый месяц, руб',
        inputRange: body.planMonth,
        changeValue: body.changePlanMonth,
    },
    {
        id: 9,
        typeFilter: 'inputRange',
        text: 'Фактический месяц, руб',
        inputRange: body.factMonth,
        changeValue: body.changeFactMonth,
    },
    {
        id: 10,
        typeFilter: 'inputRange',
        text: 'Ставка, руб/час',
        inputRange: body.tariffRateHour,
        changeValue: body.changeTariffRateHour,
    },
    {
        id: 11,
        typeFilter: 'inputRange',
        text: 'Отклонение, час',
        type: 'deviation',
        inputRange: body.deviationHours,
        changeValue: body.changeDeviationHours,
    },
    {
        id: 12,
        typeFilter: 'inputRange',
        text: 'Отклонение, руб',
        type: 'deviation',
        inputRange: body.deviationMonth,
        changeValue: body.changeDeviationMonth,
    },
    {
        id: 13,
        typeFilter: 'inputRange',
        text: 'Сумма в рамках договора, руб',
        inputRange: body.amountTheContract,
        changeValue: body.changeAmountTheContract,
    },
];

const unusedPersonsFilters = (body) => [
    {
        id: 0,
        typeFilter: 'dropDown',
        text: 'Сотрудник',
        items: body.personsList,
        value: body.selectedPersons,
        changeValue: body.changePersons,
        multiselect: true,
    },
    {
        id: 1,
        typeFilter: 'inputRange',
        text: 'Плановые часы',
        inputRange: body.planHours,
        changeValue: body.changePlanHours,
    },
    {
        id: 2,
        typeFilter: 'inputRange',
        text: 'Фактические часы',
        inputRange: body.factHours,
        changeValue: body.changeFactHours,
    },
    {
        id: 3,
        typeFilter: 'inputRange',
        text: 'Плановый месяц, руб',
        inputRange: body.planMonth,
        changeValue: body.changePlanMonth,
    },
    {
        id: 4,
        typeFilter: 'inputRange',
        text: 'Фактический месяц, руб',
        inputRange: body.factMonth,
        changeValue: body.changeFactMonth,
    },
    {
        id: 5,
        typeFilter: 'inputRange',
        text: 'Ставка, руб/час',
        inputRange: body.tariffRateHour,
        changeValue: body.changeTariffRateHour,
    },
    {
        id: 6,
        typeFilter: 'inputRange',
        text: 'Отклонение, час',
        type: 'deviation',
        inputRange: body.deviationHours,
        changeValue: body.changeDeviationHours,
    },
    {
        id: 7,
        typeFilter: 'inputRange',
        text: 'Отклонение, руб',
        type: 'deviation',
        inputRange: body.deviationMonth,
        changeValue: body.changeDeviationMonth,
    },
];

const reportsFilters = (body, selectedId) => {
    switch (selectedId) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 9:
            return [
                {
                    id: 0,
                    typeFilter: 'dropDown',
                    text: 'Статус',
                    items: statusList,
                    value: body.status,
                    changeValue: body.changeStatus,
                    multiselect: false,
                },
                {
                    id: 1,
                    typeFilter: 'dropDown',
                    text: 'Год',
                    items: body.yearItems,
                    value: body.selectedYear,
                    changeValue: body.changeSelectedYear,
                    multiselect: false,
                },
                {
                    id: 2,
                    typeFilter: 'dropDown',
                    text: 'Месяц начало',
                    items: body.monthsValues,
                    value: body.selectedMonthFrom,
                    changeValue: body.changeSelectedMonthFrom,
                    multiselect: false,
                },
                {
                    id: 3,
                    typeFilter: 'dropDown',
                    text: 'Месяц конец',
                    items: body.monthsValues,
                    value: body.selectedMonthTo,
                    changeValue: body.changeSelectedMonthTo,
                    multiselect: false,
                },
            ];
        case 8:
            return [
                {
                    id: 0,
                    typeFilter: 'dropDown',
                    text: 'Статус',
                    items: statusList,
                    value: body.status,
                    changeValue: body.changeStatus,
                    multiselect: false,
                },
                {
                    id: 1,
                    typeFilter: 'dropDown',
                    text: 'Год',
                    items: body.yearItems,
                    value: body.selectedYear,
                    changeValue: body.changeSelectedYear,
                    multiselect: false,
                },
            ];
        case 10:
            return [
                {
                    id: 0,
                    typeFilter: 'dropDown',
                    text: 'Специализация',
                    items: body.specialitysList,
                    value: body.selectedSpecialties,
                    changeValue: body.changeSelectedSpecialties,
                    multiselect: true,
                },
                {
                    id: 1,
                    typeFilter: 'dropDown',
                    text: ' Компетенция',
                    items: body.competenceList,
                    value: body.selectedCompetence,
                    changeValue: body.changeSelectedCompetence,
                    multiselect: true,
                },
            ];
        case 11:
            return [
                {
                    id: 0,
                    typeFilter: 'dropDown',
                    text: 'Статус',
                    items: statusList,
                    value: body.status,
                    changeValue: body.changeStatus,
                    multiselect: false,
                },
                {
                    id: 1,
                    typeFilter: 'dropDown',
                    text: 'Тип проекта',
                    items: body.projectTypeList,
                    value: body.projectType,
                    changeValue: body.changeProjectType,
                    multiselect: true,
                },
                {
                    id: 2,
                    typeFilter: 'dropDown',
                    text: 'Менеджер',
                    items: body.managersList,
                    value: body.selectedManager,
                    changeValue: body.changeSelectedManager,
                    multiselect: true,
                },
                {
                    id: 3,
                    typeFilter: 'dropDown',
                    text: 'Год',
                    items: body.yearItems,
                    value: body.selectedYear,
                    changeValue: body.changeSelectedYear,
                    multiselect: false,
                },
                {
                    id: 4,
                    typeFilter: 'dropDown',
                    text: 'Месяц начало',
                    items: body.monthsValues,
                    value: body.selectedMonthFrom,
                    changeValue: body.changeSelectedMonthFrom,
                    multiselect: false,
                },
                {
                    id: 5,
                    typeFilter: 'dropDown',
                    text: 'Месяц конец',
                    items: body.monthsValues,
                    value: body.selectedMonthTo,
                    changeValue: body.changeSelectedMonthTo,
                    multiselect: false,
                },
            ];
        default:
            return [];
    }
};

export const filtersAreaHandler = (type, body, selectedId) => {
    switch (type) {
        case 'PERSONS':
            return personsFilters(body);
        case 'PROJECTS_TEAM':
            return projectsTeamsFilters(body);
        case 'PERSONS_CV':
            return personsCVFilters(body);
        case 'BASE_TARIFFS':
            return baseTariffRatesFilters(body);
        case 'PROJECTS':
            return projectsFilters(body);
        case 'PRODUCTION_PLANS':
            return productionPlansFilters(body);
        case 'UNUSED_PERSONS':
            return unusedPersonsFilters(body);
        case 'REPORTS':
            return reportsFilters(body, selectedId);
        case 'ADMIN_PANEL':
            return adminPanel(body);
        default:
            return [];
    }
};
