import { Fragment } from 'react';
import { months } from 'helpers/tableHandler';
import { shape } from 'prop-types';
import styles from './styles.module.scss';

const TableHeader = ({ items = {} }) => (
    <thead className={styles.table__head}>
        <tr>
            <td rowSpan="2">Тип Проекта</td>
            <td rowSpan="2">Проект</td>
            <td colSpan="2">Итого</td>
            {items?.totalInfoByPeriods &&
                items?.totalInfoByPeriods.map((item, ind) => (
                    <td key={ind} colSpan="2">
                        {months[item.month - 1]}
                    </td>
                ))}
        </tr>
        <tr className={styles.table__header_tr_inside}>
            <td>План</td>
            <td>Факт</td>
            {items?.totalInfoByPeriods &&
                items?.totalInfoByPeriods.map((item, ind) => (
                    <Fragment key={ind}>
                        <td>План</td>
                        <td>Факт</td>
                    </Fragment>
                ))}
        </tr>
    </thead>
);

export default TableHeader;

TableHeader.propTypes = {
    items: shape({}),
};
