import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useValidator from 'hooks/useValidator';
import { func } from 'prop-types';
import Button from 'components/Button';
import DropdownList from 'components/DropdownList';
import { getAllPersons } from 'redux/thunks/personThunks';
import Textarea from 'components/Textarea';
import CVChangeForm from 'components/Pages/CVPage/CVChangeForm';
import { cvModel } from 'mock/mockData';
import { postPersonCv } from 'redux/thunks/cvThunks';
import { getAllSpecialties } from 'redux/thunks/specialtiesThunks';
import styles from 'components/ModalsContent/CvPersonAddContent/styles.module.scss';

const СvPersonAddContent = ({ closeModal = () => {} }) => {
    const dispatch = useDispatch();
    const specialtiesList = useSelector(
        (state) => state.specialtiesAndCompetence.DropdownSpecialtiesListValues
    );
    const personsList = useSelector(
        (state) => state.persons.DropdownListValues
    );

    const [submitTouched, setSubmitTouched] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState({});
    const [selectedSpeciality, setSelectedSpeciality] = useState({});
    const [comment, setComment] = useState('');
    const [contentCvModal, setContentCvModal] = useState({
        programmingLanguage: [],
        professionalSkills: [],
        tools: [],
        operatingSystems: [],
        foreignLanguages: [],
        education: [],
        certificates: [],
    });

    useEffect(() => {
        dispatch(getAllPersons({}, { property: 'fullName', order: 'asc' }));
        dispatch(getAllSpecialties());
    }, []);

    const onSave = () => {
        if (isValid) {
            postPersonCv(
                {
                    personId: selectedPerson.id,
                    specialityName: selectedSpeciality.value,
                    ...contentCvModal,
                    comment,
                },
                dispatch,
                closeModal
            );
        }
        setSubmitTouched(true);
    };

    const { isValid, validator } = useValidator({
        selectedSpeciality: selectedSpeciality?.value,
        selectedPerson: selectedSpeciality?.value,
    });

    return (
        <div className={styles.cv_add__content}>
            <div>
                <DropdownList
                    selected={selectedPerson}
                    setSelected={setSelectedPerson}
                    placeholderInput="Сотрудник"
                    items={personsList}
                    error={!validator.selectedPerson && submitTouched}
                />
                <DropdownList
                    setSelected={setSelectedSpeciality}
                    selected={selectedSpeciality}
                    items={specialtiesList}
                    placeholderInput="Специализация"
                    error={!validator.selectedSpeciality && submitTouched}
                />
                <div className={styles.content_form_tag}>
                    {Object.keys(cvModel).map((field, index) =>
                        field !== 'experience' ? (
                            <div key={index} className={styles.form}>
                                <CVChangeForm
                                    existingTagsList={contentCvModal[field]}
                                    info={contentCvModal}
                                    fieldName={field}
                                    setInfo={setContentCvModal}
                                    isEditing
                                    placeholder={cvModel[field].title}
                                />
                            </div>
                        ) : null
                    )}
                </div>
                <Textarea
                    value={comment}
                    onChange={setComment}
                    placeholder="Комментарий"
                />
            </div>
            <div className={styles.buttons__container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                />
                <Button text="Сохранить" onClick={onSave} />
            </div>
        </div>
    );
};

СvPersonAddContent.propTypes = {
    closeModal: func,
};
export default СvPersonAddContent;
