import { useContext, useEffect } from 'react';
import { func } from 'prop-types';
import API from 'api/api';
import { checkStatusAsyncTasks } from 'redux/thunks/asyncTaskThunks';
import { setUnlodaProductionPlanEroror } from 'redux/actions/productionPlans';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import Button from 'components/Button';
import LoadFile from 'components/loadFileButton';
import ProductionPlansErrorHandler from 'components/ModalsContent/ProductionPlansErrorModal';
import { useToastify } from 'helpers/toastify/useToastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styles from 'components/ModalsContent/DownloadButtonsProductionPlans/styles.module.scss';

const DownloadInProductionPlans = ({
    closeModal = () => {},
    getProductionPlans = () => {},
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const addFilePlan = (file) => API.upload.plan(file);
    const addFileFact = (file) => API.upload.fact(file);
    const addProductionPlan = (file) => API.upload.ProductionPlan(file);
    const { openModal } = useContext(ModalContext);

    const onLoadFilePlan = (file) => {
        closeModal();
        addFilePlan(file)
            .then((res) => {
                dispatch(setUnlodaProductionPlanEroror(res.data));
                navigate('/production-plans/error-report-card');
                useToastify('План начал загружаться', 'success');
            })
            .catch((res) =>
                useToastify(
                    res.message
                        ? `Ошибка при загрузке плана, ${res.message}`
                        : 'Ошибка при загрузке плана',
                    'error'
                )
            );
    };
    const onLoadFileFact = (file) => {
        closeModal();
        addFileFact(file)
            .then((res) => {
                ProductionPlansErrorHandler(
                    res.data,
                    openModal,
                    getProductionPlans
                );
                useToastify('План успешно загружен', 'success');
            })
            .catch((res) =>
                useToastify(
                    res.message
                        ? `Ошибка при загрузке плана, ${res.message}`
                        : 'Ошибка при загрузке плана',
                    'error'
                )
            );
    };
    const onLoadFileProductionPlan = (file) => {
        closeModal();
        addProductionPlan(file)
            .then((res) => {
                dispatch(checkStatusAsyncTasks());
                ProductionPlansErrorHandler(
                    res.data,
                    openModal,
                    getProductionPlans
                );
                useToastify('Производственный план начал загрузку', 'success');
            })
            .catch((res) =>
                useToastify(
                    res.message
                        ? `Ошибка при загрузке плана, ${res.message}`
                        : 'Ошибка при загрузке плана',
                    'error'
                )
            );
    };

    return (
        <div className={styles.modal__content}>
            <div className={styles.modal__main_container}>
                <div className={styles.modal__main_container_btn}>
                    <LoadFile
                        idFile="upload_file_plan"
                        text="Табель учета рабочего времени"
                        onLoadFile={onLoadFilePlan}
                        formDataKey="file"
                    />
                </div>
                <div className={styles.modal__main_container_btn}>
                    <LoadFile
                        idFile="upload_file_fact"
                        text="Отработанные часы"
                        onLoadFile={onLoadFileFact}
                        formDataKey="file"
                    />
                </div>
                <div className={styles.modal__main_container_btn}>
                    <LoadFile
                        idFile="upload_file_prod_plan"
                        text="Производственный план"
                        onLoadFile={onLoadFileProductionPlan}
                        formDataKey="file"
                    />
                </div>
            </div>
            <div className={styles.button_container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                    wide
                />
            </div>
        </div>
    );
};

DownloadInProductionPlans.propTypes = {
    closeModal: func,
    getProductionPlans: func,
};
export default DownloadInProductionPlans;
