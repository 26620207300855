import API from 'api/api';

import { useToastify } from 'helpers/toastify/useToastify';
import { logout } from 'redux/actions/auth';
import {
    editProjectTeamData,
    getProjectTeamsData,
} from 'redux/actions/projectTeams';

export const getAllTeams = (projectIds) => (dispatch) => {
    API.teams
        .getAllTeams({ projectIds })
        .then((res) => {
            dispatch(getProjectTeamsData(res.data.content));
        })
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        });
};

export const editTeamPeriod = (id, data, closeModal) => async (dispatch) => {
    try {
        const res = await API.periods.editTeamPeriod(id, { ...data });
        dispatch(editProjectTeamData(res.data));
    } catch (err) {
        if (err.name === 'UNAUTHORIZED') {
            dispatch(logout());
            localStorage.clear();
        }
        useToastify(err?.message ?? 'Произошла ошибка', 'error');
    } finally {
        closeModal();
    }
};
