import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Directories from 'components/Pages/Directories';
import Reports from 'components/Pages/Reports';
import Projects from 'components/Pages/Projects';
import ProjectTeams from 'components/Pages/ProjectTeams';
import Organizations from 'components/Pages/Directories/DirectoriesPages/Organizations';
import Specialties from 'components/Pages/Directories/DirectoriesPages/Specialties';
import Persons from 'components/Pages/Directories/DirectoriesPages/Persons';
import BaseTariffRates from 'components/Pages/Directories/DirectoriesPages/BaseTariffRates';
import PersonsCV from 'components/Pages/Directories/DirectoriesPages/PersonsCV';
import ProductionPlans from 'components/Pages/ProductionPlans';
import AdminPanel from 'components/Pages/AdminPanel';
// import UsersCalendar from 'components/Pages/UsersCalendar';
import User from 'components/Pages/Profile';
import CVPage from 'components/Pages/CVPage';
import Modules from 'components/Pages/Modules';
import NotFound from 'components/Pages/NotFound';
import Positions from 'components/Pages/Directories/DirectoriesPages/Positions';
import ImportProject from 'components/Pages/Projects/ImportProjects';
import ImportPersons from 'components/Pages/Directories/DirectoriesPages/Persons/ImportPersons';
import ReportCardPage from 'components/Pages/ProductionPlans/ReportCard';
import styles from './styles.module.scss';

const mainPagesRoutes = [
    {
        id: 0,
        title: 'default',
        path: '/',
        accessToPages: [
            'ADMIN',
            'SUPERVISOR',
            'MANAGER',
            'ACCOUNTANT',
            'HR',
            'PS',
            'USER',
        ],
        component: <User />,
    },
    {
        id: 1,
        title: 'Управление справочниками',
        path: '/directories',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
        component: <Directories />,
    },
    {
        id: 2,
        title: 'Команды проектов',
        path: '/teams',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
        component: <ProjectTeams />,
    },
    {
        id: 3,
        title: 'Проекты компании',
        path: '/projects',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER'],
        component: <Projects />,
    },
    {
        id: 4,
        title: 'Планы производства',
        path: '/production-plans',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'ACCOUNTANT'],
        component: <ProductionPlans />,
    },
    {
        id: 5,
        title: 'Отчёты',
        path: '/reports',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'ACCOUNTANT', 'HR'],
        component: <Reports />,
    },
    {
        id: 6,
        title: 'Панель администратора',
        path: '/admin-panel',
        accessToPages: ['ADMIN'],
        component: <AdminPanel />,
    },
    {
        id: 7,
        title: 'Справочник организаций',
        path: '/directories/organizations',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'ACCOUNTANT'],
        component: <Organizations />,
    },
    {
        id: 8,
        title: 'Справочник специализаций',
        path: '/directories/specialties',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
        component: <Specialties />,
    },
    {
        id: 9,
        title: 'Справочник сотрудников',
        path: '/directories/workers',
        accessToPages: [
            'ADMIN',
            'SUPERVISOR',
            'MANAGER',
            'ACCOUNTANT',
            'HR',
            'PS',
        ],
        component: <Persons />,
    },
    {
        id: 10,
        title: 'Справочник базовых тарифных ставок',
        path: '/directories/base-tariff-rates',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'ACCOUNTANT'],
        component: <BaseTariffRates />,
    },
    {
        id: 11,
        title: 'Профиль',
        path: '/user',
        accessToPages: [
            'ADMIN',
            'SUPERVISOR',
            'MANAGER',
            'ACCOUNTANT',
            'HR',
            'PS',
            'USER',
        ],
        component: <User />,
    },
    // {
    //     id: 12,
    //     title: 'Календарь событий',
    //     path: '/calendar',
    //     accessToPages: ['ADMIN', 'SUPERVISOR', 'HR', 'PS'],
    //     component: <UsersCalendar />,
    // },
    {
        id: 13,
        title: 'cv',
        path: '/cv/:id',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR', 'USER'],
        component: <CVPage />,
    },
    {
        id: 14,
        title: 'Справочник CV',
        path: '/directories/persons-cv',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
        component: <PersonsCV />,
    },
    {
        id: 15,
        title: 'Модули',
        path: '/modules',
        accessToPages: ['ADMIN', 'SUPERVISOR'],
        component: <Modules />,
    },
    {
        id: 16,
        title: 'Импорт проектов',
        path: '/projects/import',
        accessToPages: ['ADMIN', 'SUPERVISOR'],
        component: <ImportProject />,
    },
    {
        id: 17,
        title: 'Импорт сотрудников',
        path: '/directories/workers/import',
        accessToPages: ['ADMIN', 'SUPERVISOR'],
        component: <ImportPersons />,
    },
    {
        id: 18,
        title: 'Исправление ошибки в загрузке табеля',
        path: '/production-plans/error-report-card',
        accessToPages: ['ADMIN', 'SUPERVISOR'],
        component: <ReportCardPage />,
    },
    {
        id: 19,
        title: 'Страница не найдена',
        path: '*',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR', 'USER'],
        component: <NotFound />,
    },
    {
        id: 20,
        title: 'Справочник должностей',
        path: '/directories/positions',
        accessToPages: [
            'ADMIN',
            'SUPERVISOR',
            'MANAGER',
            'ACCOUNTANT',
            'HR',
            'PS',
        ],
        component: <Positions />,
    },
];

const MainRoutes = () => {
    const currentRole = useSelector((state) => state.auth.userData.userRole);

    return (
        <div className={styles.homepage__main_window}>
            <Routes>
                {mainPagesRoutes
                    .filter(
                        (el) => el.accessToPages.indexOf(currentRole) !== -1
                    )
                    .map((item) => (
                        <Route
                            key={item.id}
                            path={item.path}
                            element={item.component}
                        />
                    ))}
            </Routes>
        </div>
    );
};

export default MainRoutes;
