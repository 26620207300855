import { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accessByRole } from 'helpers/roleHelpers';
import PageHeader from 'components/PageHeader';
import Table from 'components/Tables/Table';
import { headers, projectType } from 'helpers/tableHandler';
import DeleteModal from 'components/ModalsContent/DeleteModal';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import ProductionPlansAddContent from 'components/ModalsContent/ProductionPlansAddContent';
import ProjectTeamsContent from 'components/ModalsContent/ProjectTeamsContent';
import ProductionPlansEditContent from 'components/ModalsContent/ProductionPlansEditContent';
import ProductionPlansCalendar from 'components/ModalsContent/ProductionPlansCalendar';
import DownloadInProductionPlans from 'components/ModalsContent/DownloadButtonsProductionPlans';
import UploadInProductionPlans from 'components/ModalsContent/UploadButtonsInProductionPlans';
import TimeModal from 'components/ModalsContent/TimeModal';
import JournalModal from 'components/ModalsContent/JournalModal';
import Loader from 'components/Loader';
import { setCurrentDate } from 'redux/actions/productionPlans';
import { getAllUnusedPersons } from 'redux/thunks/unusedPersonsThunks';
import { editSort } from 'helpers/editFormsValues';
import classNames from 'classnames/bind';
import { getAllPlans } from 'redux/thunks/productionPlansThunks';
import { production_plans, projectTypesList } from 'mock/mockData';
import FiltersModal from 'components/Filters/FiltersModal';
import { filtersAreaHandler } from 'helpers/Filters/filtersAreaHandler';
import {
    getAllPersonsForDropDown,
    getFilteredPersonsForDropDown,
} from 'redux/thunks/personThunks';
import { getAllSpecialties } from 'redux/thunks/specialtiesThunks';
import { getAllOrganizations } from 'redux/thunks/organizationsThunks';
import { getAllProjectsForDropDown } from 'redux/thunks/projectThunks';
import {
    initPlaceholder,
    valueForRequest,
} from 'helpers/Filters/FiltersModalHelpers';
import { synchronizeJira } from 'redux/thunks/synchronize';
import { getStatusAsyncTasks } from 'redux/thunks/asyncTaskThunks';
import isEmpty from 'helpers/isEmpty';
import { getAllModules } from 'redux/thunks/modulesThunks';
import styles from './styles.module.scss';

const ProductionPlans = () => {
    const cx = classNames.bind(styles);
    const dispatch = useDispatch();
    const { openModal, closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSync, setIsLoadingSync] = useState(false);
    const [isLoadingUnusedPersons, setIsLoadingUnusedPersons] = useState(false);
    const [isOpenFilters, setIsOpenFilters] = useState(false);

    const tableScrollRef = useRef({});
    const changeTableScroll = (newTableScrollLeft, newTableScrollTop) => {
        tableScrollRef.current.left = newTableScrollLeft;
        tableScrollRef.current.top = newTableScrollTop;
    };

    const data = useSelector((state) => state.productionPlans.data);
    const date = useSelector((state) => state.productionPlans.date);
    const unusedPersons = useSelector((state) => state.unusedPersons.data);
    const systems = useSelector((state) => state.modules.data);
    const asyncTasks = useSelector((state) => state.asyncTasks.data);

    const currentRole = useSelector((state) => state.auth.userData.userRole);

    const loaderTextSync = 'Идёт синхронизация...';
    const loaderTextAsyncTasks = `План производства загружается. 
    Шаг ${asyncTasks?.countCompleteStep} / 
    ${asyncTasks?.countAllStep ? asyncTasks?.countAllStep : '...'}
    .`;
    const resultLoaderText = () => {
        if (isLoading) {
            return '';
        }
        if (isLoadingSync) {
            return loaderTextSync;
        }
        if (!isEmpty(asyncTasks) || asyncTasks.status === 'IN_PROGRESS') {
            return loaderTextAsyncTasks;
        }
        return '';
    };
    const isShowLoader =
        isLoading ||
        isLoadingSync ||
        !isEmpty(asyncTasks) ||
        asyncTasks.status === 'IN_PROGRESS';

    const personsList = useSelector(
        (state) => state.persons.DropdownListValues
    );
    const typeProjectItems = projectTypesList.map((type, id) => ({
        id,
        value: projectType[type],
        valueRequest: type,
    }));
    const specialityList = useSelector(
        (state) => state.specialtiesAndCompetence.DropdownSpecialtiesListValues
    );
    const organizationsList = useSelector(
        (state) => state.organizations.DropdownListValues
    );
    const managerList = useSelector(
        (state) => state.persons.DropdownListValuesFiltered
    );
    const projects = useSelector((state) => state.projects);

    const [selectedPersons, setselectedPersons] = useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = useState([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState([]);
    const [selectedManagers, setSelectedManagers] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedTypesProject, setSelectedTypesProject] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(date);
    const [planHours, setPlanHours] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });
    const [factHours, setFactHours] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });
    const [planMonth, setPlanMonth] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });
    const [factMonth, setFactMonth] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });
    const [tariffRateHour, setTariffRateHour] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });
    const [deviationHours, setDeviationHours] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });
    const [deviationMonth, setDeviationMonth] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });
    const [amountTheContract, setAmountTheContract] = useState({
        from: null,
        placeholderMin: '',
        to: null,
        placeholderMax: '',
    });

    const [selectedTable, setSelectedTable] = useState({
        id: 1,
        value: 'План Производства',
    });
    const [filtersPlan, setFiltersPlans] = useState({});
    const [filtersPersons, setFiltersPersons] = useState({});
    const [sortPlan, setSortPlan] = useState({
        property: 'personFullName',
        order: 'asc',
    });
    const [sortPersons, setSortPersons] = useState({
        property: 'personFullName',
        order: 'asc',
    });

    const year = selectedPeriod.getFullYear();
    const month = selectedPeriod.getMonth();

    useEffect(() => {
        dispatch(getAllModules()); // hardCode
        dispatch(getStatusAsyncTasks());
        dispatch(getAllPersonsForDropDown());
        dispatch(getAllSpecialties());
        dispatch(getAllOrganizations());
        dispatch(getAllProjectsForDropDown());
        dispatch(
            getFilteredPersonsForDropDown({
                specialityIds: '1',
            })
        );
    }, [dispatch]);

    useEffect(() => {
        clearFiltersAndSorting();
        setSelectedPeriod(date);
    }, [date]);

    useEffect(() => {
        tableScrollRef.current = {};
        localStorage.setItem('prodPlanDate', selectedPeriod);
        dispatch(setCurrentDate(selectedPeriod));
    }, [selectedPeriod]);

    useEffect(() => {
        getProductionPlans();
    }, [filtersPlan, selectedPeriod, sortPlan]);

    useEffect(() => {
        getUnusedPersons();
    }, [filtersPersons, selectedPeriod, sortPersons]);

    useEffect(() => {
        if (isOpenFilters) {
            setValue();
        }
    }, [isOpenFilters]);

    const getProductionPlans = () => {
        dispatch(
            getAllPlans(
                {
                    yearIs: selectedPeriod.getFullYear(),
                    monthIs: selectedPeriod.getMonth() + 1,
                    ...filtersPlan,
                },
                sortPlan,
                setIsLoading,
                closeModal
            )
        );
    };

    const getUnusedPersons = () => {
        dispatch(
            getAllUnusedPersons(
                {
                    yearIs: selectedPeriod.getFullYear(),
                    monthIs: selectedPeriod.getMonth() + 1,
                    ...filtersPersons,
                },
                sortPersons,
                setIsLoadingUnusedPersons,
                closeModal
            )
        );
    };

    const dataForFilters = useMemo(() => {
        if (selectedTable.id === 1) {
            return {
                selectedPersons,
                changePersons: setselectedPersons,
                personsList,
                selectedSpeciality,
                changeSpeciality: setSelectedSpeciality,
                specialityList,
                selectedOrganizations,
                changeOrganizations: setSelectedOrganizations,
                organizationsList,
                selectedManagers,
                changeManagers: setSelectedManagers,
                managerList,
                selectedProjects,
                changeProjects: setSelectedProjects,
                projectsList: projects.DropdownListValues,
                selectedTypesProject,
                changeTypesProject: setSelectedTypesProject,
                typesProject: typeProjectItems,
                planHours,
                changePlanHours: setPlanHours,
                factHours,
                changeFactHours: setFactHours,
                planMonth,
                changePlanMonth: setPlanMonth,
                factMonth,
                changeFactMonth: setFactMonth,
                tariffRateHour,
                changeTariffRateHour: setTariffRateHour,
                deviationHours,
                changeDeviationHours: setDeviationHours,
                deviationMonth,
                changeDeviationMonth: setDeviationMonth,
                amountTheContract,
                changeAmountTheContract: setAmountTheContract,
            };
        }
        if (selectedTable.id === 2) {
            return {
                selectedPersons,
                changePersons: setselectedPersons,
                personsList,
                planHours,
                changePlanHours: setPlanHours,
                factHours,
                changeFactHours: setFactHours,
                planMonth,
                changePlanMonth: setPlanMonth,
                factMonth,
                changeFactMonth: setFactMonth,
                tariffRateHour,
                changeTariffRateHour: setTariffRateHour,
                deviationHours,
                changeDeviationHours: setDeviationHours,
                deviationMonth,
                changeDeviationMonth: setDeviationMonth,
            };
        }
        return {};
    }, [
        amountTheContract,
        deviationHours,
        deviationMonth,
        factHours,
        factMonth,
        managerList,
        organizationsList,
        personsList,
        planHours,
        planMonth,
        projects.DropdownListValues,
        selectedManagers,
        selectedOrganizations,
        selectedPersons,
        selectedProjects,
        selectedSpeciality,
        selectedTable.id,
        selectedTypesProject,
        specialityList,
        tariffRateHour,
        typeProjectItems,
    ]);

    const filtersForModal = useMemo(() => {
        if (selectedTable.id === 1) {
            return filtersAreaHandler('PRODUCTION_PLANS', dataForFilters);
        }
        if (selectedTable.id === 2) {
            return filtersAreaHandler('UNUSED_PERSONS', dataForFilters);
        }
        return {};
    }, [dataForFilters, selectedTable.id]);

    const initPlaceholderAllFilters = (
        planHoursNumbers,
        factHoursNumbers,
        planMonthNumbers,
        factMonthNumbers,
        tariffRateHourNumbers,
        deviationHoursNumbers,
        deviationMonthNumbers,
        amountTheContractNumbers
    ) => {
        initPlaceholder(
            planHoursNumbers,
            {
                min: planHours.placeholderMin,
                max: planHours.placeholderMax,
            },
            setPlanHours
        );

        initPlaceholder(
            factHoursNumbers,
            {
                min: factHours.placeholderMin,
                max: factHours.placeholderMax,
            },
            setFactHours
        );

        initPlaceholder(
            planMonthNumbers,
            {
                min: planMonth.placeholderMin,
                max: planMonth.placeholderMax,
            },
            setPlanMonth
        );

        initPlaceholder(
            factMonthNumbers,
            {
                min: factMonth.placeholderMin,
                max: factMonth.placeholderMax,
            },
            setFactMonth
        );

        initPlaceholder(
            tariffRateHourNumbers,
            {
                min: tariffRateHour.placeholderMin,
                max: tariffRateHour.placeholderMax,
            },
            setTariffRateHour
        );

        initPlaceholder(
            deviationHoursNumbers,
            {
                min: deviationHours.placeholderMin,
                max: deviationHours.placeholderMax,
            },
            setDeviationHours
        );

        initPlaceholder(
            deviationMonthNumbers,
            {
                min: deviationMonth.placeholderMin,
                max: deviationMonth.placeholderMax,
            },
            setDeviationMonth
        );

        if (amountTheContractNumbers) {
            initPlaceholder(
                amountTheContractNumbers,
                {
                    min: amountTheContract.placeholderMin,
                    max: amountTheContract.placeholderMax,
                },
                setAmountTheContract
            );
        }
    };

    const setValue = () => {
        if (selectedTable.id === 1) {
            const planHoursNumbers = data
                .filter((elem) => elem.planHours)
                .map((elem) => elem.planHours);
            const factHoursNumbers = data
                .filter((elem) => elem.factHours)
                .map((elem) => elem.factHours);
            const planMonthNumbers = data
                .filter((elem) => elem.planMonth)
                .map((elem) => elem.planMonth);
            const factMonthNumbers = data
                .filter((elem) => elem.factMonth)
                .map((elem) => elem.factMonth);
            const tariffRateHourNumbers = data
                .filter((elem) => elem.tariffRateHour)
                .map((elem) => elem.tariffRateHour);
            const deviationHoursNumbers = data
                .filter((elem) => elem.deviationHours)
                .map((elem) => elem.deviationHours);
            const deviationMonthNumbers = data
                .filter((elem) => elem.deviationMonth)
                .map((elem) => elem.deviationMonth);
            const amountTheContractNumbers = data
                .filter((elem) => elem.amountUnderTheContract)
                .map((elem) => elem.amountUnderTheContract);

            initPlaceholderAllFilters(
                planHoursNumbers,
                factHoursNumbers,
                planMonthNumbers,
                factMonthNumbers,
                tariffRateHourNumbers,
                deviationHoursNumbers,
                deviationMonthNumbers,
                amountTheContractNumbers
            );
        }
        if (selectedTable.id === 2) {
            const planHoursNumbers = unusedPersons
                .filter((elem) => elem.planHours)
                .map((elem) => elem.planHours);
            const factHoursNumbers = unusedPersons
                .filter((elem) => elem.factHours)
                .map((elem) => elem.factHours);
            const planMonthNumbers = unusedPersons
                .filter((elem) => elem.planMonth)
                .map((elem) => elem.planMonth);
            const factMonthNumbers = unusedPersons
                .filter((elem) => elem.factMonth)
                .map((elem) => elem.factMonth);
            const tariffRateHourNumbers = unusedPersons
                .filter((elem) => elem.tariffRateHour)
                .map((elem) => elem.tariffRateHour);
            const deviationHoursNumbers = unusedPersons
                .filter((elem) => elem.deviationHours)
                .map((elem) => elem.deviationHours);
            const deviationMonthNumbers = unusedPersons
                .filter((elem) => elem.deviationMonth)
                .map((elem) => elem.deviationMonth);

            initPlaceholderAllFilters(
                planHoursNumbers,
                factHoursNumbers,
                planMonthNumbers,
                factMonthNumbers,
                tariffRateHourNumbers,
                deviationHoursNumbers,
                deviationMonthNumbers
            );
        }
    };

    const handleClickOnFilter = () => {
        if (selectedTable.id === 1) {
            setFiltersPlans((prev) => ({
                ...prev,
                personIds: selectedPersons.map((person) => person?.id),
                specialityIds: selectedSpeciality.map(
                    (speciality) => speciality?.id
                ),
                organizationNames: selectedOrganizations.map(
                    (organization) => organization?.value
                ),
                managerIds: selectedManagers.map((manager) => manager?.id),
                projectIds: selectedProjects.map((project) => project?.id),
                projectTypes: selectedTypesProject.map(
                    (type) => type?.valueRequest
                ),
                planHoursFrom: valueForRequest(planHours.from),
                planHoursTo: valueForRequest(planHours.to),
                factHoursFrom: valueForRequest(factHours.from),
                factHoursTo: valueForRequest(factHours.to),
                planMonthFrom: valueForRequest(planMonth.from),
                planMonthTo: valueForRequest(planMonth.to),
                factMonthFrom: valueForRequest(factMonth.from),
                factMonthTo: valueForRequest(factMonth.to),
                tariffRateHourFrom: valueForRequest(tariffRateHour.from),
                tariffRateHourTo: valueForRequest(tariffRateHour.to),
                deviationHoursFrom: valueForRequest(deviationHours.from),
                deviationHoursTo: valueForRequest(deviationHours.to),
                deviationMonthFrom: valueForRequest(deviationMonth.from),
                deviationMonthTo: valueForRequest(deviationMonth.to),
                amountUnderTheContractFrom: valueForRequest(
                    amountTheContract.from
                ),
                amountUnderTheContractTo: valueForRequest(amountTheContract.to),
            }));
        }
        if (selectedTable.id === 2) {
            setFiltersPersons((prev) => ({
                ...prev,
                personIds: selectedPersons.map((person) => person?.id),
                planHoursFrom: valueForRequest(planHours.from),
                planHoursTo: valueForRequest(planHours.to),
                factHoursFrom: valueForRequest(factHours.from),
                factHoursTo: valueForRequest(factHours.to),
                planMonthFrom: valueForRequest(planMonth.from),
                planMonthTo: valueForRequest(planMonth.to),
                factMonthFrom: valueForRequest(factMonth.from),
                factMonthTo: valueForRequest(factMonth.to),
                tariffRateHourFrom: valueForRequest(tariffRateHour.from),
                tariffRateHourTo: valueForRequest(tariffRateHour.from),
                deviationHoursFrom: valueForRequest(deviationHours.from),
                deviationHoursTo: valueForRequest(deviationHours.to),
                deviationMonthFrom: valueForRequest(deviationMonth.from),
                deviationMonthTo: valueForRequest(deviationMonth.to),
            }));
        }
    };

    const onSave = () => {
        dispatch(setCurrentDate(selectedPeriod));
        clearFiltersAndSorting();
        setFiltersPlans((prev) => ({
            ...prev,
            year: selectedPeriod.getFullYear(),
            month: selectedPeriod.getMonth() + 1,
        }));
        setFiltersPersons((prev) => ({
            ...prev,
            yearIs: selectedPeriod.getFullYear(),
            monthIs: selectedPeriod.getMonth() + 1,
            factHoursFrom: 1,
        }));
    };

    const onSync = () => {
        dispatch(
            synchronizeJira(
                {
                    moduleId: systems.find((elem) => elem.systemId === 2).id,
                    year: selectedPeriod.getFullYear(),
                    month: selectedPeriod.getMonth() + 1,
                }, // hardCode,
                onSave,
                setIsLoadingSync
            )
        );
    };

    const handleClick = (title, children, isNotScrollable) => {
        openModal({ title, children, isNotScrollable });
    };

    const onSortHandlerPlan = (header) => {
        editSort(header, sortPlan, setSortPlan);
    };

    const onSortHandlerPersons = (header) => {
        editSort(header, sortPersons, setSortPersons);
    };

    const handleClickFilters = () => {
        setIsOpenFilters(true);
    };

    const clearFiltersAndSorting = () => {
        if (sortPlan.property && sortPlan.order)
            setSortPlan({ property: 'personFullName', order: 'asc' });

        if (Object.keys(filtersPlan).length) setFiltersPlans({});

        if (sortPersons.property && sortPersons.order)
            setSortPersons({ property: 'personFullName', order: 'asc' });

        if (Object.keys(filtersPersons).length) setFiltersPersons({});
    };

    return (
        <>
            <PageHeader
                title="Планы производства"
                productionPlansItems={production_plans}
                caseName="PRODUCTION_PLANS"
                selectedTable={selectedTable}
                setSelectedTable={setSelectedTable}
                calendarFilter
                syncButton={accessByRole(
                    'ProductionPlans',
                    'syncButton',
                    currentRole
                )}
                selectedPeriod={selectedPeriod}
                changeSelectedPeriod={setSelectedPeriod}
                filtersButton={accessByRole(
                    'ProductionPlans',
                    'filtersButton',
                    currentRole
                )}
                handlerClickFiltersButton={handleClickFilters}
                isProductionPlan
                createPlan={accessByRole(
                    'ProductionPlans',
                    'createPlan',
                    currentRole
                )}
                addPlan={accessByRole(
                    'ProductionPlans',
                    'addPlan',
                    currentRole
                )}
                downloadPlan={accessByRole(
                    'ProductionPlans',
                    'downloadPlan',
                    currentRole
                )}
                uploadPlan={accessByRole(
                    'ProductionPlans',
                    'uploadPlan',
                    currentRole
                )}
                syncJira={() => {
                    onSync();
                }}
                openAddModal={() => {
                    handleClick(
                        'Добавление сотрудника в план производства',
                        <ProjectTeamsContent
                            addProdPlan
                            closeModal={closeModal}
                        />
                    );
                }}
                openCreatePlanModal={() => {
                    handleClick(
                        'Создать план',
                        <ProductionPlansCalendar
                            closeModal={closeModal}
                            date={date}
                            isCreatePlan
                            changeSelectedPeriod={setSelectedPeriod}
                            clearFiltersAndSorting={clearFiltersAndSorting}
                        />,
                        true
                    );
                }}
                openDownloadButtonsModal={() =>
                    handleClick(
                        'Загрузить данные в таблицу',
                        <DownloadInProductionPlans
                            closeModal={closeModal}
                            getProductionPlans={getProductionPlans}
                        />
                    )
                }
                handlerUploadBtn={() =>
                    handleClick(
                        'Выгрузить данные из таблицы',
                        <UploadInProductionPlans
                            closeModal={closeModal}
                            getProductionPlans={getProductionPlans}
                            year={year}
                            month={month}
                        />
                    )
                }
            />
            <div className={styles.tables}>
                {isShowLoader ? (
                    <Loader text={resultLoaderText()} />
                ) : (
                    <>
                        {selectedTable.id && (
                            <FiltersModal
                                isActive={isOpenFilters}
                                setIsActive={setIsOpenFilters}
                                filters={filtersForModal}
                                onSave={handleClickOnFilter}
                            />
                        )}
                        {selectedTable.id === 1 &&
                            (!data.length ? (
                                <div className={cx('empty_block')}>
                                    На данном периоде нет планов производства
                                </div>
                            ) : (
                                <Table
                                    header={headers.productionPlans}
                                    items={data}
                                    caseName="PRODUCTION_PLANS"
                                    deleteIcon={accessByRole(
                                        'ProductionPlans',
                                        'productionPlansDeleteIcon',
                                        currentRole
                                    )}
                                    loockProdPlanIcon={accessByRole(
                                        'ProductionPlans',
                                        'loockProdPlanIcon',
                                        currentRole
                                    )}
                                    infoIcon={accessByRole(
                                        'ProductionPlans',
                                        'infoIcon',
                                        currentRole
                                    )}
                                    editIcon={accessByRole(
                                        'ProductionPlans',
                                        'productionPlansEditIcon',
                                        currentRole
                                    )}
                                    openEditModal={(initial) => {
                                        handleClick(
                                            'Редактирование плана производства',
                                            <ProductionPlansEditContent
                                                closeModal={closeModal}
                                                initialValues={initial}
                                                getProductionPlans={
                                                    getProductionPlans
                                                }
                                                getUnusedPersons={
                                                    getUnusedPersons
                                                }
                                            />
                                        );
                                    }}
                                    openDeleteModal={(initial) => {
                                        handleClick(
                                            `Вы действительно хотите удалить план производства ${initial.items.personName}?`,
                                            <DeleteModal
                                                closeModal={closeModal}
                                                data={data}
                                                id={initial.id}
                                                tableType="productionPlans"
                                            />
                                        );
                                    }}
                                    openProdPlanModal={(item) => {
                                        handleClick(
                                            'План производства',
                                            <TimeModal
                                                closeModal={closeModal}
                                                personId={
                                                    item.additionalData.personId
                                                }
                                                month={month}
                                                year={year}
                                            />
                                        );
                                    }}
                                    openJournalModal={(id) => {
                                        handleClick(
                                            'Ошибки плана производства',
                                            <JournalModal
                                                closeModal={closeModal}
                                                id={id}
                                                referenceTable="production_plans"
                                                getProductionPlans={
                                                    getProductionPlans
                                                }
                                            />
                                        );
                                    }}
                                    filters={filtersPlan}
                                    setFilters={setFiltersPlans}
                                    onSortHandler={onSortHandlerPlan}
                                    sort={sortPlan}
                                    tableScroll={tableScrollRef.current}
                                    changeTableScroll={changeTableScroll}
                                />
                            ))}
                        {selectedTable.id === 2 &&
                            (isLoadingUnusedPersons ? (
                                <Loader />
                            ) : !unusedPersons.length ? (
                                <div className={cx('empty_block')}>
                                    На данном периоде нет незадействованных
                                    сотрудников
                                </div>
                            ) : (
                                <Table
                                    header={headers.unusedPersons}
                                    items={unusedPersons}
                                    caseName="UNUSED_PERSONS"
                                    deleteIcon={accessByRole(
                                        'ProductionPlans',
                                        'unusedPersonsDeleteIcon',
                                        currentRole
                                    )}
                                    editIcon={accessByRole(
                                        'ProductionPlans',
                                        'unusedPersonsEditIcon',
                                        currentRole
                                    )}
                                    openEditModal={(initial) => {
                                        handleClick(
                                            'Редактирование незадействованных сотрудников',
                                            <ProductionPlansEditContent
                                                closeModal={closeModal}
                                                initialValues={initial}
                                                isUnusedPersonsTable
                                            />
                                        );
                                    }}
                                    openDeleteModal={(initial) => {
                                        handleClick(
                                            `Вы действительно хотите удалить сотрудника ${initial.items.personName}?`,
                                            <DeleteModal
                                                closeModal={closeModal}
                                                data={data}
                                                id={initial.id}
                                                tableType="unusedPersons"
                                            />
                                        );
                                    }}
                                    filters={filtersPersons}
                                    setFilters={setFiltersPersons}
                                    onSortHandler={onSortHandlerPersons}
                                    sort={sortPersons}
                                    tableScroll={tableScrollRef.current}
                                    changeTableScroll={changeTableScroll}
                                />
                            ))}
                    </>
                )}
            </div>
        </>
    );
};

export default ProductionPlans;
