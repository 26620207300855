const UploadKebabIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3457_18970)">
                <path
                    d="M9 16H15V10H19L12 3L5 10H9V16ZM5 18H19V20H5V18Z"
                    fill="#696C80"
                />
            </g>
            <defs>
                <clipPath id="clip0_3457_18970">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default UploadKebabIcon;
