import API from 'api/api';
import {
    addSpecialityData,
    editSpecialityData,
    getSpecialtiesData,
} from 'redux/actions/specialties';
import { useToastify } from 'helpers/toastify/useToastify';
import { logout } from 'redux/actions/auth';

export const getSpeciality =
    (id, setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);

        API.specialties
            .getAllSpecialties(id)
            .then((res) => dispatch(getSpecialtiesData(res.data.content)))
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => setIsLoading(false));
    };
export const editSpeciality =
    (id, specialityValue, closeModal = () => {}) =>
    (dispatch) => {
        API.specialties
            .editSpeciality(id, specialityValue)
            .then((res) => {
                dispatch(editSpecialityData(res.data));
                useToastify('Специализация изменена', 'success');
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => closeModal());
    };
export const getAllSpecialties =
    (filters = {}, sort = {}, setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        const query = { ...filters };
        if (sort.property) query.sort = `${sort.property},${sort.order}`;

        API.specialties
            .getAllSpecialties({ ...query })
            .then((res) => dispatch(getSpecialtiesData(res.data.content)))
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => setIsLoading(false));
    };
export const addSpeciality =
    (specialityValue, closeModal = () => {}) =>
    (dispatch) => {
        API.specialties
            .addSpeciality({
                name: specialityValue,
            })
            .then((res) => {
                dispatch(addSpecialityData(res.data));
                useToastify('Специализация добавлена', 'success');
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    localStorage.clear();
                    dispatch(logout());
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => closeModal());
    };
