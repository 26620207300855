import { arrayOf, func, oneOfType, shape, string } from 'prop-types';
import { bodyHandler } from 'helpers/tableHandler';
import { getAllPersonsForDropDown } from 'redux/thunks/personThunks';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import TableHeader from '../Table/TableHeader';
import TableBody from './TableBody';
import styles from './styles.module.scss';

const ReportCardErrorTable = ({
    caseName,
    header,
    items = [],
    dropDownItems = [],
    handleSelect = () => {},
    onChangeInput = () => {},
    setCheckedHandler = () => {},
    setCheckedHandlerAll = () => {},
}) => {
    const cx = classNames.bind(styles);

    return (
        <div className={cx('table__wrapper')}>
            <table className={styles.table_main}>
                <TableHeader
                    caseName={caseName}
                    header={header}
                    data={items}
                    setCheckedHandlerAll={setCheckedHandlerAll}
                />
                <TableBody
                    data={bodyHandler(caseName, items)}
                    caseName={caseName}
                    dropDownItems={dropDownItems}
                    handleSelect={handleSelect}
                    onChangeInput={onChangeInput}
                    setCheckedHandler={setCheckedHandler}
                />
            </table>
        </div>
    );
};
ReportCardErrorTable.propTypes = {
    header: oneOfType([arrayOf(shape({})), arrayOf(arrayOf(shape({})))]),
    caseName: string,
    items: arrayOf(shape({})),
    dropDownItems: arrayOf(shape({})),
    handleSelect: func,
    onChangeInput: func,
    setCheckedHandler: func,
    setCheckedHandlerAll: func,
};

export default ReportCardErrorTable;
