import API from 'api/api';
import { logout } from 'redux/actions/auth';
import { getAsyncTasks } from 'redux/actions/asyncTasks';
import { useToastify } from 'helpers/toastify/useToastify';
import isEmpty from 'helpers/isEmpty';

export const checkStatusAsyncTasks = () => async (dispatch) => {
    try {
        const res = await API.asyncTasks.getAsyncTasks();
        const keyTask = Object.keys(res.data).find((elem) =>
            elem.includes('importProductionPlanExecutor-1')
        );
        dispatch(getAsyncTasks(res.data[keyTask]));
        if (res.data[keyTask].status === 'IN_PROGRESS') {
            setTimeout(() => {
                dispatch(checkStatusAsyncTasks());
            }, 5000);
        } else if (res.data[keyTask].status === 'COMPLETE') {
            useToastify('План успешно загружен', 'success');
        }
    } catch (err) {
        if (err.name === 'UNAUTHORIZED') {
            dispatch(logout());
        }
        useToastify(
            err?.message ?? 'Ошибка загрузки плана производства',
            'error'
        );
    }
};

export const getStatusAsyncTasks = () => async (dispatch) => {
    try {
        const res = await API.asyncTasks.getAsyncTasks();
        if (isEmpty(res.data)) {
            dispatch(
                getAsyncTasks(res.data['importProductionPlanExecutor-1:72'])
            );
        } else {
            dispatch(getAsyncTasks({ status: 'COMPLETE' }));
        }
    } catch (err) {
        if (err.name === 'UNAUTHORIZED') {
            dispatch(logout());
        }
        useToastify(
            err?.message ?? 'Ошибка загрузки плана производства',
            'error'
        );
    }
};
