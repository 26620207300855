import { shape } from 'prop-types';
import TableHeader from 'components/Tables/ReportTables/UnusedPersonsTable/TableHeader';
import TableBody from 'components/Tables/ReportTables/UnusedPersonsTable/TableBody';
import NoDataInfo from 'components/NoDataInfo';
import styles from './styles.module.scss';

const UnusedPersonsTable = ({ items = {} }) => {
    return (
        <div className={styles.table__wrapper}>
            <div className={styles.table__box}>
                {items?.infoByPersons?.length ? (
                    <table className={styles.table_main}>
                        <TableHeader />
                        <TableBody items={items} />
                    </table>
                ) : (
                    <NoDataInfo />
                )}
            </div>
        </div>
    );
};

export default UnusedPersonsTable;

UnusedPersonsTable.propTypes = {
    items: shape({}),
};
