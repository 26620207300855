import { shape } from 'prop-types';
import TableHeader from 'components/Tables/ReportTables/CompetenceLevelTable/TableHeader';
import TableBody from 'components/Tables/ReportTables/CompetenceLevelTable/TableBody';
import styles from './styles.module.scss';

const CompetenceLevelTable = ({ items = {} }) => (
    <div className={styles.table__wrapper}>
        <table className={styles.table_main}>
            <TableHeader />
            <TableBody items={items} />
        </table>
    </div>
);

export default CompetenceLevelTable;

CompetenceLevelTable.propTypes = {
    items: shape({}),
};
