import {
    ADD_PERSON_DATA,
    EDIT_PERSON_DATA,
    EDIT_PERSON_STATUS,
    GET_PERSONS_DATA,
    SET_PERSONS_FILTER_STATUS,
    SET_PERSON_PHOTO,
    DELETE_PERSON_PHOTO,
    GET_FILTERED_PERSONS_DATA,
    GET_PERSON_DATA_DROPDOWN,
    GET_PERSON_DATA_DROPDOWN_FILTERED,
} from 'redux/types';

export const addPersonData = (data) => ({
    type: ADD_PERSON_DATA,
    payload: data,
});

export const editPersonData = (data) => ({
    type: EDIT_PERSON_DATA,
    payload: data,
});

export const editPersonStatus = (id, data, status) => ({
    type: EDIT_PERSON_STATUS,
    payload: { id, data, status },
});

export const getPersonsData = (data) => ({
    type: GET_PERSONS_DATA,
    payload: data,
});

export const getFilteredPersonsDataForDropDown = (data) => ({
    type: GET_PERSON_DATA_DROPDOWN_FILTERED,
    payload: data,
});

export const getAllPersonsDataForDropDown = (data) => ({
    type: GET_PERSON_DATA_DROPDOWN,
    payload: data,
});

export const getFilteredPersonsData = (data) => ({
    type: GET_FILTERED_PERSONS_DATA,
    payload: data,
});

export const setPersonsFilterStatus = (data) => ({
    type: SET_PERSONS_FILTER_STATUS,
    payload: data,
});

export const setPersonPhoto = (id, photo) => ({
    type: SET_PERSON_PHOTO,
    payload: { id, photo },
});

export const deletePersonPhoto = (id) => ({
    type: DELETE_PERSON_PHOTO,
    payload: id,
});
