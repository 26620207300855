import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import PageHeader from 'components/PageHeader';
import Loader from 'components/Loader';
import CVPerson from 'components/Pages/CVPage/CVPerson';
import CVProjects from 'components/Pages/CVPage/CVProjects';
import { getCvInformation, cvUnload } from 'redux/thunks/cvPageThunks';
import styles from './styles.module.scss';

const CVPage = () => {
    const cvId = Number(useLocation().pathname.split('/')[2]);
    const cx = classNames.bind(styles);
    const currentRole = useSelector((state) => state.auth.userData.userRole);
    const CVPageLocation = useLocation();
    const isEdit = CVPageLocation.state?.isEdit ?? false;

    const [cvPersonInfo, setCvPersonInfo] = useState(null);
    const [cvProjectsInfo, setCvProjectsInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getCvInformation(
            cvId,
            setIsLoading,
            setCvPersonInfo,
            setCvProjectsInfo
        );
    }, []);

    const downloadCv = () => {
        cvUnload(cvId, cvPersonInfo.fullName);
    };

    return (
        <div>
            <PageHeader title="CV сотрудника" handlerClick={() => {}} />
            {isLoading ? (
                <Loader />
            ) : cvPersonInfo && cvPersonInfo ? (
                <div className={cx('cv-container')}>
                    <CVPerson
                        cvId={cvId}
                        personInfo={cvPersonInfo}
                        setPersonInfo={setCvPersonInfo}
                        currentRole={currentRole}
                        isEdit={isEdit}
                    />
                    <CVProjects
                        cvId={cvId}
                        currentRole={currentRole}
                        personInfoId={cvPersonInfo.personInfoId}
                        projectsInfo={cvProjectsInfo}
                        setProjectsInfo={setCvProjectsInfo}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default CVPage;
