import React, { useEffect, useContext, useState } from 'react';
import { accessByRole } from 'helpers/roleHelpers';
import PageHeader from 'components/PageHeader';
import ModalContext from 'components/Contexts/ModalContext/ModalContext';
import AddModuleModal from 'components/ModalsContent/AddModuleModal';
import { useDispatch, useSelector } from 'react-redux';
import { getAllModules } from 'redux/thunks/modulesThunks';
import ModuleCard from 'components/ModulesCard';
import ImportPersons from 'components/Pages/Directories/DirectoriesPages/Persons/ImportPersons';
import ModuleSettings from 'components/ModuleSettings';
import Tabs from 'components/Tabs';
import styles from './styles.module.scss';
import ImportProject from '../Projects/ImportProjects';

const Modules = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.modules.data);
    const [settings, setSettings] = useState({});
    const { openModal, closeModal } = useContext(ModalContext);

    const onDeleteHandler = () => {
        setSettings({});
    };

    const currentRole = useSelector((state) => state.auth.userData.userRole);

    const tabs = {
        ...(accessByRole('Modules', 'moduleSettings', currentRole) && {
            tabModuleSettings: {
                id: 1,
                title: 'Настройки',
                component: (
                    <ModuleSettings
                        item={settings}
                        deleteHandler={onDeleteHandler}
                    />
                ),
            },
        }),
        tabImportPersons: {
            id: 2,
            title: 'Сотрудники',
            component: <ImportPersons selectedModule={settings} />,
        },
        ...(accessByRole('Modules', 'importProjects', currentRole) && {
            tabImportProjects: {
                id: 3,
                title: 'Проекты',
                component: <ImportProject selectedModule={settings} />,
            },
        }),
    };

    useEffect(() => {
        if (accessByRole('Modules', 'getAllModules', currentRole)) {
            dispatch(getAllModules());
        }
    }, []);

    const handleClick = (title, children) => {
        openModal({ title, children });
    };

    return (
        <div>
            <PageHeader
                title="Модули"
                addButton={accessByRole('Modules', 'addButton', currentRole)}
                addButtonText="Добавить модуль"
                openAddModal={() => {
                    setSettings({});
                    handleClick(
                        'Добавление модуля',
                        <AddModuleModal
                            statusModal="add"
                            closeModal={closeModal}
                        />
                    );
                }}
            />

            <div className={styles.container}>
                <div className={styles.container_modules}>
                    {accessByRole('Modules', 'getAllModules', currentRole) &&
                        data.map((item) => (
                            <ModuleCard
                                key={item.id}
                                item={item}
                                handleClick={setSettings}
                                activeModule={settings?.id}
                            />
                        ))}
                </div>
                <Tabs tabs={tabs} item={settings} />
            </div>
        </div>
    );
};

export default Modules;
