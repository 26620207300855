export const months = [
    {
        id: 1,
        value: 'Январь',
    },
    {
        id: 2,
        value: 'Февраль',
    },
    {
        id: 3,
        value: 'Март',
    },
    {
        id: 4,
        value: 'Апрель',
    },
    {
        id: 5,
        value: 'Май',
    },
    {
        id: 6,
        value: 'Июнь',
    },
    {
        id: 7,
        value: 'Июль',
    },
    {
        id: 8,
        value: 'Август',
    },
    {
        id: 9,
        value: 'Сентябрь',
    },
    {
        id: 10,
        value: 'Октябрь',
    },
    {
        id: 11,
        value: 'Ноябрь',
    },
    {
        id: 12,
        value: 'Декабрь',
    },
];

const currentDate = new Date();

export const years = [
    {
        id: 1,
        value: currentDate.getFullYear() - 1,
    },
    {
        id: 2,
        value: currentDate.getFullYear(),
    },
    {
        id: 3,
        value: currentDate.getFullYear() + 1,
    },
];

export const projectTypesDropdownValues = [
    {
        id: 0,
        value: 'Фикспрайс',
    },
    {
        id: 1,
        value: 'Тайм_материал',
    },
    {
        id: 2,
        value: 'Инвестиции',
    },
    {
        id: 3,
        value: 'Сопровождение',
    },
    {
        id: 4,
        value: 'Бенч',
    },
];

export const projectTypesList = [
    'FIXED_PRICE',
    'TIME_AND_MATERIAL',
    'INVESTMENTS',
    'SUPPORT',
    'BENCH',
];

export const reports = [
    { id: 1, value: ' По проектам', type: 'BY_PROJECT' },
    { id: 2, value: ' По сотрудникам', type: 'BY_PERSON' },
    { id: 3, value: 'Загрузка по сотрудникам', type: 'PERSON_WORKLOAD' },
    { id: 4, value: 'Загрузка по проектам', type: 'PROJECT_WORKLOAD' },
    { id: 5, value: ' По типу проекта', type: 'BY_PROJECT_TYPE' },
    { id: 6, value: 'По менеджерам', type: 'MANAGER_KPI' },
    { id: 7, value: 'По уровню компетенций', type: 'BY_COMPETENCE_LEVEL_TYPE' },
    {
        id: 8,
        value: 'По проектам (поквартально)',
        type: 'PROJECTS_BY_QUARTERS',
    },
    {
        id: 9,
        value: 'По незадействованным сотрудникам',
        type: 'UNUSED_PERSONS',
    },
    {
        id: 10,
        value: 'По стажу сотрудников',
        type: 'PERSONS_EXPERIENCE',
    },
    {
        id: 11,
        value: 'Баланс бюджета',
        type: 'BALANCE_BUDGETS',
    },
];

export const production_plans = [
    {
        id: 1,
        value: 'План Производства',
    },
    {
        id: 2,
        value: 'Незадействованные сотрудники',
    },
];

export const systemRoles = [
    { id: 0, value: 'ADMIN' },
    { id: 1, value: 'SUPERVISOR' },
    { id: 2, value: 'MANAGER' },
    { id: 3, value: 'ACCOUNTANT' },
    { id: 4, value: 'HR' },
    { id: 5, value: 'PS' },
    { id: 6, value: 'USER' },
    { id: 7, value: 'PERSONA_NON_GRATA' },
];
export const quarts = [
    { id: 0, value: '1 квартал' },
    { id: 1, value: '2 квартал' },
    { id: 2, value: '3 квартал' },
    { id: 3, value: '4 квартал' },
    { id: 4, value: '1 полугодие' },
    { id: 5, value: '2 полугодие' },
    { id: 6, value: 'за год' },
];

export const periods = [
    { id: 3, value: 'Сегодня' },
    { id: 2, value: 'Неделя' },
    { id: 1, value: 'Месяц' },
    { id: 0, value: 'Год' },
];

export const eventTypes = {
    PLANNED_CERTIFICATION: 'Плановая аттестация',
    SICK_LEAVE: 'Больничный',
    VACATION: 'Отпуск',
    TIME_OFF: 'Отгул',
};

export const eventTypesDropdownListValues = [
    { id: 'PLANNED_CERTIFICATION', value: 'Плановая аттестация' },
    { id: 'SICK_LEAVE', value: 'Больничный' },
    { id: 'VACATION', value: 'Отпуск' },
    { id: 'TIME_OFF', value: 'Отгул' },
];

export const toggleValues = [
    { id: 0, value: 'close' },
    { id: 1, value: 'open' },
];

export const cvModel = {
    experience: {
        title: 'Общий стаж работы',
        type: 'string',
    },
    programmingLanguage: {
        title: 'Языки программирования',
        type: 'tags',
    },
    professionalSkills: {
        title: 'Профессиональные навыки',
        type: 'tags',
    },
    tools: {
        title: 'Инструменты',
        type: 'tags',
    },
    operatingSystems: {
        title: 'Операционные системы',
        type: 'tags',
    },
    foreignLanguages: {
        title: 'Иностранные языки',
        type: 'tags',
    },
    education: {
        title: 'Образование',
        type: 'tags',
    },
    certificates: {
        title: 'Сертификация',
        type: 'tags',
    },
};
