import classNames from 'classnames/bind';
import { numbersDepth } from 'helpers/tableHandler';
import { shape } from 'prop-types';
import styles from './styles.module.scss';

const TableBody = ({ items = {} }) => {
    const cx = classNames.bind(styles);

    return (
        <tbody className={styles.table__body}>
            {items.infoByProjects.map((itemByProject) => (
                <tr key={Math.random()}>
                    <td className={cx('left_border')}>
                        {itemByProject.projectName}
                    </td>
                    {itemByProject.projectInfoByPeriods.map(
                        (itemsByYearMonth) => (
                            <td key={Math.random()}>
                                <span>
                                    {numbersDepth(
                                        itemsByYearMonth.projectExpenses
                                    )}
                                </span>
                            </td>
                        )
                    )}
                    <td className={cx('highlighted_cell')}>
                        <span>
                            {numbersDepth(itemByProject.totalProjectExpenses)}
                        </span>
                    </td>
                </tr>
            ))}

            <tr className={cx('highlighted_cell', 'active_text')}>
                <td className={cx('left_border')}>Всего</td>
                {items.totalInfoByPeriods.map((itemTotalExpenses) => (
                    <td key={Math.random()}>
                        <span>
                            {numbersDepth(itemTotalExpenses.projectExpenses)}
                        </span>
                    </td>
                ))}
                <td>
                    <span>{numbersDepth(items.totalExpense)}</span>
                </td>
            </tr>
        </tbody>
    );
};

export default TableBody;

TableBody.propTypes = {
    items: shape({}),
};
