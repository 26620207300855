import { useEffect, useState } from 'react';
import { func, shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addSpeciality, editSpeciality } from 'redux/thunks/specialtiesThunks';
import useValidator from 'hooks/useValidator';
import Input from 'components/Input';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import styles from 'components/ModalsContent/SpecialityContent/styles.module.scss';
import DropdownList from 'components/DropdownList';
import { getAllCompetences } from 'redux/thunks/competenceLevelsThunks';
import { editTeamPeriod } from 'redux/thunks/teamsTunks';

const TeamsPeriodEditContent = ({ items = {}, closeModal = () => {} }) => {
    const [submitTouched, setSubmitTouched] = useState(false);
    const dispatch = useDispatch();

    const competencesList = useSelector(
        (state) => state.specialtiesAndCompetence.DropdownCompetenceListValues
    );
    const [period, setPeriod] = useState();
    const [tariffRateHour, setTariffRateHour] = useState(null);
    const [competence, setCompetence] = useState({});
    const [planHours, setPlanHours] = useState(0);

    useEffect(() => {
        initValues();
        dispatch(getAllCompetences());
    }, [dispatch]);

    const initValues = () => {
        setPeriod(() => {
            const date = new Date();
            date.setMonth(items.monthPeriod - 1);
            date.setFullYear(items.yearPeriod);
            return date;
        });
        setTariffRateHour(items.tariffRateHour);
        setCompetence({
            id: items.competenceLevel.id,
            value: items.competenceLevel.competenceName,
        });
        setPlanHours(items.personPlannedHours);
    };

    const onSave = () => {
        setSubmitTouched(true);
        dispatch(
            editTeamPeriod(
                items.teamPeriodId,
                {
                    year: period.getFullYear(),
                    month: period.getMonth() + 1,
                    tariffRateHour,
                    competenceId: competence.id,
                    planHours,
                },
                closeModal
            )
        );
    };

    const { isValid, validator } = useValidator({
        period,
        tariffRateHour,
        competence,
        planHours,
    });

    return (
        <div className={styles.teams_period_content}>
            <DatePicker
                placeholder="Месяц"
                dateDrop={period}
                setDateDrop={setPeriod}
                showMonthOnly
                modal
                error={!validator.period && submitTouched}
            />
            <Input
                value={tariffRateHour}
                onChange={setTariffRateHour}
                placeholder="Тарифная ставка, руб/час."
                type="number"
                error={!validator.tariffRateHour && submitTouched}
            />
            <DropdownList
                items={competencesList}
                selected={competence}
                setSelected={setCompetence}
                placeholderInput="Уровень компетенции"
                error={!validator.competence && submitTouched}
            />
            <Input
                value={planHours}
                onChange={setPlanHours}
                placeholder="План. часы за месяц"
                type="number"
                symbolLimit={3}
                error={!validator.planHours && submitTouched}
            />
            <div className={styles.buttons_container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                />
                <Button text="Сохранить" onClick={onSave} />
            </div>
        </div>
    );
};

TeamsPeriodEditContent.propTypes = {
    items: shape({ string }),
    closeModal: func,
};
export default TeamsPeriodEditContent;
