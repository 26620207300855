import {
    ADD_PERSON_DATA,
    EDIT_PERSON_DATA,
    EDIT_PERSON_STATUS,
    GET_PERSONS_DATA,
    SET_PERSONS_FILTER_STATUS,
    SET_PERSON_PHOTO,
    DELETE_PERSON_PHOTO,
    GET_FILTERED_PERSONS_DATA,
    GET_PERSON_DATA_DROPDOWN,
    GET_PERSON_DATA_DROPDOWN_FILTERED,
} from 'redux/types';

const defaultState = {
    data: [],
    personPhoto: {},
    DropdownListValues: [],
    DropdownListValuesFiltered: [],
    isActive: '',
};

export const persons = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_PERSON_DATA:
            return {
                ...state,
                data: [action.payload, ...state.data],
            };
        case EDIT_PERSON_DATA:
            return {
                ...state,
                data: [
                    ...state.data.map((item) => {
                        if (item.id === action.payload.id) {
                            return { ...action.payload };
                        }
                        return { ...item };
                    }),
                ],
            };
        case EDIT_PERSON_STATUS:
            return {
                ...state,
                data: [
                    ...state.data.map((item) => {
                        if (item.id === action.payload.id) {
                            return {
                                ...item,
                                isActive: action.payload.status.isActive,
                            };
                        }
                        return { ...item };
                    }),
                ],
            };
        case GET_PERSON_DATA_DROPDOWN:
            return {
                ...state,
                DropdownListValues: action.payload.map((el) => ({
                    id: el.id,
                    value: el.fullName,
                })),
            };
        case GET_PERSON_DATA_DROPDOWN_FILTERED:
            return {
                ...state,
                DropdownListValuesFiltered: action.payload.map((el) => ({
                    id: el.id,
                    value: el.fullName,
                })),
            };
        case GET_PERSONS_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case GET_FILTERED_PERSONS_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case SET_PERSONS_FILTER_STATUS:
            return {
                ...state,
                isActive: action.payload,
            };
        case SET_PERSON_PHOTO:
            return {
                ...state,
                personPhoto: {
                    id: action.payload.id,
                    photo: action.payload.photo,
                },
            };
        case DELETE_PERSON_PHOTO:
            return {
                ...state,
                personPhoto: {},
            };
        default:
            return state;
    }
};
