const SaveIcon = () => (
    <svg
        width="29"
        height="28"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 29 28"
        fill="none"
    >
        <path
            d="M 5 5 L 5 6 L 5 26 L 5 27 L 6 27 L 26 27 L 27 27 L 27 26 L 27 10 L 27 9.59375 L 26.71875 9.28125 L 22.71875 5.28125 L 22.40625 5 L 22 5 L 6 5 L 5 5 z M 7 7 L 10 7 L 10 12 L 10 13 L 11 13 L 21 13 L 22 13 L 22 12 L 22 7.4375 L 25 10.4375 L 25 25 L 23 25 L 23 17 L 23 16 L 22 16 L 10 16 L 9 16 L 9 17 L 9 25 L 7 25 L 7 7 z M 12 7 L 16 7 L 16 9 L 18 9 L 18 7 L 20 7 L 20 11 L 12 11 L 12 7 z M 11 18 L 21 18 L 21 25 L 11 25 L 11 18 z"
            fill="#4D5E9E"
        />
    </svg>
);

export default SaveIcon;
