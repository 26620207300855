import {
    GET_MODULES_DATA,
    ADD_MODULE_DATA,
    DELETE_MODULE_DATA,
    EDIT_MODULE_DATA,
} from 'redux/types';

export const getModulesData = (data) => ({
    type: GET_MODULES_DATA,
    payload: data,
});

export const addModuleData = (data) => ({
    type: ADD_MODULE_DATA,
    payload: data,
});

export const editModuleData = (data) => ({
    type: EDIT_MODULE_DATA,
    payload: data,
});

export const deleteModuleData = (id) => ({
    type: DELETE_MODULE_DATA,
    payload: id,
});
