import React from 'react';
import styles from './styles.module.scss';

const TableHeader = () => (
    <thead className={styles.table__head}>
        <tr>
            <td>Уровень компетенции</td>
            <td>Специализация</td>
            <td>Сотрудник</td>
        </tr>
    </thead>
);

export default TableHeader;
