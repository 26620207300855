import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import miniLogo from 'icons/miniLogo.svg';
import logo from 'icons/logo.svg';
import Icon from 'components/Sidebar/Icon';
import {
    DirectoriesIcon,
    OpenMenuArrow,
} from 'components/svg/SidebarIcon/sidebarIcons';
import { ArrowDown } from 'icons/content/arrows';
import styles from './styles.module.scss';

const paths = [
    {
        id: 1,
        path: '/directories/workers',
        text: 'Сотрудники',
        group: 'directories',
        accessToPages: [
            'ADMIN',
            'SUPERVISOR',
            'MANAGER',
            'ACCOUNTANT',
            'HR',
            'PS',
        ],
    },
    {
        id: 2,
        path: '/directories/persons-cv',
        text: 'CV',
        group: 'directories',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
    },
    {
        id: 3,
        path: '/directories/specialties',
        text: 'Специализации',
        group: 'directories',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
    },
    {
        id: 4,
        path: '/directories/positions',
        text: 'Должности',
        group: 'directories',
        accessToPages: [
            'ADMIN',
            'SUPERVISOR',
            'MANAGER',
            'ACCOUNTANT',
            'HR',
            'PS',
        ],
    },
    {
        id: 5,
        path: '/directories/base-tariff-rates',
        text: 'Тарифные ставки',
        group: 'directories',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'ACCOUNTANT'],
    },
    {
        id: 6,
        path: '/directories/organizations',
        text: 'Организации',
        group: 'directories',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'ACCOUNTANT'],
    },
    {
        id: 7,
        path: '/projects',
        text: 'Проекты',
        group: 'main',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER'],
    },
    {
        id: 8,
        path: '/teams',
        text: 'Команды',
        group: 'main',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'HR'],
    },

    {
        id: 9,
        path: '/production-plans',
        text: 'План производства',
        group: 'main',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'ACCOUNTANT'],
    },
    {
        id: 10,
        path: '/reports',
        text: 'Отчёты',
        group: 'main',
        accessToPages: ['ADMIN', 'SUPERVISOR', 'MANAGER', 'ACCOUNTANT', 'HR'],
    },
    // {
    //     id: 11,
    //     path: '/calendar',
    //     text: 'Календарь событий',
    //     group: 'main',
    //     accessToPages: ['ADMIN', 'SUPERVISOR', 'HR', 'PS'],
    // },
    {
        id: 12,
        path: '/modules',
        text: ' Модули',
        group: 'control',
        accessToPages: ['ADMIN', 'SUPERVISOR'],
    },
    {
        id: 13,
        path: '/admin-panel',
        text: 'Панель администратора',
        group: 'control',
        accessToPages: ['ADMIN'],
    },
    {
        id: 14,
        path: '/user',
        text: 'Профиль',
        group: 'control',
        accessToPages: [
            'ADMIN',
            'SUPERVISOR',
            'MANAGER',
            'ACCOUNTANT',
            'HR',
            'PS',
            'USER',
        ],
    },
];

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState(location.pathname);
    const [isOpen, setIsOpen] = useState(false);
    const [derictoriesIsOpen, setDerictoriesIsOpen] = useState(false);
    const cx = classNames.bind(styles);

    const currentRole = useSelector((state) => state.auth.userData.userRole);

    useEffect(() => {
        const path = currentRole === 'USER' ? '/user' : '/';

        if (location.pathname === '/') {
            setActive(path);
            navigate(path);
        } else {
            setActive(location.pathname);
        }
    }, [location.pathname]);

    const directoriesList = paths
        .filter((el) => el.accessToPages.indexOf(currentRole) !== -1)
        .filter((el) => el.group === 'directories');

    return (
        <div
            className={cx('sidebar__main_column', {
                sidebar__main_column_opened: isOpen,
            })}
        >
            <nav className={styles.sidebar__main_group}>
                <div className={styles.sidebar__main_icons}>
                    {isOpen ? (
                        <div className={styles.sidebar__main_logo}>
                            <img src={logo} alt="logo" />
                        </div>
                    ) : (
                        <div className={styles.sidebar__main_miniLogo}>
                            <img src={miniLogo} alt="miniLogo" />
                        </div>
                    )}

                    {directoriesList.length > 0 && (
                        <div
                            className={styles.sidebar__main_icons_group}
                            data-tooltip-content="Справочники"
                            data-tooltip-id="directories"
                            data-tooltip-place="right"
                            data-tooltip-hidden={isOpen}
                            data-tooltip-delay-show={500}
                        >
                            <button
                                type="button"
                                className={styles.sidebar__dropDown}
                                onClick={() => {
                                    setIsOpen(true);
                                    setDerictoriesIsOpen(!derictoriesIsOpen);
                                }}
                            >
                                <DirectoriesIcon />
                                {isOpen && (
                                    <>
                                        <span
                                            className={cx(
                                                'sidebar__dropDown_text'
                                            )}
                                        >
                                            Справочники
                                        </span>
                                        <div
                                            className={cx(
                                                'sidebar__dropDown_svg',
                                                {
                                                    sidebar__dropDown_svg_open:
                                                        derictoriesIsOpen,
                                                }
                                            )}
                                        >
                                            <ArrowDown />
                                        </div>
                                    </>
                                )}
                            </button>
                            {derictoriesIsOpen && isOpen && (
                                <div
                                    className={cx(
                                        'sidebar__dropDown_icons_active',
                                        {
                                            // sidebar__dropDown_icons_disable:
                                            //     !derictoriesIsOpen,
                                        }
                                    )}
                                >
                                    {directoriesList.map((path) => (
                                        <Icon
                                            key={path.id}
                                            path={path.path}
                                            setActive={setActive}
                                            active={active}
                                            text={path.text}
                                            isOpen={isOpen}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}

                    <div className={styles.sidebar__main_icons_group}>
                        {paths
                            .filter(
                                (el) =>
                                    el.accessToPages.indexOf(currentRole) !== -1
                            )
                            .filter((el) => el.group === 'main')
                            .map((path) => {
                                return (
                                    <Icon
                                        key={path.id}
                                        path={path.path}
                                        setActive={setActive}
                                        active={active}
                                        text={path.text}
                                        isOpen={isOpen}
                                    />
                                );
                            })}
                    </div>
                    <div className={styles.sidebar__main_icons_group}>
                        {paths
                            .filter(
                                (el) =>
                                    el.accessToPages.indexOf(currentRole) !== -1
                            )
                            .filter((el) => el.group === 'control')
                            .map((path) => (
                                <Icon
                                    key={path.id}
                                    path={path.path}
                                    setActive={setActive}
                                    active={active}
                                    text={path.text}
                                    isOpen={isOpen}
                                />
                            ))}
                    </div>
                </div>
                <div
                    className={styles.sidebar__main_icons_group_bottom}
                    data-tooltip-delay-show={500}
                    data-tooltip-content="Развернуть"
                    data-tooltip-id="directories"
                    data-tooltip-place="right"
                    data-tooltip-hidden={isOpen}
                >
                    {paths
                        .filter(
                            (el) => el.accessToPages.indexOf(currentRole) !== -1
                        )
                        .filter((el) => el.group === 'bottom')
                        .map((path) => (
                            <Icon
                                key={path.id}
                                path={path.path}
                                setActive={setActive}
                                active={active}
                                text={path.text}
                                isOpen={isOpen}
                            />
                        ))}
                    <button
                        type="button"
                        className={styles.sidebar__openMenu}
                        onClick={() => {
                            setIsOpen(!isOpen);
                            setDerictoriesIsOpen(false);
                        }}
                    >
                        <span
                            className={cx('sidebar__openMenu_svg', {
                                sidebar__openMenu_svg_open: isOpen,
                            })}
                        >
                            <OpenMenuArrow />
                        </span>
                        {isOpen ? (
                            <span className={cx('sidebar__openMenu_text')}>
                                Свернуть
                            </span>
                        ) : null}
                    </button>
                </div>
            </nav>
        </div>
    );
};

export default Sidebar;
