import { useState, Fragment, useEffect } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames/bind';
import Loader from 'components/Loader';
import CVProjectInfo from 'components/Pages/CVPage/CVProjects/ProjectInfo';
import CVButtons from 'components/Pages/CVPage/CVButtons';
import {
    addCvProjects,
    removeProjectInfo,
    getCvInformation,
} from 'redux/thunks/cvPageThunks';
import { randomId } from 'helpers/editFormsValues';
import styles from './styles.module.scss';

const CVProjects = ({
    cvId,
    currentRole,
    personInfoId,
    projectsInfo = [],
    setProjectsInfo = () => {},
}) => {
    const cx = classNames.bind(styles);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [onSave, setOnSave] = useState(false);
    const [onRemove, setOnRemove] = useState(false);
    const [removePojectId, setRemoveProjectId] = useState(null);
    const [newProjects, setNewProjects] = useState([]);

    const countProjects = newProjects.length + projectsInfo.length;
    const submitArrProjects = [];

    useEffect(() => {
        if (countProjects === 0) {
            setIsEditing(false);
        }
    }, [countProjects]);

    const submit = ({ localId, ...project }) => {
        submitArrProjects.push(project);

        if (countProjects === submitArrProjects.length) {
            addCvProjects(
                submitArrProjects,
                setProjectsInfo,
                setNewProjects,
                setIsEditing,
                setIsLoading
            );
        }
    };
    const removeProject = (project) => {
        submitArrProjects.push(project);

        if (submitArrProjects.length === projectsInfo.length) {
            removeProjectInfo(
                removePojectId,
                submitArrProjects,
                setProjectsInfo,
                setIsLoading
            );
        }
    };

    const saveProjectsChange = () => {
        setOnSave(true);
    };

    const cancelProjectsChange = () => {
        getCvInformation(cvId, setIsLoading, () => {}, setProjectsInfo);
        setIsEditing(false);
    };

    const addProject = () => {
        const newProject = {
            localId: randomId(),
            personInfoId,
            name: '',
            participant: '',
            description: '',
            technologies: [],
        };
        setNewProjects([newProject, ...newProjects]);
        setIsEditing(true);
    };

    const removeNewProject = (projectId) => {
        setNewProjects(
            newProjects.filter((project) => project.localId !== projectId)
        );
    };

    return (
        <div className={cx('cv-projects')}>
            <div className={cx('cv-projects__header')}>
                <span>Проекты</span>
                {!isLoading && currentRole !== 'USER' && (
                    <CVButtons
                        isEdit={isEditing}
                        isEditing
                        isCancel
                        isSave
                        isAdd
                        onAdd={addProject}
                        onSave={saveProjectsChange}
                        onCansel={cancelProjectsChange}
                        setIsEdit={setIsEditing}
                    />
                )}
            </div>
            {}
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {newProjects?.map((newProject) => (
                        <Fragment key={newProject.localId}>
                            <CVProjectInfo
                                submit={submit}
                                onSave={onSave}
                                setOnSave={setOnSave}
                                setSaveProjects={setNewProjects}
                                setIsEditing={setIsEditing}
                                cvProject={newProject}
                                isEditing={isEditing}
                                removeNewProject={removeNewProject}
                            />
                        </Fragment>
                    ))}
                    {projectsInfo?.map((projectInfo) => (
                        <Fragment key={projectInfo.id}>
                            <CVProjectInfo
                                submit={submit}
                                onSave={onSave}
                                setOnSave={setOnSave}
                                setSaveProjects={setNewProjects}
                                setIsEditing={setIsEditing}
                                cvProject={projectInfo}
                                isEditing={isEditing}
                                onRemove={onRemove}
                                setOnRemove={setOnRemove}
                                removeProject={removeProject}
                                setRemoveProjectId={setRemoveProjectId}
                            />
                        </Fragment>
                    ))}
                </>
            )}
        </div>
    );
};

export default CVProjects;

CVProjects.propTypes = {
    cvId: number,
    currentRole: string,
    personInfoId: number,
    projectsInfo: arrayOf(shape({})),
    setProjectsInfo: func,
};
