import {
    GET_FREQUENCY_DATA,
    ADD_FREQUENCY_DATA,
    EDIT_FREQUENCY_DATA,
} from 'redux/types';

const defaultState = {
    data: [],
    DropdownListValues: [],
};

export const frequency = (state = defaultState, action) => {
    switch (action.type) {
        case GET_FREQUENCY_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case ADD_FREQUENCY_DATA:
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case EDIT_FREQUENCY_DATA: {
            return {
                ...state,
                data: [
                    ...state.data.map((item) => {
                        if (item.id === action.payload.id) {
                            return { ...action.payload };
                        }
                        return { ...item };
                    }),
                ],
            };
        }
        default:
            return state;
    }
};
