import PageHeader from 'components/PageHeader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllModules } from 'redux/thunks/modulesThunks';
import { addJiraProjects, getJiraProjects } from 'redux/thunks/ImportThunks';
import Table from 'components/Tables/Table';
import { headers } from 'helpers/tableHandler';
import { getAllProjectsForDropDown } from 'redux/thunks/projectThunks';
import Button from 'components/Button';
import { useNavigate } from 'react-router';
import isEmpty from 'helpers/isEmpty';
import Loader from 'components/Loader';
import { useToastify } from 'helpers/toastify/useToastify';
import { shape } from 'prop-types';
import styles from './styles.module.scss';

const ImportProject = ({ selectedModule }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const projectsJira = useSelector((state) => state.jiraProjects.data);
    const systems = useSelector((state) => state.modules.DropdownListValues);
    const projects = useSelector((state) => state.projects.DropdownListValues);

    const [isLoading, setIsLoading] = useState(false);
    const [typeModule, setTypeModule] = useState(selectedModule);
    const [isEdited, setIsEdited] = useState(false);
    const [data, setData] = useState([{}]);
    const [projectsItems, setProjectsItems] = useState([]);

    useEffect(() => setTypeModule(selectedModule), [selectedModule]);

    useEffect(() => {
        dispatch(getAllModules());
        dispatch(getAllProjectsForDropDown());
    }, [dispatch]);

    useEffect(() => {
        if (typeModule?.systemId === 2) {
            dispatch(getJiraProjects(setIsLoading, typeModule?.id));
        } else {
            setData([]);
        }
    }, [dispatch, typeModule]);

    useEffect(() => {
        if (projects.length > 0) {
            setProjectsItems([...projects]);
        }
    }, [projects]);

    useEffect(() => {
        setData(projectsJira);
    }, [projectsJira]);

    const handleSelect = (item, project) => {
        setIsEdited(true);

        setProjectsItems((prev) =>
            prev.map((itemProject) => {
                if (item.items.projectId) {
                    if (itemProject.id === item.items.projectId) {
                        return { ...itemProject, isSelected: false };
                    }
                }
                if (isEmpty(project)) {
                    if (project.id === itemProject.id) {
                        return { ...itemProject, isSelected: true };
                    }
                }
                return { ...itemProject };
            })
        );
        setData((prev) => {
            return prev.map((elem) => {
                if (elem.id === item.id) {
                    return {
                        ...elem,
                        projectId: project?.id ? project.id : null,
                    };
                }
                return elem;
            });
        });
    };

    const setChecked = (id) => {
        setIsEdited(true);
        setData((prev) =>
            prev.map((elem) => {
                if (elem.id === id) {
                    return { ...elem, synchronize: !elem.synchronize };
                }
                return elem;
            })
        );
    };

    const countSelectedProject = (itemsModule) => {
        let count = 0;
        itemsModule.forEach((element) => {
            if (element.projectId && element.synchronize) {
                count += 1;
            }
        });
        return count;
    };

    const handleClickCancel = () => {
        if (typeModule?.systemId === 2) {
            setData(projectsJira);
        }
    };

    const handleClick = () => {
        const onSave = data.filter((elem) => {
            return elem.synchronize;
        });
        dispatch(addJiraProjects(onSave));
    };

    return (
        <div className={styles.container}>
            <PageHeader title="Импорт проектов" />
            <div className={styles.content}>
                {!isEmpty(selectedModule) ? (
                    <div>Не выбран модуль</div>
                ) : (
                    <div className={styles.module}>
                        <div>
                            Найдено проектов:{' '}
                            {isEmpty(typeModule) ? data.length : 0}
                        </div>
                        <div>
                            Выбрано для импорта:{' '}
                            {isEmpty(typeModule)
                                ? countSelectedProject(data)
                                : 0}
                        </div>
                    </div>
                )}
                {typeModule.id &&
                    (!isLoading ? (
                        <>
                            <Table
                                header={headers.projectImport}
                                caseName="PROJECTS_IMPORT"
                                items={data}
                                dropDownItems={projectsItems}
                                handleSelect={handleSelect}
                                setChecked={setChecked}
                                changeData={setData}
                            />
                            <div className={styles.footer}>
                                <Button
                                    text="Отмена"
                                    onClick={handleClickCancel}
                                    buttonStyle="outlined"
                                />
                                <Button
                                    text="Импорт"
                                    onClick={handleClick}
                                    disabled={!isEdited}
                                />
                            </div>
                        </>
                    ) : (
                        <Loader />
                    ))}
            </div>
        </div>
    );
};

ImportProject.propTypes = {
    selectedModule: shape({}),
};

export default ImportProject;
