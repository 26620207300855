import API from 'api/api';
import { useToastify } from 'helpers/toastify/useToastify';
import { saveFile } from 'helpers/editFormsValues';

export const getCvInformation = (
    cvId,
    setIsLoading,
    setCvPersonInfo,
    setCvProjectsInfo
) => {
    setIsLoading(true);
    API.cvPersons
        .getPersonCV(cvId)
        .then((res) => {
            const { id, fullName } = res.data.person;

            setCvPersonInfo({
                personId: id,
                personInfoId: res.data.id,
                professionalSkills: res.data.professionalSkills,
                education: res.data.education,
                operatingSystems: res.data.operatingSystems,
                programmingLanguage: res.data.programmingLanguage,
                foreignLanguages: res.data.foreignLanguages,
                certificates: res.data.certificates,
                projectParticipants: res.data.projectParticipants,
                tools: res.data.tools,
                experience: res.data.experience,
                fullName,
                specialityName: res.data.specialityName,
            });
            setCvProjectsInfo(res.data.projectInfos);
        })
        .catch(() => useToastify('Ошибка при получении cv сотрудника', 'error'))
        .finally(() => setIsLoading(false));
};

export const saveCvPersonInfo = (cvId, data, setIsEdit, isLoading) => {
    isLoading(true);
    const finalData = {
        personId: data.personId,
        programmingLanguage: data.programmingLanguage,
        professionalSkills: data.professionalSkills,
        tools: data.tools,
        operatingSystems: data.operatingSystems,
        foreignLanguages: data.foreignLanguages,
        education: data.education,
        certificates: data.certificates,
        experience: data.experience,
    };

    API.cvPersons
        .editPersonCV(cvId, finalData)
        .then(() => {
            setIsEdit(false);
            useToastify('Изменения сохранены', 'success');
        })
        .catch((err) =>
            useToastify(err?.message ?? 'Ошибка при редактировании cv', 'error')
        )
        .finally(() => isLoading(false));
};

export const addCvProjects = (
    data,
    setProjectsInfo,
    setSubmitData,
    setIsEdit,
    setIsLoading
) => {
    setIsLoading(true);

    API.cvProjects
        .postProjectsCV(data)
        .then((res) => {
            setProjectsInfo(res.data);
            setSubmitData([]);
            setIsEdit(false);
            useToastify('Изменения проектов cv сохранены', 'success');
        })
        .catch((err) =>
            useToastify(
                err?.message ?? 'Ошибка при редактировании проектов cv',
                'error'
            )
        )
        .finally(() => setIsLoading(false));
};
export const saveCvProjects = (
    data,
    setProjectsInfo,
    setIsEdit,
    setIsLoading
) => {
    API.cvProjects
        .editProjectsCV(data)
        .then((res) => {
            setProjectsInfo(res.data);
            setIsEdit(false);
            useToastify('Изменения проектов cv сохранены', 'success');
        })
        .catch((err) =>
            useToastify(
                err?.message ?? 'Ошибка при редактировании проектов cv',
                'error'
            )
        )
        .finally(() => setIsLoading(false));
};

export const saveCvProject = (
    data,
    projectsInfo,
    setProjectsInfo,
    setIsEdit,
    setIsLoading
) => {
    API.cvProjects
        .editProjectCV(data.id, data)
        .then((res) => {
            const newArrProjects = projectsInfo.map((project) =>
                project.id === res.data.id ? res.data : project
            );
            setProjectsInfo(newArrProjects);
            setIsEdit(false);
            useToastify(`Изменения проекта ${data.name} сохранены`, 'success');
        })
        .catch((err) =>
            useToastify(
                err?.message ?? 'Ошибка при редактировании проектов cv',
                'error'
            )
        )
        .finally(() => setIsLoading(false));
};

export const findTags = (tagName, existingTagsList, setFoundedTags) => {
    API.cvToolTags
        .getCvToolTags({ title: tagName })
        .then((res) => {
            const filterTags = res.data.content.filter(
                (a) => existingTagsList.indexOf(a.title) === -1
            );
            setFoundedTags(filterTags);
        })
        .catch((err) =>
            useToastify(
                err?.message ?? 'Произошла ошибка поиска тэгов',
                'error'
            )
        );
};

export const removeProjectInfo = (
    id,
    submitArrProjects,
    setProjectsInfo,
    setIsLoading
) => {
    setIsLoading(true);
    API.cvProjects
        .removeProjectCV(id)
        .then(() => {
            const filterProjects = submitArrProjects.filter(
                (project) => project.id !== id
            );
            setProjectsInfo(filterProjects);
            useToastify(`Проект удалён`, 'success');
        })
        .catch((err) =>
            useToastify(err?.message ?? 'Не удалось удалить проект', 'error')
        )
        .finally(() => setIsLoading(false));
};

export const cvUnload = (id, personName) => {
    API.unload
        .cvPerson(id)
        .then((res) => {
            saveFile(`CV ${personName}`, res.data, 'application/pdf');
        })
        .catch((err) =>
            useToastify(err?.message ?? 'Не удалось выгрузить cv', 'error')
        );
};
