const checkIsValid = (obj, props) => {
    let newValidator = { ...obj };
    Object.keys(obj).forEach((el) => {
        if (props[el]) {
            newValidator = { ...newValidator, [el]: true };
        } else {
            newValidator = { ...newValidator, [el]: false };
        }
    });
    return newValidator;
};

const useValidator = (props) => {
    let validator = {};
    let isValid = false;

    Object.keys(props).forEach((el) => {
        validator[el] = false;
    });

    validator = checkIsValid(validator, props);

    isValid = !Object.values(validator).includes(false);

    return { validator, isValid };
};

export default useValidator;
