import { func, number } from 'prop-types';
import API from 'api/api';
import Button from 'components/Button';
import { months } from 'helpers/tableHandler';
import { saveFile } from 'helpers/editFormsValues';
import { useToastify } from 'helpers/toastify/useToastify';
import styles from './styles.module.scss';

const UploadInProductionPlans = ({ closeModal = () => {}, year, month }) => {
    const unloadProdPaln = () => {
        closeModal();
        API.unload
            .productionPlan({ yearIs: year, monthIs: month + 1, format: 'CSV' })
            .then((res) => {
                saveFile(
                    `План производства за ${months[month]} ${year}г.`,
                    res.data,
                    'text/csv'
                );
            })
            .catch((res) =>
                useToastify(
                    res.message
                        ? `Ошибка при загрузке плана, ${res.message}`
                        : 'Ошибка при загрузке плана',
                    'error'
                )
            );
    };
    const unloadUnusedPerson = () => {
        closeModal();
        API.unload
            .unusedPersons({ yearIs: year, monthIs: month + 1, format: 'CSV' })
            .then((res) => {
                saveFile(
                    `Незадействованные сотрудники за ${months[month]} ${year}г.`,
                    res.data,
                    'text/csv'
                );
            })
            .catch((res) =>
                useToastify(
                    res.message
                        ? `Ошибка при загрузке незадействованных сотрудников, ${res.message}`
                        : 'Ошибка при загрузке незадействованных сотрудников',
                    'error'
                )
            );
    };

    return (
        <div className={styles.modal__content}>
            <div className={styles.modal__main_container}>
                <div className={styles.modal__main_container_btn}>
                    <Button text="План производства" onClick={unloadProdPaln} />
                </div>
                <div className={styles.modal__main_container_btn}>
                    <Button
                        text="Незадействованных сотрудников"
                        onClick={unloadUnusedPerson}
                    />
                </div>
            </div>
            <div className={styles.button_container}>
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={closeModal}
                    wide
                />
            </div>
        </div>
    );
};

UploadInProductionPlans.propTypes = {
    year: number,
    month: number,
    closeModal: func,
};
export default UploadInProductionPlans;
