import { useEffect, useRef, useState } from 'react';
import { func, string, shape, oneOfType, bool } from 'prop-types';
import Loader from 'components/Loader/index';
import PersonDefaultIcon from 'components/svg/profileDefault';
import ImgIcon from 'components/svg/photo';
import DeleteIcon from 'components/svg/deleteIcon';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const UploadAvatar = ({
    onChange = () => {},
    file,
    isLoading,
    setFile = () => {},
    setIsImageChanged = () => {},
}) => {
    const [fileDataURL, setFileDataURL] = useState(null);
    const cx = classNames.bind(styles);
    const inputFile = useRef();

    useEffect(() => {
        let fileReader;
        let isCancel = false;
        if (typeof file === 'string') {
            return setFileDataURL(`data:image/jpeg;base64,${file}`);
        }
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFileDataURL(result);
                }
            };
            fileReader.readAsDataURL(file);
        }
        if (!file) {
            setFileDataURL(``);
        }

        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    }, [file]);

    return (
        <div className={styles.container}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {fileDataURL ? (
                        <img
                            className={styles.img_avatar}
                            src={fileDataURL}
                            alt="profile"
                        />
                    ) : (
                        <PersonDefaultIcon />
                    )}
                    <label htmlFor="input__file">
                        <div className={styles.select_container}>
                            <span
                                className={cx(
                                    'select_container__info',
                                    'select_container__text'
                                )}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                Выберите файл до 1МБ
                            </span>
                            <div
                                className={cx(
                                    'select_container__button',
                                    'select_container__text'
                                )}
                            >
                                <ImgIcon />
                                Загрузить фотографию
                            </div>
                            <div
                                className={cx(
                                    'select_container__button',
                                    'select_container__text'
                                )}
                                onClick={(e) => {
                                    e.preventDefault();
                                    inputFile.current.value = '';
                                    setFile({});
                                    setIsImageChanged(true);
                                }}
                            >
                                <DeleteIcon />
                                Удалить фотографию
                            </div>
                        </div>

                        <input
                            ref={inputFile}
                            className={styles.input_avatar}
                            accept=".png, .jpg, .jpeg"
                            type="file"
                            id="input__file"
                            onChange={onChange}
                        />
                    </label>
                </>
            )}
        </div>
    );
};

export default UploadAvatar;

UploadAvatar.propTypes = {
    isLoading: bool,
    onChange: func,
    file: oneOfType([shape({}), string]),
    setFile: func,
    setIsImageChanged: func,
};
