const InfoIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_4415_30588)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.27 3H15.73L21 8.27V15.73L15.73 21H8.27L3 15.73V8.27L8.27 3ZM11.0795 13.4305C11.0221 13.7212 10.9934 14.0232 10.9934 14.3365H12.6821C12.6821 14.0731 12.6932 13.8688 12.7152 13.7234C12.7373 13.5736 12.8102 13.3919 12.9338 13.1785C13.0044 13.0559 13.0927 12.9401 13.1987 12.8311C13.3091 12.7175 13.4283 12.6017 13.5563 12.4837C13.6843 12.3656 13.819 12.2293 13.9603 12.0749C14.2605 11.748 14.5077 11.3915 14.702 11.0054C14.9007 10.6194 15 10.1926 15 9.7248C15 9.41144 14.947 9.10263 14.8411 8.79836C14.7395 8.4941 14.5894 8.22616 14.3907 7.99455C14.1038 7.64033 13.7439 7.38601 13.3113 7.23161C12.883 7.0772 12.426 7 11.9404 7C11.6225 7 11.3046 7.04768 10.9868 7.14305C10.6733 7.23842 10.3907 7.38147 10.1391 7.57221C9.81678 7.80381 9.55629 8.1149 9.35762 8.50545C9.16336 8.89146 9.04415 9.31153 9 9.76567H10.6821C10.6777 9.57493 10.7064 9.40009 10.7682 9.24114C10.83 9.0822 10.9272 8.94823 11.0596 8.83924C11.1744 8.73933 11.3135 8.66667 11.4768 8.62125C11.6446 8.5713 11.819 8.54632 12 8.54632C12.2208 8.54632 12.4283 8.58265 12.6225 8.65531C12.8168 8.72343 12.9757 8.84151 13.0993 9.00954C13.1788 9.1049 13.234 9.21844 13.2649 9.35014C13.2958 9.48183 13.3113 9.62262 13.3113 9.77248C13.3113 10.0041 13.2826 10.2221 13.2252 10.4264C13.1678 10.6262 13.0486 10.8283 12.8675 11.0327C12.6468 11.2734 12.4459 11.4664 12.2649 11.6117C12.0839 11.7525 11.9205 11.8955 11.7748 12.0409C11.6291 12.1817 11.4967 12.3724 11.3775 12.6131C11.2406 12.8629 11.1413 13.1353 11.0795 13.4305ZM11.0066 15.1948V17H12.6821V15.1948H11.0066Z"
                fill="#696C80"
            />
        </g>
        <defs>
            <clipPath id="clip0_4415_30588">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export default InfoIcon;
