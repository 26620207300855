import API from 'api/api';
import { logout } from 'redux/actions/auth';
import {
    getModulesData,
    addModuleData,
    editModuleData,
    deleteModuleData,
} from 'redux/actions/modules';
import { useToastify } from 'helpers/toastify/useToastify';
import { addFrequency } from './frequencyThunks';

export const getAllModules =
    (setIsLoading = () => {}) =>
    (dispatch) => {
        setIsLoading(true);
        API.modules
            .getAllModules()
            .then((res) => {
                dispatch(getModulesData(res.data.content));
            })
            .catch((err) => {
                if (err.name === 'UNAUTHORIZED') {
                    dispatch(logout());
                    localStorage.clear();
                }
                useToastify(err?.message ?? 'Произошла ошибка', 'error');
            })
            .finally(() => setIsLoading(false));
    };

export const editModule = (id, data) => (dispatch) => {
    API.modules
        .editModule(id, data.module)
        .then((res) => {
            dispatch(editModuleData(res.data));
            useToastify('Модуль изменён', 'success');
        })
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        });
};

export const addModule = (data, frequency) => (dispatch) => {
    API.modules
        .addModule(data)
        .then((res) => {
            dispatch(addFrequency({ ...frequency, moduleId: res.data.id }));
            dispatch(addModuleData(res.data));
            useToastify('Модуль добавлен', 'success');
        })
        .catch((err) => {
            if (err.name === 'UNAUTHORIZED') {
                dispatch(logout());
                localStorage.clear();
            }
            useToastify(err?.message ?? 'Произошла ошибка', 'error');
        });
};

export const deleteModule = (id, changeState) => (dispatch) => {
    dispatch(deleteModuleData(id));
    changeState();
};
