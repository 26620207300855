import API from 'api/api';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, number, shape, string } from 'prop-types';
import useValidator from 'hooks/useValidator';
import DropdownList from 'components/DropdownList';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import ModalTable from 'components/Tables/ModalTable';
import Input from 'components/Input';
import { getMonth, getYear } from 'helpers/tableHandler';
import { useToastify } from 'helpers/toastify/useToastify';
import { getProjectTeamsData } from 'redux/actions/projectTeams';
import { getFilteredPersonsForDropDown } from 'redux/thunks/personThunks';
import { getAllProjectsForDropDownFiltered } from 'redux/thunks/projectThunks';
import { getAllCompetences } from 'redux/thunks/competenceLevelsThunks';
import { getAllOrganizations } from 'redux/thunks/organizationsThunks';
import { getBaseTariffByPerson } from 'redux/thunks/baseTariffsThunks';
import { getAllTeams } from 'redux/thunks/teamsTunks';
import { getBaseTariffRatesForSelectedPerson } from 'redux/actions/baseTariffRates';
import isEmpty from 'helpers/isEmpty';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const ProjectTeamsContent = ({
    initProjectName = null,
    addProdPlan = false,
    successCallBack = () => {},
    items = {},
    teamId = 0,
    closeModal = () => {},
    changeIsActiveFlag = () => {},
}) => {
    const cx = classNames.bind(styles);
    const [projectName, setProjectName] = useState({});
    const [selectedStartPeriod, setSelectedStartPeriod] = useState(null);
    const [selectedEndPeriod, setSelectedEndPeriod] = useState(null);
    const [selectedWorker, setSelectedWorker] = useState({});
    const [selectedOrganisation, setSelectedOrganisation] = useState({});
    const [personPlannedHours, setPersonPlannedHours] = useState();
    const [tariffRateHour, setTariffRateHour] = useState();
    const [selectedCompetence, setSelectedCompetence] = useState({});
    const [submitTouched, setSubmitTouched] = useState(false);
    const [personsList, setPersonsList] = useState([]);

    const dispatch = useDispatch();
    const persons = useSelector(
        (state) => state.persons.DropdownListValuesFiltered
    );
    const projects = useSelector((state) => state.projects);
    const baseTariffRates = useSelector(
        (state) => state.baseTariffRates.selectedPersonTariffRates
    );
    const organizations = useSelector(
        (state) => state.organizations.DropdownListValues
    );
    const competences = useSelector(
        (state) => state.specialtiesAndCompetence.DropdownCompetenceListValues
    );

    const [dateEnd, setDateEnd] = useState(null);
    const [dateStart, setDateStart] = useState(null);

    useEffect(() => {
        dispatch(getAllProjectsForDropDownFiltered({ isActive: true }));
        dispatch(getAllOrganizations());
        dispatch(getFilteredPersonsForDropDown({ isActive: true }));
        dispatch(getAllCompetences());
    }, []);

    useEffect(() => {
        if (initProjectName) setPersonsList([]);
    }, [selectedWorker]);

    const { isValid, validator } = useValidator({
        projectName: projectName?.value,
        personsList: personsList.length,
    });

    const AddPersonValidator = useValidator({
        personsList: personsList.length,
        selectedWorker: selectedWorker?.value,
    });

    useEffect(() => {
        if (dateStart && dateEnd) {
            if (selectedStartPeriod) {
                if (
                    getYear(selectedStartPeriod) < getYear(dateStart) ||
                    (getYear(selectedStartPeriod) === getYear(dateStart) &&
                        getMonth(selectedStartPeriod) < getMonth(dateStart))
                ) {
                    setSelectedStartPeriod('');
                    useToastify(
                        'Дата начала периода не может быть меньше даты начала проекта',
                        'warning'
                    );
                }
                if (
                    getYear(selectedStartPeriod) > getYear(dateEnd) ||
                    (getYear(selectedStartPeriod) === getYear(dateEnd) &&
                        getMonth(selectedStartPeriod) > getMonth(dateEnd))
                ) {
                    setSelectedStartPeriod('');
                    useToastify(
                        'Дата начала периода не может быть больше даты окончания проекта',
                        'warning'
                    );
                }
            }
            if (selectedEndPeriod) {
                if (
                    getYear(selectedEndPeriod) < getYear(dateStart) ||
                    (getYear(selectedEndPeriod) === getYear(dateStart) &&
                        getMonth(selectedEndPeriod) < getMonth(dateStart))
                ) {
                    setSelectedEndPeriod('');
                    useToastify(
                        'Дата окончание периода не может быть меньше даты начала проекта',
                        'warning'
                    );
                }
                if (
                    getYear(selectedEndPeriod) > getYear(dateEnd) ||
                    (getYear(selectedEndPeriod) === getYear(dateEnd) &&
                        getMonth(selectedEndPeriod) > getMonth(dateEnd))
                ) {
                    setSelectedEndPeriod('');
                    useToastify(
                        'Дата окончание периода не может быть больше даты окончания проекта',
                        'warning'
                    );
                }
            }
            if (selectedStartPeriod && selectedEndPeriod) {
                if (selectedStartPeriod > selectedEndPeriod) {
                    setSelectedEndPeriod('');
                    useToastify(
                        'Дата окончание периода не может быть меньше даты начала периода',
                        'warning'
                    );
                }
            }
        }
    }, [selectedStartPeriod, selectedEndPeriod]);

    useEffect(() => {
        const ProjectName = initProjectName ?? projectName?.value;
        const findedProject = projects?.data?.find(
            (proj) => proj.name === ProjectName
        );
        if (findedProject) {
            setDateStart(findedProject.dateStart);
            setDateEnd(findedProject.dateEnd);
        }
    }, [projects.data, projectName]);

    useEffect(() => {
        if (selectedWorker.id) {
            dispatch(getBaseTariffByPerson(selectedWorker.id));
        }
        setTariffRateHour();
    }, [selectedWorker]);

    const [team, setTeam] = useState({});
    const teams = useSelector((state) => state.projectTeams.data);
    useEffect(() => {
        if (teams.length) {
            setTeam(teams.find((elem) => elem.projectId === projectName.id));
        }
    }, [teams, projectName.id]);
    useEffect(() => {
        if (isEmpty(projectName) && addProdPlan) {
            dispatch(getAllTeams(projectName.id));
        }
    }, [projectName.id]);

    const onSave = () => {
        if (initProjectName || addProdPlan) {
            if (AddPersonValidator.isValid) {
                API.periods
                    .addTeamPeriod({
                        teamId: teamId === 0 ? team?.id : teamId,
                        teamPeriodCompactRequestDto: {
                            personId: selectedWorker.id,
                            organizationId: selectedOrganisation.id,
                            periods: personsList.map((item) => ({
                                startMonth:
                                    item.selectedStartPeriod.getMonth() + 1,
                                startYear:
                                    item.selectedStartPeriod.getFullYear(),
                                endMonth: item.selectedEndPeriod.getMonth() + 1,
                                endYear: item.selectedEndPeriod.getFullYear(),
                                competenceLevel: item.selectedCompetence.id,
                                personPlannedHours: Number(
                                    item.personPlannedHours
                                ),
                                tariffRateHour: item.tariffRateHour,
                                tariffRateDay: item.tariffRateHour * 8,
                            })),
                        },
                    })
                    .then((resTeam) => {
                        if (resTeam.data) {
                            API.reports
                                .getTeamPeriods({
                                    projectAndTeamIsActive: true,
                                })
                                .then((res) =>
                                    dispatch(getProjectTeamsData(res.data))
                                );
                            changeIsActiveFlag();
                            useToastify(
                                'Сотрудник добавлен в команду',
                                'success'
                            );
                            closeModal();
                            successCallBack(selectedWorker.id);
                        }
                    })
                    .catch((err) =>
                        useToastify(err?.message ?? 'Произошла ошибка', 'error')
                    );
            }
        } else if (isValid) {
            API.teams
                .addTeam({
                    projectId: projectName.id,
                    persons: createPersonsList(),
                })
                .then((resTeam) => {
                    if (resTeam.data) {
                        API.reports
                            .getTeamPeriods({ projectAndTeamIsActive: true })
                            .then((res) =>
                                dispatch(getProjectTeamsData(res.data))
                            );
                        changeIsActiveFlag();
                        useToastify('Команда добавлена', 'success');
                        closeModal();
                        successCallBack(selectedWorker.id);
                    }
                    // dispatch(addProjectTeamData(resTeam.data));
                })
                .catch((err) =>
                    useToastify(err?.message ?? 'Произошла ошибка', 'error')
                );
        }
        if (!personsList.length) {
            useToastify(
                'Добавьте в команду хотя бы одного сотрудника',
                'warning'
            );
        }
        setSubmitTouched(true);
    };

    const checkDuplicatedPeriodForPerson = () => {
        if (personsList.length) {
            const person = personsList.filter((el) => {
                if (selectedStartPeriod >= el.selectedStartPeriod) {
                    return (
                        el.person.id === selectedWorker.id &&
                        (el.selectedEndPeriod >= selectedStartPeriod ||
                            el.selectedEndPeriod >= selectedEndPeriod)
                    );
                }
                return (
                    el.person.id === selectedWorker.id &&
                    (el.selectedStartPeriod <= selectedEndPeriod ||
                        el.selectedStartPeriod <= selectedStartPeriod)
                );
            });
            return person.length;
        }
        return false;
    };

    const checkWrongOrganizationForPerson = () => {
        return personsList.find(
            (el) =>
                el.person.id === selectedWorker.id &&
                el.organization.id !== selectedOrganisation.id
        );
    };

    const createPersonsList = () => {
        const personsEnt = {};
        const personsArr = [];

        personsList.forEach((item) => {
            if (personsEnt[item.person.id]) {
                personsEnt[item.person.id] = [
                    ...personsEnt[item.person.id],
                    item,
                ];
            } else {
                personsEnt[item.person.id] = [item];
            }
        });

        for (const elem in personsEnt) {
            personsArr.push({
                personId: personsEnt[elem][0].person.id,
                organizationId: personsEnt[elem][0].organization.id,
                periods: [
                    ...personsEnt[elem].map((item) => {
                        return {
                            startMonth: item.selectedStartPeriod.getMonth() + 1,
                            startYear: item.selectedStartPeriod.getFullYear(),
                            endMonth: item.selectedEndPeriod.getMonth() + 1,
                            endYear: item.selectedEndPeriod.getFullYear(),
                            competenceLevel: item.selectedCompetence.id,
                            personPlannedHours: Number(item.personPlannedHours),
                            tariffRateHour: item.tariffRateHour,
                            tariffRateDay: item.tariffRateHour * 8,
                        };
                    }),
                ],
            });
        }
        return personsArr;
    };

    const onAddToTable = () => {
        if (checkPerson()) {
            if (!checkWrongOrganizationForPerson()) {
                if (!checkDuplicatedPeriodForPerson()) {
                    setPersonsList([
                        ...personsList,
                        {
                            person: selectedWorker,
                            organization: selectedOrganisation,
                            selectedCompetence,
                            selectedStartPeriod,
                            selectedEndPeriod,
                            personPlannedHours,
                            tariffRateHour,
                        },
                    ]);
                } else {
                    useToastify('Период сотрудника дублируется', 'warning');
                }
            } else {
                useToastify(
                    'Для одного сотрудника выбраны разные организации',
                    'warning'
                );
            }
        }
    };
    const handelCancel = () => {
        closeModal();
        successCallBack();
        // dispatch(getBaseTariffRatesForSelectedPerson());
    };

    const checkPerson = () => {
        return (
            isEmpty(selectedWorker) &&
            isEmpty(selectedOrganisation) &&
            selectedStartPeriod &&
            selectedEndPeriod &&
            isEmpty(selectedCompetence) &&
            tariffRateHour &&
            selectedEndPeriod >= selectedStartPeriod
        );
    };
    const deleteItemFromTable = (itemIndex) => {
        const newPersonsList = [...personsList];
        newPersonsList.splice(itemIndex, 1);
        setPersonsList(newPersonsList);
    };

    const handleDropdownManualInput = (value) => {
        if (value.match(/^[0-9]+$/)) {
            setTariffRateHour(value);
        } else if (!value) {
            setTariffRateHour();
        }
    };

    const handleSetTariffRateHour = (selectedItem) => {
        setTariffRateHour(selectedItem.value);
    };

    return (
        <div className={styles.project_teams_content}>
            {initProjectName ? (
                <Input disabled placeholder="Проект" value={initProjectName} />
            ) : (
                <DropdownList
                    selected={projectName}
                    setSelected={setProjectName}
                    placeholderInput="Проект"
                    title="Проект"
                    items={projects.DropdownListValuesFiltered}
                    error={!validator.projectName && submitTouched}
                />
            )}
            <DropdownList
                selected={selectedOrganisation}
                setSelected={setSelectedOrganisation}
                placeholderInput="Организация"
                title="Организация"
                items={organizations}
            />
            <DropdownList
                selected={selectedWorker}
                setSelected={setSelectedWorker}
                placeholderInput="Сотрудник"
                title="Сотрудник"
                items={persons}
                error={
                    initProjectName &&
                    !AddPersonValidator.validator.selectedWorker &&
                    submitTouched
                }
            />
            <DropdownList
                setSelected={setSelectedCompetence}
                selected={selectedCompetence}
                items={competences}
                placeholderInput="Уровень компетенций"
                title="Уровень компетенций"
            />
            <div className={styles.period_container}>
                <div className={styles.period_container__item}>
                    <DatePicker
                        showMonthOnly
                        dateDrop={selectedStartPeriod}
                        setDateDrop={setSelectedStartPeriod}
                        rangeStartDate={dateStart}
                        rangeEndDate={dateEnd}
                        placeholder="Начало периода работы на проекте"
                        title="Начало периода работы на проекте"
                        hover="red"
                    />
                </div>
                <div className={styles.period_container__item}>
                    <DatePicker
                        showMonthOnly
                        dateDrop={selectedEndPeriod}
                        setDateDrop={setSelectedEndPeriod}
                        rangeStartDate={dateStart}
                        rangeEndDate={dateEnd}
                        placeholder="Конец периода работы на проекте"
                        title="Конец периода работы на проекте"
                    />
                </div>
            </div>
            <div className={styles.inputs_container}>
                <div className={styles.input}>
                    <Input
                        onChange={setPersonPlannedHours}
                        placeholder="План. часы за месяц"
                        title="План. часы за месяц"
                        symbolLimit={3}
                        type="number"
                        value={personPlannedHours}
                    />
                </div>
                <div className={styles.input}>
                    <DropdownList
                        selected={tariffRateHour}
                        setSelected={handleSetTariffRateHour}
                        placeholderInput="Тарифная ставка в час, руб."
                        title="Тарифная ставка в час, руб."
                        items={baseTariffRates}
                        dropdownManualInput
                        handleDropdownManualInput={handleDropdownManualInput}
                    />
                </div>
                <div className={styles.input}>
                    <Input
                        disabled
                        placeholder="Тарифная ставка в день, руб."
                        title="Тарифная ставка в день, руб."
                        symbolLimit={5}
                        type="number"
                        value={tariffRateHour && tariffRateHour * 8}
                    />
                </div>
            </div>
            <div className={styles.buttons_container_end}>
                <Button
                    buttonIconName="plus"
                    buttonStyle="outlined"
                    text={
                        personsList.length
                            ? 'Добавить период сотруднику'
                            : 'Добавить сотрудника'
                    }
                    onClick={onAddToTable}
                    disabled={!checkPerson()}
                />
            </div>
            {personsList.length ? (
                <ModalTable
                    personsList={personsList}
                    deleteItemFromTable={deleteItemFromTable}
                />
            ) : null}
            <div
                className={cx('buttons_container', {
                    buttons_container__position: personsList?.length,
                })}
            >
                <Button
                    buttonStyle="outlined"
                    text="Отмена"
                    onClick={handelCancel}
                />
                <Button text="Сохранить" onClick={onSave} />
            </div>
        </div>
    );
};

ProjectTeamsContent.propTypes = {
    initProjectName: string,
    addProdPlan: bool,
    items: shape({}),
    teamId: number,
    closeModal: func,
    changeIsActiveFlag: func,
    successCallBack: func,
};
export default ProjectTeamsContent;
